.document-viewer {
  border: 1px solid $lightest-grey;
  border-radius: 0 0.5rem 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &--fullscreen {
    border-radius: 0.5rem 0.5rem 0 0;
  }

  &--fullscreen-esg {
    border-radius: 0.5rem 0.5rem 0 0;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1200;
    background-color: $white;
  }

  &--comparison {
    border-radius: 0;
    border: none;
  }

  &__title {
    padding: 0.6rem 1rem 0 1rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }

  &__metrics {
    display: flex;
    font-size: 14px;
    padding: 0 1rem 0.6rem 2.75rem;
    border-bottom: 1px solid $lightest-grey;
    color: $dark-grey;

    &__link {
      color: black;
      padding: 0 1rem 0 0.3rem;
      display: flex;
    }

    &__icon {
      cursor: pointer;
      &:hover {
        background-color: $light-grey;
        transition: 0.7s;
        border-radius: 4px;
      }
    }
  }

  &__company {
    border-bottom: 1px solid $lightest-grey;
    padding: 0.6rem 1rem;
    font-size: 0.875rem;
    display: flex;
  }

  &__document-name {
    flex-grow: 1;
    margin-left: 0.75rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 1;
    width: 1px;

    &-hidden {
      visibility: hidden;
    }
  }
  &__showall-tags {
    margin-right: 0.5rem;
    width: 2.5rem;
    height: 1.5rem;
    background: #97999b !important;
    border-radius: 6.25rem !important;
    cursor: inherit !important;
    transition: .3s;
    &__toggle-on {
      background: #007cb0 !important;
    }
  }

  &__toggle-circle {
    position: absolute;
    width: 1rem;
    height: 1rem;
    left: 0.25rem;
    top: 0.25rem;
    border-radius: 6.25rem;
    background: #ffffff;
    transition: 0.3s;
    &-on {
      left: 1.25rem;
    }
  }

  &__company-title {
    flex-grow: 1;
    display: flex;
  }

  &__company-name {
    flex-grow: 1;
    margin-left: 0.75rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 1;
    width: 1px;
    display: flex;
    flex-direction: column;

    &-hidden {
      visibility: hidden;

      .document-viewer__word-score {
        visibility: visible;
      }
    }
  }

  &__word-score {
    color: $grey;
    font-size: 0.75rem;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__halfpreview {
    margin-left: 1rem;
    position: relative;
    @include invisible-button;
    &:last-child {
      padding-left: 1rem;
      border-left: 1px solid $light-grey;
    }
    .tooltip {
      left: -4rem;
      top: 0;
      z-index: 2;
      display: none;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__externalTab {
    margin-left: 1rem;
    position: relative;
    @include invisible-button;
    &:last-child {
      padding-left: 1rem;
      border-left: 1px solid $light-grey;
    }
    .tooltip {
      left: -5.5rem;
      top: 0;
      z-index: 2;
      display: none;
    }
    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__rotateExternalTab {
    margin-left: 1rem;
    position: relative;
    @include invisible-button;
    &:last-child {
      padding-left: 1rem;
      border-left: 1px solid $light-grey;
    }
    .tooltip {
      left: -3.5rem;
      top: 0;
      z-index: 2;
      display: none;
    }
    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__compareExternalTab {
    margin-left: 1rem;
    position: relative;
    @include invisible-button;
    &:last-child {
      padding-left: 1rem;
      border-left: 1px solid $light-grey;
    }
    .tooltip {
      left: -9rem;
      top: 0;
      z-index: 2;
      display: none;
    }
    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }
  &__action {
    margin-left: 0.5rem;
    position: relative;
    @include invisible-button;

    &:last-child {
      padding-left: 1rem;
      border-left: 1px solid $light-grey;

      .tooltip {
        right: 100%;
        left: unset;
      }
    }
    .tooltip {
      left: 100%;
      top: 0;
      z-index: 2;
      display: none;
    }

    &--left-tooltip {
      &:last-child {
        padding-left: 0;
        border-left: none;
      }
      .tooltip {
        right: 100%;
        left: unset;
      }
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
    &__showall-tags {
      display: flex;
      &--disabled {
        @include primary-button-disabled;
        cursor: default !important;
        padding: 0.5rem;
        background: none !important;
      }
    }
  }

  &__add {
    display: flex;
    color: $primary;
    align-items: center;
  }

  &__keywords {
    border-bottom: 1px solid $lightest-grey;
    padding: 1rem;
    min-height: 1.9rem;
    display: flex;
    align-items: center;
  }

  &__keyword-label {
    margin-left: 0.5rem;
    min-width: 8rem;
  }

  &__highlights {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  &__highlight {
    margin-left: 0.5rem;
    padding: 0.1rem 0.25rem;
    // Highlighting is now done dynamically based on colorCode sent along with keyword
    //background-color: $yellow;
    cursor: pointer;
  }

  &__controls {
    display: flex;
    align-items: center;
  }

  &__document {
    display: flex;
    overflow: auto;
    flex: 1;
    position: relative;
    background-color: $lightest-grey;
    justify-content: center;
    user-select: none;
  }

  &__pages {
    position: absolute;
    left: 0;
    right: 0;
  }

  &__html {
    display: flex;
    overflow: auto;
    flex: 1;
    position: relative;
    justify-content: center;
  }

  &__html-content {
    position: absolute;
    left: 0;
    right: 0;
    padding: 1.5rem;
  }

  &__page-container {
    margin: 0 auto;
    padding: 2rem;
  }

  &__rightArrow {
    right: 0rem;
    margin-top: 29rem;
    position: fixed;
    z-index: 5;
    @include invisible-button;

    .tooltip {
      left: -5rem;
      top: 0.85rem;
      z-index: 2;
      display: none;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
      background-color: $lightest-grey;
      border-radius: 1.5rem;
    }
  }

  &__leftArrow {
    margin-top: 29rem;
    left: 0;
    position: fixed;
    z-index: 5;
    @include invisible-button;

    .tooltip {
      left: 3rem;
      top: 0.85rem;
      z-index: 2;
      display: none;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
      background-color: $lightest-grey;
      border-radius: 1.5rem;
    }
  }

  &__testLeft {
    margin-right: 20px;
  }
  &__test {
    margin-left: 20px;
  }

  &__page {
    background-color: $white;
    box-shadow: 0px 0px 10px 0px rgba($black, 0.3);
    position: relative;

    &--loading {
      animation: shine 1000ms infinite ease-in-out;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    user-select: none;
    cursor: text;
    outline: none;

    &__user-icon {
      background: $black;
      border-radius: 100%;
      width: 1.5rem;
      height: 1.5rem;
      min-width: 1.5rem;
      min-height: 1.5rem;
      font-style: normal;
      font-weight: 600;
      font-size: 0.6rem;
      line-height: unset !important;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }
    &-user-icon--tooltip {
      position: relative;
      .tooltip {
        z-index: 10;
        display: none;
        line-height: 1rem;
        left: 0rem;
  
        .tooltip__label {
          overflow: visible;
          min-width: 6rem;
          max-width: 50rem;
          max-height: 10rem;
          transform: translateY(-70%);
          position: absolute;
        }
      }
  
      &:hover {
        .tooltip {
          display: inline-block;
        }
      }
    }
    &__more-users-icon {
      background-color: $light-grey;
      color: $black;
  
      .tooltip {
        display:  block;;
        left: -100%;
        top: 0;
        padding-top: 1.75rem;
     
        .tooltip__label {
          padding: 0.5rem;
          overflow: auto;
          max-width: 35rem;
          max-height: 15rem;
          min-width: 15rem;
          background: $white;
          color: $black;
          border: 1px solid $light-grey;
          flex-direction: column;
          display: flex;
          gap: 0.5rem;
          > span {
            display: flex;
            gap: 0.5rem;
          }
        }
      }
  
      &:hover .tooltip {
        display: block;
      }
    }

    &__user-name{
      &:hover{
        cursor: pointer;;
      }
    }
  }

  &__overlay-menu {
    position: absolute;
    padding: 0.25rem;
    box-shadow: 0 1px 4px 0 rgba($black, 0.12);
    border: 1px solid $light-grey;
    background-color: $white;
    font-size: 0.875rem;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    max-width: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  &__overlay-action {
    padding: 0.5rem !important;
    color: $primary;
    width: 100%;
    path {
      fill: $primary;
    }
  }
  &__overlay-separator {
    height: 1.5rem;
    width: 1px;
    background-color: $light-grey;
  }

  &__breadcrumbs {
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    color: $grey;

    span {
      color: $black;
      cursor: pointer;
    }
  }
  &__shorlits-note-button {
    @include primary-button-outline;
    padding: 0;
    margin-left: 0.5rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    border: none;
    background-color: transparent;
  }
  &__comparison {
    display: flex;
    align-items: center;
  }

  // Page selector

  &__checkbox {
    padding-top: 1rem;
    font-size: 0.875rem;
    font-weight: 400;
    display: flex;

    input {
      margin-right: 0.5rem;
    }
  }

  &__options {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    gap: 0.5rem;
  }

  &__option {
    @include primary-button-outline;
  }

  &__option-apply {
    @include primary-button;
    margin-left: 1rem;

    &--disabled {
      @include primary-button-disabled;
      cursor: none;
    }
  }
}

.highlight {
  background-color: $yellow;
}

.highlight-none {
  background: rgba($black, 0) !important;
}


