.waterIntensityChart {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: inherit;
    width: inherit;
  }
  &__container {
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 1rem;
  }

  &__company {
    &-row {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      column-gap: 0.5rem;
      &--space-between {
        justify-content: space-between;
      }

      &--grid-content {
        display: grid;
        // grid-template-columns: 39% 61%;
        grid-template-columns: 30% 70%;
        grid-gap: 1rem 0.33rem;
        grid-auto-flow: row;
        word-wrap: break-word;

        @media screen and (min-width: 1500px) {
          display: grid;
          //grid-template-columns: 50% 50%;
          grid-template-columns: 38% 70%;

          grid-gap: 1rem 0.5rem;
          grid-auto-flow: row;
        }

        @media screen and (min-width: 1650px) {
          display: grid;
          grid-template-columns: 50% 50%;
          grid-gap: 1rem 1rem;
          grid-auto-flow: row;
        }
      }
    }
    &-name {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: $neutral-black;
      min-width: 4.5rem;
    }
  }
  &__value {
    font-style: normal;
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 3.5rem;
    color: $dark-blue;
    text-align: left;
    position: relative;

    &-- {
      font-size: 2rem;
      line-height: 2rem;
    }

    &--small {
      font-size: 1.75rem;
    }

    &--small2 {
      font-size: 1.5rem !important;
    }

    &--small3 {
      font-size: 1.15rem !important;
    }

    &--black {
      color: $neutral-black;
    }

    &--number-2 {
      font-size: 2rem;
      line-height: 2rem;
      color: $dark-blue;
    }

    &--number-1-5 {
      font-size: 1.5rem;
    }
  }

  &__unit {
    margin-left: 0.3rem;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: $neutral-black;
  }

  &__empty-values {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding-top: 2rem;
  }
}
