.climateRelatedDisclosuresAndMetrics {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    &--table {
      gap: 1.5rem;
    }
  }

  &__table-metric {
    text-align: left;
    font-size: 12px;
  }

  &__company {
    &-data-container {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }
    &-name {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: $dark-blue;

      &--table {
        font-size: 14px;
        line-height: 16px;
        color: $neutral-black;
      }
    }
  }
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }

  &__group {
    &-wrapper {
      display: flex;
      flex-direction: column;
      width: calc((100% - 3rem) / 4);
      row-gap: 0.5rem;

      &--table {
        row-gap: unset;
        width: calc((100% - 4.5rem) / 4);
      }
    }
    &-table {
      width: 100%;
      border-spacing: 0.5rem 0;
      td {
        padding: 1rem 0;
        border-bottom: 1px dashed #e0e0e0;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        color: $neutral-black;
      }
      tr:nth-child(1) {
        td {
          border-top: 1px dashed #e0e0e0;
        }
      }
      tr td:nth-child(1) {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }

    &-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem 0.5rem;
      row-gap: 1rem;
      border-radius: 8px;
      background: linear-gradient(
        180deg,
        #fff 0%,
        rgba(255, 255, 255, 0.4) 100%
      );
      flex-grow: 1;
      justify-content: center;
    }

    &-title {
      width: 100%;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    &-metric {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-text {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: $dark-grey;
        text-align: center;
      }
      &-value {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: $dark-blue;
        text-align: center;

        &--gray {
          color: $dark-grey;
        }
      }
    }
  }

  &__no-data-section {
    margin-top: 2rem;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
}
