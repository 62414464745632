.targetsTileChart {
  display: flex;
  flex-direction: column;

  &__single {
    &-block {
      display: flex;
      flex-wrap: wrap;
      column-gap: 1rem;
      row-gap: 2.5rem;
    }
    &-metric {
      &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(((100% - 2rem) / 3) - 1rem);
        padding: 1rem 0.5rem;
      }

      &--transparent {
        row-gap: 0.5rem;
      }
      &--white {
        background: linear-gradient(
          180deg,
          #ffffff 0%,
          rgba(255, 255, 255, 0.4) 100%
        );
        border-radius: 8px;
        padding: 1rem 0.5rem;
        row-gap: 1rem;
        width: calc(((100% - 2rem) / 3) - 1rem);
      }

      &-wrapper {
        min-height: 2rem;
      }

      &--flex {
        flex: auto;
        width: 18%;
      }

      &-lbl {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #046a38;
        text-align: center;
      }
      &-value {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: $neutral-black;
        text-align: center;

        &--scope-max {
          max-height: 3rem;
          max-width: 100%;
        }
      }
      &-year {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: $neutral-black;
        text-align: center;
        &-string {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }

  &__multiple {
    &-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      &--single-detailed {
        row-gap: 1.5rem;
      }
    }

    &-block {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }
    &-subtitle {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      color: $neutral-black;
    }
    &-row {
      display: flex;
      column-gap: 0.5rem;
    }

    &-peer {
      &-metric {
        background: linear-gradient(
          180deg,
          #ffffff 0%,
          rgba(255, 255, 255, 0.4) 100%
        );
        border-radius: 8px;
        padding: 1rem;
        row-gap: 1rem;

        &-box {
          background: linear-gradient(
            180deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0.4) 100%
          );
          border-radius: 8px;
          padding: 1rem;
          width: calc(50% - 40px);

          &--100 {
            width: 100%;
          }
          &--third {
            width: calc((100% - 128px) / 3);
          }
          &--third-detailed {
            width: calc((100% - 144px) / 3);
          }
        }
        &-subtitle {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #046a38;
          padding-bottom: 1rem;
        }
      }
      &-text {
        font-style: normal;
        word-wrap: break-word;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $neutral-black;
        &--bold {
          font-weight: 600;
          line-height: 24px;
          font-size: 14px;
        }
        &--47 {
          width: calc(47%);
        }
        &--53 {
          width: calc(53% - 8px);
        }
        &--fz-big {
          font-weight: 600;
          line-height: 24px;
          font-size: 14px;
        }
        &--align-right {
          text-align: right;
        }
        &--detailed {
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
        }
      }
      &-container {
        display: flex;
        column-gap: 1rem;
        row-gap: 1.5rem;
        flex-wrap: wrap;
      }

      &-group {
        display: flex;
        column-gap: 2.2rem;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        align-items: center;
        &--column {
          flex-direction: column;
          align-items: start;
          row-gap: 0.5rem;
        }
      }
    }

    &-detailed {
      &-group {
        height: 3rem;
        padding-bottom: 1rem;
      }
      &-metric {
        &-box {
          display: flex;
          flex-direction: column;
          width: calc(100% - 16px);

          &-wrapper {
            display: flex;
            column-gap: 1rem;
          }
        }
      }
    }
  }
  &__no-data-available {
    margin-top: 1rem;
    font-size: 12px;
  }

  &__table {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.87);

    & thead th {
      font-weight: 400;
      text-align: left;
      width: 34%;
      padding: 0.75rem 0.25rem;
      &:nth-child(1) {
        width: 30%;
        padding: 0.75rem 0.25rem 0.75rem 0;
      }
      &:last-child {
        padding: 0.75rem 0 0.75rem 0.25rem;
      }
    }
    & tbody td:nth-child(1) {
      font-weight: 400;
    }
    & tbody td {
      text-align: left;
      font-weight: 600;
      padding: 0.75rem 0.25rem;
      width: 34%;
      position: relative;

      &-scope-max {
        max-height: 2rem;
      }
      &:nth-child(1) {
        width: 30%;
        padding: 0.75rem 0.25rem 0.75rem 0;
      }

      &:last-child {
        padding: 0.75rem 0 0.75rem 0.25rem;
      }
    }
    &--detailed {
      & thead th {
        font-weight: 400;
        text-align: left;
        width: 12%;
        &:nth-child(1) {
          width: 18%;
        }
        &:nth-child(2),
        &:nth-child(3) {
          width: 22%;
        }
      }

      & tbody td {
        text-align: left;
        font-weight: 600;
        flex-grow: 1;
        width: 12%;
        font-size: 14px;
        padding: 0.75rem 0.25rem;
        &:nth-child(1) {
          width: 18%;
        }
        &:nth-child(2),
        &:nth-child(3) {
          width: 22%;
        }
      }
    }
  }

  &__table-view {
    &-target {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      &-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
      }

      &-subtitle {
        background: #f5f5f4;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 1rem;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: $neutral-black;
      }
    }

    &--no-dashed-line {
      & tr:nth-child(odd) td {
        border-bottom: unset;
      }
    }
  }

  &__scope-metric-ellipsis {
    position: relative;
    .tooltip {
      display: none;
    }
    &:hover {
      & .tooltip {
        display: flex;
        left: 0;
        top: 1.5rem;
      }
    }
  }

  &__scope-metric {
    white-space: initial;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;

    // .tooltip{
    //   transform: unset;
    //   display: none;
    //   left: 0;
    //   top: 2.5rem;
    //   max-width: 18rem;
    // }
    // &:hover{
    //   & .tooltip{
    //         display: flex;

    //       }
    // }
  }

  &__metric-container-popover {
    width: fit-content;
    display: inline-block;
  }
  &__metric-name-popover {
    &--left {
      text-align: left;
    }
  }
}
