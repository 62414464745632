.Compare {
  width: 25rem;
  min-height: 9rem;
  left: 760px;
  top: 410px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__name {
    font-size: 14px;
    line-height: 24px;
    margin-top: 15px;
    padding-left: 5%;
    color: $black;
    font-weight: 500;
    text-align: left;
  }

  &__input {
    width: 100%;
    height: auto;
    padding-top: 0.5rem;
    text-align: center;
    outline: none;

    & textarea {
      width: 90%;
      height: 4rem;
      padding: 0.6rem;
      color: #212121;
      border: 1px solid #d0d0ce;
      box-sizing: border-box;
      border-radius: 4px;
      outline: none;
      resize: none;
    }
  }

  &__content {
    text-align: left;
  }

  &__options {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  &__drop-down {
    margin-top: 1rem;
    position: relative;
    &:first-child {
      margin-top: 0;
    }
  }

  &__drop-down-label {
    display: flex;
    color: #212121;
  }
  &__contentError {
    padding-top: 0.25rem;
    font-size: 12px;
    color: #da291c;
    padding-left: 5%;
    &--saveSearchList {
      padding-left: 8.5%;
    }
  }
}
