.popover {
  position: relative;

  &__popup {
    position: fixed;
    width: 37.5rem;
    height: min-content;
    max-height: 33.75rem;
    background-color: $white;
    border: 1px solid $light-grey;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.12);
    border-radius: 0.5rem;
    z-index: 99999;
    visibility: hidden;
    &-header {
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      &-title {
        font-weight: 600;
        font-size: 14px;
        color: $neutral-black;
      }
    }
    &-tabs {
      border-top: 1px solid $light-grey;
    }
    &-body {
      border-top: 1px solid $light-grey;
      box-sizing: border-box;
      padding: 1rem 1rem 2.25rem 1rem;
      max-height: 21.875rem;
      max-width: 37.5rem;
      white-space: normal;
      overflow-wrap: break-word;
      text-align: left;
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 14px;
      overflow-y: auto;
      line-height: 1.5rem;
      color: $neutral-black;
      & a {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #007cb0;
      }
    }

    &-metric.popover__popup-metric {
      padding: 0.375rem 0.5rem;
      border-radius: 4px;
      white-space: unset;
      margin: -0.375rem -0.5rem;
      &-title {
        width: 100%;
        padding: 0;
        margin: 0;
      }
      &:hover {
        background-color: unset;
        cursor: default;
      }
    }

    &-metric--hover.popover__popup-metric--hover {
      &:hover {
        background-color: #f0f0f0;
        cursor: pointer;
      }
      &:focus {
        background-color: #f0f0f0;
      }
    }

    &-arrow {
      position: fixed;
      width: 28px;
      height: 24px;
      background-color: #ffffff;
      z-index: 100001;
      visibility: hidden;
      &-outline {
        visibility: hidden;
        background-color: #e0e0e0;
        position: fixed;
        z-index: 100000;
        width: 28px;
        height: 25px;
      }
    }
  }
}
