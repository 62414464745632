.Search {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 0.875rem;

  &__header {
    padding: 0 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: inset 0px -1px 0px $lightest-grey;
    position: fixed;
    z-index: 21;
    background-color: white;
    width: calc( 100% - 7.5rem);

    
    &-end-section {
      display: flex;
    }
  }

  &__start-new-section {
    min-width: 7rem;
  }

  &__start-new-button {
    @include invisible-button;
    display: flex;
    align-items: center;
    margin-left: 1.25rem;
    padding: 0.5rem 0.2rem;
    font-family: "Open Sans";
    color: $primary;
    &:hover {
      background-color: $light-grey;
      border-radius: 4px;
      transition: 0.7s;
      color: $secondary-blue;
    }
  }

  &__feedback-section {
    min-width: 1rem;
    display: flex;
    align-items: center;
  }

  &__results-actions {
    display: flex;
    gap: 1rem;
  }

  &__back-to-search {
    padding: 0.5rem 1.5rem;
    margin-right: 1rem;
    border: none;
    background: none;
    text-decoration: underline;
    font-family: "Open Sans";
    &:hover {
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    margin-top: 3rem;
  }

  /*
   * Filters
   */

  &__filter-side {
    display: flex;
    flex-direction: column;
    width: 1rem;
    padding: 1rem;
    background-color: $lightest-grey;
    cursor: pointer;
    position: relative;
    &:hover {
      background-color: $light-grey;
      transition: 0.7s;
    }
  }

  &__show-filters {
    margin-bottom: 0.5rem;
    @include invisible-button;
  }

  &__filter-span {
    font-weight: 600;

    &--hidden {
      transform: rotate(90deg);
    }
  }

  &__active-filters {
    margin-left: 0.5rem;
    color: $white;
    background-color: $red;
    font-size: 10px;
    border-radius: 1rem;
    padding: 0rem 0.3rem;

    &--hidden {
      position: absolute;
      top: 0.5rem;
    }
  }

  &__filter-options {
    display: flex;
    flex-direction: column;
    width: 22rem;
    padding: 1.5rem;
    background-color: $lightest-grey;
  }

  &__filter-header {
    display: flex;
    justify-content: space-between;
  }

  &__filter-content {
    display: flex;
    flex-direction: column;
    // flex-grow: 1;
  }

  &__filter-scroll {
    flex-grow: 1;
    margin-top: 1rem;
    margin-right: -1.5rem;
    padding-right: 1.5rem;
    align-items: baseline;
    position: relative;
    overflow-y: auto;
    max-height: 58vh;
    scroll-behavior: smooth;
  }

  &__hide-filters {
    @include invisible-button;
    &:hover {
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 4px;
    }
  }

  &__drop-down {
    margin-top: 1rem;
    position: relative;

    &:first-child {
      margin-top: 0;
    }
  }

  &__drop-down-label {
    display: flex;
  }

  &__drop-down-icon {
    margin-left: 0.25rem;
    min-width: 2.5rem;

    .tooltip {
      @include white-tooltip;
    }

    &:hover {
      svg {
        background-color: $light-grey;
        border-radius: 100%;
      }

      path {
        fill: $black;
      }

      .tooltip {
        display: inline-block;
      }
    }
  }

  &__filter-buttons-container {
    margin-top: 1.5rem;
    display: flex;
  }

  &__submit-button {
    @include font-family;
    background-color: $primary;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 1.5rem 0.5rem 1.25rem;
    border: none;
    border-radius: 0.25rem;
    color: $white;
    font-weight: 400;
    line-height: 1.5rem;
    font-size: 0.875rem;
  }

  &__filter-search-icon {
    padding-right: 0.25rem;

    & path {
      fill: $white;
    }
  }

  &__reset-button {
    @include invisible-button;
    display: flex;
    align-items: center;
    padding: 0.5rem 1.5rem 0.5rem 1.25rem;
    color: $primary;
    font-family: "Open Sans";
    &:hover {
      background-color: $light-grey;
      color: $secondary-blue;
      transition: 0.7s;
      border-radius: 4px;
      path {
        fill: $secondary-blue;
      }
    }
  }

  /*
   * Search Results
   */

  &__results-content {
    display: flex;
    width: 100%;
  }

  &__results-section {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    display: flex;

    &--filled {
      margin-top: 1rem;
      width: 100%;
      flex-direction: column;
    }
  }

  &__results {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 39rem;
  }

  &__empty-image {
    max-width: 13.5rem;
    margin-bottom: 1.188rem;
  }

  &__empty-title {
    font-weight: 600;
  }

  &__empty-message {
    font-size: 0.875rem;
    color: $grey;
  }

  &__link-button {
    @include invisible-button;
    color: $primary;
    text-decoration: underline;
    font-size: 0.75rem;
  }

  &__results-container {
    width: 100%;
    border: 1px solid $lightest-grey;
    box-shadow: 0 0.25rem 0.5rem rgba(238, 240, 241, 0.48);
    border-radius: 0rem 0.5rem 0.5rem 0.5rem;
    display: flex;
    flex-direction: column;

    &--show-document-viewer {
      width: 60%;
      border-radius: 0.5rem 0rem 0.5rem 0.5rem;

      .tabbed-content__item {
        padding: 0.875rem 1rem;
      }
    }
  }

  &__results-summary {
    border-bottom: 1px solid $secondary-light-grey;
    padding: 0.6rem 1rem;
    font-size: 1rem;
  }

  &__results-breadcrumb {
    font-size: 1rem;
    margin-right: 0.25rem;
  }

  &__report-breadcrumb {
    margin-left: 0.25rem;
    color: $grey;
  }

  &__tab-panel {
    flex-grow: 1;
    display: none;

    &--selected {
      display: flex;
    }
  }

  &__table {
    position: absolute;
  }

  &__table-wrapper {
    height: 26.25rem;
    overflow-y: auto;
    flex-grow: 1;
    position: relative;
  }

  &__total-results {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    margin-bottom: 1rem;
    align-items: center;
  }
  &__column-company-name {
    min-width: 9rem;
  }
  &__column-company {
    &--name {
      width: 18rem;
    }
    &--ticker {
      width: 3rem;
    }
    &--auditor {
      width: 5rem;
    }
    &--metrics {
      width: 2rem;
    }
  }

  &__column-document-type {
    width: 5%;
  }

  &__column-document-type-sec {
    width: 15%;
  }

  &__column-period-sec {
    width: 15%;
  }

  &__column-period {
    min-width: 6rem;
  }

  &__column-report-title {
    min-width: 13rem;
  }

  &__column-report-rating {
    width: 11%;
    :hover {
      text-decoration: underline;
    }
  }

  &__column-report-score {
    width: 15%;
    :hover {
      text-decoration: underline;
    }
  }

  &__column-icon {
    width: 1.5rem;
  }

  &__tabs {
    display: flex;

    .tabbed-content {
      box-shadow: none;
      margin-bottom: -1px;
      z-index: 1;

      &__item {
        border: 1px solid $lightest-grey;
        background-color: #f7f7f7;
        color: $grey;
        font-weight: 600;
        margin-right: 0.5rem;
        border-radius: 0.5rem 0.5rem 0 0;

        &--selected {
          border-bottom: none;
          background-color: $white;
          color: $black;
          padding-bottom: 0.75rem;
        }
      }
    }
  }

  &__compare-button {
    position: relative;
    font-family: "Open Sans";
    .tooltip {
      left: -6.5rem;
      top: -1.5rem;
      z-index: 25;
      display: none;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__period-years-cell {
    display: flex;
    align-items: center;
    &-text {
      position: relative !important;
      .tooltip {
        display: none !important;
        top: -1.3rem;
        left: 0;

        &__arrow {
          bottom: -1.8rem;
          left: 1rem;
        }
        &__label {
          bottom: -1.5rem;
          position: absolute;
          max-width: 4rem;
          left: 1rem;
        }
      }
      &:hover {
        .tooltip {
          display: inline-block !important;
        }
      }
    }
    &-btn {
      background-color: transparent;
      border: none;
      height: fit-content;
      font-size: 14px;
      font-family: "Open Sans";
      cursor: pointer;
      &:hover {
        background: #e0e0e0;
        border-radius: 15px;
      }
    }
  }

  &__upload {
    &-btn {
      background-color: transparent;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $primary;
      border: none;
      cursor: pointer;
      padding: unset;
      margin: 0 0.25rem;
      &:hover {
        text-decoration: underline;
      }
    }
    &-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #75787b;
      padding: 1.5rem 0;
      width: 100%;
      background-color: #f7f7f7;
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 8px;
    }
    &-msg-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.5rem 1rem;
    }
  }

  &__suggested-peers-modal {
    width: 100%;
  }
  &__suggested-peers {
    &-section {
      display: flex;
      margin-top: 10px;
    }
    &__suggested-peers-modal {
      width: 100%;
    }

    &-btn {
      font-family: "Open Sans";
      cursor: pointer;
      display: flex;
      column-gap: 0.25rem;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $primary;
      border: none;
      background-color: transparent;
      padding: 0 0.5rem;
      column-gap: 0.25rem;
      &:hover {
        color: $secondary-blue;
        background-color: $light-grey;
        transition: 0.7s;
        border-radius: 4px;
      }
      &--disabled {
        color: $dark-grey;
        & svg path{
          fill: $dark-grey;
        }
        &:hover {
          color: $dark-grey;
          background-color: transparent;
        }
      }
    }
  }

  &__actions {
    column-gap: 1rem;
    justify-content: flex-end;
  }

  &__action-icon {
    @include invisible-button;
    position: relative;
    cursor: pointer;
    .tooltip {
      display: none;
      top: -2rem;
      bottom: unset;
      left: -100%;
      &--top {
        &__arrow {
          left: 45%;
        }
      }
    }

    &:hover {
      .tooltip {
        display: inline-block;
        top: -2rem;
        bottom: unset;
        &--top {
          &__arrow {
            left: 45%;
          }
        }
      }
    }
  }

  &__actions {
    column-gap: 1rem;
    justify-content: flex-end;
  }

  &__action-icon {
    @include invisible-button;
    position: relative;
    cursor: pointer;

    .tooltip {
      display: none;
      top: -2rem;
      bottom: unset;

      &--top {
        &__arrow {
          left: 45%;
        }
      }
    }
  }

  &__options {
    margin-top: 1rem;
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
  }

  &__tabbed-content {
    column-gap: 0.5rem;
  }

  &__tab {
    &-item {
      padding-left: 0;
      &--padding-top {
        padding-top: 0;
      }
    }

    &-vertical-line {
      border-left: 1px solid #c2c2c2;
      width: 0;
      margin-right: 1.5rem;
    }
  }

  &__peer-group-empty {
    display: flex;
    padding: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    justify-content: center;
  }
}
