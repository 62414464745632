.detailed-score-table{
    &__table {
        &--left {
          width: 100%;
          td.data {
            text-align: left;
          }
        }
      }
    &__Detailrow {
      font-weight: 600;
      font-size: 14px;
      td {
        width: 20%;
      }
    }
  
    &__label {
      font-size: 0.75rem;
      position: relative;
  
      .tooltip {
        display: none;
        z-index: 1;
      }
  
      &:hover {
        .tooltip {
          display: block;
        }
      }
      
      &-button {
        font-size: 12px;
        line-height: unset;
      }

      &--left {
        width: fit-content;
        display: inline-block;
      }
    }
  
    &__data {
      text-align: right;
      font-weight: 600;
      font-size: 0.75rem;
      position: relative;
  
      .tooltip {
        position: absolute;
        transform: translateX(-50%);
        display: none;
      }
  
      &:hover {
        .tooltip {
          display: block;
        }
      }
    }
  
    &__dataProgressBar {
      align-content: left;
      text-align: left;
      font-size: 1.5rem;
    }
  
    &__no-data {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.785rem;
      font-weight: bold;
      flex-grow: 1;
      padding: 1rem;
    }
  
  }