.benchmark-grid-row {
  &__rowItem {
    position: relative;
    height: fit-content;
    box-sizing: border-box;
    background: #ffffff;
    width: 100%;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    color: #212121;
    
    td {
      padding: 0.5rem;
      background: $white;
      box-shadow: inset -1px -1px 0px $darker-grey;

      &:last-child {
        box-shadow: none;
      }
    }

    &--box {
      max-height: fit-content;
      align-items: center;
      overflow-wrap: break-word;
      text-align: center;
      width: fit-content;
      margin: 0 auto;

      &--value {
        position: relative;
      }
    }

    &--remove-button {
      @include invisible-button;
      position: absolute;
      left: 0.5rem;
      padding-top: 0.25rem;
      visibility: hidden;
      &:hover {
        background-color: $light-grey;
        border-radius: 4px;
      }
    }

    &--icon {
      background: transparent;
      position: relative;

      .tooltip {
        display: none;
        left: 100%;
      }

      &:hover {
        .tooltip {
          display: block;
        }
      }
    }

    &--center {
      margin: 0 auto;
    }

    &--company {
      box-shadow: inset -1px 0px 0px #ebebeb;

      &:hover {
        .benchmark-grid-row__rowItem--remove-button {
          visibility: visible;
        }
      }

      &--items {
        display: flex;
        flex-direction: column;

        &--name {
          max-width: 15rem;
          display: flex;
        }
        &--date {
          position: relative;
          font-style: italic;
          font-size: 0.75rem;
        }

        &--icon {
          padding-left: 1rem;
        }
      }
    }

    &--transpose {
      &:nth-child(1) {
        padding-left: 0.7rem !important;
      }

      &-remove-button {
        @include invisible-button;
        visibility: hidden;
        &:hover {
          background-color: $light-grey;
          border-radius: 4px;
        }
      }

      &:hover {
        .benchmark-grid-row__rowItem--transpose-remove-button {
          visibility: visible;
        }
      }

      &-metric-title {
        display: flex;
        gap: 0.2rem;
        align-items: center;
      }
    }
  }

  &__icon {
    margin: 0 auto;
    svg {
      max-width: 1.5rem;
      max-height: 1.5rem;
    }
  }

  &__info-icon {
    position: absolute;
    display: inline-flex;
    vertical-align: middle;
    cursor: default;
    right: -1.5rem;
    top: 50%;
    transform: translateY(-50%);
    &-tooltip {
      .tooltip__label {
        width: max-content;
        max-width: 18rem;
        text-align: left;
        color: $black;
        background: $white;
        border: 1px solid $light-grey;
        font-weight: bold;
      }

      .tooltip__arrow {
        display: none;
      }
    }

    .tooltip {
      display: none;
      left: 100%;
      bottom: 0;
      top: unset;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }

    &--bottom {
      .tooltip {
        top: 3.5rem;
        border: unset;
        right: -2rem;
        left: unset;
        max-width: unset;
      }
    }
    &--transpose {
      position: relative;
      right: unset;
      top: unset;
      transform: unset;
      .tooltip__label {
        max-width: 45rem;
      }
    }
  }
  &__topics{
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  &__multipleItem {
    display: flex;
    flex-direction: column;
    min-height: 2rem;
    max-height: fit-content;
    row-gap: 0.5rem;
    height: 100%;

    &__item {
      padding: 0rem 0.5rem;
      width: fit-content;
      font-size: 0.75rem;
      line-height: initial;
    }

    &__see-more {
      cursor: pointer;
      position: relative;
      width: fit-content;

      .tooltip {
        left: 2em;
        z-index: 10;
        bottom: 0;
        max-width: 15rem;
        display: none;
        top: -0.5em;
      }

      .tooltip__arrow {
        top: 85%;
      }

      &:hover .tooltip {
        display: block;
      }

      &--left {
        .tooltip {
          left: initial;
          right: 3em;
          top: -0.5em;
        }
      }
    }

    &__more-values {
      display: flex;
      flex-direction: column;
      text-align: center;
      &--list {
        padding-left: 1rem;
        text-align: left;
        margin: 0;
      }
    }

    &--center {
      align-items: center;
      justify-content: center;
    }
  }

  &__row-resize-handler {
    user-select: none;
    left: 0;
    bottom: 0;
    position: absolute;
    height: 0.25rem;
    width: 100%;
    z-index: 30;
    &:hover {
      cursor: row-resize;
    }
  }
}
