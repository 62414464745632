.Header {
  min-height: 3rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 500;
  .icon {
    margin-left: 3rem;
  }
}

.App-Name {
  display: flex;
  align-items: center;
  background-color: #e9e9e9;
  height: 2rem;
  position: fixed;
  margin-top: 4rem;
  width: 100%;
  z-index: 500;
  
  .icon {
    margin-left: 1rem;
    margin-right: 0.5rem;
  }
}

// DA header styles
.hp-search-wrapper header-search {
  display: flex !important;
}

.menu-panel__header .menu-line.application-line {
  width: auto !important;
  flex-grow: 1 !important;
}

.invalid-feedback {
  top: 100%;
}
