.targetTilesContent {
    &__container {
        width: 100%;
    
        display: flex;
        justify-content: center;
        &--col {
          flex-direction: column;
        }
        &--big-top {
          margin-top: 0.9375rem;
        }
        &--small-top {
          margin-top: 0.5rem;
        }
    
        &--medium-top {
          margin-top: 0.5rem;
        }
    
        &--no-data {
          align-items: center;
        }
      } 

      &__name {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        min-width: 30%;
        max-width: 30%;
      }
      
      &__value {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #212121;
        text-align: left;
        position: relative;
    
        &-wrapper {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }

        &--number {
            padding: 0 1.5rem;
            font-size: 40px;
            line-height: 48px;
        }
    
        &-tooltip {
          display: flex;
          top: -110%;
        }
    
        &--special {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }
        &--center {
          text-align: center;
        }
    
        &--small {
          font-size: 12px;
          line-height: 16px;
        }
        &--black {
          color: $neutral-black;
        }
    
        &--gray {
          color: $dark-grey;
        }
    
        &--fix-wdith {
          min-width: 60%;
          max-width: 60%;
          flex-grow: 1;
        }
        &--light-font {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        }
    
        &--tall-font {
          line-height: 32px;
          font-weight: 600;
          font-size: 14px;
        }
    
        &--grow {
          flex-grow: 1;
        }
    
        &--number {
          font-size: 40px;
          line-height: 48px;
        }
    
        &--right {
          text-align: right;
        }

        &--text-reporting {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            /* or 120% */

            display: flex;
            align-items: center;
            text-align: center;
            color: $neutral-black;
        }

        &--text-baseYear {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            /* or 120% */

            display: flex;
            align-items: center;
            text-align: center;
            color: $neutral-black;
        }
    
        &--number-2 {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 32px;
          color: $dark-blue;
        }
    
        &--number-3 {
          font-size: 24px;
        }
    }

    &__row {
      display: flex;
      column-gap: 1.625rem;
      align-items: center;
      justify-content: space-between;
      &--dashed-border {
        border-width: 0 0 1px 0;
        border-style: dashed;
        border-color: #e6e6e6;
        padding: 1rem 0.5rem;
      }
      &--dashed-top-border {
        border-width: 1px 0;
      }
      &--table {
        justify-content: space-between;
      }
    }
    &__rows-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem;
      width: 100%;
  
      &--no-row-gap {
        row-gap: unset;
      }
      &--min-row-gap {
        row-gap: 0.25rem;
      }
    }

    &__no-data {
        font-size: 1rem;
        font-weight: 700;
      }
}