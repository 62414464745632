.biodiversityMangament {
  &__container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
  }

  &__DetailedMetric {
    display: flex;
    flex-direction: column;
    background: linear-gradient(
      181deg,
      #eef5f8 0.64%,
      rgba(238, 245, 248, 0) 135.65%
    );
    padding-top: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.5rem;
    width: calc(((100% - 2.5rem) / 5));
  }

  &__Detailed-container {
    display: flex;
    gap: 1rem;
  }

  &__DetailedMetricName {
    display: flex;
    align-items: center;
    vertical-align: top;
  }
  &__detailedCompanyName {
    color: var(--neutral-black-87, #212121);
    font-family: Open Sans;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    width: 55%;
    word-break: break-word;
  }

  &__detailedCompanyMetricValue {
    color: var(--secondary-blue-6, #012169);
    font-family: Open Sans;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
    width: 45%;
    overflow-wrap: break-word;

    &--no {
      color: var(--neutral-black-54, #757575);
      font-family: Open Sans;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1rem;
    }
  }

  &__detailedMetricBorder {
    border-bottom: 1px dashed #a3cee0;
    text-align: left;
    width: fit-content;
    min-height: 5.8rem;
    display: inline-flex;
    align-items: center;
  }

  &__DetailedCompanies {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  &__detailedCompanyData {
    display: flex;
    padding-left: 0.2rem;
    gap: 0.5rem;
  }

  &__detailedMetricName {
    font-family: Open Sans;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem; /* 133.333% */
    color: $neutral-black;
  }

  &__graph-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__no-data-section {
    margin-top: 2rem;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  &__metric {
    &-wrapper {
      display: flex;
      flex-direction: column;
      width: calc(((100% - 2.5rem) / 3));
      border-radius: 8px;
      border: 1px solid var(--additional-colors-blue-3-border, #e2eff4);
      background: linear-gradient(
        181deg,
        #eef5f8 0.64%,
        rgba(238, 245, 248, 0) 135.65%
      );
      &--2-width {
        width: calc(((100% - 1.25rem) / 2));
      }
    }
    &-box {
      padding: 1rem;
      display: flex;
      align-items: center;

      &--border-bottom {
        border-bottom: 1px dashed #a3cee0;
      }

      &--y-padding {
        padding: 1.25rem 1rem;
      }

      &--min-height {
        min-height: 4rem;
      }
      &--for-innerContent {
        padding: 0.5rem 1rem;
        flex-direction: column;
        row-gap: 1rem;
      }
    }
    &-name {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: $neutral-black;
    }
    &-value {
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      color: #012169;
      &--no {
        color: $dark-grey;
      }
    }
  }

  &__popover-container {
    text-align: left;
    width: fit-content;
    display: inline-block;
  }

  &__innerContent-table {
    width: 100%;
    td,
    th {
      text-align: left;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      width: 50%;
      word-break: break-word;
    }
    td:nth-child(1),
    th:nth-child(1) {
      padding: 0.5rem 1.5rem 0.5rem 0;
    }
    td:nth-child(2),
    th:nth-child(2) {
      padding: 0.5rem 0 0.5rem 1.5rem;
    }

    th {
      font-weight: 400;
      color: $neutral-black;
    }
  }

  &__table-chart {
    & .tableChart__table-header-label {
      font-weight: 600;
    }
  }
}
