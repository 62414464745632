.msciESGRating{
    &__container{
        display: flex;
        width: 100%;
        flex-direction: column;
    }
        &__header__text__date {
        color: "#757575";
        opacity: 0.8;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: "center";
        margin-bottom: 1rem;
      }


      &__table{
        & tbody td{
            vertical-align: top;
        }
      }

      &__data{
        display: inline-grid;
      }
      &__item{
        display: block;
      }
}