.shared-dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
  &__selected {
    &:hover {
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 4px;
    }
    cursor: pointer;
    align-items: center;
    column-gap: 0.5rem;
    display: flex;
  }

  &__options {
    background-color: $white;
    z-index: 2;
    position: absolute;
    min-width: 20rem;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.16);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    top: 2.5rem;
  }
  &__role {
    min-width: 15rem;
    position: fixed;
  }
  &-dropdown-quater {
    padding-left: 0.2rem;
    span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.5rem;
    }
    .shared-dropdown__dropdown-quater {
      right: 0;
      min-width: 8rem;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.5rem;
    }
  }
  &__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid $lightest-grey;
    cursor: pointer;

    &--selected {
      background-color: $lightest-blue;
    }

    &:hover {
      background-color: $lightest-grey;
    }

    &:first-child {
      padding-top: 8px;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 8px;
    }
  }
}
.users__dropdown {
  min-width: 8rem;
  justify-content: flex-end;
  flex-direction: row;
}
