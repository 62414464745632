.DashboardTile {
  border: 1px solid $lightest-grey;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem 0 $lightest-grey;
  display: flex;
  flex-direction: column;
  min-height: 10rem;
  margin-bottom: 1rem;

  &__tile-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__main-container {
    display: flex;
    flex-grow: 1;
  }

  &--top-lighting {
    background-image: url("../shared/icon/icons/bottom-ray.svg");
    background-position: 71% 0;
    background-repeat: no-repeat;
  }

  &--double-lighting {
    background-image: url("../shared/icon/icons/small-ray.svg"),
      url("../shared/icon/icons/top-ray.svg");
    background-position: 34.93% 0, 24.6% 100%;
    background-repeat: no-repeat, no-repeat;
  }

  &--double-lighting-2 {
    background-image: url("../shared/icon/icons/lighting-3.svg"),
      url("../shared/icon/icons/lighting-2.svg");
    background-position: 15.5% 0, 74.9% 100%;
    background-repeat: no-repeat, no-repeat;
  }

  &--double-lighting-3 {
    background-image: url("../shared/icon/icons/lighting-1.svg"),
      url("../shared/icon/icons/lighting-4.svg");
    background-position: 26.94% 0, 38.38% 100%;
    background-repeat: no-repeat, no-repeat;
  }
  .tooltip {
    a,
    a:visited {
      color: $white;
      font-weight: 600;
    }
    &__label {
      padding: 0.2rem 0.5rem 0.5rem;
    }
  }

  &__tooltip {
    max-height: unset;
    max-width: 50rem;
    a,
    a:visited {
      color: $white;
    }
  }

  &__chart-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  &__container {
    &--expand {
      height: 100%;

      &-no-data {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    }

    &--export-loading {
      .TabularTile__metric-value-wrapper {
        background-image: none !important;
      }
    }
  }

  &--wave-bg {
    background: rgba(241, 248, 251, 1);
    background-image: url("../shared/icon/icons/wave.svg"),
      url("../shared/icon/icons/wave-2.svg");
    background-position: 100% 0, 0 100%;
    background-repeat: no-repeat, no-repeat;
  }

  &--horizontal {
    @media screen and (max-width: 1400px) {
      grid-column-end: span 3 !important;
    }
    @media screen and (max-width: 1200px) {
      grid-column-end: span 2 !important;
    }
  }

  &--show-info {
    background: rgba(241, 248, 251, 1);
    border: 1px dashed #75cdee;
  }

  &__tile-actions {
    display: flex;
  }

  &__scoreContainer {
    width: 100%;
    flex-grow: initial;
  }
  &__scoreWrapper {
    border-left: 1px dashed rgba(0, 0, 0, 0.12);
    border-bottom: 1px dashed rgba(0, 0, 0, 0.12);
    border-top: 1px dashed rgba(0, 0, 0, 0.12);
    padding-bottom: 1rem;
    .DashboardTile__scoreDetail,
    .DashboardTile__parentdiv {
      margin-left: 0 !important;
    }
  }
  &__GhgIntensityScoreWrapper {
    padding-bottom: 1rem;
    .DashboardTile__scoreDetail,
    .DashboardTile__parentdiv {
      margin-left: 0 !important;
    }
  }
  &__scoreRange {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 0.5rem;
    justify-content: space-between;
    span {
      font-size: 0.75rem;
      line-height: 1rem;
      color: #757575;
    }
  }
  &__scoreMinMax {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 1px;
    justify-content: space-between;
    span {
      font-size: 10px;
      font-weight: 600;
      line-height: 1rem;
      color: #012169;
    }
  }
  &__scoreNoData {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 1rem;
    justify-content: space-between;
    gap: 1rem;
    span {
      flex:1;
      font-size: 10px;
      font-weight: 600;
      line-height: 1rem;
      color: #012169;
    }
    &-nonNumeric {
      font-size: 10px;
      font-weight: 600;
      line-height: 1rem;
      color: #757575 !important;
    }
  }
  &__legend {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      min-width: 1rem;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1rem;
      color: #757575;
      text-align: left;
      padding-left: 0.5rem;
    }
    &__metric {
      display: flex;
      align-items: center;
      &:last-of-type {
        padding-left: 1rem;
      }
    }
    &__bar {
      min-width: 1.5rem;
      padding-right: 0.5rem;
    }
    &__dot {
      width: 8px;
      height: 8px;
      background: #00a3e0;
      box-shadow: 0px 0px 0px 1px #005587, inset 0px 0px 6px 3px #0076a8;
      border-radius: 51px;
    }
  }

  &__scoreDetail {
    display: flex;
    gap: 0.5rem;
    margin: 0.3rem;
    margin-top: 1rem;
    width: 100%;
    align-items: baseline;
  }

  &__scoreValue {
    font-size: 1.5rem;
    margin-left: 0.3rem;
  }

  &__scoreValueDetail {
    font-size: 24px;
    margin-left: 0.3rem;
    min-width: 3rem;
  }

  &__scoreNameDetail {
    font-size: 12px;
    margin-top: 0.3rem;
  }
  &__climateScoreDetail {
    display: flex;
    gap: 0.5rem;
    margin: 0.3rem;
    margin-top: 1rem;
    width: 100%;
    align-items: center;
  }
  &__climateScoreValueDetail {
    font-size: 24px;
    margin-left: 0.3rem;
    min-width: 3rem;
  }

  &__climateScoreNameDetail {
    font-size: 12px;
    margin-top: 0.3rem;
    min-width: 8rem;
    word-wrap: break-word;
  }

  &__scoreholder {
    display: flex;
    margin-left: 0.3rem;
    &--base {
      font-size: 1.5rem;
      font-weight: 400;
    }
    &--others {
      font-size: 1.1rem;
      font-weight: 400;
      margin-top: 0.4rem;
    }
  }
  &__scoreName {
    font-size: 12px;
    margin-top: 0.3rem;
  }
  &__scoreSpan {
    width: fit-content;
  }
  &__editInfo {
    margin-right: 0.5rem;
    cursor: pointer;
    &:hover {
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 4px;
    }
  }

  &__noData {
    margin: 1rem;
    align-items: center;
    font-size: 0.785rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }

  &__noPeerData {
    margin: 5rem;
    align-items: center;
    font-size: 0.785rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }

  &__noDataAny {
    margin: 2rem 1rem 1rem 0.5rem;
    align-items: center;
    font-size: 0.785rem;
    display: flex;
    font-weight: normal;
    text-align: left;
    &--multiple {
      display: block;
    }
  }
  &__no-data {
    color: $dark-grey-2;
    font-size: 0.75rem;
  }

  &__parentdiv {
    position: relative;
  }
  &__child {
    position: absolute;
  }
  &__score {
    position: absolute;
    top: -4px;
    span {
      position: absolute;
      width: 1rem;
      height: 0.75rem;
      left: calc(50% - 1.08125rem / 2);
      top: calc(50% - 0.75rem / 2);
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 0.75rem;
      text-align: center;
      color: #ffffff;
    }
  }

  &__tooltip {
    .tooltip__arrow {
      display: none;
    }
  }

  &__new-tile {
    background: #f1f8fb;
    border: 1px dashed #75cdee;
    flex-grow: initial;
  }
  &__groupDeatail {
    display: flex;
    margin: 2rem 0.5rem 0.8rem 0.5rem;
    align-items: center;
    gap: 0.5rem;
  }
  &__groupDeatail:first-of-type {
    margin-top: 0;
  }

  &__climateGroupDeatail {
    display: flex;
    align-items: center;
  }
  &__GhgIntensityGroup {
    display: flex;
    align-items: center;
  }
  &__climateScoreSeparator {
    margin-bottom: 1rem;
  }

  &__metricWrapperClimate {
    margin-bottom: 1rem;
  }

  &__scoreHolder {
    display: flex;
    gap: 0.3rem;
    font-size: 14px;
    margin-top: 1rem;
  }

  &__scoreLimit {
    font-weight: 600;
  }

  &__scoreLimitBorder {
    border-bottom: 1px dashed $light-grey;
    width: 100%;
  }

  &__climateGroupDeatail {
    display: flex;
    align-items: center;
  }

  &__climateMetricWrapper {
    margin-bottom: 1.5rem;
  }

  &__industryDetails {
    font-size: 12px;
    color: $grey;
  }

  &__companyDetails {
    font-size: 12px;
    font-weight: 600;
    color: #0c0a0a;
  }
  &__companyDetailed {
    font-size: 14px;
    font-weight: 600;
    color: #0c0a0a;
  }

  &__header-text {
    position: relative;
    display: flex;
    font-weight: 600;
    &-dashboard {
      position: relative;
      display: flex;
      flex-grow: 1;
    }

    .tooltip {
      top: 1.5rem;
      z-index: 2;
      opacity: 0;

      &__label {
        height: 0;
      }
    }

    &:hover {
      .tooltip {
        opacity: 1;

        &__label {
          height: 100%;
        }
      }
    }
  }

  &__dashboard-text {
    .tooltip {
      top: 1.5rem;
      z-index: 2;
      opacity: 0;

      &__label {
        height: 0;
      }
    }
    &:hover {
      .tooltip {
        opacity: 1;

        &__label {
          height: 100%;
        }
      }
    }
  }
  &__header {
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid $lightest-grey;
    position: relative;
    align-items: center;
    justify-content: space-between;

    &__sub-text {
      font-size: 0.75rem;
      padding: 0.5rem 1.5rem;
      color: $grey;
    }

    &__text {
      padding-bottom: 0.1rem;
      margin-left: 0.5rem;
      flex-grow: 1;
      overflow: hidden;
      &-dashboard {
        margin-left: 0.5rem;
        overflow: hidden;
        flex-grow: 1;
        width: 1px;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 0.5rem;
      }

      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 0.5rem;
      &__date {
        color: "#757575";
        opacity: 0.8;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: "center";
        padding-left: 1.5rem;
        padding-top: 0.5rem;
      }
    }

    &--drag {
      &:hover {
        cursor: pointer;
        background-color: $light-grey;
        transition: 0.7s;
        border-radius: 4px;
      }
    }
  }

  &__actions {
    display: flex;
    position: relative;
    column-gap: 0.3rem;

    &--insights {
      background: $dark-grey;
      padding: 0.25rem 0.5rem;
      border-radius: 1rem 0 0 1rem;
      position: absolute;
      right: -1rem;
      top: -0;
    }
  }

  &__action--expand {
    position: relative;
    .tooltip {
      top: -1.5rem;
      z-index: 2;
      opacity: 0;
      left: 0.5rem;
      transform: translateX(-50%);
    }

    &:hover {
      .tooltip {
        opacity: 1;

        &__label {
          height: 100%;
        }
      }
    }
  }

  &__action--filter,
  &__action--chart,
  &__action--download {
    > div > svg > path {
      fill: $white;
    }
    position: relative;
    .tooltip {
      top: -1.5rem;
      z-index: 2;
      opacity: 0;
      left: -2rem;
    }

    &:hover {
      .tooltip {
        opacity: 1;

        &__label {
          height: 100%;
        }
      }
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 4px;
      path {
        fill: $black;
      }
    }
  }

  &__action--filter {
    > div > div > svg > path {
      fill: $white;
    }
    .tooltip {
      left: -1rem;
    }
  }

  &__hidden-menu {
    position: relative;
    .insights-options {
      display: none;
    }

    &:hover {
      .insights-options {
        display: flex;
      }
      .insights-icon {
        visibility: hidden;
      }
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 4px;
    }
    &--show {
      .insights-options {
        display: flex;
      }
      .insights-icon {
        visibility: hidden;
      }
    }
  }

  &__content {
    display: flex;
    column-gap: 2.5rem;
    row-gap: 1rem;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 1rem;
    box-sizing: border-box;

    svg {
      height: 100%;
    }

    &-no-padding {
      padding-top: 0rem;
    }
  }

  &__legends {
    display: flex;
  }

  &__legend-groups {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    font-size: 0.875rem;
    row-gap: 0.5rem;
    max-width: 50%;

    &--group b {
      padding-right: 0.5rem;
    }
  }

  &__legend-dataset {
    max-width: 50%;
    flex-grow: 1;
  }

  &__dataset {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem;

    &-info {
      padding: 0.5rem 2rem;
      font-size: 12px;
      color: $grey;

      &-ghg {
        display: flex;
        gap: 0.4rem;
        font-size: 12px;
        color: #75787b;
        align-items: center;
        &-font {
          font-size: 12px;
          color: #75787b;
          white-space: pre;
        }
      }

      &-value {
        margin-top: 0.3rem;
      }
      &-period-value {
        display: flex;
        align-items: center;
      }
    }
  }

  &__dataset_number {
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    grid-auto-flow: column;
    grid-gap: 3px 5px;
    align-self: flex-start;
    padding: 1rem;

    &-info {
      padding: 0.5rem 2rem;
      font-size: 12px;
      color: $grey;
    }
  }

  &__data-number {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    padding: 0.3rem 1rem;
    box-sizing: border-box;
    width: calc(100% / 3);
  }

  &__data {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    padding: 0.3rem 1rem;
    box-sizing: border-box;
    width: 50%;

    &--group {
      width: 100%;
    }

    &--custom {
      width: auto;
    }

    &--centered {
      margin: 0 auto;
    }

    &--value > span:first-child {
      max-width: max-content !important;
    }

    &--color {
      width: 0.65rem;
      height: 0.65rem;
      border-radius: 0.65rem;
      margin-right: 0.5rem;
    }

    &--line-chart-color {
      @extend .customLegendMarker;
      border-color: #227786;
    }

    &--bubble-chart-color {
      @extend .customLegendMarker;
      border-color: #42c2ff;
    }

    &--value {
      display: flex;
      flex-grow: 1;
      column-gap: 0.75rem;

      > span:first-child {
        min-width: 2rem;
        max-width: 5rem;
      }
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &__title {
      text-transform: uppercase;
      color: $grey;
      font-size: 0.75rem;
      font-weight: 700;
      align-items: flex-start;
      padding: 0 1rem;
    }
  }

  &__input {
    width: 80%;
    input {
      padding: 0.3rem 0.5rem;
      border: none;
      outline: none;
      width: 100%;
    }

    input:disabled {
      background: transparent;
    }
  }

  &__action {
    @include invisible-button;
    &:hover {
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 4px;
    }
    &-menu {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0.5rem 0;
      border: 1px solid #d0d0ce;
      box-sizing: border-box;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      width: 11.875rem;
      background-color: #ffffff;
      z-index: 100;
      right: -1rem;
      top: 2.5rem;
      &--hide {
        display: none;
      }
      &-btn {
        position: relative;
      }
      &-item {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 0.5rem 1rem;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        border: none;
        background-color: #ffffff;
        &:hover {
          background-color: #f7f7f7;
          cursor: pointer;
        }
        &--red:hover {
          color: #da291c;
        }
      }
    }
  }

  &__graph-option {
    @include invisible-button;
    padding: 0.5rem;
    border: 1px solid $lightest-grey;
    margin-bottom: 0.5rem;
    &:hover {
      background-color: #f0f0f0;
      transition: 0.7s;
    }
    &--active {
      background-color: $lightest-blue;
      border-color: $primary;
    }
  }

  &__graph-options {
    display: none;
    left: -6.3rem;
    position: absolute;
    background-color: $white;
    padding: 0.5rem 0.5rem 0 0.5rem;
    border: 1px solid $lightest-grey;
    flex-wrap: wrap;
    width: 7.5rem;
    z-index: 2;
    box-shadow: 0.1rem 0.1rem 0.2rem $light-grey;

    &--show {
      display: flex;
      justify-content: space-between;
    }
  }

  &__export-option {
    @include invisible-button;
    padding: 0.5rem;
    display: flex;
    font-size: 14px;

    &--active {
      background-color: $lightest-blue;
      border-color: $primary;
    }

    &--text {
      padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    }
  }

  &__export-option:hover {
    background-color: $lightest-blue;
    border-color: $primary;
  }

  &__export-options {
    display: none;
    left: -8.7rem;
    position: absolute;
    background-color: $white;
    padding: 0.5rem;
    width: 9rem;
    z-index: 2;
    box-shadow: 0.1rem 0.1rem 0.2rem $light-grey;
    border: 1px solid $lightest-grey;

    &--show {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__stats {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin-left: 1rem;
    margin-top: 1rem;
  }

  &__stat {
    display: flex;
    padding: 0.5rem 1rem 1rem 1rem;
    background-color: $lightest-grey;
    border-radius: 0.5rem;
    column-gap: 1rem;
    font-size: 0.875rem;
    align-items: center;

    b {
      font-size: 1.5rem;
    }

    &--data {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
  }

  &__tile {
    display: flex;
    &--scaled-size {
      min-width: 65%;
    }
  }

  &__filter {
    display: none;
    left: -11.2rem;
    position: absolute;
    background-color: $white;
    padding: 0.5rem;
    flex-wrap: wrap;
    width: 19rem;
    z-index: 2;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.16);
    border-radius: 8px;

    &--show {
      display: flex;
    }
  }

  &__filterInsight {
    display: none;
    right: 1rem;
    position: absolute;
    background-color: $white;
    padding: 0.5rem;
    flex-wrap: wrap;
    width: 19rem;
    z-index: 2;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.16);
    border-radius: 8px;

    &--show {
      display: flex;
    }
  }

  &__placeholders {
    display: flex;
    padding: 1rem 1rem 0.5rem 1rem;
  }

  &__loader {
    width: 100%;
    background-color: $white;
    position: relative;
    animation: light_shine 1000ms infinite ease-in-out;
    min-height: 19rem !important;
  }

  &__tile-container--columns {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }
  &__datasetLegend {
    &--right {
      padding: 1rem;
      flex-shrink: 2;
      min-width: 30%;
    }

    &-title {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $dark-grey;
      position: relative;
      .tooltip {
        top: 1.5rem;
        z-index: 2;
        opacity: 0;
        &--bottom__arrow {
          left: 14%;
        }

        &__label {
          height: 0;
        }
      }

      &:hover {
        .tooltip {
          opacity: 1;

          &__label {
            height: 100%;
          }
        }
      }
    }
    &-list {
      padding-left: 1rem;
      margin-top: 0.5rem;
      &-item {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 28px;
        color: $neutral-black;
      }
    }
  }

  &-no-data {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__info-icon-tooltip {
    .tooltip__arrow {
      top: 98%;
    }
  }

  &__ghgIntensityTooltip {
    background-color: $black;
    color: $white;
    padding: 0.2rem 0.5rem;
    text-align: left;
    border-radius: 0.15rem;
    overflow-wrap: break-word;
    font-size: 0.75rem;
    width: fit-content;
    display: flex;
    gap: 2rem;
    margin: 0 auto;
  }
}

.bubble-chart {
  max-height: 28rem !important;
}

.pie-chart {
  display: flex;
  flex-grow: 1;
  padding: 3rem 0;

  > div {
    text-align: center;
  }

  svg {
    height: auto;
    max-height: 10rem;
    min-height: 4rem;
  }
}

.ghg-pie-chart {
  display: flex;
  flex-grow: 1;
  width: 15rem;
  padding: 3rem 0;

  > div {
    text-align: center;
  }

  svg {
    height: auto;
    max-height: 10rem;
    min-height: 4rem;
  }
}

.modalESG {
  .DashboardTile--fullscreen {
    width: 100% !important;
    box-shadow: none;

    .DashboardTile__data {
      width: 50%;
    }
    .DashboardTile__data--group {
      width: 100%;
    }
  }
}
.Filter-tile {
  width: inherit;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    min-width: 16rem;
    max-height: fit-content;

    h1 {
      font-size: 1rem;
      font-weight: 600;
      margin: 0;
    }
  }
  &__container {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }
  &__close {
    @include invisible-button;
    justify-content: flex-end;
  }
  &__filter-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__drop-down {
    margin-top: 1rem;
    position: relative;
    padding: 0.5rem 0rem 1rem 0rem;

    &:first-child {
      margin-top: 0;
    }
  }
  &__options {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    width: 18rem;
  }
  &__reset {
    @include invisible-button;
    color: $primary;
    font-size: 14px;
    line-height: 1.5rem;
    &:hover {
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 4px;
    }

    &-disabled {
      @include invisible-button;
      color: $grey;
      cursor: pointer;
      path {
        fill: $grey;
      }
    }
  }

  &__apply {
    @include primary-button;
    padding: 0.375rem 1.5rem;
    font-size: 14px;
    line-height: 1.5rem;

    &-disabled {
      @include primary-button-disabled;
      padding: 0.375rem 1.5rem;
      font-size: 14px;
      line-height: 1.5rem;
    }
  }
}

.multiple-chart {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
  column-gap: 1rem;
  row-gap: 1rem;
  flex-grow: 1;
  min-height: unset !important;

  &__pie {
    display: flex;
    width: 21%;

    & > div {
      display: flex;
      flex-direction: column;
    }
  }

  &__bar-label {
    text-align: center;
    position: relative;
    font-size: 0.75rem;
    font-weight: 400;
    padding: 0.5rem;
    .tooltip {
      transform: translateX(-50%);
      bottom: unset;
      top: unset;
      left: 50%;
      display: none;

      &__label {
        max-width: unset;
        max-height: unset;
      }
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
    &-container {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      align-items: flex-end;
    }
  }
  &__column {
    display: flex;
    width: 32%;
    flex-direction: column;
    & > div {
      display: flex;
      flex-direction: column;
      max-height: 15rem;
    }
  }

  &__bar {
    display: flex;
    width: 49%;
    flex-direction: column;
    position: relative;
    min-height: 17.5rem;

    &--vertical {
      width: 99%;
    }

    & > div {
      display: flex;
      flex-direction: column;
      max-height: 15rem;
    }

    &--show-axis > div {
      max-height: 25rem;
    }

    &__header {
      display: flex;
      flex-direction: row !important;
      font-size: 0.75rem;
    }

    &__title {
      font-weight: bold;
      position: relative;
      font-size: 0.75rem;
      .tooltip {
        top: 100%;
        display: none;
        &__label {
          max-width: unset;
          max-height: unset;
        }
      }

      &:hover {
        .tooltip {
          display: block;
        }
      }
    }

    &__subtitle {
      color: $dark-grey;
    }

    &__right-aligned {
      display: flex;
    }

    .speedometer__no-data {
      min-height: 3rem;
      transform: unset;
    }
  }

  .pie-chart {
    padding: 0;
  }
}

.speedometer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 100%;
  flex-grow: 1;
  min-height: unset !important;
  align-content: center;
  align-items: center;
  row-gap: 1rem;

  &__separator {
    height: 100%;
    width: 1px;
    background-color: $lightest-grey;
  }

  &__metricLabel {
    font-size: 0.75rem;
    font-weight: 600;
  }

  &__pie {
    display: flex;
    flex-direction: column;
    width: 30%;

    &--vertical {
      width: 100%;
      border-bottom: 1px solid $lightest-grey;
      &:last-child {
        border-bottom: none;
      }
    }

    & > div {
      display: flex;
      //flex-direction: column;
      justify-content: center;
      text-align: center;
      height: unset !important;
    }
  }

  &__no-data-indicator {
    width: 100%;
    text-align: left;
    padding: 2rem 1rem 1rem 1rem;
    font-size: 0.785rem;
  }

  &__no-data-2 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.785rem;
    font-weight: bold;
  }

  &__no-data {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.785rem;
    font-weight: bold;
    flex-grow: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
    left: 0;

    &--bar-chart {
      position: relative;
      margin-top: 50%;
    }

    &--multiple-bar-chart {
      position: relative;
      margin-top: 2rem;
    }
  }

  &__company-name {
    font-weight: bold;
    text-align: left;
    position: absolute;
  }

  &__company-text {
    font-size: 0.75rem !important;
    &__long {
      font-size: 0.55rem !important;
      width: inherit;
      word-wrap: break-word;
    }
  }

  &__label {
    position: relative;
    font-size: 0.75rem;
    font-weight: 600 !important;

    &--vertical {
      margin-bottom: 1.5rem;
    }
  }
}

.TabularTile {
  &--style-4 {
    background-image: linear-gradient(
      180.75deg,
      #eef5f8 0.64%,
      rgba(238, 245, 248, 0) 135.65%
    );

    .TabularTile__metric-title {
      font-weight: 600;
    }
  }
  &--style-4-notCompany {
    .TabularTile__metric-value-header {
      font-weight: 600;
    }
    .TabularTile__metric-row-horizontal {
      flex-grow: 1;
    }
    .TabularTile__metric-value-wrapper-horizontal {
      width: 99%;
    }
    .TabularTile__metric-row-horizontal {
      border-width: 1px 0px;
      border-radius: 0 !important;
      border-bottom-style: dashed;
      border-color: #e0e0e0;
    }
    .TabularTile__metric-value-wrapper {
      border-width: 1px 0px;
      border-radius: 0 !important;
      border-top-style: dashed;
      border-color: #e0e0e0;
    }
  }

  &--style-4-Company {
    .TabularTile__metric-title {
      font-weight: 600;
    }
    .TabularTile__metric-row-horizontal {
      border: none;
    }
    .TabularTile__metric-value-wrapper {
      border: none;
      &-Yes,
      &-yes {
        color: $green-secondary;
        background-image: linear-gradient(
          180.75deg,
          #e3f2e5 0.64%,
          #ffffff 135.65%
        );
        border-radius: 8px;
      }

      &-No,
      &-no {
        color: $orange;

        background-image: linear-gradient(
          180.75deg,
          #f8f3ee 0.64%,
          rgba(248, 245, 238, 0) 135.65%
        );
        border-radius: 8px;
      }

      &-Not,
      &-not {
        color: #757575;

        background-image: linear-gradient(
          180.75deg,
          #ebecec 0.64%,
          rgba(250, 250, 250, 0) 135.65%
        );
        border-radius: 8px;
      }
      &-Neutral,
      &-neutral {
        background-color: #f7f7f7;
      }
    }
  }

  &--style-7 {
    .TabularTile__metric-value-wrapper {
      &-Yes,
      &-yes {
        color: $green-secondary;
        background-image: linear-gradient(
          180.75deg,
          #e3f2e5 0.64%,
          #ffffff 135.65%
        );
        border-radius: 8px;
      }

      &-No,
      &-no {
        color: $orange;

        background-image: linear-gradient(
          180.75deg,
          #f8f3ee 0.64%,
          rgba(248, 245, 238, 0) 135.65%
        );
        border-radius: 8px;
      }

      &-Neutral,
      &-neutral {
        color: $dark-primary;

        background-image: linear-gradient(
          180.75deg,
          #eef6f8,
          0.64%,
          rgba(238, 245, 248, 0) 135.65%
        );
      }

      &-Not,
      &-not {
        color: #757575;

        background-image: linear-gradient(
          180.75deg,
          #ebecec 0.64%,
          rgba(250, 250, 250, 0) 135.65%
        );
        border-radius: 8px;
      }
    }
  }

  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 0.75rem;
    width: 100%;
    padding: 0 0.5rem 0 0;
    text-align: left !important;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__metrics {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-horizontal {
      flex-direction: row;

      .TabularTile__metric-row-horizontal:last-child,
      .TabularTile__metric-row-horizontal:nth-last-child(2) {
        .TabularTile__metric-title {
          .tooltip {
            left: unset;
            right: 0;

            &__arrow {
              left: unset;
              right: 2%;
            }
          }
        }
      }
    }

    &-reporting-boundary {
      row-gap: 0.5rem;
      justify-content: unset;
    }
  }

  &__metric {
    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-climate-topics {
        justify-content: none;
        padding-bottom: 1.5rem;
      }
      &-hidden {
        display: none;
      }

      &-horizontal {
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;
        width: 5.1rem;
        &-governance-detailed {
          width: 7rem;
        }
        &-climate-topics-detailed {
          gap: 1rem;
          width: 10rem;
        }

        .TabularTile__metric-title-wrapper:first-child {
          max-height: 1.25rem;
        }
      }
      &-reporting {
        &-subtitle {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          & span:last-child {
            max-width: 40%;
            position: relative;
            .tooltip {
              display: none;
            }
            &:hover {
              .tooltip {
                top: 100%;
                display: flex;
                max-width: 10rem;
                z-index: 5;
              }
            }
          }
        }
      }
    }

    &-title {
      display: flex;
      gap: 0.25rem;
      align-items: center;

      &-wrapper {
        visibility: hidden;
        display: flex;
        align-items: center;
        flex: 1 1 0px;
        &-climate-topics {
          flex: none;
          max-width: 50%;
          width: 50%;
        }
        &-climate-topics-notCompany {
          flex: none;
          max-width: 39%;
          width: 39%;
        }
        &-governance-detailed:first-child {
          min-height: 7rem;
          text-align: center;
        }
        &-climate-topics-detailed:first-child {
          min-height: 3.5rem;
          text-align: center;
        }

        &-height {
          visibility: visible;
        }
        &--visible {
          visibility: visible;
        }
      }

      color: $black;
      position: relative;

      .tooltip {
        z-index: 2;
        display: none;
        top: 1rem;

        &__arrow {
          left: 5%;
        }
      }

      &:hover {
        .tooltip {
          display: block;
        }
      }
    }

    &-icon {
      position: relative;

      .tooltip {
        z-index: 2;
        display: none;
        top: 1rem;

        &__arrow {
          left: 5%;
        }
      }

      &:hover {
        .tooltip {
          display: block;
        }
      }
    }

    &-values-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &-value-wrapper {
      border-radius: 0.5rem;
      background-color: $white;
      max-width: 6.75rem;
      flex: 1;
      box-sizing: border-box;

      &:last-child {
        margin-left: 1rem;
      }

      &-climate-topics {
        max-width: none;
      }
      &-climate-topics-notCompany {
        margin-left: 1rem;
      }

      &-horizontal {
        &:last-child {
          margin-left: 0rem;
        }

        max-width: none;
        width: 4.5rem;
        min-height: 2.5rem;
        display: flex;
        align-items: center;
        &-climate-topics-detailed {
          width: 8.5rem;
        }
      }

      &-header {
        padding: 0;
        background-color: transparent;
        text-align: center;
      }
      &-reporting-boundary {
        max-width: 40%;
      }
      &-detail {
        &:last-child {
          margin-left: 0;
        }
      }
    }

    &-value {
      font-weight: 600;
      font-size: 0.75rem;
      padding: 0.875rem 2rem;
      text-align: center;
      position: relative;

      .tooltip {
        display: none;
        top: 60%;
        left: -100%;
      }

      &:hover {
        .tooltip {
          display: block;

          &__arrow {
            left: 75%;
          }
        }
      }

      &-climate-topics-notCompany {
        padding: 0.6rem 0.6rem;
        font-size: 12px;
      }

      &-with-icon {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        padding: 0.875rem 2rem 0.875rem 1.875rem;
      }

      &-horizontal {
        flex-direction: column;
        padding: 1rem 1.6rem;
        width: 100%;

        &.multiple {
          padding: 0;
          width: 100%;
        }
      }

      &-header {
        font-weight: 400;
        padding: 0;
        font-size: 0.75rem;
        margin-bottom: 1rem;
        display: block;
        &-climate-topics {
          background: none !important;
        }

        &-hidden {
          display: none;
        }

        &.multiple {
          text-align: left;
          padding: 0;
        }
      }

      &-Yes,
      &-yes {
        color: $green-secondary;
        background-image: linear-gradient(
          180.75deg,
          #e3f2e5 0.64%,
          #ffffff 135.65%
        );
        border-radius: 8px;
        path {
          fill: $green-secondary;
        }
      }

      &-No,
      &-no {
        color: $orange;

        background-image: linear-gradient(
          180.75deg,
          #f8f3ee 0.64%,
          rgba(248, 245, 238, 0) 135.65%
        );
        border-radius: 8px;
        path {
          fill: $orange;
        }
      }

      &-Neutral,
      &-neutral {
        color: $dark-primary;

        path {
          fill: $dark-primary;
        }
      }
      &-Not,
      &-not {
        color: #757575;

        background-image: linear-gradient(
          180.75deg,
          #ebecec 0.64%,
          rgba(250, 250, 250, 0) 135.65%
        );
        border-radius: 8px;
        path {
          fill: $dark-primary;
        }
      }
      &-detail {
        color: #0076a8;
        background: linear-gradient(
          180.75deg,
          #eef5f8 0.64%,
          rgba(238, 245, 248, 0) 135.65%
        );
        border-radius: 8px;
      }
      &-climate-topics {
        background: linear-gradient(
          180.75deg,
          #ebecec 0.64%,
          rgba(250, 250, 250, 0) 135.65%
        );
        border-radius: 8px;
        min-height: 1.5rem;
        &-detailed {
          min-height: 4rem;
          display: flex;
          justify-content: center;
        }
        span {
          vertical-align: -webkit-baseline-middle;
        }
        &-yes {
          color: $green-secondary;
          path {
            fill: $green-secondary;
          }
        }

        &-no {
          color: $orange;
          path {
            fill: $orange;
          }
        }
        &-notapplicable {
          color: #757575;
        }
        &-neutral {
          color: $dark-primary;

          path {
            fill: $dark-primary;
          }
        }
      }
      &-reporting-boundary {
        background: linear-gradient(
          180.75deg,
          #eef5f8 0.64%,
          rgba(238, 245, 248, 0) 135.65%
        );
        color: #0076a8;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        padding: 0.875rem 1rem;
        border-radius: 8px;
        width: 100%;
        justify-content: center;
        &--gray {
          background: #f7f7f7;
          color: #212121;
        }

        .tooltip {
          left: -50%;
        }
      }
    }

    &-numeric {
      width: 100%;

      &-multiple {
        max-width: 13.5rem;
      }

      &-wrapper {
        margin-bottom: 0.5rem;
        display: flex;
        align-items: baseline;
      }

      &-value {
        font-size: 1.5rem;
      }

      &-title {
        margin-left: 0.5rem;
      }

      &-visual {
        position: relative;
        width: 100%;
        height: 10px;

        &-total {
          position: absolute;
          top: 50%;
          width: 100%;
          height: 1px;
          background-color: $light-grey;
        }

        &-score {
          position: absolute;
          top: 34.5%;
          height: 5px;
          background-color: $green;
          border-radius: 8px;

          &-multiple {
            background-color: $light-grey;

            &-tooltip {
              position: relative;
              height: 1rem;
              width: 100%;

              .tooltip {
                display: none;
                top: -2.45rem;
                left: unset;
                right: 0;

                &__arrow {
                  display: none;
                }
              }

              &:hover {
                .tooltip {
                  display: block;
                }
              }
            }
          }

          &-secondary {
            top: -2px;
            width: 1rem;
            height: 1rem;
            border-radius: 50px;
            background-color: #a6b3df !important;
            box-shadow: 0px 0px 0px 1px #7081bc,
              0px 0px 2px 3px rgba(50, 60, 94, 0.12),
              inset 0px 0px 6px 3px #778bcd;

            &-tooltip {
              position: relative;
              height: 1rem;
              width: 1rem;

              .tooltip {
                display: none;
                top: -2.45rem;
                left: unset;
                right: 0;

                &__arrow {
                  display: none;
                }
              }

              &:hover {
                .tooltip {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  &--framework {
    &__company-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
    }

    &__company-data {
      width: 100%;
    }

    &__metrics {
      flex-direction: column;
      gap: 1rem;
    }

    &__metric-value-wrapper {
      width: 100%;
      min-height: 3rem;
      background: linear-gradient(
        180deg,
        $white 0%,
        rgba(238, 245, 248, 0.3) 100%
      );
      border: 1px solid #e2eff4;
      position: relative;
      margin: 0 !important;

      &--yes {
        background: linear-gradient(180deg, #e7f5f0 0%, #f1faf7 100%);
        border: 1px solid #6fc2b4;
      }
      &--neutral {
        border: 1px solid $grey;
      }

      .tooltip {
        top: 100%;
        transform: translateX(-50%);
        display: none;
      }

      &:hover {
        .tooltip {
          display: block;
        }
      }
    }

    &__metric-value {
      color: $dark-grey;
      font-weight: 400;
      padding: 0.875rem 0.5rem;
      position: relative;
      &--yes {
        font-weight: 600;
        color: #0d8390;
        font-size: 0.75rem;
      }
      &--neutral {
        font-weight: 600;
        padding: 0.5rem;
      }
      &-container {
        display: inline-block;
      }
      &--fs-12 {
        font-size: 0.75rem;
      }
    }

    &__metric-title {
      display: none;
    }

    &__metric-row {
      flex-grow: 1;
      gap: 0.5rem;
      width: 100%;
      flex-wrap: wrap;
    }

    &__neutral-label {
      font-size: 0.6rem;
      position: absolute;
      bottom: -0.25rem;
      left: 50%;
      transform: translateX(-50%);
      font-weight: normal;
    }
  }

  &__Governance {
    padding-top: 1.25rem;
    display: grid;
    width: 50%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.9375rem;
    margin-left: -0.3125rem;
    &-tile {
      display: flex;
      flex-direction: column;
      border: 1px solid #f0f0f0;
      height: 7.5rem;
      width: 11.6875rem;
      border-radius: 8px;
    }

    &-value {
      &--Yes,
      &--yes {
        background: linear-gradient(180.75deg, #e3f2e5 0.64%, #ffffff 135.65%);
        color: #009a44;
        border-bottom: 1px solid #f0f0f0;
        padding-top: 0.3125rem;
        text-align: center;
        font-size: 1rem;
        font-weight: 700;
        min-height: 1.875rem;
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 8px 8px 0 0;
      }
      &--No,
      &--no {
        background: linear-gradient(
          180.75deg,
          #f8f3ee 0.64%,
          rgba(248, 245, 238, 0) 135.65%
        );
        color: #ec7016;
        padding-top: 0.3125rem;
        border-bottom: 1px solid #f0f0f0;
        text-align: center;
        font-size: 1rem;
        font-weight: 700;
        min-height: 1.875rem;
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 8px 8px 0 0;
      }
      &--Not,
      &--not {
        background: linear-gradient(
          180.75deg,
          #ebecec 0.64%,
          rgba(250, 250, 250, 0) 135.65%
        );
        color: #757575;
        border-bottom: 1px solid #f0f0f0;
        padding-top: 0.3125rem;
        text-align: center;
        font-size: 1rem;
        font-weight: 700;
        min-height: 1.875rem;
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 8px 8px 0 0;
      }
    }

    &-text {
      text-align: center;
      padding: 0.3125rem;
    }

    &-metric {
      @include font-family;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
    }
    &-metric-container {
      padding: 0.85rem;
    }
  }
}

.EnergyUndertaken-tile {
  &__content {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    font-size: 0.75rem;
    line-height: 18px;
    width: 100%;
    text-align: left !important;
  }
  &__company-row {
    display: flex;
    align-items: flex-start;
    &__detailed {
      justify-content: space-between;
      padding-right: 0.5rem;
      align-items: center;
    }
    &__header {
      align-items: flex-start;
    }
  }
  &__values-wrapper {
    width: 100%;
  }
  &__metric {
    width: 50%;
    &__notCompany {
      width: 35%;
    }
    &__title__detailed {
      width: 15%;
    }
    &__detailed {
      flex: 1 1 10%;
      min-height: 4rem;
    }
    &__header {
      text-align: left;
      span {
        margin-left: 0.3rem !important;
        word-break: break-word;
        font-weight: 600;
        display: inline-block;
        text-align: left;
        white-space: pre-wrap;
      }
      &__detailed {
        text-align: center;
        flex: 1 1 20%;
        margin: 0.3rem;
        span {
          margin-left: 0 !important;
        }
      }
    }
    &__value {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }
    &--12 {
      font-size: 0.75rem;
    }
    &--left {
      text-align: left;
    }
    &--no-margin.EnergyUndertaken-tile__metric--no-margin {
      margin-left: 0;
    }
    &-container {
      display: inline-block;
    }
    &-button {
      line-height: unset;
    }
    &-text {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 18px;
      text-align: center;
    }
  }
  &__legend {
    display: flex;
    justify-content: flex-end;
    span {
      min-width: 1rem;
      font-weight: 400;
      font-size: 0.625rem;
      line-height: 1rem;
      color: #757575;
      text-align: left;
    }
  }
  &__legend__metric {
    min-width: 3.5rem;
    display: flex;
    justify-content: space-around;
    padding-right: 0.5rem;
    align-items: center;
  }
  &__legend__bar {
    min-width: 1.5rem;
    padding-right: 0.5rem;
  }
  &__detailedView {
    display: flex;
    justify-content: space-between;
    gap: 0.3rem;
    width: 100%;

    &-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      gap: 1.5rem;
      flex: 1 1 10%;

      &--metricFieldWrapper {
        align-items: flex-start;
      }
      &--metricField {
        margin-left: 0;
        white-space: break-spaces;
        text-align: left;
      }
    }
    &-column:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }
  &__no-data-section {
    margin-top: 2rem;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    width: calc(100% - 2rem);
  }
}

.trend-tile {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  max-height: none !important;
  flex-grow: 1;

  &__container {
    width: fit-content;
    height: fit-content;
  }
  &-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px dashed #e0e0e0;
    padding-bottom: 7%;
    max-width: -webkit-fill-available;
    max-height: none !important;

    &-peer {
      flex-direction: column;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-bottom: 7%;
      max-width: -webkit-fill-available;
      max-height: none !important;
    }

    &-detailed {
      border-bottom: none;
      flex-direction: row;
    }
  }

  &-main-content-peers {
    display: flex;
    border-bottom: 1px dashed $light-grey;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 0.68rem;
  }

  &__table {
    width: 100%;
    max-width: -webkit-fill-available;
    &-rating-content {
      border-top: 1px dashed $light-grey;
      width: 100%;
    }
    &-fieldValue {
      font-weight: 600;
    }
    &-title {
      margin-bottom: 0.5rem;
    }
    &--centered {
      td.data {
        text-align: center;
      }
    }
    // &--left {
    //   td.data {
    //     text-align: right;
    //   }
    // }

    &-data {
      display: flex;
      justify-content: space-between;
      margin-top: 0.5rem;

      &--margin-bottom {
        margin-bottom: 0.5rem;
      }

      &--gray {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #757575;
      }

      &--16 {
        font-size: 1rem;
      }
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed $light-grey;
    padding: 0.75rem 0;
    align-items: center;
    &:first-child tr {
      border-top: 1px dashed $light-grey;
    }
  }
  &-company {
    @include font-family;
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
  }
  &-peers {
    padding-right: 1rem;
  }
  &-details {
    flex-basis: 16rem;
    &--name {
      color: #757575;
      font-size: 0.75rem;
      &-fit-content {
        display: inline-block;
        width: fit-content;
      }
    }
  }
  &__containerForRating {
    min-height: 3rem;
    margin-top: 0.3rem;
    margin-right: 0.25rem;
  }
  &__icon {
    flex-grow: 6;
    margin-left: 0.2rem;
    // .tooltip {
    //   z-index: 2;
    //   display: none;
    // }

    // &:hover {
    //   .tooltip {
    //     display: inline-block;
    //   }
    // }
  }
  &-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-height: none !important;
  }
  &-rating-content {
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 16rem;
    max-width: 16rem;
  }
  &-rating-content-peers {
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 13rem;
  }
  &-rating {
    @include font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    padding-right: 0.25rem;
    &-detail {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #212121;
    }
    &__CCC {
      color: #db2a1c;
    }
    &__B {
      color: #db2a1c;
    }
    &__BB {
      color: #ffb700;
    }
    &__BBB {
      color: #ffb700;
    }
    &__A {
      color: #ffb700;
    }
    &__AA {
      color: #009a44;
    }
    &__AAA {
      color: #009a44;
    }
  }
  &-empty-tile {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.785rem;
    font-weight: bold;
    flex-grow: 1;
  }
  &-empty-rating {
    @include font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #757575;
    padding: 1rem 1rem 1rem 0;
    &-data {
      display: flex;
      padding: 0.5rem 0 0.5rem 0;
    }
    &-table {
      font-size: 14px;
      padding: 0;
    }
  }
  &__multipleItem__see-more {
    cursor: pointer;
    position: relative;
    width: fit-content;

    // .tooltip {
    //   left: 2em;
    //   z-index: 10;
    //   bottom: 0;
    //   max-width: 15rem;
    //   display: none;
    //   top: -0.5em;
    // }

    // .tooltip__arrow {
    //   top: 85%;
    // }

    // &:hover .tooltip {
    //   display: block;
    // }

    &--left {
      .tooltip {
        left: initial;
        right: 3em;
        top: -0.5em;
      }
    }
  }
  &__multipleItem__more-values--list {
    padding-left: 1rem;
    text-align: left;
    margin: 0;
  }
  &-data {
    justify-content: space-between;
    flex-direction: column;
    margin-top: 8%;
    display: flex;
    align-items: flex-start;
    text-align: start;
    flex-grow: 1;
    max-height: none !important;
    &-peers {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-top: 8%;
      align-items: flex-start;
      text-align: start;
      width: 100%;

      &-detailed {
        flex-direction: row;
        margin-top: 0;
        justify-content: space-between;
        margin-left: 5%;
      }
    }
    &-title {
      @include font-family;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #757575;
      flex-basis: 40%;
      &-peers {
        font-size: 11px;
        padding-top: 11%;
      }
    }
    &-item {
      @include font-family;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 4%;
      padding-bottom: 4%;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #212121;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex-grow: 1;
      &-peers {
        padding-top: 11%;
        font-size: 11px;
      }

      &-detailed {
        white-space: unset;
      }
    }
  }
}

.linksTile {
  &__body {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    padding-top: 0.5rem;
    position: relative;
    &--no-data {
      height: 100%;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__link {
    &-container {
      display: flex;
      margin-top: 1rem;
      cursor: pointer;
      text-decoration: none;
      border-color: transparent;
      border-style: solid;
      &:hover {
        border-color: $primary;
        border-radius: 8px;
      }
    }
    &-data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    &-icon {
      width: 2.5rem;
      height: 2.5rem;
      background: linear-gradient(
        180.75deg,
        #eef5f8 0.64%,
        rgba(238, 245, 248, 0) 135.65%
      );
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
      flex-grow: 0;
    }

    &-text {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      color: $neutral-black;
    }
    &-label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
    &-description {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      color: $dark-grey;
    }

    &-external {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.5rem;
    }
  }

  &__add {
    display: flex;
    margin-top: 1.5rem;
    background-color: #ffffff;
    border: none;
    align-items: center;
    cursor: pointer;
    &-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $primary;
      margin-left: 0.25rem;
    }
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.limit-size {
  text-align: center;
  min-height: 21.5rem;
  > div {
    max-height: 21.5rem;
  }
}

.vertical-bar-chart__container {
  div {
    overflow: visible !important;
  }
}

.bar-chart-metric-label {
  position: absolute;
  letter-spacing: -0.5px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  line-height: 14px;
}

.tabularCDPView {
  &__container {
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem 0;
    width: 100%;
  }

  &__data-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    &-left {
      width: 47.5%;
      &--full-width {
        width: 100%;
      }
    }

    &--sm {
      width: 24%;
    }

    &--detailed {
      flex-wrap: wrap;
      justify-content: flex-start;
      column-gap: 1rem;
      row-gap: 1rem;
    }

    &-right {
      width: 47.5%;
      //display: flex;
      &--margin-top {
        margin-top: 1.375rem;
      }
    }
  }

  &__subtitle {
    &-wrapper {
      margin-bottom: 1rem;
    }
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    position: relative;
    .tooltip {
      top: 1.5rem;
      z-index: 2;
      opacity: 0;
      &--bottom__arrow {
        left: 10%;
      }

      &__label {
        height: 0;
      }
    }

    &:hover {
      .tooltip {
        opacity: 1;

        &__label {
          height: 100%;
        }
      }
    }
  }

  &__label-box {
    display: flex;
    justify-content: space-between;
    column-gap: 0.5rem;
    margin-bottom: 0.5rem;
    background: #f7f7f7;
    border-radius: 4px;
    padding: 0.5rem 1rem;

    &--dotted {
      margin: 0;
      padding: 0.75rem 0 0.75rem 1rem;
      border-bottom: 1px dashed #e0e0e0;
      background-color: white;
    }

    &--top-dotted {
      border-top: 1px dashed #e0e0e0;
    }

    &--grey {
      background-color: #f7f7f7;
    }

    &--missed {
      background-color: #f0f8ee;
    }
    &--pd-0 {
      padding: 0;
      background-color: white;
    }
    &--br-8 {
      border-radius: 8px;
      background-color: #f9f9f9;
    }
  }

  &__value {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    &--fs-12 {
      font-size: 12px;
    }
    &--gray {
      display: flex;
      justify-content: center;
      background-color: #f7f7f7;
      padding: 0.8rem 1rem;
      width: 50%;
      border-radius: 2px;
    }
  }

  &__metricHeader {
    max-width: 50rem;
    font-size: 12px;
    text-align: left;
    .tooltip {
      display: none;
      z-index: 1;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }

  &__metricName {
    max-width: 50rem;
    .tooltip {
      display: none;
      z-index: 1;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }

  &__label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    &-risk {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: left;
    }
    &--white {
      background-color: white;
      padding: 0.8rem 1rem;
    }
    &-container {
      display: flex;
      align-items: center;
    }
    .tooltip {
      display: none;
      z-index: 1;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }

  &__guidance {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    svg path {
      fill: #43b02a;
    }
  }
  &__note {
    display: flex;
    &-label {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__no-data-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__accordion {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1.25rem;
    &-item {
      width: 100%;
    }
    &-header {
      display: flex;
      width: 100%;
      background-color: white;
      border: 1px solid #e0e0e0;
      border-top: none;
      padding: 1rem 1.125rem;

      & svg path {
        fill: black;
      }
      &-text {
        margin-left: 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        &--peer {
          margin: 0 0 1rem 0;
        }
      }
      &--top-border {
        border-top: 1px solid #e0e0e0;
      }
    }
    &-body {
      display: flex;
      width: 100%;
      background: #ffffff;
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #e9e9e9;
      padding: 1rem 2.5rem;
      flex-direction: column;
    }
    &-collapsable {
      display: none;
      width: 100%;
      &--show {
        display: flex;
      }
    }
    &-actions {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1.25rem;
      column-gap: 2rem;
    }
    &-btn {
      display: flex;
      border: none;
      background-color: white;
      color: $primary;
      cursor: pointer;
      column-gap: 0.25rem;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      & svg path {
        fill: $primary;
      }
      padding: unset;
    }
  }
  &__relevant {
    &-no-data {
      width: 100%;
      text-align: left;
      padding: 1rem 0;
      font-size: 0.785rem;
      &-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }

    &-table {
      width: 100%;
      border-spacing: 0 0.5rem;

      & thead {
        & th {
          text-align: left;
          padding: 0.5rem 1rem;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }
      }
      & tbody {
        & tr {
          background: #f9f9f9;
          border-radius: 8px;

          & td {
            text-align: left;
            padding: 0.5rem 1rem;
            border: none;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            position: relative;

            .tooltip {
              display: none;
              z-index: 10;
              top: 60%;
              .tooltip__arrow {
                left: 15%;
              }
              .tooltip__label {
                max-width: 10rem;
              }
            }
            &:hover {
              .tooltip {
                display: flex;
              }
            }
          }
          & td:nth-child(1) {
            font-weight: 400;
            line-height: 18px;
          }

          & td:nth-child(1) {
            font-weight: 400;
            line-height: 18px;
          }

          & td:nth-child(1) {
            font-weight: 400;
            line-height: 18px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
          & td:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }
      &-row {
        & td {
          &:nth-child(1) {
            background-color: white;
          }
          &:nth-child(2) {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
        }
      }

      &-row-industry {
        background-color: white;
        & td {
          background-color: #f9f9f9;
          border-radius: 2px;
          &:nth-child(1) {
            background-color: white;
            padding-left: 0.5rem;
          }
        }
      }

      &--tableView {
        border-spacing: 0.5rem;
        & thead {
          & th {
            font-weight: 600;
            &:nth-child(1) {
              padding-left: 0.5rem;
            }
          }
        }
        & tbody {
          & tr {
            & td {
              font-weight: 400;
              line-height: 18px;
              &:last-child {
                border-top-right-radius: unset;
                border-bottom-right-radius: unset;
                border-radius: 2px;
              }
            }
          }
        }
      }
    }
  }
}

.left-data {
  text-align: left;
}
.data {
  font-size: 0.75rem;
}

.energySpendChart {
  &__container {
    width: 100%;

    display: flex;
    justify-content: center;

    &--col {
      flex-direction: column;
    }
    &--big-top {
      margin-top: 0.9375rem;
    }
    &--small-top {
      margin-top: 0.5rem;
    }

    &--medium-top {
      margin-top: 0.5rem;
    }

    &--no-data {
      align-items: center;
    }
  }
  &__value {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $dark-blue;
    text-align: left;
    position: relative;

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    &-tooltip {
      display: flex;
      overflow: hidden;
      flex-direction: column;
      top: 0;
    }

    &-tooltip-neutral {
      display: flex;
      top: -110%;
    }

    &--special {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
    &--center {
      text-align: center;
    }
    &--horizontal-padding {
      padding: 0 1.5rem;
    }

    &--small {
      font-size: 12px;
      line-height: 16px;
    }
    &--black {
      color: $neutral-black;
    }

    &--gray {
      color: $dark-grey;
    }

    &--fix-wdith {
      min-width: 60%;
      max-width: 60%;
      flex-grow: 1;
    }
    &--light-font {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    &--tall-font {
      line-height: 32px;
      font-weight: 600;
      font-size: 14px;
    }

    &--grow {
      flex-grow: 1;
    }

    &--number {
      font-size: 40px;
      line-height: 48px;
    }

    &--right {
      text-align: right;
    }

    &--number-2 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 32px;
      color: $dark-blue;
    }

    &--number-3 {
      font-size: 24px;
    }
  }

  &__unit {
    margin-left: 0.5rem;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $neutral-black;

    &-2 {
      margin-left: 0.5rem;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: $dark-grey;

      &--table {
        color: $neutral-black;
        line-height: 16px;
      }
    }
  }

  &__name {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    min-width: 30%;
    max-width: 30%;
  }
  &__row {
    display: flex;
    column-gap: 1.625rem;
    align-items: center;
    justify-content: space-between;
    &--dashed-border {
      border-width: 0 0 1px 0;
      border-style: dashed;
      border-color: #e6e6e6;
      padding: 1rem 0.5rem;
    }
    &--dashed-top-border {
      border-width: 1px 0;
    }
    &--table {
      justify-content: space-between;
    }
  }
  &__rows-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    width: 100%;

    &--no-row-gap {
      row-gap: unset;
    }
    &--min-row-gap {
      row-gap: 0.25rem;
    }
  }

  &__empty-values {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding-top: 2rem;
  }

  &__no-data {
    font-size: 0.785rem;
    font-weight: 700;
    margin-top: 2rem;
  }

  &__justify-unset {
    justify-content: unset;
  }
}

.general-neutral-value {
  &--color.general-neutral-value--color {
    color: #005587;
  }
}

.customLegendMarker {
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 0.65rem;
  border-width: 2px;
  border-style: solid;
  margin-right: 0.5rem;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltip {
  max-width: 25rem;
  &__label {
    max-width: 50rem;
    max-height: unset;
  }
  .tooltip__arrow {
    display: none;
  }
}

.TimeChart {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &__chartContainer {
    text-align: center;
    justify-content: center;
  }
  &__metrics {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #212121;
    margin: 0.5rem;
  }
  &__metricLeft {
    margin-left: 1.4rem;
    &-container {
      display: inline-block;
    }
  }
  &__metric--12 {
    font-size: 0.75rem;
  }
  &__metric-button {
    line-height: unset;
  }
  &__metric_row {
    display: flex;
    flex-direction: column;
    width: 10rem;
    padding: 0.4rem;
  }
  &__metricTextRowTable {
    width: 33.33%;
    padding: 0.2rem;
  }
  &__metric_column {
    display: flex;
    flex-direction: row;
  }
  &__metricsValues {
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 2rem;
    min-width: 40px;
    gap: 1.6rem;
    padding-bottom: 0.2rem;
  }
  &__NoDataField {
    justify-content: center;
  }
  &__metricsTableValues {
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 25px;
    gap: 0rem;
    justify-content: center;
  }
  &__circularIcon {
    padding-top: 0.2rem;
  }

  &__metricUnit {
    color: #757575;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #757575;
    margin: 0.2rem;
  }
  &__metricTextRow {
    padding: 0.2rem;
    width: 13rem;
  }
  &__fieldValues {
    text-align: left;
  }
  &__fieldPeerValues {
    font-weight: 600;
    font-size: 1rem;
  }

  &__fieldTextValues {
    color: #757575;
    font-weight: 400;
    font-size: 14px;
    margin: 0.2rem;
    text-align: center;
  }
  &__noData {
    text-align: center;
    font-weight: 600;
    font-size: 1.4rem;
    margin-left: 1%;
  }
  &__fieldTextValuesRight {
    color: #757575;
    font-weight: 400;
    font-size: 0.75rem;
    margin: 0.2rem;
    text-align: left;
  }
  &__chartForPeer {
    width: 100%;
    font-size: 12px;
  }

  &__PeerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    gap: 0.2rem;
  }

  &__metricView {
    border-radius: 4px;
    text-align: center;
    justify-content: center;
    padding: 0.5rem;
    margin: 0.5rem;
    max-width: 32%;
    min-width: 29%;
  }
  &__metricTitle {
    background: linear-gradient(
      180.75deg,
      #eef5f8 0.64%,
      rgba(238, 245, 248, 0) 135.65%
    );
    text-align: left;
    padding-bottom: 0.5rem;
    padding-top: 0.4rem;
  }
  &__metricsForPeers {
    display: flex;
    padding: 0.2rem;
    justify-content: space-between;
    gap: 0.5rem;
    &-fieldValue {
      margin-right: 2.5rem;
    }
  }
  &__metricName {
    text-align: left;
    width: 7rem;
    margin-left: 0.3rem;
    word-wrap: break-word;
    white-space: break-spaces;
  }
  &__table_PeerIndustry {
    width: 100%;
  }
  &__tableCompanyName {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    max-width: 8rem;
    word-wrap: break-word;
  }
  &__tableFieldValue {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #212121;
    &-industry {
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 16px;
      text-align: left;
      color: #212121;
    }
  }
  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #212121;
  }
  &__label-button {
    line-height: unset;
    font-size: 12px;
  }

  td {
    border-bottom: 1px dashed #d0d0ce;
    padding: 0.75rem 0.1rem;
  }
}

.chart {
  &__range-score {
    @include tile-score-range;
  }

  &__legend {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  &__legend-value {
    font-size: 0.75rem;
    color: $dark-grey;
    display: flex;
    gap: 0.3rem;
    align-items: center;
  }

  &__legend-null-indicator {
    background-color: $light-grey;
  }
  &__legend-indicator {
    width: 1.5rem;
    height: 0.5rem;
    border-radius: 0.25rem;
    &--detailed {
      width: 0.5rem;
      aspect-ratio: 1;
    }
  }
}

.circular-bar-chart,
.businessEthicsAndFraudScore,
.productSafetyAndQualityGradient {
  &__chart-container {
    justify-content: left;
  }
  &__chart-bar {
    width: 100%;
  }
}
