.tableChart{
    &__container{
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__table{
        width: 100%;

        &-header{
            
            & tr th {
                border-bottom: 1px dashed $light-grey;
                padding: 0.75rem 0.25rem;
                overflow-wrap: break-word;
                inline-size: 5rem;
          
                &:first-child {
                  padding-left: 0;
                }
                &:last-child {
                  padding-right: 0;
                }
            }

            &-label-container{
                text-align: center;
            }

            &-label{
                text-align: center;
                font-size: 0.75rem;
                font-weight: 400;
                &--left{
                    text-align: left;
                }
            }

           
        }

        &-body{
            & tr td {
                border-bottom: 1px dashed $light-grey;
                padding: 0.75rem 0.5rem;
                overflow-wrap: break-word;
                inline-size: 5rem;
                &:first-child {
                  padding-left: 0;
                }
                &:last-child {
                  padding-right: 0;
                }
            }
            &-cell--border-top{
                & tr:first-child{
                    & td{
                        border-top: 1px dashed $light-grey;
                    }
                }
            }
            
            &-value{
                text-align: center;
                font-size: 0.75rem;
                font-weight: 400;
                position: relative;
              
                &--left{
                    text-align: left;
                }
                &--bold{
                    font-weight: 600;
                }
                &--right{
                    text-align: right;
                }
                &--center{
                    text-align: center;
                }
            }
        }
    }

    &__missing-data-msg{
        margin: 2rem 1rem 0rem 0.5rem;
        align-items: center;
        font-size: 0.785rem;
        display: flex;
        font-weight: normal;
        text-align: left;
      }

      &__fit-content {
        width: fit-content;
        display: inline-block;
       
      }

      &__fit-content-reporting {
        width: fit-content;
        display: inline-block;
        margin-right:0.3rem;
       
      }

      &__text--line-height{
        line-height: unset;
      }

      &__text--top{
        vertical-align: top;
      }
}

