.pillarScore{

    &__dataset {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 1rem 0;
        margin-bottom: 1rem;
    
        &-info {
          padding: 0.5rem 2rem;
          font-size: 12px;
          color: $grey;
        }
      }
    &__data {
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        padding: 0.3rem 1rem;
        box-sizing: border-box;
        width: 50%;
    
        &--group {
          width: 100%;
        }
    
        &--custom {
          width: auto;
        }
    
        &--centered {
          margin: 0 auto;
        }
    
        &--value > span:first-child {
          max-width: max-content !important;
        }
    
        &--color {
          width: 0.65rem;
          height: 0.65rem;
          border-radius: 0.65rem;
          margin-right: 0.5rem;
          aspect-ratio: 1;
        }
    
        &--line-chart-color {
          @extend .customLegendMarker;
          border-color: #227786;
        }
    
        &--bubble-chart-color {
          @extend .customLegendMarker;
          border-color: #42c2ff;
        }
    
        &--value {
          display: flex;
          flex-grow: 1;
          column-gap: 0.75rem;
    
          > span:first-child {
            min-width: 2rem;
            max-width: 5rem;
          }
        }
      }

      &__wrapper{
        display: flex;
        width: inherit;
        flex-direction: column;
    }

    &__header__sub-text{
      font-size: 0.75rem;
      padding: 0 0 1.5rem 0.5rem;
      color: $grey;
  }
}