.RequestUploadPanel {
  &__body {
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    overflow-x: hidden;
    height: calc(100% - 4.5rem);
    font-size: 14px;
    padding: 0 1rem;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 0.9rem 1rem;
    box-sizing: border-box;
    gap: 0.5rem;
  }

  &__field {
    border: 1px solid #d0d0ce;
    border-radius: 4px;
    padding: 0.75rem 1rem;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: "Open Sans";
    line-height: 24px;
    color: #63666a;
    &-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }

    &-wrapper:first-child {
      margin-top: 1rem;
    }

    &-label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0.5rem;
    }
    &-textarea {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #63666a;
      border: 1px solid #d0d0ce;
      border-radius: 4px;
      padding: 0.75rem 1rem;
      height: 8.5rem;
      resize: none;
    }
  }
  &__character-limit {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #63666a;
    margin-top: 0.25rem;
  }
}
