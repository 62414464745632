.TableOfContents {
  max-height: 60vh;
  overflow-x: overlay;
  border: 1px solid $lightest-grey;
  border-bottom: none;

  &__container {
    flex-grow: 1;
  }

  &__secondHeader {
    @include font-family;
    height: 48px;
    background: #ffffff;
    box-shadow: inset 0px -1px 0px #ebebeb;

    display: flex;
    flex-direction: row;
    align-items: center;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    &-icon {
      margin: 0 0.5rem;
    }

    & input {
      border: none;
      outline: none;
    }

    &-collapse {
      color: $primary;
      cursor: pointer;
      text-align: right;
      font-size: 13px;
      margin-right: 1rem;
    }

    &-show {
      color: $primary;
      cursor: pointer;
      text-align: right;
      font-size: 13px;
      margin-right: 1rem;
    }
  }

  &__searchDocument {
    display: flex;
    flex-grow: 1;
  }

  &__actions {
    display: flex;
  }

  &__row {
    @include font-family;
    width: 100%;
    min-height: 48px;
    height: fit-content;
    left: 0px;
    background: #ffffff;
    box-shadow: inset 0px -1px 0px #ebebeb;
    align-items: center;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    &-icon {
      margin-left: 5px;
      cursor: pointer;
      position: relative;

      .tooltip {
        left: 100%;
        top: 0;
        z-index: 2;
        display: none;
      }

      &:hover {
        .tooltip {
          display: inline-block;
        }
      }
    }

    &-icon2 {
      left: 15%;
      height: 1.25rem;
      padding: 0 0.5rem;
      cursor: pointer;
    }

    &-content {
      left: 15%;
      width: 80%;
      height: 100%;
      cursor: pointer;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 0.5rem;
      padding-top: 3px;
      padding-bottom: 3px;
    }

    &-exists {
      height: 48px;
      background: yellow;
      width: 3px;
    }

    &--selected {
      background-color: $lightest-blue;
      box-shadow: inset 3px 0px 0px $primary;
    }

    &__item {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0.75rem 1rem;
      color: $dark-grey;
      font-size: 0.875rem;
      line-height: 1.5rem;
    }
  }

  &__row:hover {
    background: #f1f8fb;
    box-shadow: inset 3px 0px 0px #007cb0, inset 0px -1px 0px #ebebeb;
    // inset -3px 0px 0px #ffe67f,
  }
  &__keywords {
    width: 1.5rem;
    padding-left: 0.7rem;
  }
}
