.ESG {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  position: relative;
  margin-top: 6rem;
  &__Search {
    max-height: none !important;
  }

  &__content {
    flex-grow: 1;
    margin-left: $side-menu-size;
  }

  .loading-icon {
    @include loading-icon;

    &--center {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
}

.Downloads.content-download {
  height: calc(100% - 6rem);
  margin-top: 6rem;

  @media screen and (max-width: 1300px) {
    height: calc(100% - 9rem);
  }
}

.Downloads.dashboard-download {
  height: calc(100% - 6.7rem);
  margin-top: 6rem;
}

.activeMaintenanceMessage-Powerbi {
  &-0,
  &-1,
  &-3 {
    height: 100%;
  }
  &-2 {
    height: 82%;
  }
}

.adjustHeight {
  height: 100%;
}

.messageCountBasedHeight{
  &-0{
    height:100%;
  }
 
  &-1{
    height:85%;
  }
  &-2{
    height:77%;
  }
  &-3{
    height:67%;
  }
}