.action-container {
  &__actions {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    text-align: right;
    display: flex;
    gap: 0.5rem;

    button:nth-child(1) {
      margin-right: 1rem;
      padding: 0.5rem 1.5rem;
    }

    &-disabled {
      color: $dark-grey;
      background-color: transparent !important;
    }
  }

  &__invisible-button {
    color: $primary;

    &-disabled {
      color: $dark-grey;
    }
  }

  &-disabled {
    color: $dark-grey;
  }
}
