.maintenance-modal {
  width: 30rem;
  min-height: 5rem;
  height: auto;
  left: 760px;
  top: 410px;
  display: flex;
  flex-direction: column;

  &__region-container {
    position: relative;
    width: 50%;
  }

  &__chevron {
    margin-top: 0.1rem;
    margin-right: 0.2rem;
  }

  &__label {
    font-size: 14px;
    margin-bottom: 0.3rem;
    font-weight: 600;
  }

  &__message-input {
    width: 100%;
    font-family: "Open Sans";
    height: 7rem;
    padding: 0.5rem;
    border: 1px solid #d0d0ce;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
    resize: none;
    font-size: 0.95rem;
    font-weight: none;
    &:hover {
      border-color: $primary;
    }
  }

  &__outline {
    width: 100%;
    padding: 0.2rem 0.2rem;
    height: 1.5rem;
  }

  &__outlineContainer {
    width: 100%;
  }

  &__outlineForTimezone {
    width: 100%;
    padding: 0.2rem 0rem;
    height: 1.5rem;
  }

  &--maintenance {
    width: 22rem;
  }
  &--deleteSearch {
    min-height: 5.3rem;
  }

  &__input {
    width: 100%;
  }

  &__input-container {
    font-size: 14px;
    margin-bottom: 0.8rem;
  }

  &__action-container {
    margin-top: 0.5rem;
    justify-content: end;
    position: inherit;
  }

  &__contentError {
    font-size: 14px;
    color: #f54242;
  }

  &__character-count {
    text-align: right;
    padding-top: 0.25rem;
    font-size: 12px;
    color: #63666a;
    padding-right: 0.5%;
  }

  &__disabled {
    cursor: none;
    opacity: 0.5;
    pointer-events: none;
  }
}

.maintenance__btn {
  &.maintenance__btn {
    padding: 0.5rem 1.5rem;
  }
}
