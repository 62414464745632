.SavedSearchTable {
  width: 100%;
  border-radius: 8px;
  font-weight: 400;
  overflow-y: hidden;

  &__shared-with-count {
    width: 1.5rem;
    padding-left: 0.2rem;
    margin-left: 0.2rem;
    position: relative;
    &-tooltip {
      line-height: 1rem;
      max-width: 10rem;
      max-height: 6rem;
      overflow: auto;
    }

    svg:not(:root) {
      transform: translateX(8px) translateY(4px);
    }

    &:hover {
      background: $secondary-light-grey;
      cursor: pointer;
      height: 20px;
      border-radius: 15px;
    }
  }

  &__auto-delete {
    &-7 {
      color: $orange;
    }

    &-less-than-7 {
      color: $red;
    }
  }

  &__new-item {
    font-weight: 600;
  }

  &__link {
    cursor: pointer;
  }

  &__row {
    &:hover {
      background-color: transparent;
    }
  }

  &__column-content {
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
  }

  &__shared-with {
    width: 100%;
    gap: 0rem;
  }

  &__actions {
    gap: 0.5rem;
    justify-content: flex-end;

    &:hover {
      .tooltip {
        display: none;
      }
    }
  }

  &__action-icon {
    @include invisible-button;
    position: relative;
    cursor: pointer;
    .tooltip {
      display: none;
      top: -2rem;
      bottom: unset;

      &--top {
        &__arrow {
          left: 45%;
        }
      }
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
      background-color: $light-grey;
      border-radius: 32px;
    }
  }

  &__publish {
    .tooltip {
      left: -2.7rem;
    }
  }

  &__unpublish {
    .tooltip {
      left: -3rem;
    }
  }

  &__share {
    .tooltip {
      left: -1.2rem;
    }
  }

  &__edit {
    .tooltip {
      left: -0.8rem;
    }
  }

  &__delete {
    .tooltip {
      left: -1.4rem;
    }
  }

  &__topic {
    &.tooltip {
      max-width: 8rem;
    }
  }

  &__text-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 1px;
    flex-grow: 1;
  }

  &__column-text {
    font-weight: 400;
    font-size: 0.875rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
    .tooltip {
      left: -4rem;
      top: -200%;
    }
  }

  &__table-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__notAdmin-table-container {
    padding-top: 1rem;
  }

  &__data {
    padding: 0.5rem 1rem;
    border: 1px solid $lightest-grey;
    border-radius: 0.25rem 0.25rem 0 0;
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  &__results-count {
    font-size: 0.875rem;
  }

  &__table {
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;
    border-collapse: collapse;
    font-size: 0.875rem;
    border: 1px solid #ebebeb;
    border-top: none;

    &-wrapper {
      // position: relative;
      height: 48vh;
      overflow-y: auto;
      flex-grow: 1;
      box-shadow: inset 0px -1px 0px $lightest-grey;

      @media screen and (min-width: 1400px){
        height: 58vh;
      }
  
      @media screen and (min-width: 1600px){
        height: 61vh;
      }

      @media screen and (width: 1272px){
        height: 49.35vh;
      }
    }

    &-notAdmin-wrapper {
      height: 52vh;
      overflow-y: auto;
      flex-grow: 1;
      box-shadow: inset 0px -1px 0px $lightest-grey;

      @media screen and (min-width: 1400px){
        height: 58vh;
      }
  
      @media screen and (min-width: 1600px){
        height: 61vh;
      }

      @media screen and (width: 1272px){
        height: 49.35vh;
      }
    }

    &-inner-wrapper {
      // position: absolute;
      width: 100%;
    }

    &__header {
      &-content {
        position: static;
        width: auto;
        line-height: 1rem;
        text-align: left;

        &:last-child > div {
          justify-content: flex-end;
        }
      }

      &-text {
        font-weight: 600;
        font-size: 0.75rem;

        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }

    th:nth-child(1),
    td:nth-child(1) {
      width: auto;
    }

    th:nth-child(9),
    td:nth-child(9) {
      width: 6rem;
    }

    tr {
      box-sizing: border-box;
    }

    tr {
      width: 100%;
    }

    th,
    td {
      flex: 1;
    }

    th > div {
      padding: 0;
    }

    td,
    th {
      padding: 0.5rem 1rem;
    }

    thead {
      position: sticky;
      background: $white;
      z-index: 1;
      top: -1px;
    }

    @media screen and (min-width: 1200px) {
      tr {
        display: flex;
        align-items: center;
      }

      thead th:nth-child(1),
      tbody td:nth-child(1) {
        flex: 0 0 12%;
        min-width: 8rem;
      }

      thead th:nth-child(2),
      tbody td:nth-child(2) {
        flex: 0 0 11%;
        min-width: 7.5rem;
      }

      thead th:nth-child(3),
      tbody td:nth-child(3) {
        min-width: 4rem;
      }

      thead th:nth-child(4),
      tbody td:nth-child(4) {
        min-width: 4rem;
      }

      thead th:nth-child(5),
      tbody td:nth-child(5) {
        flex: 0 0 8%;
        min-width: 4rem;
      }

      thead th:nth-child(6),
      tbody td:nth-child(6) {
        flex: 0 0 8%;
        min-width: 4rem;
      }

      thead th:nth-child(7),
      tbody td:nth-child(7) {
        flex: 0 0 7%;
        min-width: 4rem;
      }

      thead th:nth-child(8),
      tbody td:nth-child(8) {
        min-width: 5rem;
      }

      thead th:nth-child(9),
        tbody td:nth-child(9) {
        min-width: 6rem;
      }
    }
  }
}
