.peer-benchmark {
  display: flex;
  height: calc(100% - 3rem);
  flex-direction: column;
  width: 100%;
  margin-top: 3rem;
  &--padding {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  &__editabled-input {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    margin-right: 1rem;
    border: none;
    background-color: transparent;
    width: 27rem;

    &--active {
      height: fit-content;
      color: #212121;
      border: 1px solid #d0d0ce;
      box-sizing: border-box;
      border-radius: 4px;
      outline: none;
    }

    &--error {
      border: 1px solid #f54242;
    }
  }

  &__header {
    padding: 0.6rem 0rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    position: relative;

    &-not-editable-title {
      margin-right: 1rem;
      position: relative;
      white-space: nowrap;
      .tooltip {
        display: none;
        top: 2rem;
        max-width: 20rem;
        height: fit-content;
        line-height: 14px;
      }
      .tooltip__label {
        white-space: pre-wrap;
        overflow-wrap: break-word;
      }

      &:hover {
        .tooltip {
          display: inline-block;
          z-index: 500;
        }
      }
    }
    &-pencil-container {
      cursor: pointer;
      line-height: normal;
      margin-right: 1rem;
      cursor: pointer;
      &:hover {
        background-color: $light-grey;
        transition: 0.7s;
        border-radius: 4px;
      }
    }
    &-for-current {
      padding-left: 3.35rem;
      padding-right: 3rem;
    }
    &-actions {
      display: flex;
      align-items: center;
    }
    &-title {
      display: flex;
    }
    &-name {
      align-items: center;
      justify-content: center;
      // flex-direction: column;
      & > svg path {
        fill: #007cb0;
      }
    }
  }

  &__back-button {
    cursor: pointer;
    font-size: 1rem;
    position: absolute;
    left: 1.2rem;
    top: calc(50% - 0.7rem);

    &:hover {
      background-color: #d9d7d7;
      transition: 0.7s;
      border-radius: 4px;
    }
  }

  &__empty-container {
    display: flex;
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }
  &__name {
    &-container {
      display: flex;
      align-items: center;
      position: relative;
    }

    &-error {
      position: absolute;
      bottom: -1.7rem;
      font-size: 14px;
      line-height: 24px;
      color: #f54242;
      font-weight: 500;
    }
  }
  &__empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin-top: 4rem;
  }
  &__empty-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $black;
    font-style: normal;
  }

  &__img {
    margin-bottom: 2rem;
  }

  &__empty-redirect {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    color: #75787b;

    font-style: normal;
  }
  &__link {
    border: none;
    background-color: transparent;
    padding: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &::before {
      content: "\00a0";
    }
    &::after {
      content: "\00a0";
    }
  }

  &__options {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-shadow: inset 0px 1px 0px #e0e0e0;
    margin-top: 2rem;
    padding: 1rem;
    &--btn {
      margin-top: 1rem;
      margin-right: 2rem;
    }

    &-save {
      margin-left: 1.5rem;
    }
  }

  &__trend-indicator {
    display: flex;
    padding: 1rem;
    font-size: 0.75rem;
    column-gap: 0.5rem;
    padding-left: 0rem;
  }

  &__no-data {
    font-size: 0.75rem;
  }

  &__export-button {
    @include invisible-button;
    @include font-family;
    display: flex;
    align-items: center;
    color: $primary;
    margin-right: 1rem;
    position: relative;
    padding: 0.5rem 0.5rem;
    cursor: pointer;
    .tooltip {
      top: -1.2rem;
      left: -1.2rem;
      display: none;
    }
    &:hover {
      .tooltip {
        display: inline-block;
      }
      background-color: $light-grey;
      color: $secondary-blue;
      transition: 0.7s;
      border-radius: 4px;
    }
  }

  &__edit-button {
    @include invisible-button;
    @include font-family;
    display: flex;
    align-items: center;
    color: $primary;
    margin-right: 1rem;
    padding: 0.5rem 0.5rem;
    &:hover {
      background-color: $light-grey;
      color: $secondary-blue;
      transition: 0.7s;
      border-radius: 4px;
      path {
        fill: $secondary-blue;
      }
    }
  }

  &__export-button-disabled {
    @include invisible-button;
    @include font-family;
    align-items: center;
    border: none;
    background-color: transparent;
    position: relative;
    cursor: auto;
    display: flex;
    color: $grey;
    flex-direction: row;

    path {
      fill: $grey;
    }

    .tooltip {
      top: -1.7rem;
      left: -3rem;
      z-index: 5;
      display: none;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }

    svg {
      cursor: pointer;
    }
  }
  &__current-content {
    height: $peer-benchmark-grid-height;
    width: 94vw;
    @media screen and (max-width: 1400px) {
      height: $peer-benchmark-grid-height-sm;
    }
  }

  &__active-filter-wrapper {
    padding: 0 2rem;
  }

  &__start-new-btn {
    transition: background-color color 0.3s ease-in-out;
    &:hover {
      background-color: #007cb0;
      color: white;
      transition: 0.3s !important;
    }
  }

  .data-source {
    padding: 0 0 1rem 0;
    font-size: 0.875rem;
  }
}
