.filter-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  opacity: 0;
  transition: all 200ms ease-in-out;
  z-index: -1;
  height: fit-content;

  &--show {
    opacity: 1;
    z-index: 9999;
  }

  &__back {
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: -20.4em;
    margin-left: 1rem;
    padding: 0.5rem;
    margin-top: 1rem;
    width: 24rem;
  }

  &__back-to-today {
    @include invisible-button;
    display: flex;
    align-items: center;
    // padding: 0.5rem;
    color: $primary;
    width: 6rem;
  }

  &__content {
    width: 19.5rem;
    background-color: $white;
    border-radius: 0.5rem;
    transform: translateY(-50%);
    transition: all 200ms ease-in-out;
    height: fit-content;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.16);

    &--show {
      transform: translateY(0);
    }
  }

  &__header {
    padding: 1rem 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    max-height: fit-content;

    h1 {
      padding-left: 0.5rem;
      font-size: 1rem;
      font-weight: 600;
      margin: 0;
      font-family: Open Sans;
      font-style: normal;
      line-height: 1.5rem;
    }
    div {
      transform: translateY(0.2rem);
    }
  }

  &__close {
    @include invisible-button;
  }

  &__body {
    height: fit-content;
    padding: 0 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__calendar-options {
    display: flex;
    justify-content: flex-end;
    gap: 1.5rem;
  }

  &__options {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
  }

  &__outline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: $white;
    border: 1px solid $light-grey;
    border-radius: 0.25rem;
    height: 1.5rem;
    position: relative;
    &:hover,
    &:focus-within {
      border-color: $primary;
    }

    &__datepicker {
      position: absolute;
      top: 8.5rem;
      height: fit-content;
      left: 0;
    }
  }

  &__placeholder {
    position: absolute;
    color: $grey;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  &__input {
    padding: 0;
    height: 1rem;
    border: none;
    flex-grow: 1;
    width: 1px;
    outline: none;
    z-index: 1;
    resize: none;
    background-color: transparent;
    @include font-family;
    font-size: 0.875rem;
    line-height: 1.5rem;

    &:focus {
      background-color: $white;
    }
  }

  &__input-size {
    min-height: 1.25rem;
    min-width: 1px;
    max-width: 14rem;
    visibility: hidden;
    position: absolute;
  }

  &__reset {
    @include invisible-button;
    color: $primary;
    font-size: 14px;
    line-height: 1.5rem;
    padding: 0rem 1.5rem;
    &:hover {
      background-color: $light-grey;
      border-radius: 4px;
      transition: 0.7s;
      color: $secondary-blue;
    }
  }
  &__cancel {
    @include invisible-button;
    color: $primary;
    font-size: 14px;
    line-height: 1.5rem;
  }

  &__calendar-apply {
    @include invisible-button;
    color: $primary;
    font-size: 14px;
    line-height: 1.5rem;
  }

  &__calendar-apply-disabled {
    @include invisible-button;
    color: $grey;
    font-size: 14px;
    line-height: 1.5rem;
  }

  &__apply {
    @include primary-button;
    padding: 0.375rem 1.5rem;
    font-size: 14px;
    line-height: 1.5rem;

    &-disabled {
      @include primary-button-disabled;
      padding: 0.375rem 1.5rem;
      font-size: 14px;
      line-height: 1.5rem;
    }
  }
}
