$grey: #75787b;
$darker-grey: #e0e0e0;
$dark-grey: #757575;
$dark-grey-2: #a3a3a3;
$darkest-grey: #63666A;
$light-grey: #d0d0ce;
$lighter-grey: #f7f7f7;
$lightest-grey: #f0f0f0;
$secondary-light-grey: #ebebeb;
$omnia-grey: #e9e9e9;
$black: #000;
$white: #fff;
$primary: #007cb0;
$secondary-blue: #005478;
$secondary-blue-light: #00a3e0;
$dark-blue: #012169;
$dark-primary: #005587;
$light-blue: #a3cee0;
$lighter-blue: #e2eff4;
$lightest-blue: #f1f8fb;
$lightest-blue-2: #f7fbfc;
$red: #da291c;
$red-secondary: #c82518;
$dark-red: #941c13;
$yellow: #ffe67f;
$cool-gray: #53565a;
$green-secondary: #43b02a;
$green: #009a44;
$dark-green: #046a38;
$dark-green-2: #26890D;
$light-green: #86bc25;
$lightest-green: #F0F8EE;
$orange: #ed8b00;
$neutral-black: #212121;
$orange-secondary: #ec7016;
$green-blue: #00abab;
$light-yellow: #FFFBEA;
$dark-yellow: #FFCD00;

