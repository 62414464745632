.climateRelatedTopics {
  &__container {
    width: 100%;
    height: inherit;
    padding-top: 1rem;
  }
}

.climate-tabular {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-evenly;

  &--table {
    gap: 0rem;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__metric {
    width: calc(50% - 1rem);
    display: flex;
    flex-direction: column;
    border: 1px solid $lighter-blue;
    border-radius: 0.5rem;
    background-color: $lightest-blue-2;
    font-size: 0.75rem;
    font-weight: 600;

    &-width-5 {
      width: calc(20% - 1rem);
      @media screen and (max-width: 1703px) {
        width: calc(33.333% - 1rem);
      }
      @media screen and (max-width: 75rem) {
        width: calc(50% - 1rem);
      }
    }
    &-width-3 {
      width: calc(33.3% - 1rem);
    }
    &-width-1 {
      width: 100%;
    }

    &--main {
      width: 100%;
      text-align: center;

      & > div:first-child {
        flex-direction: row;
      }
    }

    &--table {
      background-color: transparent;
      border: none;
      width: 50%;
      &:nth-child(1) > .climate-tabular__metric-title--table {
        border-radius: 0.5rem 0 0 0;
      }
      &:nth-child(2) > .climate-tabular__metric-title--table {
        border-radius: 0 0.5rem 0 0;
      }
      &:nth-last-child(1) > .climate-tabular__data-container--table {
        border-radius: 0 0 0.5rem 0;
      }
      &:nth-last-child(2) > .climate-tabular__data-container--table {
        border-radius: 0 0 0 0.5rem;
      }
    }
  }

  &__metric-title {
    height: 1rem;
    padding: 1rem;
    line-height: 1rem;
    border-bottom: 1px dashed $light-blue;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--table {
      background-color: $lighter-grey;
      border: 1px solid $darker-grey;
    }

    span {
      font-size: 0.75rem;
      font-weight: 600;
      text-align: left;
    }
  }

  &__metric-title-regular {
    height: 1rem;
    padding: 1rem;
    line-height: 1rem;
    border-bottom: 1px dashed $light-blue;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--table {
      background-color: $lighter-grey;
      border: 1px solid $darker-grey;
    }

    span {
      font-size: 0.75rem;
      font-weight: 400;
      text-align: left;
      line-height: normal;
    }
  }

  &__metric-span {
    width: fit-content;
  }

  &__metric-data {
    color: $dark-blue;
    flex: 0 0 50%;
    &--table {
      color: $black;
      flex-grow: 1;
    }

    &--negative {
      color: $dark-grey;
    }

    &--crrm-1 {
      span {
        color: $dark-grey;
      }
    }
  }

  &__data-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0.7rem;
    flex-grow: 1;

    &--table {
      border: 1px solid $darker-grey;
      flex-direction: column;
      padding: 0;
      gap: 0;
    }

    &--detailed {
      flex-direction: column;
    }

    span {
      font-weight: 400;
      word-wrap: break-word;
      flex: 0 0 50%;
    }
  }

  &__data {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-grow: 1;
    justify-content: space-between;
    &--table {
      width: 100%;
      flex-direction: row;
      border-bottom: 1px dashed $darker-grey;
      padding: 0.75rem;
      box-sizing: border-box;
      &:last-child {
        border-bottom: none;
      }

      > span {
        min-width: 25%;
        width: 25%;
      }
    }

    &--detailed {
      width: 100%;
      flex-direction: row;
      > span {
        min-width: 25%;
        width: 25%;
        flex: 1;
      }
    }
    &--detailed-long {
      width: 100%;
      flex-direction: row;
      > span {
        min-width: 50%;
        width: 25%;
      }
    }
  }

  &__no-data-section {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: calc(100% - 2rem);
    margin-top: 2rem;
  }
}
