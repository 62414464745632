.TabularTile {
  &--style-4 {
    background-image: linear-gradient(
      180.75deg,
      #eef5f8 0.64%,
      rgba(238, 245, 248, 0) 135.65%
    );

    .TabularTile__metric-title {
      font-weight: 600;
    }
  }
  &--style-4-notCompany {
    .TabularTile__metric-value-header {
      font-weight: 600;
    }
    .TabularTile__metric-row-horizontal {
      flex-grow: 1;
    }
    .TabularTile__metric-value-wrapper-horizontal {
      width: 99%;
    }
    .TabularTile__metric-row-horizontal {
      border-width: 1px 0px;
      border-radius: 0 !important;
      border-bottom-style: dashed;
      border-color: #e0e0e0;
    }
    .TabularTile__metric-value-wrapper {
      border-width: 1px 0px;
      border-radius: 0 !important;
      border-top-style: dashed;
      border-color: #e0e0e0;
    }
  }

  &--style-4-Company {
    .TabularTile__metric-title {
      font-weight: 600;
    }
    .TabularTile__metric-row-horizontal {
      border: none;
    }
    .TabularTile__metric-value-wrapper {
      border: none;
      &-Yes,
      &-yes {
        color: $green-secondary;
        background-image: linear-gradient(
          180.75deg,
          #e3f2e5 0.64%,
          #ffffff 135.65%
        );
        border-radius: 8px;
      }

      &-No,
      &-no {
        color: $orange;

        background-image: linear-gradient(
          180.75deg,
          #f8f3ee 0.64%,
          rgba(248, 245, 238, 0) 135.65%
        );
        border-radius: 8px;
      }

      &-Not,
      &-not {
        color: #757575;

        background-image: linear-gradient(
          180.75deg,
          #ebecec 0.64%,
          rgba(250, 250, 250, 0) 135.65%
        );
        border-radius: 8px;
      }
      &-Neutral,
      &-neutral {
        background-color: #f7f7f7;
      }
    }
  }

  &--gov {
    .TabularTile__metric-value-wrapper {
      &-Yes,
      &-yes {
        color: $green-secondary;
        background-image: linear-gradient(
          180.75deg,
          #e3f2e5 0.64%,
          #ffffff 135.65%
        );
        border-radius: 8px;
      }

      &-No,
      &-no {
        color: $orange;

        background-image: linear-gradient(
          180.75deg,
          #f8f3ee 0.64%,
          rgba(248, 245, 238, 0) 135.65%
        );
        border-radius: 8px;
      }

      &-Neutral,
      &-neutral {
        color: $dark-primary;

        background-image: linear-gradient(
          180.75deg,
          #eef6f8,
          0.64%,
          rgba(238, 245, 248, 0) 135.65%
        );
      }

      &-Not,
      &-not {
        color: #757575;

        background-image: linear-gradient(
          180.75deg,
          #ebecec 0.64%,
          rgba(250, 250, 250, 0) 135.65%
        );
        border-radius: 8px;
      }
    }
  }

  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 0.75rem;
    width: 100%;
    padding: 0 0.5rem 0 0;
    text-align: left !important;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__metrics {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-horizontal {
      flex-direction: row;

      .TabularTile__metric-row-horizontal:last-child,
      .TabularTile__metric-row-horizontal:nth-last-child(2) {
        .TabularTile__metric-title {
          .tooltip {
            left: unset;
            right: 0;

            &__arrow {
              left: unset;
              right: 2%;
            }
          }
        }
      }
    }

    &-reporting-boundary {
      row-gap: 0.5rem;
      justify-content: unset;
    }
  }

  &__metric {
    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-climate-topics {
        justify-content: none;
        padding-bottom: 1.5rem;
      }
      &-hidden {
        display: none;
      }

      &-horizontal {
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;
        width: 5.1rem;
        &-governance-detailed {
          width: 7rem;
        }
        &-climate-topics-detailed {
          gap: 1rem;
          width: 10rem;
        }

        .TabularTile__metric-title-wrapper:first-child {
          max-height: 1.25rem;
        }
      }
      &-reporting {
        &-subtitle {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          & span:last-child {
            max-width: 40%;
            position: relative;
            .tooltip {
              display: none;
            }
            &:hover {
              .tooltip {
                top: 100%;
                display: flex;
                max-width: 10rem;
                z-index: 5;
              }
            }
          }
        }
      }
    }

    &-title {
      display: flex;
      gap: 0.25rem;
      align-items: center;

      &-wrapper {
        visibility: hidden;
        display: flex;
        align-items: center;
        flex: 1 1 0px;
        &-climate-topics {
          flex: none;
          max-width: 50%;
          width: 50%;
        }
        &-climate-topics-notCompany {
          flex: none;
          max-width: 39%;
          width: 39%;
        }
        &-governance-detailed:first-child {
          min-height: 7rem;
          text-align: center;
        }
        &-climate-topics-detailed:first-child {
          min-height: 3.5rem;
          text-align: center;
        }

        &-height {
          visibility: visible;
        }
        &--visible {
          visibility: visible;
        }
      }

      color: $black;
      position: relative;

      .tooltip {
        z-index: 2;
        display: none;
        top: 1rem;

        &__arrow {
          left: 5%;
        }
      }

      &:hover {
        .tooltip {
          display: block;
        }
      }

      &-reporting {
        white-space: unset;
        font-size: 0.75rem;
        text-align: left;
        line-height: unset;
        display: inline;
        margin-right: 2rem !important;
      }

      &-lbl {
        white-space: unset;
        font-size: 0.75rem;
        line-height: unset;
        display: inline;
        text-align: left;
      }

      &-container {
        margin-left: 0.375rem;
      }
    }

    &-icon {
      position: relative;

      .tooltip {
        z-index: 2;
        display: none;
        top: 1rem;

        &__arrow {
          left: 5%;
        }
      }

      &:hover {
        .tooltip {
          display: block;
        }
      }
    }

    &-values-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &-value-wrapper {
      border-radius: 0.5rem;
      background-color: $white;
      max-width: 6.75rem;
      flex: 1;
      box-sizing: border-box;

      &:last-child {
        margin-left: 1rem;
      }

      &-climate-topics {
        max-width: none;
      }
      &-climate-topics-notCompany {
        margin-left: 1rem;
      }

      &-horizontal {
        &:last-child {
          margin-left: 0rem;
        }

        max-width: none;
        width: 4.5rem;
        min-height: 2.5rem;
        display: flex;
        align-items: center;
        &-climate-topics-detailed {
          width: 8.5rem;
        }
      }

      &-header {
        padding: 0;
        background-color: transparent;
        text-align: center;
      }
      &-reporting-boundary {
        max-width: 40%;
      }
      &-detail {
        &:last-child {
          margin-left: 0;
        }
      }
    }

    &-value {
      font-weight: 600;
      font-size: 0.75rem;
      padding: 0.875rem 2rem;
      text-align: center;
      position: relative;
      &-ghg-gov--blue {
        color: $dark-blue;
      }

      .tooltip {
        display: none;
        top: 60%;
        left: -100%;
      }

      &:hover {
        .tooltip {
          display: block;

          &__arrow {
            left: 75%;
          }
        }
      }

      &-climate-topics-notCompany {
        padding: 0.6rem 0.6rem;
        font-size: 12px;
      }

      &-with-icon {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        padding: 0.875rem 2rem 0.875rem 1.875rem;
      }

      &-horizontal {
        flex-direction: column;
        padding: 1rem 1.6rem;
        width: 100%;

        &.multiple {
          padding: 0;
          width: 100%;
        }
      }

      &-header {
        font-weight: 400;
        padding: 0;
        font-size: 0.75rem;
        margin-bottom: 1rem;
        display: block;
        &-climate-topics {
          background: none !important;
        }

        &-hidden {
          display: none;
        }

        &.multiple {
          text-align: left;
          padding: 0;
        }
      }

      &-Yes,
      &-yes {
        color: $green-secondary;
        background-image: linear-gradient(
          180.75deg,
          #e3f2e5 0.64%,
          #ffffff 135.65%
        );
        border-radius: 8px;
        path {
          fill: $green-secondary;
        }
      }

      &-No,
      &-no {
        color: $orange;

        background-image: linear-gradient(
          180.75deg,
          #f8f3ee 0.64%,
          rgba(248, 245, 238, 0) 135.65%
        );
        border-radius: 8px;
        path {
          fill: $orange;
        }
      }

      &-Neutral,
      &-neutral {
        color: $dark-primary;

        path {
          fill: $dark-primary;
        }
      }
      &-Not,
      &-not {
        color: #757575;

        background-image: linear-gradient(
          180.75deg,
          #ebecec 0.64%,
          rgba(250, 250, 250, 0) 135.65%
        );
        border-radius: 8px;
        path {
          fill: $dark-primary;
        }
      }
      &-detail {
        color: #0076a8;
        background: linear-gradient(
          180.75deg,
          #eef5f8 0.64%,
          rgba(238, 245, 248, 0) 135.65%
        );
        border-radius: 8px;
      }
      &-climate-topics {
        background: linear-gradient(
          180.75deg,
          #ebecec 0.64%,
          rgba(250, 250, 250, 0) 135.65%
        );
        border-radius: 8px;
        min-height: 1.5rem;
        &-detailed {
          min-height: 4rem;
          display: flex;
          justify-content: center;
        }
        span {
          vertical-align: -webkit-baseline-middle;
        }
        &-yes {
          color: $green-secondary;
          path {
            fill: $green-secondary;
          }
        }

        &-no {
          color: $orange;
          path {
            fill: $orange;
          }
        }
        &-notapplicable {
          color: #757575;
        }
        &-neutral {
          color: $dark-primary;

          path {
            fill: $dark-primary;
          }
        }
      }
      &-reporting-boundary {
        background: linear-gradient(
          180.75deg,
          #eef5f8 0.64%,
          rgba(238, 245, 248, 0) 135.65%
        );
        color: #0076a8;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        padding: 0.875rem 1rem;
        border-radius: 8px;
        width: 100%;
        justify-content: center;
        &--gray {
          background: #f7f7f7;
          color: #212121;
        }

        .tooltip {
          left: -50%;
        }
      }
    }

    &-numeric {
      width: 100%;

      &-multiple {
        max-width: 13.5rem;
      }

      &-wrapper {
        margin-bottom: 0.5rem;
        display: flex;
        align-items: baseline;
      }

      &-value {
        font-size: 1.5rem;
      }

      &-title {
        margin-left: 0.5rem;
      }

      &-visual {
        position: relative;
        width: 100%;
        height: 10px;

        &-total {
          position: absolute;
          top: 50%;
          width: 100%;
          height: 1px;
          background-color: $light-grey;
        }

        &-score {
          position: absolute;
          top: 34.5%;
          height: 5px;
          background-color: $green;
          border-radius: 8px;

          &-multiple {
            background-color: $light-grey;
          }

          &-secondary {
            top: -2px;
            width: 1rem;
            height: 1rem;
            border-radius: 50px;

            &-tooltip {
              position: relative;
              height: 1rem;
              width: 1rem;

              .tooltip {
                display: none;
                top: -2.45rem;
                left: unset;
                right: 0;

                &__arrow {
                  display: none;
                }
              }

              &:hover {
                .tooltip {
                  display: block;
                }
              }
            }

            &-more {
              background: radial-gradient(
                50% 50% at 50% 50%,
                #90e74a 0%,
                #68bc25 49.86%,
                #046a38 100%
              );
            }

            &-less {
              background: radial-gradient(
                50% 50% at 50% 50%,
                #ffc700 0%,
                #ff9d00 49.86%,
                #c82518 100%
              );
            }
          }
        }
      }
    }
  }

  &--framework {
    &__company-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
    }

    &__company-data {
      width: 100%;
    }

    &__metrics {
      flex-direction: column;
      gap: 1rem;
    }

    &__metric-value-wrapper {
      width: 100%;
      min-height: 3rem;
      background: linear-gradient(
        180deg,
        $white 0%,
        rgba(238, 245, 248, 0.3) 100%
      );
      border: 1px solid #e2eff4;
      position: relative;
      margin: 0 !important;

      &--yes {
        background: linear-gradient(180deg, #e7f5f0 0%, #f1faf7 100%);
        border: 1px solid #6fc2b4;
      }
      &--neutral {
        border: 1px solid $grey;
      }

      .tooltip {
        top: 100%;
        transform: translateX(-50%);
        display: none;
      }

      &:hover {
        .tooltip {
          display: block;
        }
      }
    }

    &__metric-value {
      color: $dark-grey;
      font-weight: 400;
      padding: 0.875rem 0.5rem;
      position: relative;

      &--yes {
        font-weight: 600;
        color: #0d8390;
        font-size: 0.75rem;
      }
      &--neutral {
        font-weight: 600;
        padding: 0.5rem;
      }

      &-container {
        display: inline-block;
      }
      &--fs-12 {
        font-size: 0.75rem;
      }
    }

    &__metric-title {
      display: none;
    }

    &__metric-row {
      flex-grow: 1;
      gap: 0.5rem;
      width: 100%;
      flex-wrap: wrap;
    }

    &__neutral-label {
      font-size: 0.6rem;
      position: absolute;
      bottom: -0.25rem;
      left: 50%;
      transform: translateX(-50%);
      font-weight: normal;
    }
  }

  &__Governance {
    padding-top: 1.25rem;
    display: grid;
    width: 50%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.9375rem;
    margin-left: -0.3125rem;
    &-tile {
      display: flex;
      flex-direction: column;
      border: 1px solid #f0f0f0;
      height: 7.5rem;
      width: 11.6875rem;
      border-radius: 8px;
      &-GHG {
        display: flex;
        flex-direction: column;
        border: 1px solid #f0f0f0;
        border-radius: 8px;
        margin-bottom: 1rem;
      }
    }

    &-tileEsrs {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-width: calc(33% - 10px);
      border: 1px solid #f0f0f0;
      height: 7.5rem;
      width: 11.6875rem;
      border-radius: 8px;
      &--metricName {
        line-height: 1.2rem !important;
      }
      &-GHG {
        display: flex;
        flex-direction: column;
        flex: 1;
        border: 1px solid #f0f0f0;
        border-radius: 8px;
        margin-bottom: 1rem;

        &--first {
          // flex-basis: 100%;
          grid-column: 1 / span 3;
        }
      }
    }
    &-wrapper-GHG {
      display: flex;
      padding-left: 1rem;
      min-height: 3rem;
      align-items: center;
      padding-bottom: 0.5rem;
      :nth-child(1) {
        width: 10rem;
        padding-right: 1rem;
      }
      &--esrs-firstmetric {
        left: 25%;
        position: relative;
      }

      @media screen and (max-width: 1170px) {
        font-size: 0.7rem;
      }
    }

    &-TextAlign {
      padding-bottom: 0rem !important;
    }

    &-row-Column1 {
      width: 50%;
    }

    &-row-Column2 {
      width: 44%;
    }

    &-row-cell1 {
      width: 20% !important;
    }

    &-value {
      &--Yes.TabularTile__Governance-value--Yes,
      &--yes.TabularTile__Governance-value--yes {
        background: linear-gradient(180.75deg, #e3f2e5 0.64%, #ffffff 135.65%);
        color: #009a44;
        border-bottom: 1px solid #f0f0f0;
        padding-top: 0.3125rem;
        text-align: center;
        font-size: 1rem;
        font-weight: 700;
        min-height: 1.875rem;
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 8px 8px 0 0;
      }
      &--No.TabularTile__Governance-value--No,
      &--no.TabularTile__Governance-value--no {
        background: linear-gradient(
          180.75deg,
          #f8f3ee 0.64%,
          rgba(248, 245, 238, 0) 135.65%
        );
        color: #ec7016;
        padding-top: 0.3125rem;
        border-bottom: 1px solid #f0f0f0;
        text-align: center;
        font-size: 1rem;
        font-weight: 700;
        min-height: 1.875rem;
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 8px 8px 0 0;
      }
      &--Not.TabularTile__Governance-value--Not,
      &--not.TabularTile__Governance-value--not {
        background: linear-gradient(
          180.75deg,
          #ebecec 0.64%,
          rgba(250, 250, 250, 0) 135.65%
        );
        color: #757575;
        border-bottom: 1px solid #f0f0f0;
        padding-top: 0.3125rem;
        text-align: center;
        font-size: 1rem;
        font-weight: 700;
        min-height: 1.875rem;
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 8px 8px 0 0;
      }
    }

    &-text {
      text-align: center;
      padding: 0.3125rem;
      &--12 {
        font-size: 0.75rem;
      }
      &-GHG {
        min-height: 3rem;
        background: linear-gradient(
          180.75deg,
          #eef5f8 0.64%,
          rgba(238, 245, 248, 0) 135.65%
        );
        border-bottom: 1px solid #e2eff4;
        align-items: center;
        display: flex;
        justify-content: space-around;
        margin-bottom: 0.5rem;
      }
    }

    &-metric {
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 400;
      padding: 0.85rem;
      &--no-padding {
        padding: 0;
      }

      text-align: center;

      &-button {
        line-height: unset;
      }
      &-GHG {
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: center;
        background: none;
        border-radius: none;
        color: $dark-blue;
        &--no-background.TabularTile__Governance-metric-GHG--no-background {
          background: none;
        }

        &-value-size {
          font-size: 15px;
        }

        &--ESRS {
          margin: 1rem;
          border-bottom: none !important;
        }
      }
    }

    &-longName {
      padding-right: 0rem !important;
      word-break: break-word;
    }

    &-GHG {
      padding: 0.5rem;

      &--esrs {
        // display: flex;
        // flex-wrap: wrap;
        // gap:0.5rem;
        // min-height: 10rem;
        display: grid;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        min-height: 10rem;

        &-peerGrid {
          @media (min-width: 768px) and (max-width: 1100px) {
            grid-template-columns: repeat(3, 19%);
          }

          @media (min-width: 1100px) and (max-width: 1200px) {
            grid-template-columns: repeat(3, 18.9%);
          }

          @media (min-width: 1200px) {
            grid-template-columns: repeat(3, 21.3%);
          }

          @media (min-width: 1220px) and (max-width: 1230px) {
            grid-template-columns: repeat(3, 20.2%);
          }

          @media (min-width: 1300px) {
            grid-template-columns: repeat(3, 23.8%);
          }

          @media screen and (min-width: 1500px) {
            grid-template-columns: repeat(3, 26.7%);
          }

          @media (min-width: 1600px) {
            grid-template-columns: repeat(3, 28%);
          }

          @media (min-width: 1680px) {
            grid-template-columns: repeat(3, 29.9%);
          }

          @media screen and (min-width: 1900px) {
            grid-template-columns: repeat(3, 32.6%);
          }
        }

        &-IndustryGrid {
          @media (min-width: 768px) and (max-width: 1100px) {
            grid-template-columns: repeat(3, 16.7%);
          }

          @media (min-width: 1100px) and (max-width: 1200px) {
            grid-template-columns: repeat(3, 18.9%);
          }

          @media (min-width: 1200px) {
            grid-template-columns: repeat(3, 21.3%);
          }

          @media (min-width: 1220px) and (max-width: 1230px) {
            grid-template-columns: repeat(3, 20.2%);
          }

          @media (min-width: 1300px) {
            grid-template-columns: repeat(3, 23.8%);
          }

          @media screen and (min-width: 1500px) {
            grid-template-columns: repeat(3, 26.7%);
          }

          @media (min-width: 1600px) {
            grid-template-columns: repeat(3, 28%);
          }

          @media (min-width: 1680px) {
            grid-template-columns: repeat(3, 29.9%);
          }

          @media screen and (min-width: 1900px) {
            grid-template-columns: repeat(3, 32.6%);
          }
        }
      }

      &-detailed {
        display: flex;
        flex-wrap: wrap;
        .TabularTile__Governance-tile-GHG {
          width: 23rem;
          margin: 1rem;
        }
        .TabularTile__Governance-text-GHG {
          justify-content: flex-start;
          padding-left: 0.5rem;
        }
        .TabularTile__Governance-text-span {
          width: fit-content;
        }
      }
      &-metric-container {
        padding: 0.85rem;
      }
    }

    &-fistrow-container {
      display: flex;
    }

    &-metric-column-second {
      flex-direction: column;
      width: calc(((100% - 2.5rem) / 3));
    }
  }

  &__DetailedMetric {
    border-radius: 0.5rem;
    width: calc(((100% - 2.5rem) / 4));
    border: 1px solid #f0f0f0;
  }

  &__DetailedMetricName {
    padding-top: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
    vertical-align: top;
    background: linear-gradient(
      180.75deg,
      #eef5f8 0.64%,
      rgba(238, 245, 248, 0) 135.65%
    );
    border-bottom: 1px dashed #a3cee0;
  }

  &__detailedMetricBorder {
    text-align: left;
    width: fit-content;
    min-height: 4rem;
    display: inline-flex;
    align-items: center;
  }

  &__DetailedCompanies {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__detailedCompanyData {
    display: flex;
    padding: 0rem 0.5rem;
    gap: 0.5rem;
  }

  &__detailedCompanyName {
    color: var(--neutral-black-87, #212121);
    font-family: Open Sans;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    width: 55%;
    word-break: break-word;
  }

  &__detailedCompanyMetricValue {
    color: var(--secondary-blue-6, #012169);
    font-family: Open Sans;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
    width: 45%;

    &--noValue {
      color: var(--neutral-black-54, #757575);
      font-family: Open Sans;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1rem;
    }

    &--Yes,
    &--yes {
      color: #009a44;
      padding-top: 0.3125rem;
      font-size: 1rem;
      font-weight: 700;
      min-height: 1.875rem;
    }

    &--No,
    &--no {
      color: #ec7016;
      padding-top: 0.3125rem;
      font-size: 1rem;
      font-weight: 700;
      min-height: 1.875rem;
    }
  }

  &__Detailed-container {
    display: flex;
    gap: 1rem;
  }

  &__deatiled-no-data-section {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding-top: 1.5rem;
    margin-bottom: -1rem;
  }
}
