.metrics-accordion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid $darker-grey;
    box-sizing: border-box;

    &:not(:first-child) {
      border-top: none;
    }

    &-header {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
     
    }

    &-button {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      border: none;
      background-color: #ffffff;
      //gap: 0.5rem;
      padding: 0.75rem 1rem;
      font-family: "Open Sans";
      font-style: normal;
     
      &--fit-content{
        width: fit-content;
      }

      &:hover {
        //background-color: $lightest-blue;
        cursor: pointer;
      }
      & svg path {
        fill: #000000;
      }
      &--disabled {
        color: #75787b;
        cursor: text;
        & svg path {
          fill: #75787b;
        }
        &:hover {
          background-color: transparent;
          cursor: auto;
        }
      }

      &--short {
        width: auto;
        padding: 0.75rem 0;
        & div {
          display: flex;
        }
        & svg path {
          fill: #000000 !important;
        }
      }
    }
    &-collapse {
      &--hide {
        display: none;
      }
    }
    &-body {
      padding: 0.25rem 2.5rem;
      & svg path {
        fill: #007cb0;
      }
    }

    &__subcategories-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &__subcategory{
      &-checkbox-container{
        display: flex;
        position: relative;
        width: fit-content;
      }
    }

    &-category {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0.5rem;

      &-sub {
        justify-content: flex-start;
      }
    }

    &-factors {
      display: flex;
      flex-direction: column;
      margin-left: 1.5rem;
      margin-bottom: 1rem;
      &--hide {
        display: none;
      }
      &--wide {
        margin-left: 1.5rem;
        width: 90%;
        margin-bottom: 0;
        @media (min-width: 1380px) {
          width: 44%;
          margin-bottom: 1rem;
        }
      }
      &--shadow {
        box-shadow: none;
        padding-left: 0;
        margin-bottom: 1rem;
        margin-left: 1.5rem;
        @media (min-width: 1380px) {
          padding-left: 1.75rem;
        }
      }
      &-container {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #bbbcbc;
        margin-bottom: 1rem;
        &:last-of-type(){
          border-bottom: unset;
        }
      }
    }
    &-checkbox {
      display: flex;
    }

    &-category,
    &-checkbox {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      position: relative;
      & input {
        border-radius: 2px;
        border: 1px solid #d0d0ce;
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        background: #007cb0;
        margin-right: 0.5rem;
      
      }
    

      .checkbox__indeterminate {
        background: #007cb0;
        border: 1px solid #007cb0;

        & div {
          display: flex;
        }
      }

      .checkbox__label {
        position: relative;
        flex: 2;
      }
      .tooltip {
        display: none;
        z-index: 100;
        top: auto;
        bottom: 1.5rem;
        max-width: 27rem;
      }
      .tooltip__label {
        max-width: 27rem;
      }

      &:hover {
        .tooltip--top {
          display: inline-block;
        }
      }

      &--main-category{
        margin-bottom: unset;
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        padding-right: 1rem;
        & input {
          margin-right: 0.5rem;
         
         
        }
        .checkbox__checkbox-blue{
          
          svg path{
            fill: unset;
          }
        }
        .checkbox__indeterminate {
          background: #007cb0;
          border: 1px solid #007cb0;
         
          margin-top: 3px;
  
          & div {
            display: flex;
          }
        }
      }
    }
    &-checkbox {
      width: fit-content;
    }
    &-category {
      &--sub {
        height: 1.5rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
       
        &-label{
          display: flex;
          align-items: center;

          &-disabled{
            &.metrics-accordion__item-category--sub-label.metrics-accordion__item-category--sub-label{
            &:hover{
              .checkbox__label, .checkbox{
                background-color: unset;
                cursor: auto;
              }
             
            }
          }
          }
          
        }
      }
    }
  }

  &__not-results {
    color: #f54242;
    display: flex;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-bottom: 1rem;
    padding: 0.75rem 1rem;
    border-top: 1px solid #E0E0E0;
    width: calc(100% - 2rem);
  }

  &__fields-count--green{
    color: $green-secondary;
  }
}
