.suggested-peers {
  &__modal {
    min-height: 40vh;
    max-height: 55vh;
  
  }
  &__group {
    &-row {
      &:hover {
        .suggested-peers__actions {
          display: flex;
          visibility: visible;
          column-gap: 0.75rem;
        }
      }

      &--active {
        background-color: #f1f8fb !important;
        .suggested-peers__actions {
          display: flex;
          visibility: visible;
          column-gap: 0.75rem;
          margin-right: 0;
          margin-left: auto;
        }
      }
    }
    &-input {
      display: flex;
      flex-direction: column;
      input {
        background: #ffffff;
        border: 1px solid #007cb0;
        border-radius: 4px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        padding: 0.75rem 1rem;
      }

      &--error {
        input {
          border: 1px solid #f54242;
        }
      }
    }
  }

  &__actions {
    visibility: hidden;
    background-color: #53565a;
    border-radius: 3rem 0 0 3rem;
    padding: 0.75rem;
    width: fit-content;

    &--middle {
      margin-right: 0;
      margin-left: auto;
    }

    &--active {
      background-color: $primary;
    }
    &-wrapper {
      display: flex;
      justify-content: flex-end;
      padding: unset;

      &--middle {
        display: table-cell;
      }
    }
  }

  &__action-icon {
    svg path {
      fill: white;
    }
    .tooltip {
      left: -50%;
    }
    &:last-child {
      .tooltip {
        left: -70%;
      }
    }
  }

  &__drop-down {
    width: 100%;
  }

  &__error-name {
    padding-top: 0.25rem;
    font-size: 12px;
    color: #f54242;
  }

  &__wrapper--hidden {
    visibility: hidden;
  }

  &__modal-wrapper {
    width: 70%;
  }

  &__modal--border {
    border: 1px solid #53565a;
  }

  &__options {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    gap: 0.5rem;
  }

  &__column {
    &-companies {
      width: 100%;
      &-wrapper {
        width: 60%;
      }
    }
    &-group-name {
      width: 100%;
      &-wrapper {
        width: 20%;
      }
    }
  }
}
.Dashboard--suggested-peers--lowerMargin{
  margin-bottom: -4rem !important;
}
