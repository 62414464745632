.saved-peerbenchmarks-table {
  width: 100%;
  border-radius: 8px;
  font-weight: 400;
  overflow-y: auto;
  min-width: 68.5rem;

  &__shared-with-main {
    font-size: 0.875rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;

    svg:not(:root) {
      transform: translateX(8px) translateY(4px);
    }

    &:hover {
      background: $secondary-light-grey;
      cursor: pointer;
    }
  }
  &__shared-with-count {
    width: 1.5rem;
    padding-left: 0.2rem;
    margin-left: 0.2rem;
    position: relative;

    &-tooltip {
      line-height: 1rem;
      max-width: 10rem;
      max-height: 6rem;
      overflow: auto;
    }

    svg:not(:root) {
      transform: translateX(8px) translateY(4px);
    }

    &:hover {
      background: $secondary-light-grey;
      cursor: pointer;
      height: 20px;
      border-radius: 15px;
    }
  }

  &__auto-delete {
    &-7 {
      color: $orange;
    }

    &-less-than-7 {
      color: $red;
    }
  }

  &__link {
    cursor: pointer;
    &--newItem {
      font-weight: 600;
    }
  }

  &__row {
    &:hover {
      background-color: transparent;
    }
  }

  &__column-content {
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
  }

  &__shared-with {
    width: 100%;
    gap: 0rem;
  }

  &__actions {
    gap: 0.5rem;
    justify-content: flex-end;

    &:hover {
      .tooltip {
        display: none;
      }
    }
  }

  &__action-icon {
    @include invisible-button;
    position: relative;
    cursor: pointer;
    .tooltip {
      display: none;
      top: -2rem;
      bottom: unset;

      &--top {
        &__arrow {
          left: 45%;
        }
      }
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
      background-color: $light-grey;
      border-radius: 32px;
    }
  }

  &__share {
    .tooltip {
      left: -1.2rem;
    }
  }

  &__edit {
    .tooltip {
      left: -0.8rem;
    }
  }

  &__delete {
    .tooltip {
      left: -1.4rem;
    }
  }

  &__topic {
    &.tooltip {
      max-width: 8rem;
    }
  }

  &__text-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 1px;
    flex-grow: 1;
  }

  &__column-text {
    font-weight: 400;
    font-size: 0.875rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
    .tooltip {
      left: -4rem;
      top: -200%;
    }
  }

  &__table-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__data {
    padding: 0.5rem 1rem;
    border: 1px solid $lightest-grey;
    border-radius: 0.25rem 0.25rem 0 0;
    display: flex;
    gap: 1rem;
    align-items: center;
    border-bottom: none;
  }

  &__results-count {
    font-size: 0.875rem;
  }

  &__table {
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;
    border-collapse: collapse;
    font-size: 0.875rem;
    border: 1px solid #ebebeb;

    &-wrapper {
      position: relative;
      flex-grow: 1;
      box-shadow: inset 0px -1px 0px $lightest-grey;
    }

    &-inner-wrapper {
      width: 100%;
    }

    &__header {
      &-content {
        position: static;
        width: auto;
        line-height: 1rem;
        text-align: left;

        &:last-child > div {
          justify-content: flex-end;
        }
      }

      &-text {
        font-weight: 600;
        font-size: 0.75rem;

        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }

    th:nth-child(1),
    td:nth-child(1) {
      width: 15%;
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 25%;
    }

    tr {
      box-sizing: border-box;
    }

    tr {
      width: 100%;
    }

    th > div {
      padding: 0;
    }

    td,
    th {
      padding: 0.5rem 1rem;
    }

    thead {
      position: sticky;
      background: $white;
      z-index: 1;
      top: -1px;
    }

    @media screen and (min-width: 1200px) {
      tr {
        display: flex;
        align-items: center;
      }

      thead th:nth-child(1),
      tbody td:nth-child(1) {
        width: 15%;
      }

      thead th:nth-child(2),
      tbody td:nth-child(2) {
        width: 25%;
      }

      thead th:nth-child(3),
      tbody td:nth-child(3) {
        width: 10%;
      }

      thead th:nth-child(4),
      tbody td:nth-child(4) {
        width: 10%;
      }

      thead th:nth-child(5),
      tbody td:nth-child(5) {
        width: 10%;
      }

      thead th:nth-child(6),
      tbody td:nth-child(6) {
        width: 10%;
      }

      thead th:nth-child(7),
      tbody td:nth-child(7) {
        width: 10%;
      }

      thead th:nth-child(8),
      tbody td:nth-child(8) {
        width: 10%;
      }
    }
  }
}
