.PublishSearch {
  width: 20rem;
  min-height: 17rem;
  height: auto;
  left: 760px;
  top: 410px;
  display: flex;
  flex-direction: column;

  &--saveSearchlist {
    width: 25rem;
  }
  &--deleteSearch {
    min-height: 5.3rem;
  }
  &__dropdown {
    width: 100%;
  }
  &__name {
    font-size: 14px;
    line-height: 24px;
    color: $black;
    font-weight: 500;
    text-align: left;

    &--saveSearchList {
      white-space: pre-wrap;
      word-wrap: break-word;
      font-family: "Open Sans";
    }
    &--deleteSavedSearch {
      font-size: 14px;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }

  &__nameError {
    font-size: 14px;
    line-height: 24px;
    padding-left: 1rem;
    color: #f54242;
    font-weight: 500;
    text-align: left;
  }

  &__topicNameError {
    font-size: 14px;
    line-height: 24px;
    padding-left: 1rem;
    color: #f54242;
    font-weight: 500;
    text-align: left;
  }

  &__input {
    width: 100%;
    height: auto;
    padding-top: 0.5rem;
    text-align: center;
    outline: none;

    & input,
    textarea {
      width: 100%;
      height: fit-content;
      padding: 0.6rem;
      color: #212121;
      border: 1px solid #d0d0ce;
      box-sizing: border-box;
      border-radius: 4px;
      outline: none;
      resize: none;
      @include font-family;
      &:hover,
      &:focus-within {
        border-color: $primary;
      }
    }
    &--saveSearchList input,
    textarea {
      padding: 0.75rem 1rem;
      &:hover,
      &:focus-within {
        border-color: $primary;
      }
    }
  }

  &__inputError {
    width: 100%;
    height: auto;
    padding-top: 0.5rem;
    text-align: center;
    outline: none;

    & input,
    textarea {
      width: 100%;
      height: fit-content;
      padding: 0.6rem;
      color: #212121;
      border: 1px solid #f54242;
      box-sizing: border-box;
      border-radius: 4px;
      outline: none;
      resize: none;
    }
    &--saveSearchList input,
    textarea {
      padding: 0.75rem 1rem;
    }
  }
  &__topicInputError {
    width: 100%;
    height: auto;
    padding-top: 0.5rem;
    text-align: center;
    outline: none;

    & input,
    textarea {
      width: 100%;
      height: fit-content;
      padding: 0.6rem;
      color: #212121;
      border: 1px solid #f54242;
      box-sizing: border-box;
      border-radius: 4px;
      outline: none;
      resize: none;
    }
    &--saveSearchList input,
    textarea {
      padding: 0.75rem 1rem;
    }
  }
  &__cancel-topic-section {
    margin-left: 1rem;
  }
  &__cancel-topic {
    @include invisible-button;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    color: $primary;
    &:hover {
      background-color: rgba(211, 208, 208, 0.48);
      border-radius: 5px;
    }
    .icon {
      color: $primary;
      margin-right: 0.5rem;
    }
  }

  &__content {
    text-align: right;
    padding-top: 0.25rem;
    font-size: 12px;
    color: #63666a;
    padding-right: 10%;
    &--saveSearchList {
      padding-right: 4.5%;
    }
  }

  &__contentError {
    padding-top: 0.25rem;
    font-size: 12px;
    color: #f54242;
    padding-left: 5%;
    &--saveSearchList {
      padding-left: 8.5%;
    }
  }

  &__options {
    padding-top: 1rem;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;

    &--saveSearchList {
      padding-top: 2rem;
    }

    &--deleteSearch {
      padding-top: 2rem;
    }
  }

  &__description {
    &.RenameShortlist__description {
      font-family: inherit;
      height: 8.5rem;
      resize: none;
    }

    &-label {
      margin-top: 1rem;
    }
  }

  &__name {
    position: relative;

    &__compare {
      position: absolute;
      right: 0.9rem;
      transform: translateY(0.2rem);

      &--button {
        position: relative;
        @include invisible-button;
        color: #007cb0;
        padding-right: 1rem;
        font-family: "Open Sans";
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

.SaveSearchList__btn {
  &.SaveSearchList__btn {
    padding: 0.5rem 1.5rem;
  }
}
