.Downloads {
  z-index: 1102;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 26.3rem;
  height: 100%;
  right: 0rem;
  top: 0rem;
  background: #ffffff;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.06);

  &__over-fullscreen {
    z-index: 1201;
  }

  &__header {
    width: 100%;
    height: 3.5rem;
    line-height: 1.5rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    box-shadow: inset 0px -1px 0px #e7e7e7;

    &__content {
      font-size: 16px;
      line-height: 24px;
      display: flex;
      flex-direction: row;

      &__icon {
        width: 16px;
        height: 16px;
        border-radius: 12px;
        background-color: #43b02a;
        justify-content: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        margin-left: 0.4rem;
        margin-top: 3px;

        &-content {
          color: white;
          font-weight: 500;
          font-size: 10px;
        }
      }
    }
  }

  &__secondHeader {
    padding: 1rem;
    font-size: 0.75rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    color: $dark-grey;
    font-weight: 700;
  }

  &__body {
    width: 100%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &__loading {
      position: relative;
      animation-name: spin;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      margin: auto;
    }

    &__action {
      .tooltip {
        top: -1.35rem;
        left: -1.82rem;
        z-index: 10;
        display: none;
      }

      &:hover {
        .tooltip {
          display: inline-block;
        }
      }
    }

    &__outer {
      width: 100%;

      &__container {
        width: 90%;
        display: flex;
        padding-top: 0.3rem;
        flex-direction: column;
        font-size: 14px;
        margin-left: 1rem;
        position: relative;

        &__title {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          overflow-wrap: break-word;

          position: relative;
          width: 21rem;
          left: 0px;

          &-icon {
            position: relative;
          }

          &-content {
            margin-left: 0.23rem;
            margin-bottom: 0.5rem;
            margin-top: 0.5rem;
            overflow-wrap: break-word;
          }

          &__timestamp {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            position: relative;
            height: 0.6rem;
            margin-left: 2rem;
            padding-top: 0.1rem;
            padding-bottom: 0.3rem;
            font-size: 12px;
            line-height: 16px;
            color: #757575;
          }
        }

        &-download {
          position: absolute;
          width: 24px;
          height: 24px;
          right: 0px;
          left: 21.2rem;
          cursor: pointer;
          margin-top: -1rem;
          &:hover {
            background-color: $light-grey;
            border-radius: 4px;
            transition: 0.3s;
          }
        }

        &--nd {
          font-weight: 650;
        }

        &--started {
          position: relative;
          width: 90%;
          height: 0.25rem;
          margin-left: 1rem;
          background-color: $light-grey;
        }

        &--downloading {
          position: relative;
          width: 90%;
          height: 0.25rem;
          margin-left: 1rem;
          background-color: #43b02a;
        }

        &--progress {
          position: relative;
          width: 90%;
          height: 0.25rem;
          margin-left: 1rem;
          background-color: $light-grey;
        }

        &--downloaded {
          position: relative;
          width: 90%;
          height: 0.25rem;
          margin-left: 1rem;
          background-color: $primary;
        }

        &--error {
          position: relative;
          width: 90%;
          height: 0.25rem;
          margin-left: 1rem;
          background-color: hsl(352, 79%, 48%);
        }

        &--notDownloaded {
          position: relative;
          width: 90%;
          height: 0.25rem;
          margin-left: 1rem;
          background-color: #43b02a;
        }
      }
    }

    &__outer--selected {
      background: #f1f8fb;
      box-shadow: inset 0px -1px 0px #ebebeb;
    }

    &__outer:hover {
      background: #f1f8fb;
      box-shadow: inset 0px -1px 0px #ebebeb;
    }
  }
}
