$base-z-index: 1101;
.maintenance-message {
  &__banner {
    background-color: rgba(255, 251, 234, 0.2);
    border: 1px solid #ffcd00;
    box-sizing: border-box;
    height: 40px;
    display: flex;
    align-items: center;
    color: #212121;
    font-size: 12px;
    margin-bottom: 4px;
    margin-right: 2px;
    justify-content: space-between;
    padding: 0.25rem;

    &-msg {
      display: flex;
      align-items: center;

      &-text {
        margin-left: 0.25rem;
        line-break: anywhere;
      }
    }
  }

  &__close {
    position: relative;

    .tooltip {
      top: 0;
      z-index: $base-z-index + 5;
      display: none;
    }
    .tooltip--left {
      right: 100%;
      left: unset;
    }

    .tooltip--right {
      left: 100%;
      right: unset;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__message-container {
    z-index: 52;
    background-color: #fff;
    position: fixed;
    width: calc(100% - 3.5rem);

    &-for-contentbenchmark {
      z-index: 5001;
      position: static;
      width: 100%;
    }
  }
}
