.benchmark-grid {
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  padding: 0 2rem;
  max-width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
  }

  &__table {
    box-sizing: border-box;
    height: fit-content;
    border-spacing: 0;
    border-collapse: collapse;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;

    &--container {
      flex-grow: 1;
      display: flex;
      border: 1px solid #e0e0e0;
      box-shadow: 0px 4px 8px rgba(238, 240, 241, 0.48);
      box-sizing: border-box;
      border-radius: 8px;
      width: 100%;
      overflow: auto;
    }

    &--body {
      width: 100%;
      height: fit-content;
      overflow-x: auto;

      &--normal {
        & tr:first-child {
          position: sticky;
          z-index: 31;
          top: 5.2rem;
          border-bottom: none;
        }
      }
    }

    &--row {
      width: 100%;
    }

    &--heading {
      top: 0px;
      background: white;
      flex-grow: 1;
    }

    thead,
    th {
      position: sticky;
      z-index: 32;
      top: 0;
    }

    th:last-child {
      outline: none;
    }

    th:nth-child(1) {
      position: sticky;
      z-index: 33;
      left: 0;
      box-sizing: border-box;
    }

    th {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 1rem;
      align-items: center;
      color: $black;
      padding: 1.25rem 2rem 1.25rem 2rem;
      box-shadow: inset -1px -1px 0px $darker-grey;
      background: $white;

      &:last-child {
        box-shadow: none;
      }
    }

    th:not(:nth-child(1)) {
      white-space: nowrap;
      box-sizing: border-box;
    }

    tbody td:nth-child(1) {
      position: sticky;
      z-index: 5;
      left: 0;
      padding-left: 2rem;
    }

    .single-item {
      margin: 0 auto;
      justify-content: center;

      span {
        font-size: 0.875rem;
        padding: 0.25rem 0.5rem;
      }
    }

    &--transpose {
      & thead {
        z-index: 50;
      }

      & th:nth-child(1) {
        min-width: 19rem;
      }

      & th:nth-child(2) {
        position: sticky;
        z-index: 20;
        left: 19rem;
      }

      & tbody td:nth-child(1) {
        z-index: 30;
      }

      &__company-rows {
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;

        &:hover {
          .benchmark-grid__rowItem--remove-button {
            visibility: visible;
          }
        }
      }

      & th:not(:nth-child(1)) > &__company-rows {
        align-items: center;
      }
    }

    &--header {
      background: #ffffff;
      box-sizing: border-box;

      &-remove-button {
        @include invisible-button;
        visibility: hidden;
        position: absolute;
        left: -1.25rem;
        &:hover {
          background-color: $light-grey;
          border-radius: 4px;
        }
      }

      &--content {
        &:hover {
          .benchmark-grid__table--header-remove-button {
            visibility: visible;
          }
        }

        &-wrapper {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &--content--container {
        align-items: center;
        display: flex;
        height: 100%;
      }

      &--content--items {
        display: flex;
        gap: 0.2rem;
        flex-direction: column;
        &--company {
          display: flex;
          &:hover {
            .filter {
              visibility: visible;
              background-color: $lightest-grey;
              border-radius: 4px;
            }
          }
          &--text {
            margin-right: 3px;
          }
        }
        .filter {
          line-height: 1.5rem;
          visibility: hidden;
          cursor: pointer;

          &__active {
            visibility: visible;
          }
        }

        .filter-modal {
          position: absolute;
          top: 1.2rem;
          z-index: 1;

          &--right {
            left: 2rem;
          }

          &--left {
            left: -15rem;
          }
        }

        &--rating {
          display: flex;
          align-items: center;
          &--label {
            font-size: 0.75rem;
            font-style: italic;
            font-weight: normal;
          }
          &--tooltip {
            .tooltip {
              @include white-tooltip;
              top: 3rem;
            }

            &:hover {
              svg {
                border-radius: 100%;
              }

              path {
                fill: $black;
              }

              .tooltip {
                display: inline-block;
              }
            }
          }
        }

        &--transpose {
          padding: 0;
        }
      }

      &--content--normal {
        box-shadow: none;
      }
    }
  }

  &__rowItem--remove-button {
    @include invisible-button;
    visibility: hidden;
    position: absolute;
    left: -1.25rem;
    &:hover {
      background-color: $light-grey;
      border-radius: 4px;
    }
  }

  &__drag-header {
    position: relative;
    .hide-drag-icon {
      display: none;
    }
    &__icon {
      position: absolute;
      right: -1.5rem;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      path {
        fill: $dark-grey;
      }
    }
  }

  &__drag-th {
    &:hover {
      .hide-drag-icon {
        display: block;
      }
    }
  }

  &__col-resize-handler {
    user-select: none;
    top: 0;
    right: 0;
    position: absolute;
    width: 0.25rem;
    height: calc(#{$peer-benchmark-grid-height} - 2vh);
    z-index: 32;
    @media screen and (max-width: 1400px) {
      height: calc(#{$peer-benchmark-grid-height-sm} - 2vh);
    }
    &:hover {
      cursor: col-resize;
    }
  }

  &__base-company {
    position: absolute;
    right: -1.25rem;
    top: 0;
  }
}
