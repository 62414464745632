.savedInsightsList {
  display: flex;
  flex-wrap: wrap;
  padding: 0 3rem;
  gap: 1.5rem;
  margin-bottom: 1rem;

  &__empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000;
    font-style: normal;
  }

  &__empty-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
  &__img {
    width: 249px;
    height: 210px;
    margin-bottom: 2rem;
  }
}
