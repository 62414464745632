.create-tag {
  width: 35rem;
  min-height: 9rem;
  height: auto;
  display: flex;
  flex-direction: column;

  &__name {
    font-size: 14px;
    line-height: 24px;
    color: $black;
    font-weight: 500;
    text-align: left;
  }

  &__dropdown {
    margin-bottom: 1rem;
  }

  &__input {
    width: 100%;
    height: auto;
    padding-top: 0.5rem;
    text-align: center;
    outline: none;

    & input,
    textarea {
      @include font-family;
      width: 100%;
      height: fit-content;
      padding: 0.6rem;
      color: #212121;
      border: 1px solid #d0d0ce;
      box-sizing: border-box;
      border-radius: 4px;
      outline: none;
      font-family: "Open Sans";
      &:hover,
      &:focus-within {
        border-color: $primary;
      }
    }

    textarea {
      padding: 0.75rem 1rem;
      margin-top: 0.5rem;
      min-height: 8rem;
      resize: none;

      &:hover,
      &:focus-within {
        border-color: $primary;
      }
    }
  }

  &__char-limit {
    float: right;
    padding-top: 0.25rem;
    font-size: 12px;
    color: #63666a;
  }

  &__options {
    padding-top: 1rem;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  &__continue {
    margin-left: 1rem;

    &-disabled {
      background-color: $grey;
    }
  }
}
