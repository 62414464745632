.manage-files {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__success {
    color: $green-secondary;
  }

  &__failed {
    color: $red;

    > div {
      display: flex;
      gap: 0.5rem;
    }
  }

  &__loading {
    width: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  &__header {
    padding-left: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: inset 0 -1px 0 $lightest-grey;
    position: fixed;
    z-index: 2;
    background-color: white;
    width: calc(100% - 4rem);
  }

  &__content {
    // padding: 1.5rem 2.25rem;
    padding: 0rem 2rem;    
    padding-top: 0.6rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex-grow: 1;
    margin-top: 3rem;

    &-header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &-actions {
        display: flex;
        column-gap: 1rem;
      }
      &-text {
        font-size: 1.25rem;
        font-weight: 400;
      }
    }
  }

  &__table-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__data {
    padding: 0.5rem 1rem;
    border: 1px solid $lightest-grey;
    border-radius: 0.25rem 0.25rem 0 0;
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  &__results-count {
    font-size: 0.875rem;
  }

  &__upload-button {
    @include primary-button;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__column-content {
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
  }

  &__actions {
    justify-content: flex-end;
    gap: 0.5rem;

    &:hover {
      .tooltip {
        display: none;
      }
    }
  }

  &__row {
    box-shadow: none;
    &:hover {
      background-color: unset;
    }
  }

  &__status {
    gap: 0.5rem;

    &:hover {
      .tooltip {
        display: none;
      }
    }

    &-icon {
      position: relative;
      .tooltip {
        width: 6rem;
        bottom: 1.5rem;
        left: -2.8rem;
        top: unset;
      }

      &:hover {
        .tooltip {
          display: inline-block;
        }
      }
    }
  }

  &__action-icon {
    position: relative;
    cursor: pointer;
    .tooltip {
      display: none;
      top: -2rem;
      left: -2rem;
      bottom: unset;

      &--top {
        &__arrow {
          left: 45%;
        }
      }
    }
  }

  &__download {
    &:hover {
      .tooltip {
        display: inline-block;
      }
      background-color: $light-grey;
      border-radius: 32px;
    }
  }

  &__edit {
    .tooltip {
      left: -1rem;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
      background-color: $light-grey;
      border-radius: 32px;
    }
  }

  &__delete {
    .tooltip {
      left: -1.5rem;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
      background-color: $light-grey;
      border-radius: 32px;
    }
  }

  &__table {
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;
    border-collapse: collapse;
    font-size: 0.875rem;

    &-wrapper {
      // position: relative;
      height: 43vh;
      overflow-y: scroll;
      overflow-x: hidden;
      flex-grow: 1;
      box-shadow: inset 0px -1px 0px $lightest-grey;

      @media screen and (min-width: 1400px){
        height: 64vh;
      }
  
      @media screen and (min-width: 1600px){
        height: 70vh;
      }

      @media screen and (width: 1272px){
        height: 45vh;
      }
    }

    &-inner-wrapper {
      // position: absolute;
      width: 100%;
    }

    &__header {
      box-shadow: none;

      &-content {
        position: static;
        width: auto;
        line-height: 1rem;
        text-align: left;

        &:last-child > div {
          justify-content: flex-end;
        }
      }

      &-text {
        font-weight: 600;
        font-size: 0.75rem;

        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }

    th:nth-child(1),
    td:nth-child(1) {
      width: auto;
    }

    tr {
      border: 1px solid $lightest-grey;
      box-sizing: border-box;
    }

    tr {
      width: 100%;
    }

    th,
    td {
      flex: 1;
    }

    th > div {
      padding: 0;
    }

    td,
    th {
      padding: 0.5rem 1rem;
    }

    thead {
      position: sticky;
      background: $white;
      z-index: 1;
      top: -1px;
    }

    @media screen and (min-width: 1200px) {
      tr {
        display: flex;
        align-items: center;
      }

      thead th:nth-child(1),
      tbody td:nth-child(1) {
        min-width: 4.5rem;
      }

      thead th:nth-child(2),
      tbody td:nth-child(2) {
        flex: 0 0 15%;
        min-width: 8.5rem;
      }

      thead th:nth-child(3),
      tbody td:nth-child(3) {
        flex: 0 0 15%;
        min-width: 8.5rem;
      }

      thead th:nth-child(4),
      tbody td:nth-child(4) {
        min-width: 3rem;
      }

      thead th:nth-child(5),
      tbody td:nth-child(5) {
        flex: 0 0 7%;
        min-width: 4rem;
      }

      thead th:nth-child(6),
      tbody td:nth-child(6) {
        flex: 0 0 7%;
        min-width: 4rem;
      }

      thead th:nth-child(7),
      tbody td:nth-child(7) {
        flex: 0 0 7%;
        min-width: 4rem;
      }

      thead th:nth-child(8),
      tbody td:nth-child(8) {
        min-width: 5rem;
      }

      thead th:nth-child(9),
      tbody td:nth-child(9) {
        min-width: 4rem;
      }
    }
  }
}
