.edit-file {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 23rem;
    max-height: 75vh;
    overflow: auto;
  }
  &__top-errors {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.1875rem;
    border: 1px solid #da291c;
    background-color: #ffecea;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
    margin-bottom: 1rem;
    &-icon {
      margin-right: 0.5rem;
    }
    &-msg {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__field {
    &s {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
    &-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin-bottom: 1rem;
      position: relative;
    }
    &-label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0.5rem;
    }
    &-input {
      padding: 0.75rem 1rem;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      border: 1px solid #d0d0ce;
      box-sizing: border-box;
      border-radius: 4px;
      &--disabled {
        background-color: $omnia-grey;
      }
      &--error {
        border: 1px solid #da291c;
        &:focus {
          outline: 1px solid #da291c;
        }
      }
    }
    &-count {
      text-align: right;
      padding-top: 0.25rem;
      font-size: 12px;
      color: #63666a;
      padding-right: 4.5%;
    }
    &-drop-down {
      .drop-down__label {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0.5rem;
      }
      .drop-down__placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
      }
      .drop-down__options {
        width: 23rem;
      }
      .drop-down {
        &__outline {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 0.5rem;
          padding: 0.5rem 1rem;
          background-color: $white;
          border: 1px solid $light-grey;
          border-radius: 0.25rem;
          min-height: 1.8rem;

          &--not-empty {
            padding-top: 0;
            min-height: 2.3rem;
          }

          &:focus-within {
            border-color: $primary;
          }
        }

        &__placeholder {
          position: absolute;
          color: $grey;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
        }

        &__input {
          padding: 0;
          height: 1.5rem;
          border: none;
          flex-grow: 1;
          width: 1px;
          outline: none;
          z-index: 1;
          resize: none;
          background-color: transparent;
          @include font-family;
          font-size: 14px;

          &:focus {
            background-color: $white;
          }

          &--not-empty {
            margin-top: 0.5rem;
          }
        }

        &__input-size {
          min-height: 1.25rem;
          min-width: 1px;
          max-width: 14rem;
          visibility: hidden;
          position: absolute;
        }

        &__values {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          flex: 1;
        }

        &__value {
          background-color: $lightest-grey;
          padding: 0.25rem 0.25rem 0.25rem 0.5rem;
          border-radius: 1rem;
          margin-right: 0.5rem;
          margin-top: 0.5rem;
          display: flex;
          align-items: center;

          &--content {
            background-color: transparent;
            margin-top: 0;

            &:first-child {
              margin-top: 0.5rem;
            }
          }
        }

        &__remove {
          @include invisible-button;
          background-color: $grey;
          border-radius: 1rem;
          margin-left: 0.25rem;

          path {
            fill: $white;
          }
        }

        &__chevron-button {
          @include invisible-button;
          &:hover {
            background-color: $light-grey;
            transition: 0.7s;
            border-radius: 4px;
          }
          &--not-empty {
            margin-top: 0.5rem;
          }
        }

        &__chevron-icon {
          cursor: pointer;
          transition: transform 200ms ease-in-out;
          &--open {
            transform: rotate(180deg);

            path {
              fill: $primary;
            }
          }

          &--close {
            transform: rotate(0);
          }
        }

        &__options {
          width: 100%;
          border-radius: 0.25rem;
          margin-top: 0.25rem;
          background-color: $white;
          position: absolute;
          z-index: 2;
          overflow: hidden;
          transition: max-height 200ms ease-in-out;

          &--show {
            max-height: 11.8rem;
            border: 1px solid $light-grey;
            min-height: 2.5rem;
            box-shadow: 0px 1px 4px $light-grey;
            overflow: auto;
          }

          &--hide {
            max-height: 0rem;
          }
        }

        &__loading {
          margin: 0 auto;
        }

        &__option {
          @include invisible-button;
          background-color: $white;
          padding: 0.75rem 1rem;
          width: 100%;
          display: flex;
          justify-content: space-between;

          &--selected {
            background-color: $lightest-blue;
          }

          &:first-child {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
          }

          &:last-child {
            border-bottom-left-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
          }

          &:hover {
            background-color: $lightest-grey;
          }
        }

        &__option-category {
          padding: 0.5rem 1rem;
          color: $grey;
          font-weight: 700;
        }

        &__option-value {
          text-align: left;
        }

        &__ticker {
          padding-right: 0.5rem;
          min-width: 3.5rem;
        }
      }
      &--error {
        .drop-down {
          &__outline {
            border: 1px solid #da291c;
          }
        }
      }
    }
  }
  &__options {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 1rem 0 1rem 0;
    gap: 0.5rem;
  }
}
