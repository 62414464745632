.insight-dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  &__shareicon {
    color: #007cb0;
    display: flex;
    cursor: pointer;
    .path {
      color: $primary;
    }
    :hover {
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 8px;
    }
  }

  &__header {
    padding: 1.5rem 2rem 1.5rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    position: relative;
  }

  &__quater {
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  &__new-tile {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    &--show-info {
      width: 100%;
      margin: 0.5rem;
    }
  }

  &__insights-add-new-info {
    cursor: pointer;
    &:hover {
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 4px;
    }
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 12%;
    align-items: center;
    justify-content: center;

    & textarea {
      width: 60%;
      height: 40%;
      padding: 0.6rem;
      border: 1px solid #d0d0ce;
      box-sizing: border-box;
      border-radius: 4px;
      outline: none;
      resize: none;
      font-size: 0.95rem;
      font-weight: none;
      overflow-y: auto;
    }

    &--text {
      cursor: pointer;
      margin-left: 0.5rem;
      color: #007cb0;
      font-size: 0.875rem;
    }
  }

  &__add-info {
    &--header {
      padding: 1rem;
      border-bottom: 1px solid #f0f0f0;
    }

    &--text {
      cursor: pointer;
      display: flex;
      width: 13rem;
      .pencil-icon {
        display: none;
        margin-left: 0.5rem;
      }

      &:hover {
        .pencil-icon {
          display: inline-block;
        }
      }
    }
  }

  &__add-container {
    width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;

    &--body {
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }

  &__editor {
    margin: 3rem 1rem 2rem 1rem;
  }

  &__options {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  &__header-data {
    display: flex;
    flex-direction: column;
  }
  &__header-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__header-info {
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
    width: 100%;
  }
  &__header-chip {
    padding: 0.25rem;
    border-radius: 8px;
    font-size: 14px;
  }
  &__header-last-updated {
    flex-wrap: wrap;
    white-space: break-spaces;
    display: flex;
    flex: 1;
  }
  &__baseCompany {
    margin-left: 1rem;
  }
  &__name {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    &-text {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 40px;
    }
  }

  &__content {
    @include grid-dashboard;
    // TODO: uncomment for graphtile refactor
    // grid-template-rows: repeat(auto-fill, 1px);

    .data-source-container {
      display: flex;
      justify-content: flex-end;
      gap: 0.3rem;
      padding: 1.5rem;
      margin-block-start: auto;
    }

    .data-source {
      font-size: 0.875rem;
      justify-content: flex-end;
      color: $grey;
      position: relative;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    &-status {
      display: flex;
      align-items: center;
      &-text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }
    }
    &__export {
      display: flex;
      background-color: transparent;
      border: none;
      padding: 0.5rem;
      margin-left: 1rem;
      &-text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $primary;
      }
    }
  }
  &__talkingPoints-button {
    @include invisible-button;
    position: relative;
    display: flex;
    gap: 0.5rem;
    margin-left: 2rem;
    .tooltip {
      top: 1.5rem;
      left: -2rem;
      z-index: 2;
      opacity: 0;

      &__label {
        height: 0;
      }
    }

    &:hover {
      .tooltip {
        opacity: 1;

        &__label {
          height: 100%;
        }
      }
      cursor: pointer;
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 4px;
      path {
        fill: $secondary-blue;
      }
    }
  }

  &__back-to-dashboard {
    cursor: pointer;
    font-size: 1rem;
    position: absolute;
    left: 0.2rem;
    top: calc(50% - 1rem);
  }

  &__back-to-dashboard:hover {
    background-color: $light-grey;
    transition: 0.7s;
    border-radius: 4px;
  }

  &__tabs {
    position: sticky;
    bottom: 0.5rem;
    display: flex;
    grid-column-gap: 0.3rem;
    column-gap: 0.3rem;
    justify-content: center;
    margin-top: -2rem;
    z-index: 60;
  }

  &__tab-separator {
    background-color: $white;
    border-top: 1px solid $omnia-grey;
    position: sticky;
    bottom: 0;
    height: 0.5rem;
    box-sizing: border-box;
    z-index: 60;
  }

  &__tab-button {
    border: 1px solid $omnia-grey;
    background-color: $lighter-grey;
    color: $dark-grey;
    outline: none;
    cursor: pointer;
    font-family: "Open Sans";
    padding: 0.5rem 1rem;
    font-weight: 600;
    line-height: 1rem;
    font-size: 0.75rem;
    border-radius: 0.4rem 0.4rem 0rem 0;
    display: flex;
    column-gap: 0.5rem;
    border-bottom: none;
    margin-top: -9px;
  }

  &__tab-button:hover {
    color: $neutral-black;
    background-color: $white;

    path {
      fill: $neutral-black;
    }
  }

  &_selected {
    color: $green;
    background-color: $white;
    margin-bottom: -1px;
    cursor: auto;

    path {
      fill: $green;
    }

    &:hover {
      color: $dark-green;

      path {
        fill: $dark-green;
      }
    }
  }

  &__divider {
    border-left: 1px solid #e9e9e9;
    height: 1.5rem;
  }
  &__edit-view-button-insight {
    display: flex;
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    border: 1px solid #007cb0;
    border-radius: 4px;
    gap: 0.25rem;
    background: white;
    color: $primary;
    cursor: pointer;
    .path {
      color: $primary;
    }
  }
  &__edit-view-button {
    @include invisible-button;
    display: flex;
    gap: 0.5rem;
    color: $primary;

    .path {
      color: $primary;
    }
  }
  &__export-all {
    position: relative;

    &-button {
      @include primary-button;
      border: 1px solid $primary;
      background-color: $primary;
      display: flex;
      gap: 0.5rem;
      color: $white;
      align-items: center;

      &--disabled {
        @include primary-button-disabled;
        display: flex;
        gap: 0.5rem;
        color: $dark-grey;
        align-items: center;
      }

      &--active {
        path {
          transform: rotate(180deg);
          transform-origin: 50% 50%;
        }
      }
    }
    &-button:hover {
      transition: 0.7s;
    }
    &-options {
      display: none;
      position: absolute;
      top: 2.5rem;
      background-color: $white;
      z-index: 3;
      box-shadow: 0.1rem 0.1rem 0.2rem $light-grey;
      border: 1px solid $lightest-grey;

      &--show {
        display: block;
      }
    }
    &-option {
      @include invisible-button;
      padding: 0.5rem;
      display: flex;
      gap: 0.5rem;
      font-size: 0.875rem;

      &:hover {
        background-color: $lightest-blue;
        border-color: $primary;
      }

      &--text {
        padding: 0.2rem 0.5rem 0.2rem 0.5rem;
        white-space: nowrap;
      }
    }
  }

  &__button {
    &--disabled {
      background-color: #f0f0f0 !important;
      border: 1px solid #9ea6a9 !important;
      path {
        fill: #bcc0c2 !important;
      }
    }
  }
  &__talking-points {
    z-index: 1000;
    display: flex;
    position: fixed;
    width: 26.5rem;
    height: 87vh;
    right: 0rem;
    top: 5.7rem;
    background: #ffffff;
    box-shadow: -1px -1px 1px #e0e0e0;
    border-bottom: 1px solid #e0e0e0;

    &__header {
      width: 100%;
      min-height: 3rem;
      height: fit-content;
      left: 0rem;
      top: 0rem;
      box-shadow: inset 0px -1px 0px #e7e7e7;
      padding-bottom: 5rem;

      &__content {
        position: absolute;
        width: 20rem;
        min-height: 2rem;
        height: fit-content;
        left: 1rem;
        top: 0.5rem;
        font-size: 16px;
        font-weight: 400;
        box-sizing: border-box;
      }

      &__cross {
        position: absolute;
        right: 1rem;
        top: 0.5rem;
        cursor: pointer;
      }
    }

    &__column-company {
      &--name {
        width: 15rem;
      }
      &--ticker {
        width: 3rem;
      }
      &--auditor {
        width: 5rem;
      }
      &--metrics {
        width: 2rem;
      }
      &--source {
        width: 6.1rem;
      }
    }

    &__continue {
      margin-left: 1rem;
    }

    &__remove {
      @include invisible-button;
      background-color: $grey;
      border-radius: 1rem;
      margin-left: 0.25rem;

      path {
        fill: $white;
      }
    }
    &__dropdown-label {
      font-size: 14px;
      font-weight: 600;
    }

    &__placeholder {
      font-size: 14px;
    }

    &__body {
      box-sizing: border-box;
      width: 100%;
      padding: 0 1rem;
      font-size: 14px;
      overflow-x: hidden;
    }

    &__dashboard-name {
      &--label {
        font-weight: 600;
        font-size: 14px;
        margin: 1rem 0 0.5rem 0;
      }
    }
  }

  &__ghgEmissionFilter {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__toggle-view {
    display: flex;
    flex-direction: row;
  }

  &__toggle {
    background-color: $white;
    border: 1px solid $primary;
    padding: 0.25rem;
    cursor: pointer;
    position: relative;
    path {
      fill: $primary;
    }
    .tooltip {
      top: 2rem;
      left: -1.5rem;
      z-index: 2;
      opacity: 0;

      &__label {
        height: 0;
      }
    }

    &:hover {
      .tooltip {
        opacity: 1;

        &__label {
          height: 100%;
        }
      }
      cursor: pointer;
      background-color: $light-grey;
      transition: 0.7s;
      path {
        fill: #005478;
      }
    }

    &--active {
      background-color: $primary;
      color: $white;
      path {
        fill: $white;
      }
    }

    &--active:hover {
      background-color: #005478;
      transition: 0.7s;
      path {
        fill: $white;
      }
    }

    &--text {
      @include font-family;
      font-size: 0.875rem;
      padding: 0.25rem 0.75rem;
    }
  }

  &__refresh-btn{
    position: relative;
    .tooltip {
      left: -4rem;
      top: 1.5rem;
      z-index: 2;
      display: none;
    }

    &:hover {
      background: none;
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__refresh-modal-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__refresh-modal-content {
    font-size: 0.875rem;
  }
}

.jodit-status-bar-link {
  visibility: hidden;
  display: none;
}

.jodit-xpath {
  visibility: hidden;
  display: none;
}

.jodit-ui {
  &-block {
    &__className {
      visibility: hidden;
      display: none;
    }
  }
  &-form {
    &__nofollow {
      visibility: hidden;
      display: none;
    }
  }
  &-input {
    font-family: "Open Sans";
    &__input {
      font-family: "Open Sans" !important;
    }
  }
  &-button {
    &__text:not(:empty) {
      font-family: "Open Sans" !important;
    }
  }
  &-checkbox__label {
    font-family: "Open Sans";
    &:hover {
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 4px;
    }
  }
}
