.mapfiles-table {
  width: 100%;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;

  &__table {
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;
    border-collapse: collapse;
    position: relative;
    box-sizing: border-box;
    flex-grow: 1;
    border: 1px solid #ebebeb;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  &__table--wrapper {
    // position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
  }

  &__heading--fieldsMissing {
    display: flex;
    align-items: center;
    padding: 0.625rem 1rem;
    background: #ffecea;
    /* Functional / Red */
    border: 0.063px solid #da291c;
    box-sizing: border-box;
    border-radius: 0.126rem;

    &--icon {
      padding-left: 1.188rem;
    }

    &--text {
      padding-left: 0.688rem;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1, 5rem;
      color: $black;
    }
  }

  &__table--inner--wrapper {
    // position: absolute;
    width: 100%;
  }

  &__header {
    border: 0.063rem solid $lightest-grey;
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem 1rem;
    position: sticky;
    background: $white;
    z-index: 1;

    &--content {
      line-height: 1rem;
      text-align: left;

      &:last-child {
        span {
          width: 100%;
          text-align: right;
        }
      }
    }

    &--text {
      font-weight: 600;
      font-size: 0.75rem;

      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  th,
  td {
    flex: 1;
  }
}
