.global-search-modal{
    &__container{
        display: flex;
        flex-direction: column;
        height: calc(100vh - 8rem);
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 0.25rem;
       @include vertical-scroll;
    }

    &__guidance{
        display: flex;
        flex-direction: column;
        background: #EBF7F8;
        border: 1px solid #00ABAB;
        border-radius: 2px;
        padding: 1rem;
        row-gap: 0.5rem;
        margin-bottom: 1.5rem;
        &-header{
            display: flex;
            column-gap: 0.5rem;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }
        &-text{
            &-wrapper{
                display: flex;
                flex-direction: column;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
    
            }
            &-list{
                margin: unset;
            }
           
            &-list:nth-child(1){
               & li{
                list-style-type: disc;
               }
            }
        }
        &-btn{
            display: flex;
            justify-content: space-between;
        }
        &-chevron{
            transition: rotate 1s;
            
            &--open{
                rotate: 180deg;
            }
        }
    }

    &__tabbed-content{
        &-container{
            display: flex;
            flex-direction: column;
            margin-top: 1rem;
            row-gap: 1rem;
            margin-bottom: 2.5rem;
            flex-grow: 1;
        }
        &-item{
            @include fz-14--400;
            font-weight: 600;
        }
    }

    &__options{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.1rem;
      
        &-left{
            display: flex;
            align-items: center;
            column-gap: 0.5rem;
            position: relative;
            .checkbox__checkbox-blue--global-search{
                transform: unset;
                top: unset;
            }
            .checkbox{
                width: 1rem;
                height: 1rem;
              
            }
            .checkbox__label{
                @include fz-14--400; 
            }
        }

        &-right{
            display: flex;
            align-items: center;
            column-gap: 1rem;
        }
       
    }
    
    &__search-section{
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
    }

  
    &__company-name{
        &-section{
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
            &__dropdown{
                padding: 0.5rem 1rem;
                @include fz-14--400;
                background: #FFFFFF;
                border: 1px solid #D0D0CE;
                border-radius: 4px;
                flex-grow: 1;
                display: flex;
                justify-content: space-between;

                .shared-dropdown__options{
                    width: 100%;
                    margin-left: -1rem;
                    top: calc(100% + 0.25rem);
                }
                .shared-dropdown__option{
                    svg{
                        display: none
                    }
                }
                span{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
            }
            &-cross-container{
                position: relative;
                display: flex;
                align-items: center;

                &:hover{
                    .tooltip{
                        display: block;
                    }
                }
                .tooltip{
                    display: none;
                    right: calc(-100%);
                    top: -10px;
                    left: unset
                }
            }
        }
        

        &-label{
            @include fz-14--400; 
        }
    }

    &__selected-company{
        padding: 1.5rem;
        background: $lighter-grey;
        border-radius: 8px;
        row-gap: 1rem;
        display: flex;
        flex-direction: column;
    }

    &__dropdown{
        width: 100%;
        position: relative;

        &-container{
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
        }
        &-label{
            @include fz-14--400; 
        }

        &-textarea{
            @include fz-14--400; 
            padding: 0.5rem 1rem;
            border: 1px solid #D0D0CE;
            border-radius: 4px;
            transition: all ease-in-out 1s;
            &-placeholder{
                color: $darkest-grey;
            }

            &:focus-visible{
                border: 1px solid #007CB0;
                outline: unset;
            }

            &-cross-btn{
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 1rem;
                top: calc( 1.25rem - 0.75rem);
            }
        }

        &-search-box{
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            &-limit-legend{
                @include fz-12--400;
                line-height: 1rem;
                color: $darkest-grey;
                padding: 0.25rem 1rem;
            }
        }

        &-options{
            position: absolute;
            top: 2.75rem;
            width: calc(100% - 0.1rem);
            background: #FFFFFF;
            border: 1px solid #D0D0CE;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
            border-radius: 2px;
            z-index: 500;
            max-height: 5rem;
            overflow-y: auto;
            @include vertical-scroll;
            &-suggested-map{
                display: flex;
                flex-direction: column;
                width: calc(100% - 2rem);
                border-bottom: 1px solid #E0E0E0;
                
                &-label{
                    @include fz-12--400;
                    font-weight: 600;
                    color: $dark-grey;
                    padding: 0.5rem 1rem;
                }
            }

            &-wrapper{
                display: flex;
                flex-direction: column;
                width: 100%;
                
            }

            &-item{
                display: flex;
                width: calc(100% - 2rem);
                padding: 0.625rem 1rem;
                justify-content: space-between;
            
                &--hide{
                    display: none;
                }
                &-name{
                    margin-left: 0.5rem;
                    &-wrapper{
                        display: flex;
                        align-items: center;
                    }
                }
                &-vendor{
                    &-wrapper{
                        display: flex;
                        column-gap: 0.25rem;
                    }
                }
                &-checkbox-wrapper{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    width: fit-content;
                    height: fit-content;
                    .checkbox{
                        width: 1rem;
                        height: 1rem;
                        margin: unset;
                    }
                    .checkbox__label{
                        margin-left: 0.5rem;
                        width: 5rem;
                        word-break: break-word;
                    }
                    .checkbox__checkbox-blue{
                        margin: unset;
                        left: 0;
                    }
                }
            }

        }

        &-actions{
            &-wrapper{
                display: flex;
                justify-content: flex-end;
                column-gap: 1rem;
                padding: 1rem;
            }
        }

        &-reset-btn.button.global-search-modal__dropdown-reset-btn{
            padding: 0.5rem;
            
            
        }
        &-reset-btn-enabled.global-search-modal__dropdown-reset-btn-enabled{
            color: $primary;
        }

    }
    &__pill{
        border: 1px solid $dark-grey;
        border-radius: 110px;
        padding: 0.25rem 1.25rem;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        height: fit-content;
    }

    &__company-name-selected{
        display: flex;
        column-gap: 2rem;
        justify-content: space-between;
    }

    &__warning{
        &-text{
            @include fz-14--400;
            padding: 0.75rem 1rem;
            background-color: $light-yellow;
            border: 1px solid $dark-yellow;
            margin-bottom: 1.5rem;
            border-radius: 2px;
            display: flex;
            align-items: center;
            column-gap: 0.25rem;
            line-height: 18.75px;
        }
    }
    &__error{
        &-text{
           
            background-color: rgba(218, 41, 28, 0.06);
            border: 1px solid rgba(218, 41, 28, 0.24);
       
        }
    }
}