.my-comparisons {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;

  &__container {
    height: 100%;
  }

  &__header {
    height: 2rem;
    padding: 0.6rem 0.75rem;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 400;
  }
  &__feedback-section {
    min-width: 1rem;
  }
  &__commenting {
    margin-right: 0.4rem;
  }
  &__feedback-button {
    @include font-family;
    background-color: $primary;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    border: none;
    margin-right: 0.3rem;
    border-radius: 0.25rem;
    color: $white;
    font-weight: 400;
    line-height: 1rem;
    font-size: 0.875rem;
  }
  &__empty-container {
    display: flex;
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  &__empty-header {
    height: 2rem;
    padding: 1rem;
    display: flex;
    justify-content: right;
  }

  &__empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin-top: 10%;
  }
  &__empty-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $black;
    font-style: normal;
  }

  &__empty-redirect {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    color: #75787b;

    font-style: normal;
  }
  &__link {
    &:hover {
      text-decoration: underline;
    }
    &::before {
      content: "\00a0";
    }
    &::after {
      content: "\00a0";
    }
  }
}
