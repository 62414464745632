.saved-comparisons-table {
  width: 100%;
  //box-shadow: 0px 4px 8px rgba(238, 240, 241, 0.48);
  border-radius: 8px;
  font-weight: 400;

  &__table-wrapper {
    // position: relative;
    height: 59vh;
    // width: 89vw;
    flex-grow: 1;
    overflow-y: auto;
    border: 1px solid #ebebeb;

    @media screen and (min-width: 1400px){
      height: 64vh;
    }

    @media screen and (min-width: 1600px){
      height: 70vh;
    }

    @media screen and (width: 1272px){
      height: 60.5vh;
    }
  }

  thead {
    width: 100%;
    position: sticky;
    top: 0px;
    background: white;
    z-index: 2;
  }

  th,
  td {
    flex: 1;
  }

  th {
    display: flex;

    > div {
      display: flex;
      align-items: center;
    }
  }

  thead th:nth-child(1),
  tbody td:nth-child(1) {
    min-width: 10.7rem;
  }

  thead th:nth-child(2),
  tbody td:nth-child(2) {
    min-width: 10.5rem;
  }

  thead th:nth-child(3),
  tbody td:nth-child(3) {
    max-width: 6.5rem;
    min-width: 3.5rem;
  }

  thead th:nth-child(4),
  tbody td:nth-child(4) {
    max-width: 6.5rem;
    min-width: 3.5rem;
  }

  thead th:nth-child(5),
  tbody td:nth-child(5) {
    max-width: 6rem;
    min-width: 4rem;
  }

  thead th:nth-child(6),
  tbody td:nth-child(6) {
    min-width: 7rem;
    max-width: 8rem;
  }
  thead th:nth-child(7),
  tbody td:nth-child(7) {
    min-width: 7rem;
    max-width: 10rem;
  }

  thead th:nth-child(8),
  tbody td:nth-child(8) {
    max-width: 8rem;
    min-width: 4rem;
  }

  thead th:nth-child(9),
  tbody td:nth-child(9) {
    max-width: 9rem;
    min-width: 6rem;
  }

  thead th:nth-child(10),
  tbody td:nth-child(10) {
    max-width: 5.625rem;
  }

  tr {
    box-shadow: inset 0px -1px 0px #ebebeb;
    display: flex;
    position: relative;

    a {
      text-decoration: none;
      color: black;
      position: relative;
      width: 100%;
      display: flex;
    }
  }

  &__table {
    // position: absolute;
    width: 100%;
  }

  &__shared-by,
  &__shared-with {
    cursor: pointer;
  }

  &__shared-with {
    cursor: pointer;
    display: flex;
    gap: 0.5rem;
  }

  &__user-icon {
    background: $black;
    border-radius: 100%;
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
    font-style: normal;
    font-weight: 600;
    font-size: 0.6rem;
    line-height: unset !important;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__user-icon--tooltip {
    position: relative;
    .tooltip {
      z-index: 10;
      display: none;
      line-height: 1rem;
      left: 0rem;

      .tooltip__label {
        overflow: visible;
        min-width: 6rem;
        max-width: 50rem;
        max-height: 10rem;
        transform: translateY(-70%);
        position: absolute;
      }
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__more-users-icon {
    background-color: $light-grey;
    color: $black;

    .tooltip {
      display: none;
      left: -100%;
      top: 0;
      padding-top: 1.75rem;
      &:hover {
        display: block;
      }

      .tooltip__label {
        padding: 0.5rem;
        overflow: auto;
        max-width: 35rem;
        max-height: 15rem;
        min-width: 15rem;
        background: $white;
        color: $black;
        border: 1px solid $light-grey;
        flex-direction: column;
        display: flex;
        gap: 0.5rem;
        > span {
          display: flex;
          gap: 0.5rem;
        }
      }
    }

    &:hover .tooltip {
      display: block;
    }
  }

  &__shared-with-count {
    overflow: unset !important;
    line-height: 1.5rem;
    position: relative;
    padding-right: 0.2rem;

    .tooltip {
      display: none;
      list-style: none;
      line-height: 1rem;
      left: 0.75rem;
      top: -0.25rem;
      z-index: 10;

      .tooltip__arrow {
        top: -8.5%;
      }
      .tooltip__label {
        overflow: auto;
        max-width: 35rem;
        max-height: 6rem;
        position: absolute;
        transform: translateY(-70%);
      }
    }

    svg:not(:root) {
      transform: translateX(8px) translateY(4px);
    }

    &:hover {
      background: #e0e0e0;
      border-radius: 15px;
      height: fit-content;
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__autoDelete7 {
    color: #ed8b00;
  }
  &__autoDeleteLessThan7 {
    color: #da291c;
  }

  &__name,
  &__description {
    cursor: pointer;
    position: relative;
    padding-right: 0.2rem;

    .tooltip {
      display: none;
      list-style: none;
      line-height: 1rem;
      left: 8rem;
      top: 0rem;
      white-space: pre-wrap;
      word-wrap: break-word;
      z-index: 10;

      .tooltip__arrow {
        top: -8.5%;
      }
      .tooltip__label {
        overflow: auto;
        max-width: 20rem;
        max-height: fit-content;
        position: absolute;
        transform: translateY(-80%);
        font-weight: inherit;
        color: white;
      }
    }

    svg:not(:root) {
      transform: translateX(8px) translateY(4px);
    }

    &:hover {
      height: fit-content;
      .tooltip {
        display: inline-block;
      }
    }

    & > span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      max-width: 100%;
    }
  }

  &__newItem {
    font-weight: 600;
  }

  &__descriptionTooltip {
    position: relative;
    padding-right: 0.2rem;

    .tooltip {
      z-index: 10;
      display: none;
      list-style: none;
      line-height: 1rem;
      left: 8rem;
      top: 0rem;
      white-space: pre-wrap;
      word-wrap: break-word;

      .tooltip__arrow {
        top: -8.5%;
      }
      .tooltip__label {
        overflow: auto;
        overflow-x: hidden;
        width: fit-content;
        min-width: 8rem;
        max-width: 25rem;
        max-height: 6rem;
        position: absolute;
        transform: translateY(-80%);
      }
    }

    svg:not(:root) {
      transform: translateX(8px) translateY(4px);
    }

    &:hover {
      height: fit-content;
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__items {
    color: #007cb0;
    position: relative;
    display: flex;
    justify-content: flex-start;
    padding-right: 10px;
  }

  &__doc-icon {
    width: 2rem;
    .tooltip {
      display: none;
      left: -20rem;
      z-index: 10;

      .tooltip__arrow {
        top: 9%;
      }
      .tooltip__label {
        overflow: auto;
        // overflow-x: hidden;
        min-width: 19.8rem;
        max-width: 25rem;
        max-height: 6rem;
        transform: translateY(-80%);
      }
    }

    svg:not(:root) {
      transform: translateX(8px) translateY(4px);
    }

    &:hover {
      background: #e0e0e0;
      cursor: pointer;
      height: 25px;
      border-radius: 15px;

      .tooltip {
        display: inline-block;
      }
    }
  }

  &__share,
  &__edit,
  &__delete {
    .tooltip {
      display: none;
      top: -25px;
      left: -5%;
      z-index: 10;
    }

    .tooltip__arrow {
      left: 43%;
    }

    &:hover {
      .tooltip {
        display: inline-block;
        z-index: 10;
      }
      background-color: $light-grey;
      border-radius: 32px;
    }
    &--disabled {
      &:hover {
        .tooltip {
          display: none;
        }
        background: none;
        border: none;
      }
      background: none;
      border: none;
      color: #75787b;
      cursor: default;
      path {
        fill: #75787b;
      }
    }
  }

  &__edit {
    .tooltip {
      left: 30%;
    }
  }

  &__delete {
    .tooltip {
      left: 47%;
    }
  }

  &__items-text {
    color: #007cb0;
    margin-left: 0.2rem;
  }

  &__heading-text {
    display: flex;
  }

  &__heading-text th {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #212121;
    text-align: left;
    padding: 0px 16px 0 16px;
  }

  &__heading-content {
    text-align: left;

    .filter {
      line-height: 1.5rem;
      visibility: hidden;
      padding: 0.3rem;
      cursor: pointer;
      &:hover {
        background-color: $light-grey;
      }
      &__active {
        visibility: visible;
      }
    }

    &:hover {
      .filter {
        visibility: visible;
      }
    }

    .filter-modal {
      z-index: 1;
      position: absolute;
      top: -0.375rem;
      &--right {
        left: 2rem;
      }

      &--left {
        left: -15rem;
      }
    }
  }

  &__icons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    span {
      padding-right: 0.5rem;

      &:last-child {
        padding-right: 0px;
      }
    }
  }

  &__rows {
    display: flex;
  }

  &__newItem {
    font-weight: 600;
  }

  td {
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    letter-spacing: 0;
    word-wrap: break-word;
    height: 30px;
    padding: 0 16px 0 16px;
    margin-top: 5px;
    position: relative;
  }

  td > span,
  &__items-text {
    line-height: 24px;
  }

  &__header {
    font-size: 16px;
    line-height: 24px;

    box-shadow: inset 0px -1px 0px #ebebeb;
    padding: 16px 0 0 16px;
    margin-top: 0px;
  }

  thead tr:first-child td {
    margin-top: 0px;
    padding: 0.2rem 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__outside-td {
    text-align: -webkit-right;
  }

  &__header-inner-content {
    padding: 0.75rem 0.5rem 0.75rem 0;
    @include font-family;
  }

  &__header-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: left;
    background: none;
    border: none;
    &:hover {
      background-color: $lightest-grey;
      border-radius: 4px;
    }
    &--sorted {
      padding-right: 0;
    }
  }

  &__sort-icon {
    padding-right: 0.5rem;
  }

  &__header-content {
    text-align: left;
  }

  &__header-text {
    font-weight: 600;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;
  }

  &__link {
    cursor: pointer;
    &:hover {
      background-color: $lightest-grey;
    }
    &--newItem {
      font-weight: 600;
    }
  }

  &__hover-tooltip {
    height: 100%;
    text-align: left;
  }

  &__name-tooltip div {
    text-align: left;
  }

  &__spinner-container {
    display: flex;
    justify-content: center;
  }

  &__spinner {
    width: 2rem;
    padding: 1rem 0;
  }

  &__autoDelete {
    position: relative;
    .tooltip {
      z-index: 10;
      display: none;
      line-height: 1rem;
      left: 3rem;

      .tooltip__label {
        overflow: visible;
        min-width: 6rem;
        max-width: 50rem;
        max-height: 10rem;
        transform: translateY(-70%);
        position: absolute;
      }
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__autoDelete7 {
    color: #ed8b00;
  }
  &__autoDeleteLessThan7 {
    color: #da291c;
  }
}
