.maintenance-messages {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  padding-top: 0.5rem;

  &__img {
    width: 16rem;
    height: 13.125rem;
  }

  &__empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin-top: 10%;
  }

  &__emptytext {
    width: 26rem;
    height: 1.5rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5px;
    text-align: center;
  }

  &__header {
    padding: 0.5rem 0rem;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-top: -1rem;
  }

  &__link {
    color: $primary;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__row {
    box-shadow: none;

    &:hover {
      background-color: unset;
    }
  }

  &__actions {
    justify-content: flex-end;
    gap: 0.5rem;

    &:hover {
      .tooltip {
        display: none;
      }
    }
  }

  &__column-content {
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
  }

  &__action-icon {
    position: relative;
    cursor: pointer;

    .tooltip {
      display: none;
      top: -2rem;
      left: -2rem;
      bottom: unset;

      &--top {
        &__arrow {
          left: 45%;
        }
      }
    }
  }

  &__table-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    white-space: normal !important;
  }

  &__data {
    padding: 0.3rem 1rem;
    border: 1px solid $lightest-grey;
    border-radius: 0.25rem 0.25rem 0 0;
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  &__results-count {
    font-size: 0.875rem;
  }

  &__edit {
    .tooltip {
      left: -1rem;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }

      background-color: $light-grey;
      border-radius: 32px;
    }
  }

  &__delete {
    .tooltip {
      left: -1.5rem;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }

      background-color: $light-grey;
      border-radius: 32px;
    }
  }

  &__table {
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;
    border-collapse: collapse;
    font-size: 0.875rem;

    &-wrapper {
      // position: relative;
      height: 44vh;
      overflow-y: scroll;
      overflow-x: hidden;
      flex-grow: 1;
      box-shadow: inset 0px -1px 0px $lightest-grey;

      @media screen and (min-width: 1400px){
        height: 64vh;
      }
  
      @media screen and (min-width: 1600px){
        height: 70vh;
      }

      @media screen and (width: 1272px){
        height: 46vh;
      }
    }

    &-inner-wrapper {
      // position: absolute;
      width: 100%;
    }

    &__header {
      box-shadow: none;

      &-content {
        position: static;
        width: auto;
        line-height: 1rem;
        text-align: left;

        &:last-child > div {
          justify-content: flex-end;
        }
      }

      &-text {
        font-weight: 600;
        font-size: 0.75rem;

        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }

    th:nth-child(1),
    td:nth-child(1) {
      width: auto;
    }

    tr {
      border: 1px solid $lightest-grey;
      box-sizing: border-box;
    }

    tr {
      width: 100%;
    }

    th,
    td {
      flex: 1;
      white-space: normal !important;
    }

    th > div {
      padding: 0;
    }

    td,
    th {
      padding: 0.5rem 1rem;
    }

    thead {
      position: sticky;
      background: $white;
      z-index: 1;
      top: -1px;
    }

    @media screen and (min-width: 1200px) {
      table td:first-child {
        max-width: 200px;
        white-space: normal !important;
      }

      thead th:nth-child(1),
      tbody td:nth-child(1) {
        width: 2%;
      }

      thead th:nth-child(2),
      tbody td:nth-child(2) {
        width: 5%;
      }

      thead th:nth-child(3),
      tbody td:nth-child(3) {
        width: 17%;
      }

      thead th:nth-child(4),
      tbody td:nth-child(4) {
        width: 15%;
      }

      thead th:nth-child(5),
      tbody td:nth-child(5) {
        width: 15%;
      }

      thead th:nth-child(6),
      tbody td:nth-child(6) {
        width: 15%;
      }

      thead th:nth-child(7),
      tbody td:nth-child(7) {
        width: 15%;
      }

      thead th:nth-child(8),
      tbody td:nth-child(8) {
        width: 10%;
      }

      thead th:nth-child(9),
      tbody td:nth-child(9) {
        width: 6%;
      }
    }
  }

  &__indicator {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
  }

  &__activeIndicator {
    background-color: green;
  }

  &__upcomingIndicator {
    background-color: orange;
  }

  &__expiredIndicator {
    background-color: gray;
  }

  &__loading {
    width: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  &__disabled {
    cursor: none;
    opacity: 0.5;
    pointer-events: none;
  }
}
.message-table-column-text {
  white-space: normal !important;
}
