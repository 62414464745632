.Note {
  width: 20rem;
  min-height: 9rem;
  height: auto;
  left: 760px;
  top: 410px;
  display: flex;
  flex-direction: column;

  &__saveNote {
    width: 40rem;
  }

  &--deleteSearch {
    min-height: 5.3rem;
  }
  &__name {
    font-size: 14px;
    line-height: 24px;
    color: $black;
    font-weight: 500;
    text-align: left;

    &__saveNote {
      white-space: pre-wrap;
      word-wrap: break-word;
    }
    &--deleteSavedSearch {
      font-size: 14px;
      padding-left: 4%;
      white-space: pre-wrap;
      word-wrap: break-word;
      padding-right: 4%;
    }
  }

  &__nameError {
    font-size: 14px;
    line-height: 24px;
    padding-left: 5%;
    color: #f54242;
    font-weight: 500;
    text-align: left;
  }

  &__input {
    width: 100%;
    height: auto;
    padding-top: 0.5rem;
    text-align: center;
    outline: none;

    & input,
    textarea {
      width: 100%;
      height: fit-content;
      padding: 0.6rem;
      color: #212121;
      border: 1px solid #d0d0ce;
      box-sizing: border-box;
      border-radius: 4px;
      outline: none;
    }
    &__saveNote input,
    textarea {
      padding: 0.75rem 1rem;
    }
  }

  &__inputError {
    width: 100%;
    height: auto;
    padding-top: 0.5rem;
    text-align: center;
    outline: none;

    & input,
    textarea {
      width: 90%;
      height: fit-content;
      padding: 0.6rem;
      color: #212121;
      border: 1px solid #f54242;
      box-sizing: border-box;
      border-radius: 4px;
      outline: none;
    }
    &__saveNote input,
    textarea {
      padding: 0.75rem 1rem;
      width: 91%;
    }
  }

  &__content {
    text-align: right;
    padding-top: 0.25rem;
    font-size: 12px;
    color: #63666a;
  }

  &__contentError {
    padding-top: 0.25rem;
    font-size: 12px;
    color: $red;
    padding-left: 1rem;
    &__saveNote {
      padding-left: 1rem;
    }
  }

  &__options {
    padding-top: 1rem;
    text-align: right;
    display: flex;
    justify-content: space-between;

    &-no-delete {
      justify-content: flex-end;
    }

    &__saveNote {
      padding-top: 2rem;
    }

    &--deleteSearch {
      padding-top: 2rem;
    }

    &-end {
      display: flex;
      gap: 0.5rem;
    }
  }

  &__delete-note-button {
    cursor: pointer;
    color: #da291c;
    display: flex;
    gap: 0.6rem;
    align-items: center;
    border: none;
    background-color: transparent;
    font-size: 14px;
    line-height: 24px;
    svg {
      transform: translateY(-0.1rem);
    }
  }

  &__continue {
    margin-left: 1rem;

    &-disabled {
      background-color: $grey;
    }
  }
  &__note {
    &.Note__note {
      font-family: inherit;
      height: 8.5rem;
      resize: none;
    }

    &-label {
      margin-top: 1rem;
    }
  }
}

.SaveNote__btn {
  &.SaveNote__btn {
    padding: 0.5rem 1.5rem;
  }
}

.SaveNote__btn__ShortlistItems {
  @include primary-button-outline;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border: none;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  &:hover {
    background-color: $light-grey;
    color: $secondary-blue;
    transition: 0.7s;
    border-radius: 4px;
    path {
      fill: $secondary-blue;
    }
  }
}
.SaveNote__btn__ShortlistItems-disabled {
  @include primary-button-outline-disabled;
  align-items: center;
  border: none;
  background-color: transparent;
  position: relative;
  cursor: auto;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  path {
    fill: $grey;
  }
  &:hover {
    background-color: transparent;
    color: $grey;
    path {
      fill: $grey;
    }
  }
}

.Note__ShortlistItems {
  height: 11rem;
  padding: 1rem;
  background-color: #fafafa;
  box-shadow: inset 0px -1px 0px #ebebeb;
}

.Note__noteShortlistItems {
  font-family: inherit;
  background: #ffffff;
  border: 1px solid #d0d0ce;
  box-sizing: border-box;
  border-radius: 4px;
  height: 6.5rem;
  width: 100%;
  resize: none;
}

.Note__nameShortlistItems {
  padding-bottom: 0.5rem;
  font-family: Open Sans;
  font-size: 14px;
  line-height: 24px;
}
.ShortlistItemsActions {
  display: flex;
  justify-content: space-between;
}
.ShortlistItemsCount {
  display: flex;
  justify-content: flex-end;
}

.ShortlistItemsButtons {
  display: flex;
  justify-content: flex-end;
}
.shortlistItemsCancel {
  @include primary-button-outline;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border: none;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  font-family: Open Sans;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  &:hover {
    background-color: $light-grey;
    color: $secondary-blue;
    border-radius: 4px;
    transition: 0.7s;
  }
}

.delete-note-button-ShortlistItems {
  cursor: pointer;
  color: #da291c;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 24px;
  svg {
    transform: translateY(-0.1rem);
  }
  &:hover {
    background-color: $red;
    color: $white;
    transition: 0.3s;
    border-radius: 4px;
  }
}
