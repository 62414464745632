.energyConsumption {
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  &__company {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 100%;

    &-name {
      font-size: 0.75rem;
      font-weight: 400;
      font-style: normal;
    }
  }

  &__metrics {
    width: 100%;
  }

  &__metric {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f1f8fb;
    grid-gap: 0.75rem;
    margin: 0.75rem;
    height: 3rem;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    border-radius: 0.625rem;

    &-name {
      font-size: 0.875rem;
      font-weight: 400;
      font-style: normal;
      flex: 1 1 100%;
    }
    &-value {
      display: flex;
      gap: 0.1rem;

      font-size: 0.75rem;
      font-weight: 600;
      font-style: normal;
      color: #012169;
    }
    &-unit {
      font-size: 0.75rem;
      font-weight: 400;
      font-style: normal;
      color: black;
    }
  }

  &__no-data-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__no-data-text {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    width: calc(100% - 2rem);
    margin-top: 2rem;
  }

  &__no-data-pill {
    width: 1.25rem;
    height: 0.4rem;
    border-radius: 0.25rem;
    border: solid lightgray 1px;
    background: repeating-linear-gradient(
      45deg,
      #f0f0ef,
      #f0f0f0 1px,
      white 1px,
      white 10px
    );
    margin-top: 2rem;
  }

  &__detailedView {
    .multiple-chart {
      &__bar {
        width: 32%;
      }
    }
  }
}
