p {
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
}
.footer {
  color: $grey;
  font-size: 0.7rem;
  padding: 0.5rem;
  z-index: 600;
  background-color: white;

  &__link {
    color: $primary;
    font-size: inherit;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
    @include font-family;
    &:hover {
      color: $dark-blue;
    }
  }
}
