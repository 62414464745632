.SideMenu {
  display: flex;
  flex-direction: column;
  width: $side-menu-size;
  padding: 0.25rem;
  box-sizing: border-box;
  box-shadow: inset -1px 0px 0px $lightest-grey;
  position: absolute;
  background-color: $white;
  z-index: 550;
  position: fixed;
  height: 100%;
  transition: width 200ms ease-in-out;

  &--open {
    width: 20rem;
    z-index: 500;
  }

  &__menu-item {
    padding: 0.75rem;
    position: relative;
    display: flex;
    max-height: 1.5rem;
    cursor: pointer;
    @include blank-link;
    &:hover {
      background-color: $light-grey;
      transition: 0.3s;
      border-radius: 32px;
    }
    &__icon {
      min-width: 16px;
      min-height: 16px;
      width: fit-content;
      height: fit-content;
      border-radius: 12px;
      background-color: #43b02a;
      justify-content: center;
      text-align: center;
      display: flex;
      flex-direction: column;
      margin-left: 0.4rem;
      margin-top: 3px;
      position: absolute;
      left: 1.4rem;
      top: 0rem;

      &-content {
        color: white;
        font-weight: 500;
        font-size: 9px;
        padding: 2px 2px 2px 2px;
      }
    }

    // &__tooltip{
    //   position: absolute;
    //   z-index: 500;
    // }

    &__menu__item {
      padding: 0.75rem;
      position: relative;
      display: flex;
      max-height: 1.5rem;
      cursor: pointer;
      @include blank-link;

      &__icon {
        min-width: 16px;
        min-height: 16px;
        width: fit-content;
        height: fit-content;
        border-radius: 12px;
        background-color: #43b02a;
        justify-content: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        margin-left: 1.8rem;
        margin-top: -2.9rem;
        position: absolute;

        &-content {
          color: white;
          font-weight: 500;
          font-size: 9px;
          padding: 2px 2px 2px 2px;
        }
      }
    }

    .tooltip {
      left: 75%;
      top: 25%;
      display: none;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }

    &--active {
      border: 1px solid $light-blue;
      background-color: $lighter-blue;
      padding: 0.6875rem;
    }

    &--button {
      @include invisible-button;
      padding: 0.75rem;
      width: $side-menu-size;
      max-height: $side-menu-size;
    }
  }

  &__menu-label {
    margin-left: 1rem;
    visibility: hidden;
    opacity: 0;
    &--show {
      transition: opacity 300ms ease-in;
      visibility: visible;
      opacity: 1;
    }
  }
}
