.button {
  border: none;
  font-size: 0.875rem;
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  line-height: 1.5rem;
  gap: 0.5rem;
  white-space: nowrap;

  &:hover {
    background-color: $light-grey;
    transition: 0.7;
    border-radius: 4px;
  }
  &.button-critical {
    @include critical-button;
  }

  &.button-critical {
    @include critical-button;
  }

  &.button-critical-small {
    @include critical-button-small;
  }

  &.button-primary {
    @include primary-button;

    path {
      fill: $white;
    }

    &.disabled {
      @include primary-button-disabled;
    }

    &.button-primary:hover {
      transition: 0.7s;
    }
  }

  &.button-primary-small {
    @include primary-button-small;

    path {
      fill: $white;
    }

    &.disabled {
      @include primary-button-small-disabled;
    }

    &.button-primary-small:hover {
      transition: 0.7s;
    }
  }

  &.button-secondary {
    @include secondary-button;

    &.disabled {
      @include secondary-button-disabled;

      path {
        fill: $grey;
      }
    }

    .button-secondary:hover {
      transition: 0.7s;
    }
  }

  &.button-secondary-small {
    @include secondary-button-small;

    &.disabled {
      @include secondary-button-disabled;

      path {
        fill: $grey;
      }
    }

    .button-secondary:hover {
      transition: 0.7s;
    }
  }

  &.text-button {
    @include text-button;

    &.disabled {
      @include text-button-disabled;
    }
  }

  &.icon-text-button {
    @include icon-text-button;

    &.disabled {
      @include text-button-disabled;
      &:hover {
        background-color: transparent !important;
        color: $dark-grey !important;
        path {
          fill: $dark-grey !important;
        }
      }
    }

    &.icon-text-button:hover {
      background-color: $light-grey;
      transition: 0.7s;
      color: #005478;
      path {
        fill: #005478;
      }
    }
  }

  &.button-no-border {
    outline: none !important;
  }

  &.button-parent-font {
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
  }
}

.button {
  @include invisible-button;
}

.primary {
  @include primary-button;
}

.primary-outline {
  @include primary-button-outline;
  &:hover {
    background-color: $light-grey;
    transition: 0.7s;
    border-radius: 4px;
  }
}

.primary-outline-disabled {
  @include primary-button-outline-disabled;
}

.primary-disabled {
  @include primary-button-disabled;
}
