.verification{
    &__container{
        width: 100%;

    }
    &__metric-values{
        .tooltip {
            display: none;
            z-index: 1;
            top:3rem;
            left:9rem;
          }
      
          &:hover {
            .tooltip {
              display: block;
            }
          }
    }
    &__chart-container{
        margin: 1rem;
        &-metric{
            font-weight: 400;
            font-size: 14px;
            color: #757575;

            &-wrapper{
                width: fit-content;
                display: inline-block;
                padding-bottom: 0.3rem;
            }
        }
        &-metric-value{
            font-weight: 400;
            font-size: 14px;
            color: #212121;
        }
    }

    &__table{
        width:100%;
        border-collapse: collapse;
        table, tr, td, thead{
            padding: 6px;
            margin: auto;
            align-items: center;
            }
            thead{
                background: linear-gradient(180.75deg, #EEF5F8 0.64%, rgba(238, 245, 248, 0) 135.65%);
                border-radius: 4px;
                font-size: 12px;
                font-weight: 600; 
            }

            &-metric{
                font-size: 12px;
                color: #757575;
                font-weight: 400;
                text-align: left;
                &-td{
                        min-width: 3rem;
                }
            }
            &-data{
                font-size: 12px;
                color: #212121;
                font-weight: 400;
            }

            &-detailViewMetric{
                font-weight: 600;
                color: #212121;
            }
            &-detailViewHeader{
                font-weight: 600;
                color: #012169;
                font-size: 0.75rem;
                text-align: left;
                &-btn{
                    line-height: unset;
                }
            }
    }

}

.row{
    background-color: aqua;
    display: flex;
    justify-content: space-evenly;
   }