.saved-shortlist-table {
  &__container {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 4px 8px rgba(238, 240, 241, 0.48);
  border-radius: 8px;
  align-items: baseline;
  overflow-y: hidden;
  font-weight: 400;
  border: 1px solid #ebebeb;
  width: 100%;
  // position: absolute;
  thead {
    width: 100%;
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    background: white;
  }

  thead th:nth-child(1),
  tbody td:nth-child(1) {
    width: calc(((100% - 16rem) / 9) * 2);
  }

  thead th,
  tbody td {
    width: calc((100% - 16rem) / 9);
  }

  thead th:nth-child(8),
  tbody td:nth-child(8) {
    text-align: right;
    justify-content: end;
  }

  thead th:nth-child(8) > div {
    padding-right: 0.9375rem;
  }

  tbody {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-grow: 1;
    height: 46vh;
    
    @media screen and (min-width: 1400px){
      height: 64vh;
    }

    @media screen and (min-width: 1600px){
      height: 70vh;
    }

    @media screen and (width: 1272px){
      height: 47.9vh;
    }
  }
  tr {
    box-shadow: inset 0px -1px 0px #ebebeb;
    display: flex;
    position: relative;

    a {
      text-decoration: none;
      color: black;
      position: relative;
      width: 100%;
      display: flex;
    }
  }

  &__shared-by,
  &__shared-with {
    cursor: pointer;
    & > span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      max-width: 100%;
    }
  }

  &__shared-with {
    cursor: pointer;
    display: flex;
  }

  &__shared-with-main {
    position: relative;

    .tooltip {
      display: none;
      line-height: 1rem;
      left: 5rem;

      .tooltip__arrow {
        top: -1px;
      }
      .tooltip__label {
        overflow: auto;
        min-width: 6rem;
        max-width: 50rem;
        max-height: 10rem;
        transform: translateY(-80%);
        position: absolute;
      }
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__shared-with-count {
    overflow: unset !important;
    line-height: 1.5rem;
    position: relative;
    padding-right: 0.2rem;

    &-tooltip {
      line-height: 1rem;
      max-width: 10rem;
      max-height: 6rem;
      overflow: auto;
    }

    svg:not(:root) {
      transform: translateX(8px) translateY(4px);
    }

    &:hover {
      background: #e0e0e0;
      border-radius: 15px;
      height: fit-content;
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__name {
    cursor: pointer;

    & > span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      max-width: 100%;
    }
  }

  &__items {
    color: #007cb0;
    position: relative;
    display: flex;
    justify-content: flex-start;
    padding-right: 10px;
  }

  &__doc-icon {
    width: 2rem;

    &--tooltip {
      pointer-events: auto;
      .tooltip__label {
        overflow: auto;
        min-width: 15rem;
        max-width: 15rem;
        max-height: 7rem;
        //transform: translateY(-80%);
      }
    }

    svg:not(:root) {
      transform: translateX(8px) translateY(4px);
    }

    &:hover {
      background: #e0e0e0;
      cursor: pointer;
      height: 25px;
      border-radius: 15px;

      .tooltip {
        display: inline-block;
      }
    }
  }

  &__share,
  &__edit,
  &__delete {
    .tooltip {
      display: none;
      top: -25px;
      left: -13%;
    }

    .tooltip__arrow {
      left: 43%;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
      background-color: $light-grey;
      border-radius: 32px;
    }
  }

  &__edit {
    .tooltip {
      left: 18%;
    }
  }

  &__delete {
    .tooltip {
      left: 40%;
    }
  }

  &__items-text {
    color: #007cb0;
    margin-left: 0.2rem;
  }

  &__header {
    font-size: 16px;
    line-height: 24px;

    display: flex;
    word-wrap: break-word;
    text-align: center;
    order: 0;
    position: static;
    padding: 16px 0 0 16px;
  }

  &__heading-text {
    display: flex;
  }

  &__heading-text th {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #212121;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding: 0.5rem 1rem;
    vertical-align: middle;
    display: flex;
  }

  &__icons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    span {
      padding-right: 0.5rem;
    }
  }

  &__rows {
    display: flex;
  }

  &__newItem {
    font-weight: 600;
  }

  td {
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    letter-spacing: 0;
    word-wrap: break-word;
    padding: 0.5rem 1rem;
    position: relative;
  }

  td > span,
  &__items-text {
    line-height: 24px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    z-index: 1;
  }

  th:nth-child(3),
  td:nth-child(3) {
    z-index: 1;
  }

  th:nth-child(4),
  td:nth-child(4) {
    :hover {
      z-index: 4;
    }
  }
  th:nth-child(5),
  td:nth-child(5) {
    :hover {
      z-index: 4;
    }
  }
  th:nth-child(6),
  td:nth-child(6) {
    :hover {
      z-index: 4;
    }
  }
  th:nth-child(7),
  td:nth-child(7) {
    :hover {
      z-index: 4;
    }
  }
  &__outside-td {
    margin-top: 0;
    text-align: -webkit-right;
  }

  &__header-inner-content {
    @include font-family;
  }

  &__header-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: left;
    background: none;
    border: none;
    padding: 0;
    &:hover {
      background-color: $light-grey;
      border-radius: 4px;
    }
    &--sorted {
      padding-right: 0;
    }
  }

  &__sort-icon {
    padding-right: 0.5rem;
  }

  &__header-content {
    text-align: left;
  }

  &__header-text {
    font-weight: 600;
    font-size: 0.75rem;
  }

  &__link {
    cursor: pointer;
    &:hover {
      background-color: $lightest-grey;
    }
  }

  &__hover-tooltip {
    height: 100%;
  }
}
