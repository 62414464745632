.saved-groupings {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__no-data {
    padding: 1rem;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 600;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__grouping {
    display: flex;
    gap: 0.8rem;
    background-color: $lighter-grey;
    border-radius: 0.5rem;
    padding: 1rem 2.5rem 1rem 1.5rem;
    align-items: baseline;
    position: relative;

    &__name {
      width: 25%;
      overflow-wrap: break-word;
    }

    &__remove {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
}
