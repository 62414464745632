.tags-panel {
  &__container {
    display: flex;
    flex-direction: column;
    padding-bottom: 2.5rem;
  }

  &__wrapper {
    margin-top: 8.05rem;

    .SidePanel__header div {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    }

    &--max-margin {
      margin-top: 12rem;
      height: calc(100% - 12rem);
    }

    &--min-margin {
      margin-top: 4.5rem;
      height: calc(100% - 4.5rem);
      //padding-bottom: 0.5rem;
    }
  }
}
