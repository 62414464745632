.embed-container {
  width: 96%;
  background: #eaeaea;
  color: #6e6e6e;
  height: 71%;
  min-width: 800px;
  text-align: center;
}
.active-maintenance-height {
  &-1 {
    height: 62%;
  }
  &-2 {
    height: 62%;
  }
  &-3 {
    height: 42%;
  }
}
.powerbi-dashboard {
  width: 100%;
  height: 100%;
  margin-top: 3rem;

  &__header {
    display: flex;
    justify-content: flex-end;
    padding: 0.6rem;
    width: 95.5%;
  }

  &__export-all {
    position: relative;

    &-button {
      @include primary-button;
      border: 1px solid $primary;
      display: flex;
      gap: 0.5rem;
      align-items: center;

      &--disabled {
        @include primary-button-disabled;
        display: flex;
        gap: 0.5rem;
        color: $dark-grey;
        align-items: center;
      }

      &--active {
        path {
          transform: rotate(180deg);
          transform-origin: 50% 50%;
        }
      }
    }

    &-button:hover {
      transition: 0.6s;
    }
    &-options {
      display: none;
      position: absolute;
      top: 2.5rem;
      background-color: $white;
      z-index: 3;
      box-shadow: 0.1rem 0.1rem 0.2rem $light-grey;
      border: 1px solid $lightest-grey;

      &--show {
        display: block;
      }
    }

    &-option {
      @include invisible-button;
      padding: 0.5rem;
      display: flex;
      gap: 0.5rem;
      font-size: 0.875rem;

      &:hover {
        background-color: $lightest-blue;
        border-color: $primary;
      }

      &--text {
        padding: 0.2rem 0.5rem 0.2rem 0.5rem;
        white-space: nowrap;
      }
    }
  }
}

iframe {
  border: none;
}
