.horizontalGraduatedChart {
  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__legend {
    &-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: flex-start;
      row-gap: 0.75rem;
      margin-bottom: 1.5rem;
    }
    &-text {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: $dark-grey;
    }
    &-companies {
      display: flex;
      column-gap: 0.8rem;
    }
    &-company {
      &-block {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: $dark-grey;
        column-gap: 0.5rem;
      }
      &-pill {
        width: 20px;
        height: 6px;
        border-radius: 4px;
        // background-color: #007cb0;
      }
    }
  }
  &__wrapper {
    width: calc(100% - 2rem);
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    justify-content: center;
  }

  &__bar {
    height: 3.5rem;
    width: 100%;
    top: 0;
    position: absolute;
    &-section {
      width: 100%;
      display: flex;
      height: 3.5rem;
      background-color: $lightest-grey;
      position: relative;
      margin-bottom: 0.25rem;
    }
  }

  &__circle {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #007cb0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: absolute;
    z-index: 50;
    &--bottom {
      top: 0.75rem;
    }
  }

  &__ruler {
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: calc((100% - 11px) / 10);
    margin-bottom: 0.25rem;
    &-section {
      width: 100%;
      display: flex;
      position: relative;
      flex-direction: column;
      &--top {
        padding-bottom: 1rem;
        overflow: visible;
      }
    }

    &-measure {
      width: 1px;
      background-color: #bbbcbc;
      height: 0.5rem;
      &:nth-child(1),
      &:nth-child(6),
      &:nth-child(11) {
        height: 1rem;
      }
    }
  }

  &__arrow-down {
    width: 0;
    height: 0;
    border-left: 9.6px solid transparent;
    border-right: 9.6px solid transparent;
    border-top: 14.4px solid #007cb0;
    position: absolute;
    z-index: 5;
    top: -16px;
    left: -9px;

    &-wrapper {
      position: absolute;
      top: 2.5rem;
      z-index: 5;
    }
    &-container {
      position: relative;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-top: 16px solid white;
    }
  }

  &__arrow-up {
    width: 0;
    height: 0;
    border-left: 9.6px solid transparent;
    border-right: 9.6px solid transparent;
    position: absolute;
    top: 0.05rem;
    left: -9px;

    &-wrapper {
      position: absolute;
      z-index: 5;
      top: -0.5rem;
    }
    &-container {
      position: relative;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 16px solid white;
    }
  }

  &__numeric {
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }

  &__number {
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: $dark-grey;

    &-section {
      width: calc(100% + 6.5px);
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-left: -0.375px;
      height: 2rem;
      &--top {
        width: 100%;
      }
    }
  }

  &__no-data-section {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: calc(100% - 2rem);
    margin-top: 2rem;
    align-self: flex-start;
  }
}

.productSafetyAndQualityGradient,
.rawMaterialAndSourcingScore,
.businessEthicsAndFraudScore,
.supplyChainLaborScore,
.workforceRelatedScore {
  &__container {
    width: 100%;
    height: 100%;
  }
  &__detailedcontainer {
    display: flex;
    flex-direction: column;
    width: inherit;
    height: inherit;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  &__legend-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: $dark-grey;
  }
  &__table-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    height: 100%;
    width: 100%;
    &--company-peer {
      & .tableChart__table-body tr:nth-child(1) {
        td {
          border-top: 1px dashed $light-grey;
        }
      }
    }
  }

  &__no-data-section {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: calc(100% - 2rem);
    margin-top: 2rem;
    align-self: flex-start;
  }
}

.rawMaterialAndSourcingScore {
  &__detailedView {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: inherit;
    .chart {
      &__range-score {
        @include tile-score-range;
      }

      &__legend {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        display: flex;
        grid-gap: 1rem;
        gap: 0.5rem;
        flex-wrap: wrap;
      }

      &__legend-value {
        font-size: 0.75rem;
        color: #757575;
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }

      &__legend-indicator {
        width: 1.5rem;
        height: 0.5rem;
        border-radius: 0.25rem;

        &--detailed {
          width: 0.5rem;
          aspect-ratio: 1;
        }
      }
    }
  }
}

.supplyChainLaborScore {
  &__detailedView {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: inherit;

    .chart {
      &__range-score {
        @include tile-score-range;
      }

      &__legend {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        display: flex;
        grid-gap: 1rem;
        gap: 0.5rem;
        flex-wrap: wrap;
      }

      &__legend-value {
        font-size: 0.75rem;
        color: #757575;
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }

      &__legend-indicator {
        width: 1.5rem;
        height: 0.5rem;
        border-radius: 0.25rem;

        &--detailed {
          width: 0.5rem;
          aspect-ratio: 1;
        }
      }
    }
  }
}

.workforceRelatedScore{
  &__detailedView {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: inherit;
    .chart {
      &__range-score {
        @include tile-score-range;
      }

      &__legend {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        display: flex;
        grid-gap: 1rem;
        gap: 0.5rem;
        flex-wrap: wrap;
      }

      &__legend-value {
        font-size: 0.75rem;
        color: #757575;
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }

      &__legend-indicator {
        width: 1.5rem;
        height: 0.5rem;
        border-radius: 0.25rem;

        &--detailed {
          width: 0.5rem;
          aspect-ratio: 1;
        }
      }
    }
  }
}