.ghgIntensity {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  &__subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $neutral-black;
  }

  &__label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
  }

  &__metric {
    &-block {
      display: flex;
      flex-direction: column;
      background: linear-gradient(
        180.75deg,
        #eef5f8 0.64%,
        rgba(238, 245, 248, 0) 135.65%
      );
      border: 1px solid #e2eff4;
      border-radius: 8px;
      min-width: 19.75rem;
      & > :nth-child(1) {
        border-bottom: 1px dashed #a3cee0;
      }
      &-detailed-view {
        width: 23.4%;
        min-width: unset;
      }
    }
    &-block-esrs {
      display: flex;
      flex-direction: column;
      background: linear-gradient(
        180.75deg,
        #eef5f8 0.64%,
        rgba(238, 245, 248, 0) 135.65%
      );
      border: 1px solid #e2eff4;
      border-radius: 8px;
      min-width: auto !important;
      & > :nth-child(1) {
        border-bottom: 1px dashed #a3cee0;
      }
      &-detailed-view {
        width: 23.4%;
        min-width: unset;
      }
    }
    &-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 1.25rem 1rem;
      align-items: center;
      column-gap: 2rem;
      &--half-horizontal-padding {
        padding: 1rem 0.5rem;
      }
      &--no-horizontal-padding {
        padding: 1rem 0;
      }
    }
    &-label {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      text-align: left;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.87);
    }
    &-value {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #012169;
      &--black {
        color: rgba(0, 0, 0, 0.87);
      }
    }
    &-unit {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #212121;
      padding: 1.25rem 1rem;
      word-break: break-word;
      &--bold {
        font-weight: 600;
      }
      &--half-horizontal-padding {
        padding: 1rem 0.5rem;
      }
      &--no-horizontal-padding {
        padding: 1rem 0;
      }
      &--static-height {
        height: 2rem;
        display: flex;
        align-items: center;
        padding: 1rem 1rem;
      }

      &--wrapper {
        width: fit-content;
      }
    }
    &-no-data {
      color: $dark-grey;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }
  }

  &__detailed-view {
    &-container {
      display: flex;
      flex-direction: column;
    }
    &-subtitle {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: $neutral-black;
      margin-bottom: 1rem;
      width: fit-content;
    }
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      column-gap: 1rem;
      row-gap: 1rem;
    }
  }

  &__no-data-available {
    font-size: 12px;
  }

  &__table-view {
    display: flex;
    flex-direction: column;

    &-subtitle {
      background: rgba(0, 0, 0, 0.02);
      border-radius: 4px;
      padding: 0.5rem;
      margin-bottom: unset;
    }
    &-subtitle-2 {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #212121;
      margin-bottom: 0.5rem;
    }
    &-metric-label {
      font-weight: 400;
      &--bold {
        font-weight: 600;
      }
    }
  }
  &__table {
    &-block {
      display: flex;
      flex-direction: column;
      min-width: 19.75rem;
      & > div {
        border-bottom: 1px dashed #d0d0ce;
      }
      &--top-border {
        & > :nth-child(1) {
          border-top: 1px dashed #d0d0ce;
          border-bottom: 1px dashed #d0d0ce;
        }
      }
    }
    &-block-esrs {
      display: flex;
      flex-direction: column;
      min-width: auto !important;
      & > div {
        border-bottom: 1px dashed #d0d0ce;
      }
      &--top-border {
        & > :nth-child(1) {
          border-top: 1px dashed #d0d0ce;
          border-bottom: 1px dashed #d0d0ce;
        }
      }
    }
    &-group {
      display: flex;
      flex-direction: column;
    }
  }

  &__collapsedExpandedButton {
    text-decoration: none;
    background-color: #f1f8fb;
    padding: 0;
    border-radius: 0.5rem;
    border: none;
    width: fit-content;
    cursor: pointer;
    transition: background-color 0.7s ease;
  }

  &__collapsedExpandedButton :hover {
    background-color: #e2eff4;
    border-radius: 0.5rem;
  }

  &__collapsedExpandedButtonContent {
    padding: 0.5rem 0.875rem 0.5rem 0.875rem;
    display: flex;
    gap: 0.2rem;
    align-items: center;
  }
}
