.savedInsightTile {
  width: 26.5rem;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    box-shadow: 10px 10px 5px 0px $light-grey;
    transition: 0.3s;
  }
  &__header {
    display: flex;
    border: 1px solid #f0f0f0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-top: 1.2rem;
    padding-right: 1.5rem;
    justify-content: space-between;
    &-left {
      display: flex;
      flex-direction: column;
    }
  }

  &__user-avtar {
    display: flex;
    justify-content: center;
    color: #f0f0f0;
    background: #000000;
    width: 1.5rem !important;
    height: 1.5rem !important;
    line-height: 1.4rem;
    border-radius: 1.5rem;
    text-align: center;
    padding: 0.2rem;
    margin-left: 0.45rem;
    font-weight: 600;
    font-size: 14px;
  }

  &__sharedBy {
    display: flex;
    flex-direction: row;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  &__icons {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0.5rem;
  }
  &__icon {
    padding-right: 0.2rem;
    &:hover {
      background: $light-grey;
      cursor: pointer;
      height: 30px;
      border-radius: 28px;
    }
  }
  &__name {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: $neutral-black;
    margin-bottom: 0.25rem;
    padding: 0 1.25rem;
    border-left: 2px solid #68bc25;
    position: relative;

    .tooltip {
      display: none;
      left: 25%;
      top: 100%;
    }
    &:hover {
      .tooltip {
        display: block;
      }
    }
  }
  &__company {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $neutral-black;
    padding: 0 0 0 1.5rem;
  }
  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $black;
    margin-bottom: 0.25rem;
    &--bold {
      font-weight: 600;
    }
  }

  &__body {
    display: flex;
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    justify-content: space-between;
    &-left {
      display: flex;
      flex-direction: column;
    }
    &-right {
      display: flex;
      flex-direction: column;
    }
  }

  &__category {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.8;
    text-align: right;
  }

  &__categoryDiv {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.3rem;
    opacity: 0.8;
    text-align: right;
    justify-content: right;
    padding-top: 0.2rem;
  }

  &__remainingDays {
    margin-left: 0.2rem;
    padding: 0.2rem;
    &--red {
      color: red;
    }
  }

  &__marketCap {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #26890d;
  }

  &__footer {
    display: flex;
    padding: 0 1.5rem 1.5rem;
    column-gap: 1rem;
    justify-content: space-between;
    margin-block-start: auto;
    &-text {
      display: flex;
      column-gap: 0.25rem;
      &--delete {
        @include invisible-button;
        width: 3.5rem;
        margin-left: 8rem;
        color: red;
        font-family: "Open Sans";
        &:hover {
          background-color: red;
          color: white;
          font-weight: 500;
          border-radius: 8px;
          transition: 0.3s;
          cursor: pointer;
        }
      }
    }
  }
}
