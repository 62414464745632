.Shortlist {
  padding: 0.4rem 3rem;
  width: 100%;
  @include font-family;

  &__document-viewer {
    display: flex;
    width: 100%;
    padding: 1.5rem 3rem;
    height: 53vh;
    width: 87vw;

    @media screen and (min-width: 1400px) {
    height: 59vh;
    width: 89vw;
    }

    @media screen and (min-width: 1600px) {
      height: 72vh;
      width: 92vw;
    }
  }

  &__top-section {
    display: flex;
    margin-bottom: 0.5rem;
    justify-content: space-between;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.25rem;
    overflow: hidden;
    line-height: 1rem;
    &-text {
      .tooltip {
        left: 15%;
        top: 1%;
        z-index: 25;
        white-space: pre-wrap;
        display: none;

        &__label {
          max-width: 23rem;
          text-align: left;
          background-color: $black;
          color: $white;
          padding: 1rem;
          word-wrap: break-word;
          border: 1px solid $light-grey;
          position: fixed;
        }

        &__arrow {
          display: none;
        }
      }

      &:hover {
        .tooltip {
          display: inline-block;
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__cta-section {
    margin-left: 1rem;
  }

  &__save-button {
    @include primary-button;
    @include font-family;
    height: 36px;
  }

  &__saved-actions {
    display: flex;
    align-items: center;
  }

  &__saved-actions-disabled {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__shortlist-note-button {
    position: relative;
    path {
      fill: $primary;
    }
    .tooltip {
      top: -1.7rem;
      left: 1.5rem;
      z-index: 25;
      display: none;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }

    svg {
      cursor: pointer;
    }
  }

  &__share-button {
    position: relative;
    gap: 1rem;
    cursor: auto;

    path {
      fill: $primary;
    }

    .tooltip {
      top: -1.3rem;
      left: -2.5rem;
      z-index: 5;
      display: none;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__results-right {
    display: flex;
    align-items: center;
  }

  &__export-button {
    position: relative;

    .tooltip {
      top: -1.7rem;
      left: -3rem;
      z-index: 25;
      display: none;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }

    svg {
      cursor: pointer;
    }
  }

  &__lists-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__empty-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 1.33rem;

    @media screen and (min-width: 1400px){
      margin-top: 4.5rem;
    }

    @media screen and (min-width: 1600px){
      margin-top: 7rem;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 39rem;
  }

  &__empty-image {
    max-width: 13.5rem;
    margin-bottom: 1.188rem;
  }

  &__empty-title {
    font-weight: 600;
  }

  &__empty-message {
    font-size: 0.875rem;
    color: $grey;
  }

  &__results-container {
    width: 100%;
    border: 1px solid $lightest-grey;
    box-shadow: 0 0.25rem 0.5rem rgba(238, 240, 241, 0.48);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;

    &--show-document-viewer {
      width: 40%;
      border-radius: 0.5rem 0rem 0.5rem 0.5rem;

      .tabbed-content__item {
        padding: 0.875rem 1rem;
      }
    }

    &--esg {
      margin-bottom: 2rem;
    }
  }

  &__results-summary {
    border-bottom: 1px solid $secondary-light-grey;
    padding: 0.25rem 1rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left {
      display: flex;
      align-content: center;
      gap: 0.5rem;
    }
  }

  &__results-count {
    line-height: 2rem;
    margin-right: 0.5rem;
  }

  &__results-export-button {
    @include invisible-button;
    @include font-family;
    color: $primary;
    display: flex;
    align-items: center;
    position: relative;
    &:hover {
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 4px;
    }
  }

  &__results-export {
    &-button {
      position: relative;
      cursor: pointer;
      color: $primary;
      padding: 0.5rem;
    }

    &-disabled {
      cursor: auto;
      color: $grey;
      path {
        fill: $grey;
      }

      .tooltip {
        top: -1.8rem;
        left: -2.5rem;
        z-index: 3;
        display: none;
      }

      &:hover {
        .tooltip {
          display: block;
        }
        background-color: transparent !important;
      }
    }
  }

  &__results-esg-filter,
  &__results-sec-filter {
    @include primary-button-outline-rounded;
    @include font-family;
    &:hover {
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 16px;
    }
    &--selected {
      @include primary-button-rounded;
      &:hover {
        background-color: $secondary-blue;
        transition: 0.7s;
        border-radius: 16px;
      }
    }
  }

  &__results-export-icon {
    margin-right: 0.4rem;
    height: 1.5rem;
    width: 1.5rem;
  }

  &__table-wrapper {
    max-height: 43vh;
    position: relative;
    overflow-y: auto;
    flex-grow: 1;

    @media screen and (min-width: 1400px){
      max-height: 53vh;
    }

    @media screen and (min-width: 1600px){
      max-height: 65vh;
    }

    @media screen and (width: 1272px){
      height: 43.035vh;
    }

    &-overflow {
      overflow-y: visible;
    }
  }

  &__table-pagination {
    height: 3rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span {
      cursor: pointer;
      padding-right: 1rem;
    }
  }

  &__total-results {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  &__column-company-name {
    width: 100%;
  }

  &__column-pages-icon {
    width: 1rem;
  }

  &__column-report-period {
    width: 6rem;
  }

  &__column-report-ratings {
    width: 9rem;
    padding-right: 0rem;
    :hover {
      text-decoration: underline;
    }
    &__disable {
      width: 9rem;
      padding-right: 0rem;
      text-decoration: none;
    }
    .tooltip {
      left: -4.5rem;
    }
  }

  &__column-report-governance {
    width: 5rem;
    padding-right: 0rem;
    :hover {
      text-decoration: underline;
    }
    &__disable {
      width: 5rem;
      padding-right: 0rem;
      text-decoration: none;
    }

    .tooltip {
      left: -4.1rem;
    }
  }
  &__column-document-type {
    width: 2rem;
  }

  &__column-checkbox {
    input {
      border-radius: 2px;
      border: 1px solid $light-grey;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }
  }

  &__column-icon {
    width: 1.5rem;

    &-download {
      padding-right: 0rem;
      &:hover {
        background-color: $light-grey;
        border-radius: 4px;
      }
      .tooltip {
        left: -4.5rem;
      }
    }

    &-remove {
      .tooltip {
        left: -9rem;
      }
      &:hover {
        background-color: $light-grey;
        border-radius: 4px;
      }
    }
  }
  &__compare-button {
    position: relative;
    @include invisible-button;
    color: #007cb0;
    padding-right: 1rem;
    font-size: 14px;
    line-height: 24px;
    &--disabled {
      color: #75787b;
      path {
        fill: #75787b;
      }
      &:hover {
        background-color: white !important;
        cursor: pointer;
      }
    }
    .tooltip {
      left: -95%;
      top: -225%;
      z-index: 2;
      display: none;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 4px;
    }
  }

  &__period-years-cell {
    display: flex;
    align-items: center;
    cursor: pointer;
    &-text {
      position: relative !important;
      .tooltip {
        display: none !important;
        top: -1.3rem;
        left: 0;

        &__arrow {
          bottom: -1.8rem;
          left: 1rem;
        }
        &__label {
          bottom: -1.5rem;
          position: absolute;
          max-width: 6rem;
          left: 1rem;
        }
      }
      &:hover {
        .tooltip {
          display: inline-block !important;
        }
      }
    }
    &-btn {
      @include font-family;
      background-color: transparent;
      border: none;
      height: fit-content;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        background: #e0e0e0;
        border-radius: 15px;
      }
    }
  }
}
