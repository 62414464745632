.TimeChart {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &__chartContainer {
      text-align: center;
      justify-content: center;

      &--wide{
        width: inherit;
        justify-content: space-between;
      }
    }
    &__metrics {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #212121;
      margin: 0.5rem;
    }
    &__metricLeft {
      margin-left: 1.4rem;
      &-container{
        display: inline-block;
      }
    }
    &__metric-button{
      line-height: unset;
    }
    &__metric_row {
      display: flex;
      flex-direction: column;
      width: 10rem;
      padding: 0.4rem;

       &--company{
        width: unset;
       }
    }
    &__metricTextRowTable {
      width: 33.33%;
      padding: 0.2rem;
    }
    &__metric_column {
      display: flex;
      flex-direction: row;
    }
    &__metricsValues {
      display: flex;
      flex-direction: row;
      font-weight: 600;
      font-size: 2rem;
      min-width: 40px;
      gap: 1.6rem;
      padding-bottom: 0.2rem;
    }
    &__NoDataField {
      justify-content: center;
    }
    &__metricsTableValues {
      display: flex;
      flex-direction: row;
      font-weight: 600;
      font-size: 25px;
      gap: 0rem;
      justify-content: center;
    }
    &__circularIcon {
      padding-top: 0.2rem;
    }
  
    &__metricUnit {
      color: #757575;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #757575;
      margin: 0.2rem;
    }
    &__metricTextRow {
      padding: 0.2rem;
      width: 13rem;
    }
    &__fieldValues {
      text-align: left;
    }
    &__fieldPeerValues {
      font-weight: 600;
      font-size: 1rem;
    }
  
    &__fieldTextValues {
      color: #757575;
      font-weight: 400;
      font-size: 14px;
      margin: 0.2rem;
      text-align: center;
    }
    &__noData {
      text-align: center;
      font-weight: 600;
      font-size: 1.4rem;
      margin-left: 1%;
    }
    &__fieldTextValuesRight {
      color: #757575;
      font-weight: 400;
      font-size: 0.75rem;
      margin: 0.2rem;
      text-align: left;
    }
    &__chartForPeer {
      width: 100%;
      font-size: 12px;
    }
  
    &__PeerContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      gap: 0.2rem;
    }
  
    &__metricView {
      border-radius: 4px;
      text-align: center;
      justify-content: center;
      padding: 0.5rem;
      margin: 0.5rem;
      max-width: 32%;
      min-width: 29%;
    }
    &__metricTitle {
      background: linear-gradient(
        180.75deg,
        #eef5f8 0.64%,
        rgba(238, 245, 248, 0) 135.65%
      );
      text-align: left;
      padding-bottom: 0.5rem;
      padding-top: 0.4rem;
    }
    &__metricsForPeers {
      display: flex;
      padding: 0.5rem;
      justify-content: space-between;
      &-fieldValue {
        margin-right: 2.5rem;
      }
    }
    &__metricName {
      text-align: left;
      width: 7rem;
      margin-left: 0.3rem;
    }
    &__table_PeerIndustry {
      width: 100%;
    }
    &__tableCompanyName {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: left;
    }
    &__tableFieldValue {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #212121;
      &-industry {
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 16px;
        text-align: left;
        color: #212121;
      }
    }
    &__label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #212121;
    }
    td {
      border-bottom: 1px dashed #d0d0ce;
      padding: 0.75rem 0.1rem;
    }
  }