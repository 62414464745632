.Table {
  width: 100%;

  &__table {
    width: 100%;
    border-spacing: 0;
  }

  &__header {
    background: $white;
    box-shadow: inset 0px -1px 0px $secondary-light-grey;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  &__header-content {
    text-align: left;

    .filter {
      line-height: 1.5rem;
      visibility: hidden;
      cursor: pointer;
      padding: 0.3rem;
      &:hover {
        background-color: $light-grey;
        border-radius: 4px;
      }
      &__active {
        visibility: visible;
      }
    }

    &:hover {
      .filter {
        visibility: visible;
      }
    }

    .filter-modal {
      position: absolute;
      top: -1.2rem;
      z-index: 1;

      &--right {
        left: 2rem;
      }

      &--left {
        left: -15rem;
      }
    }
  }

  &__header-text {
    font-weight: 600;
    font-size: 0.75rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    position: relative;
  }

  &__column {
    padding: 0.75rem 0.5rem;
  }

  &__text-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 1px;
    flex-grow: 1;
  }

  &__column-content {
    display: flex;
    flex-direction: row;
    position: relative;

    .tooltip {
      left: 100%;
      top: 0;
      z-index: 2;
      display: none;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__icon {
    width: 1.5rem;
  }

  &__content-icon {
    padding-right: 0.75rem;
  }

  &__clickable {
    cursor: pointer;
  }

  &__row {
    box-shadow: inset 0px -1px 0px $secondary-light-grey;

    &-link {
      cursor: pointer;
    }

    &:hover {
      background-color: $lightest-grey !important;
    }
  }

  &__row--selected {
    background-color: $lightest-blue;
    //box-shadow: inset 3px 0px 0px $primary;
  }

  &__column-text {
    font-size: 0.875rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
    .tooltip {
      left: -4rem;
      top: -200%;
    }
  }

  &__column-subtitle {
    font-weight: 400;
    font-size: 0.75rem;
    color: $grey;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__text-icon {
    align-items: center;
  }

  &__spinner-container {
    display: flex;
    justify-content: center;
  }

  &__spinner {
    width: 2rem;
    padding: 1rem 0;
  }

  &__header-inner-content {
    padding: 0.75rem 0.5rem;
    display: flex;
    @include font-family;
  }

  &__header-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: left;
    background: none;
    border: none;
    padding-right: 2.25rem;
    &:hover {
      background-color: $lightest-grey;
      border-radius: 4px;
    }
    &--sorted {
      padding-right: 0.75rem;
    }
  }

  &__sort-icon {
    padding-right: 0.5rem;
  }
  &__keywords {
    width: 0.4rem;
    padding-right: 0;
  }

  &__checkbox-container {
    width: 1.3rem;
    padding-right: 0;
    position: relative;
    margin: 0 auto;

    > input {
      width: 1rem;
      height: 1rem;
    }
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 0;
  }

  &__shortlist-note-button {
    @include primary-button-outline;
    padding: 0;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    border: none;
    background-color: transparent;
  }
}
