.document-controls {
  position: absolute;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.75rem 0.5rem;
  z-index: 3;
  background: rgba($black, 0.8);
  color: $white;
  display: flex;
  align-items: center;
  width: fit-content;

  .page-controls {
    margin-right: 0.5rem;
    border-right: 1px solid;
  }

  .zoom-controls {
    display: flex;
    button {
      vertical-align: baseline !important;
    }
  }
  .zoom-controls,
  .page-controls {
    flex-grow: 1;
    flex-shrink: 0;

    button {
      cursor: pointer;
    }

    .page-count {
      color: $light-grey;
    }

    button,
    input {
      border: 0;
      background: transparent;
      color: $white;
      font-size: 1rem;
      text-align: right;
      vertical-align: bottom;

      path {
        fill: $white;
      }

      &:disabled {
        opacity: 0.2;
        border-bottom: 0 !important;
      }
    }

    input {
      background-color: $black;
      max-width: 2rem;
      margin-right: 5px;
    }
  }
}
