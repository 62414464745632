.climate-related-incentive {
  display: flex;
  gap: 1rem;
  flex-direction: column;

  &__groupName{
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    min-height: 2rem;

}

  &__wrapper {
    background-color: #f7fbfc;
    width: 100%;
    height: 100%;
    border: 1px solid #e2eff4;
    border-radius: 0.5rem;

    &--detail{
      height: unset !important;
    }
  }

  &__otherMetric {
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    &--metricValue {
      width: 7rem;
      height: 2rem;
      font-size: 0.75rem;
      font-weight: 400;
      margin-bottom: 1rem;
    }
  }
  &__header {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 0.5rem;
    padding: 1rem;

    &--peer {
      gap: 1rem;
    }

    &-name {
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: left;
    }

    &-border {
      border-top: 1px dashed lightblue;
    }
  }

  &__firstHeader {
    display: grid;
    padding: 1rem;
  }

  &__metricValue {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    margin: 1rem;
    color: #757575;

    &--yes {
      font-size: 0.77rem;
      color: #012169;
    }
  }

  &__metricValue2 {
    width: 10rem;
    height: 1.5rem;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  &__table {
    &-firstMetric {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid grey;
      padding-bottom: 0.5rem;

      &--name {
        font-size: 0.75rem;
        font-weight: 400;
      }

      &--value {
        font-size: 0.75rem;
        font-weight: 400;
        margin-right: 1rem;

        &--yes {
          font-size: 0.75rem;
          font-weight: 400;
          margin-right: 1rem;
        }
      }
    }

    &-otherMetric {
      margin-top: 1rem;

      &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;

        &--name {
          font-size: 0.75rem;
          font-weight: 600;
          width: 10rem;
          line-height: normal;
          text-align: left;
        }
      }

      &-detail {
        &--wrapper {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1rem;
          border-bottom: 1px dashed #e0e0e0;
        }

        &--value {
          font-size: 0.76rem;
          font-weight: 400;
          margin-bottom: 0.2rem;
        }
      }
    }
  }

  &__chart {
    &-otherMetric {
      margin-top: 1rem;

      &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;

        &--name {
          font-size: 0.75rem;
          font-weight: 600;
          width: 10rem;
        }
      }

      &-detail {
        margin-top: 1rem;
        padding: 0.75rem;
        &--wrapper {
          display: grid;
          grid-template-columns: 50% 50%;
          column-gap: 0.5rem;
          margin-bottom: 1rem;
        }

        &--tableWrapper {
          display: grid;
          grid-template-columns: 50% 50%;
          column-gap: 0.5rem;
          margin-bottom: 0.7rem;
          border-top: 1px dashed #e0e0e0;

          &-detail{
            border-top: unset ;
           
          }
        }

        &--value {
          font-size: 0.76rem;
          font-weight: 400;
          overflow-wrap: break-word;
        }
        &-detailView{
          margin-top:unset
        }
      }
    }
  }

  &__chart-peer {
    &--companyWrapper {
      margin: 1rem;
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      column-gap: 1rem;
      overflow-wrap: break-word;

      &--companyName {
        font-size: 0.75rem;
        font-weight: 400;
      }
    }

    &--valueWrapper {
      margin: 1rem;
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      column-gap: 1rem;
      overflow-wrap: break-word;

      &--value {
        font-size: 0.75rem;
        font-weight: 400;

        &--yes {
          font-size: 0.813rem;
          font-style: normal;
          font-weight: 600;
          color: #012169;
        }
      }
    }

    &--header {
      display: grid;
      grid-template-columns: 30% 35% 30%;
      gap: 1rem;
      padding: 0.938rem;

      &-name {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &-border {
        border-top: 1px dashed lightblue;
      }
    }

    &-body {
      padding: 1rem;
      &--value {
        display: grid;
        grid-template-columns: 30% 70%;
        grid-column-gap: 1rem;
        column-gap: 1rem;
        margin-bottom: 2rem;

        &-detail {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          gap: 1rem;
        }
      }

      &--companyName {
        font-size: 0.75rem;
        font-weight: 400;
        overflow-wrap: break-word;
      }

      &--valueDetail {
        display: grid;
        grid-template-columns: 45% 44%;
        column-gap: 3rem;
        margin-bottom: 1rem;

        &--value {
          font-size: 0.75rem;
          font-weight: 400;
          overflow-wrap: break-word;
        }
      }
    }
  }

  &__peer {
    display: grid;
    grid-template-columns: 30% 70%;
    column-gap: 0.5rem;
    padding: 0.625rem;
  }

  &__tablePeer {
    width: 100%;
    border-bottom: 1px solid #212121;
    &-header {
      tr th {
        padding: 0.75rem 0.25rem;
        overflow-wrap: break-word;
        inline-size: 5rem;
      }
    }
    th {
      font-weight: 400;
      font-size: 0.75rem;
      text-align: left;
    }
    tbody {
      margin-top: 1rem;
    }

    &-data {
      font-size: 0.875;
      font-weight: 400;

      &-first {
        font-size: 0.75rem;
        font-weight: 400;
      }
    }

    &-container {
      display: grid;
      grid-template-columns: 24% 24% 24% 24%;
      grid-template-rows: min-content;
      grid-gap: 0.75rem;
      margin-top: 1rem;

      &--header {
        background-color: #f7f7f7;
        padding: 0.3rem;
        font-size: 0.875rem;
        font-weight: 600;
        overflow-wrap: break-word;
      }

      &--metricHeader {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-column-gap: 0.5rem;
        column-gap: 0.5rem;
        padding: 0.3rem;

        &-detail{
          border-bottom: 1px dashed lightblue;
          padding:0.5rem;
        }
      }
    }
    &--detail{
      padding:0.5rem;
      border-bottom: none !important
    }
  }

  &__metricValueDetail{
    font-size: 0.75rem;
    font-weight: 400;

    &--yes{
      color: #012169;
      font-weight: 600;
    }
  }

  &__tableIndustry {
    width: 100%;
    border-bottom: 1px solid #212121;
    &-header {
      tr th {
        padding: 0.75rem 0.25rem;
        overflow-wrap: break-word;
        inline-size: 5rem;
      }
    }
    th {
      font-weight: 400;
      font-size: 0.75rem;
      text-align: left;
    }
    tbody {
      margin-top: 1rem;
    }

    &-data {
      font-size: 0.875;
      font-weight: 600;

      &-first {
        font-size: 0.75rem;
        font-weight: 400;
      }
    }

    &-tableWrapper {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-column-gap: 0.5rem;
      column-gap: 0.5rem;
      margin-bottom: 0.7rem;
      border-bottom: 1px dashed #e0e0e0;
      padding-bottom: 0.5rem;
    }

    &-container {
      display: grid;
      grid-template-rows: min-content;
      grid-gap: 0.75rem;
      margin-top: 1rem;

      &--header {
        padding: 0.3rem;
        font-size: 0.875rem;
        font-weight: 600;
        overflow-wrap: break-word;
      }

      &--metricHeader {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-column-gap: 0.5rem;
        column-gap: 0.5rem;
        padding: 0.3rem;
      }

      &--metricHeaderName {
        font-size: 0.75rem;
        font-weight: 600;
        line-height: normal;
        text-align: left;
      }
    }
  }
}

.noData {
  width: 100%;
  text-align: left;
  font-size: 0.785rem;
}
