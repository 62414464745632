.upload-files {
  &__container {
    width: 38rem;
    position: relative;
  }
  &__dropzone {
    width: 37rem;
    height: 13rem;
    border: 1px dashed $primary;
    background-color: $lightest-blue;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 3rem;
    &--white {
      background-color: white;
    }
    &--disabled {
      border: 1px dashed #75787b;
      background-color: #d0d0ce;
    }
    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: $lightest-blue;
      width: 100%;
      height: 17rem;

      &--white {
        background-color: $white;
        justify-content: flex-start;
        overflow-y: auto;
        height: 18rem;
        overflow-x: hidden;
      }
      &--disabled {
        background-color: #d0d0ce;
      }
    }
  }

  &__icon-container {
    margin-bottom: 1rem;
    &--disabled {
      & svg path {
        fill: #75787b;
      }
    }
  }
  &__subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0.25rem;
  }
  &__formats {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $cool-gray;
    margin-bottom: 1.375rem;
  }
  &__btn-select {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    font-family: "Open Sans";
    line-height: 24px;
    color: $primary;
    background-color: transparent;
    border: none;
    &:hover {
      background-color: $lightest-grey;
      color: $dark-blue;
    }
    &--disabled {
      color: #75787b;
      &:hover {
        background-color: #d0d0ce;
        color: #75787b;
      }
    }
  }
  &__options {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-top: 3.25rem;
  }
  &__btn--left {
    margin-right: 1rem;
  }
  &__file {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-left: 5px;
      padding-right: 6px;

      margin-bottom: 0.5rem;
    }
    &-data {
      display: flex;
      align-items: center;
    }
    &-name {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      &-wrapper {
        position: relative;

        cursor: pointer;

        .tooltip {
          display: none;
          position: absolute;
          left: 10%;
          white-space: pre-wrap;
          word-wrap: break-word;
          z-index: 100;
          line-height: 1rem;

          .tooltip__arrow {
            bottom: -0.8rem;
          }

          .tooltip__label {
            overflow: auto;
            overflow-x: hidden;
            width: fit-content;
            max-width: 30rem;
            max-height: 10rem;
            position: absolute;
            top: -0.8rem;
          }
        }
        &:hover {
          height: fit-content;
          .tooltip--top {
            display: inline-block;
            z-index: 100;
          }
        }

        &--bottom {
          position: relative;

          cursor: pointer;

          .tooltip {
            display: none;
            position: absolute;
            left: 10%;
            white-space: pre-wrap;
            word-wrap: break-word;
            z-index: 100;
            line-height: 1rem;

            .tooltip__arrow {
              top: 1rem;
              margin-left: 0;
            }

            .tooltip__label {
              overflow: auto;
              overflow-x: hidden;
              width: fit-content;
              max-width: 30rem;
              max-height: 10rem;
              position: absolute;
              top: 1rem;
            }
          }
          &:hover {
            height: fit-content;
            .tooltip--bottom {
              display: inline-block;
              z-index: 100;
            }
          }
        }
      }
    }
    &-type-icon {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-status-icon {
      & svg path {
        fill: $green-secondary;
      }
      &--failed {
        & svg path {
          fill: hsl(352, 79%, 48%);
        }
      }
    }

    &-errors-messages-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &-error-message {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 21px;
      color: hsl(352, 79%, 48%);
      margin-bottom: 1px;
    }
  }
  &__progress-bar {
    width: 100%;
    height: 1px;
    background: $green-secondary;
    transition: background-color, width 0.5s ease-in-out;
    &--uploading {
      background-color: $primary;
    }

    &--uploaded {
      background-color: $green-secondary;
    }
    &--failed {
      background-color: hsl(352, 79%, 48%);
    }
  }

  &__errors {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
    max-height: 7.2rem;
    overflow: visible;
    &--yscroll {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-height: 7.2rem;
      margin-top: 1rem;
      overflow-y: auto;
    }
  }
  &__error-file {
    &-wrapper {
      position: relative;

      cursor: pointer;

      .tooltip {
        display: none;
        position: absolute;
        left: 10%;
        white-space: pre-wrap;
        word-wrap: break-word;
        z-index: 100;
        line-height: 1rem;
        .tooltip__arrow {
          bottom: -0.875rem;
        }

        .tooltip__label {
          overflow: auto;
          overflow-x: hidden;
          width: fit-content;
          max-width: 30rem;
          max-height: 10rem;
          position: absolute;
          bottom: -0.625rem;
        }
      }
      &:hover {
        height: fit-content;
        .tooltip--top {
          display: inline-block;
          z-index: 100;
        }
      }

      &--bottom {
        position: relative;

        cursor: pointer;

        .tooltip {
          display: none;
          position: absolute;
          left: 10%;
          white-space: pre-wrap;
          word-wrap: break-word;
          z-index: 100;
          line-height: 1rem;
          .tooltip__arrow {
            top: 1.2rem;
            margin-left: 0;
          }

          .tooltip__label {
            overflow: auto;
            overflow-x: hidden;
            width: fit-content;
            max-width: 30rem;
            max-height: 10rem;
            position: absolute;
            top: 1.1rem;
          }
        }
        &:hover {
          height: fit-content;
          .tooltip--bottom {
            display: inline-block;
            z-index: 100;
          }
        }
      }
    }
    &-validation {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 21px;
      color: hsl(352, 79%, 48%);
      margin-bottom: 1px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__count {
    &-wrapper {
      display: flex;
      justify-content: flex-start;
      margin-top: 1rem;
    }
    &-label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      margin-left: 0.5rem;
    }
  }
}
