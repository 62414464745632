.addAdditionalInformation{
  &__wrapper{
    padding: 1rem;
    &--blue-dashed{
      background-color: #f1f8fb;
      border: 1px dashed #75cdee;
    }
    
  }
    &__insights-add-new-info {
        cursor: pointer;
        &:hover {
          background-color: $light-grey;
          transition: 0.7s;
          border-radius: 4px;
        }
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    
        & textarea {
          width: 60%;
          height: 40%;
          padding: 0.6rem;
          border: 1px solid #d0d0ce;
          box-sizing: border-box;
          border-radius: 4px;
          outline: none;
          resize: none;
          font-size: 0.95rem;
          font-weight: none;
          overflow-y: auto;
        }
    
        &--text {
          cursor: pointer;
          margin-left: 0.5rem;
          color: #007cb0;
          font-size: 0.875rem;
        }
      }
    
      &__add-info {
        &--header {
          padding: 1rem;
          border-bottom: 1px solid #f0f0f0;
        }
    
        &--text {
          cursor: pointer;
          display: flex;
          width: 13rem;
          .pencil-icon {
            display: none;
            margin-left: 0.5rem;
          }
    
          &:hover {
            .pencil-icon {
              display: inline-block;
            }
          }
        }
      }
    
      &__add-container {
        width: 100%;
        overflow-wrap: break-word;
        word-wrap: break-word;
    
        &--body {
            margin-top: 1rem;
          overflow-wrap: break-word;
          word-wrap: break-word;
        }
      }
    
      &__editor {
        margin: 3rem 1rem 2rem 1rem;
      }

      &__options {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
      }



      &__header{
       
        &-wrapper{
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
            width: 100%;
        }
        &__text {
            font-weight: 600;
            padding-bottom: 0.1rem;
            flex-grow: 1;
            overflow: hidden;
            &-dashboard {
              overflow: hidden;
              flex-grow: 1;
              width: 1px;
              white-space: nowrap;
              text-overflow: ellipsis;
              padding-right: 0.5rem;
            }
      
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-right: 0.5rem;
            &__date {
              color: "#757575";
              opacity: 0.8;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              text-align: "center";
              padding-left: 1.5rem;
              padding-top: 0.5rem;
            }
          }
      }

      &__editInfo {
        margin-right: 0.5rem;
        cursor: pointer;
        &:hover {
          background-color: $light-grey;
          transition: 0.7s;
          border-radius: 4px;
        }
      }
}