.Notifications {
  width: 25rem;
  min-height: 5.5rem;
  left: 760px;
  top: 410px;
  display: flex;
  flex-direction: column;
  height: fit-content;

  &__name {
    font-size: 14px;
    line-height: 24px;
    margin-top: 15px;
    padding-left: 5%;
    color: $black;
    font-weight: 500;
    text-align: left;
  }

  &__content {
    text-align: left;
    color: black;
    padding-left: 5%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding-right: 5%;
  }

  &__options {
    padding-top: 1rem;
    padding-right: 1rem;
    text-align: right;
    padding-bottom: 1rem;
  }

  &__continue {
    margin-left: 1rem;
  }
}
