.reportingDisclosure {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  font-size: 0.875rem;

  &--horizontal {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    &--horizontal {
      width: 100%;
    }
  }

  &__section-name {
    font-size: 0.75rem;
    font-weight: 600;
    position: relative;

    &-container {
      width: fit-content;
    }

    .tooltip {
      top: 100%;
      display: none;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }

  &__company-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  &__company-name {
    width: 100%;
    font-size: 0.75rem;
  }

  &__section-data {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &--1 {
      display: grid;
      grid-template-columns: 31% 31% 31%;
    }

    &--detailedView {
      width: 100%;

      &--row {
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        width: 100%;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background: linear-gradient(180deg, #ffffff 0%, #eef5f8 100%);
        border: 1px solid #e2eff4;
        border-radius: 12px;
        align-items: center;

        &--companyName {
          padding-left: 1rem;
          width: 40%;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #212121;
          justify-content: flex-start;
          text-align: left;
        }
        &--metricsContainer {
          display: flex;
          flex-direction: column;
          width: 60%;
        }

        &--text {
          padding-left: 1rem;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #012169;
          display: flex;
          justify-content: flex-start;
          text-align: left;

          &--paddingBot {
            padding-bottom: 0.25rem;
          }

          &--date {
            line-height: 24px;
            color: #00a3e0;
          }
        }
      }
    }
  }

  &__metric-wrapper {
    width: 100%;
    flex: 1 1;
    min-height: 3rem;
    max-width: 6.75rem;
    background: linear-gradient(
      180deg,
      $white 0%,
      rgba(238, 245, 248, 0.3) 100%
    );
    border: 1px solid #e2eff4;
    border-radius: 1rem;
    position: relative;
    color: $dark-grey;
    display: flex;
    justify-content: center;
    align-items: center;

    &-lbl {
      color: $dark-grey;
      font-size: 0.75rem;
    }

    .tooltip {
      top: 100%;
      display: none;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }

    &--2 {
      max-width: unset;
      font-weight: 600;
      padding: 0.75rem;
      font-size: 1.25rem;
    }
    &--0 > div:first-child {
      overflow: hidden;
      font-size: 12px;
      padding: 0.5rem;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      width: 1px;
      flex-grow: 1;
      // padding: 0 1.5rem;
      line-height: 1.5rem;
    }
    &--2 {
      color: #00a3e0;
    }
    &--1 {
      min-height: 4.1rem;
      min-width: 6rem;
      max-width: unset;
      font-size: 0.75rem;
    }

    &--yes {
      background: linear-gradient(180deg, #e7f5f0 0%, #f1faf7 100%);
      border: 1px solid #6fc2b4;
      > div {
        font-weight: 600;
        color: #0d8390;
      }

      &-lbl {
        font-weight: 600;
        font-size: 0.75rem;
        color: #0d8390;
      }
    }

    &--neutral {
      border: 1px solid $grey;
      > div {
        font-weight: 600;
        align-self: start;
      }
    }
  }

  &__metric {
    padding: 0.875rem 0.5rem;
    text-align: center;
    line-height: 1rem;
    &--no-padding {
      padding: 0;
    }
  }

  &__neutral-label {
    font-size: 0.6rem;
    position: absolute;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    font-weight: normal;
    color: $dark-grey;
  }

  &__no-data {
    text-align: center;
    font-weight: bold;
    padding: 1.5rem 0.5rem;
  }

  &__no-data-footnote {
    font-size: 0.75rem;
    width: 100%;
  }
  &__Verification {
    padding-top: 1.25rem;
    width: 100%;
    display: flex;
    justify-content: center;
    // margin-left: -0.3125rem;
    &-tile {
      display: flex;
      flex-direction: column;
      border: 1px solid #a3cee080;
      // height: 7.5rem;
      width: 100%;
      border-radius: 8px;
    }

    &-value {
      background: linear-gradient(to bottom, #62b5e5 0.64%, #ffffff 135.65%);
      color: #012169;
      border-bottom: 1px solid #f0f0f0;
      padding-top: 0.3125rem;
      text-align: center;
      font-size: 1rem;
      font-weight: 700;
      min-height: 1.875rem;
      border-top: none;
      border-right: none;
      border-left: none;
      border-radius: 8px 8px 0 0;
    }

    &-text {
      text-align: center;
      padding: 0.3125rem;
    }

    &-metric {
      @include font-family;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
    }
    &-metric-container {
      padding: 0.85rem;
      justify-content: center;
      &-center {
        text-align: center;
      }
    }
    &-metric-data-container {
      display: flex;
      gap: 0.8rem;
      margin-bottom: 0.6rem;
    }
    &-metric-name {
      flex: 1;
    }
    &-metric-data {
      flex: 2;
    }
  }
  &-table {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1rem;

    &__section {
      display: flex;
      gap: 1rem;
      flex-direction: column;
    }

    &__company,
    &__data {
      font-size: 0.75rem !important;
    }

    &__section-name {
      padding: 0 0.5rem;
      font-size: 0.75rem;
      width: fit-content;
      font-weight: 600;
      &-lbl {
        font-size: 0.75rem;
        font-weight: 600;
      }
    }

    &__data {
      width: 100%;
      td {
        padding: 1rem 0.5rem;
        font-size: 0.875rem;
        border-top: 1px dashed $light-grey;
        position: relative;
        &:last-child {
          width: 60%;
          font-weight: 600;
        }
      }

      tr:last-child td {
        border-bottom: 1px dashed $light-grey;
      }

      &-value {
        .tooltip {
          top: unset;
          bottom: 0;
          display: none;
          transform: translateY(40%);
        }

        &:hover .tooltip {
          display: block;
        }
      }
    }
  }

  &-table-company {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 1rem;
    padding: 1rem 0.5rem;
    font-size: 0.875rem;
    border-top: 1px dashed $light-grey;
    justify-content: space-between;
    &__section {
      display: flex;
      gap: 1rem;
      flex-direction: column;
    }

    &__company,
    &__data {
      font-size: 0.75rem !important;
    }

    &__section-name {
      // padding: 0 0.5rem;
      font-size: 0.75rem;
      width: fit-content;
      font-weight: 400;
      text-align: left;
      &-lbl {
        font-size: 0.75rem;
        font-weight: 600;
      }
      &-4 {
        font-weight: 600;
      }
    }

    &__section-value {
      padding: 0.375rem 0.5rem;
      font-size: 0.75rem;
      width: fit-content;
      font-weight: 600;
      text-align: right;
      &-lbl {
        font-size: 0.75rem;
        font-weight: 600;
      }
      &-padding {
        display: block;
        padding-bottom: 0.2rem;
      }
    }

    &__data {
      width: 100%;
      td {
        padding: 1rem 0rem;
        font-size: 0.75rem;
        border-top: 1px dashed $light-grey;
        position: relative;
        &:last-child {
          width: 60%;
          font-weight: 600;
        }
      }

      tr:last-child td {
        border-bottom: 1px dashed $light-grey;
      }

      &-value {
        .tooltip {
          top: unset;
          bottom: 0;
          display: none;
          transform: translateY(40%);
        }

        &:hover .tooltip {
          display: block;
        }
      }
    }
    &-4 {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 1rem;
    }
  }
  &-table-industry {
    width: 100%;
    &__label {
      font-size: 0.75rem;
      position: relative;

      .tooltip {
        display: none;
        z-index: 1;
      }

      &:hover {
        .tooltip {
          display: block;
        }
      }
      &--align-left {
        text-align: left;
        &-bold {
          text-align: left;
          font-weight: 600;
          // width: 100%;
        }
      }
      &--padding {
        padding-left: 0.45rem !important;
      }

      &-button {
        font-size: 12px;
        line-height: unset;
      }

      &--fit-content {
        width: fit-content;
        display: flex;
      }

      &--center {
        display: flex;
        justify-content: center;
      }
      &--left {
        display: flex;
        justify-content: flex-start;
      }
      &--left {
        width: fit-content;
        display: inline-block;
      }
    }
    &__row {
      &:first-child td {
        border-top: 1px dashed $light-grey;
      }
      td {
        border-bottom: 1px dashed $light-grey;
        padding: 0.75rem 0.75rem;
        overflow-wrap: break-word;
        max-width: 10rem;
        inline-size: 5rem;

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }

      &--padding {
        td {
          border-bottom: 1px dashed $light-grey;
          padding: 0.75rem 0.5rem;
        }
      }
      &-climate-topics {
        &:first-child td {
          border: none;
        }
        td:first-child {
          background: none;
        }
        td {
          border: none;
          background: #f7f7f7;
          border-radius: 8px;
          width: 20%;
          font-size: 12px;
          height: 3.5rem;
        }
      }
    }
    &__data {
      text-align: right;
      font-weight: 600;
      font-size: 0.75rem;
      position: relative;
      .tooltip {
        position: absolute;
        transform: translateX(-50%);
        display: none;
      }

      &:hover {
        .tooltip {
          display: block;
        }
      }

      &--padding {
        padding: 0.75rem !important;
      }
    }
    &__left {
      vertical-align: top;
      text-align: left;
      font-size: 0.82rem;
      font-weight: normal;
      &-bold {
        vertical-align: top;
        text-align: left;
        font-weight: 600;
      }
    }
    &--data {
      display: inline-grid;
    }
    &--item {
      display: block;
      justify-content: center;
    }
    &__no-data {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.785rem;
      font-weight: bold;
      flex-grow: 1;
      padding: 1rem;
    }
  }
  &__peer-table {
    &-header {
      background: rgba(0, 0, 0, 0.02);
      border-radius: 4px;
      flex-grow: 1;
      tr {
        display: flex;
        align-items: stretch;
        th:last-child {
          flex-grow: 3;
          flex-shrink: 0;
        }
      }
    }
    &-header-text {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 16px;
      color: #000000;
      vertical-align: top;
      padding: 0.2rem;
      width: 30%;
      flex-shrink: 2;
      display: flex;
      flex-direction: row;
    }
    &__body {
      display: flex;
      flex-direction: column;
    }
    &-subheader {
      display: flex;
      td {
        font-weight: 400;
        line-height: 16px;
        color: #757575;
        font-size: 0.75rem;
        padding: 0.2rem 0.2rem;
        width: 30%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-shrink: 2;
      }
      td:last-child {
        flex-grow: 3;
        flex-shrink: 0;
      }
      &:first-child {
        border-bottom: 1px dashed #d0d0ce;
      }
    }
    &-chart {
      display: flex;
      td {
        font-weight: 400;
        line-height: 16px;
        color: #212121;
        font-size: 0.75rem;
        border-bottom: 1px dashed #d0d0ce;
        padding: 0.5rem 0.2rem;
        text-align: left;
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 2;
        td {
          padding: 0;
        }
      }
      :last-child {
        flex-grow: 3;
        flex-shrink: 0;
      }
    }
    &-blocklevelmetric {
      display: block;
      padding-bottom: 0.4rem;
    }
  }
}

.reporting-disclosure-table {
  &__table {
    display: flex;
    flex-direction: column;
  }

  &__company {
    font-size: 0.75rem;
  }

  &__label {
    &--align-left {
      text-align: left;
    }
    &--left {
      width: fit-content;
      display: inline-block;
    }
  }
  &__assurance {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: flex-start;
    td {
      border-bottom: none !important;
    }
  }

  &__data {
    text-align: right;
    font-weight: 600;
    font-size: 0.75rem;
    position: relative;

    .tooltip {
      position: absolute;
      transform: translateX(-50%);
      display: none;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }
}
