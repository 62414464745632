.ghg-target {
  &__fitMultiTarget {
    width: fit-content;
  }

  &__singleTarget {
    display: flex;
    justify-content: space-between;
    border-top: 1px dashed $light-grey;
    padding: 0.5rem 0.2rem;
    &-metricName {
      font-size: 0.75rem;
      font-weight: 400;
    }
    &-metricValue {
      font-size: 0.75rem;
      font-weight: 600;
    }
  }

  &__singleTarget:last-child {
    border-bottom: 1px dashed $light-grey;
  }

  &__metricName {
    width: fit-content;
  }

  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    margin-top: 5rem;
  }
  &__label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.785rem;
    font-weight: bold;
  }

  &__wrapper {
    background: linear-gradient(180.75deg, #f1eef8 0.64%, #ffffff 135.65%);
    border: 1px solid rgba(61, 32, 116, 0.1);
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    &--30 {
      width: calc((100% - 1rem) / 3);
    }
    &--60 {
      width: calc(((100% - 1rem) / 3) * 2);
    }
  }
  &__multiTargetwrapper {
    text-align: center;
    align-items: center;
    background: linear-gradient(180.75deg, #f1eef8 0.64%, #ffffff 135.65%);
    border: 1px solid rgba(61, 32, 116, 0.1);
    border-radius: 0.5rem;
    margin-bottom: 1rem;

    &-header {
      display: flex;
      justify-content: center;
    }
  }
  &__tableWrapper {
    background: linear-gradient(180.75deg, #f1eef8 0.64%, #ffffff 135.65%);
    border: 1px solid rgba(61, 32, 116, 0.1);
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
  }
  &__inner-wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  &__data {
    flex: 1;
  }

  &__data:first-child {
    border-right: 1px dashed rgba(61, 32, 116, 0.1);
  }

  &__header {
    padding: 1rem;
    font-weight: 600;
    font-size: 12px;
    line-height: normal;
  }

  &__tableFirst {
    width: 30%;
  }
  &__tableLast {
    width: 40%;
  }

  &__tableHeader {
    padding: 1rem;
    font-weight: 600;
    font-size: 12px;
    td {
      border-bottom: 1px dashed rgba(61, 32, 116, 0.1);
      padding: 1rem;
    }
  }

  &__tableBody {
    font-size: 0.875rem;
    font-weight: 600;
    color: #012169;
    td {
      padding: 1rem;
    }

    td:first-child {
      color: #000;
      font-weight: 400;
    }

    &-metricData {
      .tooltip {
        top: unset;
        display: none;
        transform: translateY(40%);
      }

      &:hover .tooltip {
        display: block;
      }
    }
  }

  &__body {
    padding: 1rem;
    border-top: 1px dashed rgba(61, 32, 116, 0.1);
    font-size: 0.875rem;
    font-weight: 600;
    color: #012169;

    .tooltip {
      top: unset;
      display: none;
      transform: translateY(40%);
    }

    &:hover .tooltip {
      display: block;
    }
  }

  &__companyName {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &__compTable {
    border-top: 1px dashed $light-grey;
    border-bottom: 1px dashed $light-grey;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 0.5rem;

    &-table {
      width: 100%;
    }

    &-header {
      font-size: 0.75rem;
      font-weight: 400;
      margin-bottom: 0.5rem;
    }
    &-body {
      font-size: 0.75rem;
      font-weight: 600;
    }

    &-tableHeader {
      font-size: 12px;
      td {
        border-bottom: 1px dashed rgba(61, 32, 116, 0.1);
        padding: 1rem;
      }
    }

    &-tableFirst {
      width: 30%;
    }
    &-tableLast {
      width: 50%;
    }

    &-tableBody {
      font-size: 0.875rem;
      font-weight: 600;
      width: 100%;
      td {
        border-bottom: 1px dashed $light-grey;
        padding: 1rem;
      }

      td:first-child {
        font-weight: 600;
      }
    }
  }

  &__singleTargetPeer {
    &-body {
      display: flex;
      gap: 30%;
      padding: 1rem;
      border-top: 1px dashed rgba(61, 32, 116, 0.1);
      &-container {
        width: 35%;
      }
    }

    &-companyName {
      font-size: 0.75rem;
      font-weight: 400;
      margin-bottom: 0.5rem;
    }

    &-data {
      font-size: 0.75rem;
      font-weight: 600;
      color: rgba(1, 33, 105, 1);
    }
  }

  &__singleTargetPeerDetail {
    display: flex;
    gap: 1rem;
    &-body {
      padding: 1rem;
      border-top: 1px dashed rgba(61, 32, 116, 0.1);
    }

    &-data {
      display: flex;
      gap: 2.5rem;
      flex-wrap: nowrap;
      align-items: center;
    }

    &-value {
      font-size: 0.75rem;
      font-weight: 600;
      color: rgba(1, 33, 105, 1);
    }

    &-valueBaseline {
      font-size: 0.75rem;
      font-weight: 600;
    }

    &-companyName {
      font-size: 0.75rem;
      font-weight: 400;
      margin-bottom: 0.5rem;
      max-width: 5rem;
    }
  }

  &__detailPeerChart {
    display: contents;

    &-tableWrapper {
      flex-grow: 1;
    }
    &-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &-wrapper {
      display: flex;
      height: 100%;
    }
  }

  &__detailedView {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__detialView {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 0.5rem;
  }

  &__indTable {
    &-headerRow {
      td {
        border-bottom: 1px dashed $light-grey;
      }
    }

    &-groupName {
      font-size: 0.75rem;
      font-weight: 400;
    }

    &-compName {
      font-size: 0.75rem;
      font-weight: 400;
    }

    &-tableDataRow {
      border-bottom: 1px dashed $light-grey;
      font-size: 0.75rem;
      font-weight: 600;
      width: 20%;
    }

    &-dataRow {
      height: 4rem;
    }

    &-targetName {
      width: 7rem;
    }
  }

  &__detailPeer {
    border: 1px solid #d0d0ce;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    width: 99%;
    display: flex;
    flex-direction: column;

    &-groupName {
      font-size: 0.75rem;
      font-weight: 400;
    }

    &-groupValue {
      font-size: 0.75rem;
      font-weight: 600;
      color: rgba(33, 33, 33, 1) !important;
    }

    &-valueFirst {
      padding: 1rem;
      border-top: 1px dashed rgba(61, 32, 116, 0.1);
      font-size: 0.75rem;
      font-weight: 600;
    }

    &-body {
      display: flex;
      flex-grow: 1;
      //height:94.5%
    }

    &-header {
      background: rgba(0, 0, 0, 0.02);
      align-items: center;
      text-align: center;
      padding: 0.8rem;
      font-size: 0.75rem;
      font-weight: 600;
      border-bottom: 1px solid $light-grey;
    }

    &-tableWrapper {
      display: flex;
      //flex:1 1;
    }

    &-multiTargetwrapper {
      text-align: center;
      align-items: center;
      width: 28%;
    }

    &-tableBody {
      border-left: 1px dashed rgba(61, 32, 116, 0.1);
      width: 72%;
    }

    &-wrapper {
      // border-left: 1px dashed rgba(61, 32, 116, 0.1);
      width: 72%;
    }

    &-tableHeader {
      font-weight: 600;
      font-size: 12px;
      padding: 0.5rem;
    }

    &-tableHeader {
      padding: 1rem;
      font-weight: 600;
      font-size: 12px;
      line-height: normal;
      td {
        border-bottom: 1px dashed rgba(61, 32, 116, 0.1);
        padding: 1.45rem;
      }
    }
  }

  &__scope-target-metric {
    white-space: initial;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;

    &--max-width {
      width: 40%;
    }

    .tooltip {
      transform: unset;
      display: none;
      transform: unset;
      left: -1.5rem;
      top: 2.5rem;
      max-width: 15rem;
      &.tooltip__label {
        max-width: 15rem;
      }
    }

    &:hover {
      & .tooltip {
        display: flex;
      }
    }
  }
}

.detail {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 0.5rem;
}

.ghg-target-table {
  &__table {
    width: 100%;
  }

  &__row {
    &:first-child td {
      border-top: 1px dashed $light-grey;
    }
    td {
      border-bottom: 1px dashed $light-grey;
      padding: 0.75rem 0.5rem;
      overflow-wrap: break-word;
      max-width: 10rem;
      inline-size: 5rem;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

  &__label {
    font-size: 0.75rem;
    position: relative;

    .tooltip {
      display: none;
      z-index: 1;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }
}
