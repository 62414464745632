.calendar-container {
  display: inline-flex;
  justify-content: space-evenly;
  font-size: 14px;
}

.react-datetime-picker__wrapper {
  flex-grow: 0;
  border-radius: 4px;
  border: 1px solid #d0d0ce;
  padding: 0.4rem;
  &:hover {
    border-color: $primary;
  }
}

.react-datetime-picker {
  width: 90%;
  margin-top: 0.3rem;
}

.react-datetime-picker__calendar {
  z-index: 9;
}

.react-datetime-picker__inputGroup__leadingZero {
  margin-bottom: 0.1rem;
}
