.opportunitiesRenewableEnergy {
  &__range {
    @include tile-score-range;
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 20rem;
    margin: 0 auto;
  }

  &__legend {
    &-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: flex-start;
      row-gap: 0.75rem;
      margin-bottom: 2rem;
      margin-top: 0.5rem;
    }
    &-text {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: $dark-grey;
    }
    &-companies {
      display: flex;
      column-gap: 0.8rem;
    }
    &-company {
      &-block {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: $dark-grey;
        column-gap: 0.5rem;
      }
      &-pill {
        width: 20px;
        height: 6px;
        border-radius: 4px;
        background-color: #007cb0;
      }
    }
  }

  &__table-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    &--company-peer {
      & .tableChart__table-body tr:nth-child(1) {
        td {
          border-top: 1px dashed $light-grey;
        }
      }
    }
  }

  &__no-data-section {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: calc(100% - 2rem);
    margin-top: 2rem;
  }

  &__detailedView {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    .chart {
      &__range-score {
        @include tile-score-range;
      }

      &__legend {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        display: flex;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        flex-wrap: wrap;
      }

      &__legend-value {
        font-size: 0.75rem;
        color: #757575;
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }

      &__legend-indicator {
        width: 1.5rem;
        height: 0.5rem;
        border-radius: 0.25rem;
        &--detailed {
          width: 0.5rem;
          aspect-ratio: 1;
        }
      }
    }
  }
}
