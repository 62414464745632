.climate{
&__wrapper{
    display: flex;
    width: inherit;
    flex-direction: column;
}

&__header__sub-text{
    font-size: 0.75rem;
    padding: 0 0 1.5rem 0.5rem;
    color: $grey;
}

}