.DisclaimerModal {
  width: 20rem;
  min-height: 5rem;
  height: auto;
  left: 760px;
  top: 410px;
  display: flex;
  flex-direction: column;

  &--disclaimer {
    width: 22rem;
  }
  &--deleteSearch {
    min-height: 5.3rem;
  }
  &__name {
    font-size: 14px;
    line-height: 24px;
    padding-left: 10%;
    color: $black;
    font-weight: 500;
    text-align: left;

    &--disclaimer {
      padding-right: 4%;
      font-size: 14px;
      line-height: 24px;
      padding-left: 5%;
      color: $black;
      font-weight: 400;
      text-align: left;
    }
    &--deleteSavedSearch {
      font-size: 14px;
      padding-left: 4%;
      white-space: pre-wrap;
      word-wrap: break-word;
      padding-right: 4%;
    }
  }

  &__content {
    text-align: right;
    padding-top: 0.25rem;
    font-size: 12px;
    color: #63666a;
    padding-right: 10%;
    &--disclaimer {
      padding-right: 4.5%;
    }
  }

  &__options {
    padding-top: 1rem;
    padding-right: 10%;
    text-align: right;
    &--disclaimer {
      padding-top: 3rem;
      padding-right: 4.5%;
    }
  }

  &__continue {
    margin-left: 1rem;
    padding: 0.6rem 1.5rem;
    background-color: #007cb0;
    border: none;
    color: $white;
    cursor: pointer;
    border-radius: 0.25rem;
    &-disabled {
      background-color: $grey;
    }
  }
}

.Disclaimer__btn {
  &.disclaimer__btn {
    padding: 0.5rem 1.5rem;
  }
}
