.validateReportsPanel{

    &__container{
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    &__body{
        padding: 1rem;
        flex-grow: 1;
    
    }

    &__subtitle{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 1rem;
    }

    &__footer{
        padding: 1rem;
        display: flex;
        justify-content: flex-end;
        column-gap: 1rem;
        box-shadow: inset 0px 1px 0px #E0E0E0;
    }

    
  &__outline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: $white;
    border: 1px solid $light-grey;
    border-radius: 0.25rem;
    height: 1.5rem;
    margin-bottom: 0.5rem;
    &:hover,
    &:focus-within {
      border-color: $primary;
    }

    &__datepicker {
      position: absolute;
      height: fit-content;
      left: -41%;
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      border: 1px solid  #E0E0E0;
    }
  }

  &__placeholder {
    position: absolute;
    color: $grey;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  &__input {
    position: relative;
    padding: 0;
    height: 1rem;
    border: none;
    flex-grow: 1;
    width: 1px;
    outline: none;
    z-index: 1;
    resize: none;
    background-color: transparent;
    @include font-family;
    font-size: 0.875rem;
    line-height: 1.5rem;

    &:focus {
      background-color: $white;
    }
  }

  &__input-size {
    min-height: 1.25rem;
    min-width: 1px;
    max-width: 14rem;
    visibility: hidden;
    position: absolute;
  }

  &__calendar-section{
    display: flex;
    .rdrCalendarWrapper{
        position: unset;
        width: 36rem;
        box-shadow: unset;
        height: 17.5rem;
      }
      .rdrMonth{
        width: 18rem;
      }
    
  }

  &__back-to-today{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    border: none;
    color: #007CB0;
    background: unset;
    padding: 0;
  }


&__back{
    padding: 0.875rem 1.5rem;
    box-shadow: inset 0px 1px 0px #E0E0E0;
}

&__error{
  &-message{
    font-size: 14px;
    color: #f54242;
    font-weight: 500;
    padding-left: 0.5rem;
  }
}
 
}