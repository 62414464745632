.circular-bar-chart {
  width: 100%;
  aspect-ratio: 1;
  margin: 0 auto;
  padding: 8%;
  box-sizing: border-box;
  border: 1px solid $light-grey;
  border-radius: 50%;
  position: relative;

  &__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: inherit;
  }

  &__chartContent{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: inherit;
  }

  &__detailedWrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: inherit;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__container {
    max-width: 20rem;
    margin: 2rem auto 0 auto;
    width: 100%;
    height: inherit;
  }

  &__detailedContainer{
    display: flex;
    flex-direction: column;
    width: inherit;
    height: inherit;
    gap: 1.5rem;
  }

  &__subTitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: $dark-grey;
  }

  &__table-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    width: 100%;
    &--company-peer {
      & .tableChart__table-body tr:nth-child(1) {
        td {
          border-top: 1px dashed $light-grey;
        }
      }
    }
  }

  &__legend {
    &-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: flex-start;
      row-gap: 0.75rem;
      margin-bottom: 3rem;
    }
    &-text {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: $dark-grey;
    }
    &-companies {
      display: flex;
      column-gap: 1rem;
      flex-wrap: wrap;
    }
    &-company {
      &-block {
        display: flex;
        align-items: baseline;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: $dark-grey;
        column-gap: 0.5rem;
      }
      &-pill {
        width: 20px;
        height: 6px;
        border-radius: 4px;
        min-width: 20px;
        background-color: #007cb0;
      }
    }
  }

  &__range {
    @include tile-score-range;
  }

  &__missing-data-msg {
    margin: 2rem 1rem 0rem 0.5rem;
    align-items: center;
    font-size: 0.785rem;
    display: flex;
    font-weight: normal;
    text-align: left;
  }

  &__peer-arrow {
    z-index: 1;
    position: absolute;
    top: 3%;
    width: 1.5%;
    height: 46%;
    transform-origin: bottom center;
    width: fit-content;
  }

  &__onlyArrow {
    z-index: 1;
    position: absolute;
    top: 3%;
    width: 1.5%;
    height: 41.2%;
    transform-origin: bottom center;
    border-left: 0.3125rem solid transparent;
    border-right: 0.3125rem solid transparent;
    border-top: 0.625rem solid;

    @media screen and (min-width: 1220px) and (max-width: 1395px) {
      height: 42%;
    }
    @media screen and (min-width: 1395px) and (max-width: 1450px) {
      height: 42.5%;
    }
    @media screen and (min-width: 1450px) {
      height: 43%;
    }
  }

  &__onlyArrow-wide {
    z-index: 1;
    position: absolute;
    top: 3%;
    width: 1.5%;
    height: 43%;
    transform-origin: bottom center;
    border-left: 0.3125rem solid transparent;
    border-right: 0.3125rem solid transparent;
    border-top: 0.625rem solid;
  }

  &__donut {
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__hole {
    width: 60%;
    aspect-ratio: 1;
    z-index: 2;
    border-radius: 50%;
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-container {
      justify-content: center;
      align-items: center;
      padding: 0.7rem;
    }

    &-company {
      display: flex;
      gap: 5px;
      align-items: center;
      border-bottom: 1px solid #d0d0ce;
      padding-bottom: 0.2rem;
      justify-content: center;

      &--value {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin-right: 5px;
      }

      &--name {
        font-size: 12px;
        font-weight: 400;
        color: #a3a3a3;
        word-break: normal;
      }

      &--name-long {
        font-size: 0.47rem !important;
      }

      &--name-extra-long {
        font-size: 0.4rem !important;
      }
    }
    &-peer {
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: center;

      &--value {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin-right: 5px;
      }
      &--name {
        font-size: 12px;
        font-weight: 400;
        color: #a3a3a3;
      }
    }
  }

  &__value {
    width: 50%;
    aspect-ratio: 1;
    justify-content: center;
    display: flex;

    margin: 0 auto;
    z-index: 1;

    font-weight: 600;
    font-size: 20rem;
  }

  &__range-gap,
  &__half-gap {
    width: 10%;
    height: 4%;
    background-color: $white;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border-right: 1px solid $light-grey;
    border-left: 1px solid $light-grey;
  }

  &__half-gap {
    top: 100%;
    width: 1px;
    border: none;
    background: $light-grey;
  }

  &__min-value,
  &__max-value,
  &__half-value {
    width: 8%;
    aspect-ratio: 1;
    display: flex;
    font-weight: 400;
    font-size: 20rem;
    position: absolute;
    top: 0;
    left: 50%;
    fill: $dark-grey;
  }

  &__min-value {
    transform: translate(25%, -125%);
  }

  &__max-value {
    transform: translate(-150%, -125%);
  }

  &__half-value {
    transform: translate(-50%, 125%);
    top: unset;
    bottom: 0;
  }
}
