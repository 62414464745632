.map-files {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;

  &__header {
    padding: 1.5rem 0rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &--title {
      font-size: 1.25rem;
      font-weight: 400;
    }

    &--actions {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }
  }

  &__table {
    &--container {
      flex-grow: 1;
      display: flex;
    }

    @media screen and (min-width: 1200px) {
      thead th:nth-child(1),
      tbody td:nth-child(1) {
        min-width: 20rem;
      }

      thead th:nth-child(2),
      tbody td:nth-child(2) {
        flex: 0 0 13%;
        min-width: 10rem;
      }

      thead th:nth-child(3),
      tbody td:nth-child(3) {
        flex: 0 0 13%;
        min-width: 9rem;
      }

      thead th:nth-child(4),
      tbody td:nth-child(4) {
        min-width: 15rem;
      }

      thead th:nth-child(5),
      tbody td:nth-child(5) {
        flex: 0 0 7%;
        min-width: 4rem;
      }
    }
  }

  &__heading--text {
    display: flex;
    flex-direction: row;
  }

  &__heading--fieldsMissing {
    display: flex;
    align-items: center;
    padding: 0.625rem 1rem;
    background: #ffecea;
    /* Functional / Red */
    border: 0.063px solid #da291c;
    box-sizing: border-box;
    border-radius: 0.126rem;

    &--icon {
      padding-left: 1.188rem;
    }

    &--text {
      padding-left: 0.688rem;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1, 5rem;
      color: $black;
    }
  }

  &__heading--content {
    box-sizing: border-box;
    text-align: left;
    flex-grow: 1;
  }

  &__heading--text th {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #212121;
    text-align: left;
    padding: 0.75rem 0rem 0.75rem 1rem;
    box-shadow: inset 0px -1px 0px #e0e0e0;
  }

  &__heading--checkbox {
    position: relative;
    display: inline-flex;
    left: -0.5rem;
    margin-right: 0.5rem;
  }

  &__save {
    @include primary-button;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &--disabled {
      @include primary-button-disabled;
    }
  }

  &__loading {
    width: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  &__empty-container {
    display: flex;
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  &__empty-header {
    height: 2rem;
    padding: 2rem;
    display: flex;
    justify-content: right;
  }

  &__empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin-top: 6%;

    @media screen and (min-width: 1400px){
      margin-top: 8%;
    }

    @media screen and (min-width: 1600px){
      margin-top: 11%;
    }
  }

  &__img {
    width: 249px;
    height: 210px;
  }

  &__emptytext {
    width: 309px;
    height: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  &__emptyredirect {
    width: 263px;
    height: 24px;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    color: #75787b;
    margin-left: 1.3rem;
  }

  &__link {
    color: $primary;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  thead {
    width: 100%;
    position: sticky;
    top: 0px;
    background: white;
    z-index: 2;
    flex-grow: 1;
  }

  th,
  td {
    flex: 1;
  }

  th {
    display: flex;

    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  tr {
    display: flex;
    position: relative;
    height: fit-content;
    box-sizing: border-box;
  }

  td {
    position: relative;
    height: fit-content;
  }
}
