.toast {
  &__container {
    box-sizing: border-box;
    position: fixed;
    z-index: 999999;
    font-size: 0.875rem;
  }

  &__notification {
    transition: 0.3s ease;
    pointer-events: auto;
    overflow: hidden;
    margin-bottom: 1rem;
    border-radius: 3px 3px 3px 3px;
    box-shadow: 0 0 10px #999;
    color: $white;
    opacity: 0.9;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    background-color: $black;

    &:hover {
      box-shadow: 0 0 12px $white;
      opacity: 1;
      cursor: pointer;
    }
  }

  &__icon {
    position: absolute;
    right: 1rem;
    margin-left: 1rem;
    @include invisible-button;

    path {
      fill: #fff;
    }
  }

  &__title {
    font-weight: 700;
    text-align: left;
    margin-top: 0;
    margin-bottom: 6px;
    width: 300px;
  }

  &__message {
    margin: 0;
    margin-right: 2rem;
    text-align: left;
    margin-left: -1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    word-wrap: break-word;
    max-width: 40rem;
  }
}

.top-right {
  top: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.bottom-center {
  bottom: 12px;
  right: 50%;
  transform: translateX(25%);
  transition: transform 0.6s ease-in-out;
  animation: toast-in-bottom 0.7s;
}

.bottom-right {
  bottom: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.top-left {
  top: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.bottom-left {
  bottom: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}
