.unauthorized {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background: $white;
  z-index: 9999;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 29rem;
    padding: 0.25rem;

    .loading-icon {
      @include loading-icon;
    }
  }

  &__image {
    max-width: 13.5rem;
  }

  &__title {
    font-weight: 600;
    margin-top: 2rem;
  }

  &__message {
    font-size: 14px;
    color: $grey;
    line-height: 1.8;
  }

  &__link {
    text-decoration: none;
    color: $primary;
  }
}
