.dropdown {
  position: relative;

  &__outline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    padding: 0.75rem 1rem;
    background-color: $white;
    border: 1px solid $light-grey;
    border-radius: 0.25rem;
    height: 1.5rem;
    &:hover,
    &:focus-within {
      border-color: $primary;
    }
  }

  &__placeholder {
    position: absolute;
    color: $grey;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  &__input {
    padding: 0;
    height: 1rem;
    border: none;
    flex-grow: 1;
    width: 1px;
    outline: none;
    z-index: 1;
    resize: none;
    background-color: transparent;
    @include font-family;
    font-size: 0.875rem;
    line-height: 1.5rem;

    &:focus {
      background-color: $white;
    }
  }

  &__input-size {
    min-height: 1.25rem;
    min-width: 1px;
    max-width: 14rem;
    visibility: hidden;
    position: absolute;
  }

  &__values {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding-top: 0.5rem;
  }

  &__value {
    font-size: 0.75rem;
    background-color: $lightest-grey;
    padding: 0.25rem 0.35rem 0.25rem 0.75rem;
    border-radius: 6.875rem;
    display: flex;
    line-height: 1.25rem;

    &--content {
      background-color: transparent;
      margin-top: 0;

      &:first-child {
        margin-top: 0.5rem;
      }
    }
  }

  &__option-container {
    display: flex;
    flex-direction: column;
  }

  &__option-value {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  &__option-secondary-value {
    font-size: 0.75rem;
    line-height: 1rem;
    color: $light-grey;
  }

  &__remove {
    @include invisible-button;
    background-color: $grey;
    border-radius: 1rem;
    margin-left: 0.5rem;

    path {
      fill: $white;
    }
  }

  &__chevron-button {
    @include invisible-button;
    &:hover {
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 4px;
    }
    &--not-empty {
      margin-top: 0.5rem;
    }
  }

  &__chevron-icon {
    cursor: pointer;
    transition: transform 200ms ease-in-out;
    &--open {
      transform: rotate(180deg);

      path {
        fill: $primary;
      }
    }

    &--close {
      transform: rotate(0);
    }
  }

  &__options {
    width: 100%;
    border-radius: 0.25rem;
    margin-top: 0.25rem;
    background-color: $white;
    position: absolute;
    z-index: 2;
    overflow: hidden;
    transition: max-height 200ms ease-in-out;

    &--show {
      max-height: 11.8rem;
      border: 1px solid $light-grey;
      min-height: 2.5rem;
      box-shadow: 0px 1px 4px $light-grey;
      overflow: auto;
    }

    &--hide {
      max-height: 0rem;
    }
  }

  &__loading {
    margin: 0 auto;
  }

  &__option {
    @include invisible-button;
    background-color: $white;
    padding: 0.75rem 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;

    &--selected {
      background-color: $lightest-grey;
    }

    &:first-child {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }

    &:last-child {
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    &:hover {
      background-color: $lightest-grey;
    }
  }

  &__unclickable {
    cursor: auto;
    line-height: 1.5rem;
    font-size: 0.875rem;

    &:hover {
      background-color: transparent;
    }
  }

  &__option-category {
    padding: 0.5rem 1rem;
    color: $grey;
    font-weight: 700;
  }

  &__option-value {
    text-align: left;
  }
}
