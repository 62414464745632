.dashboard {
  display: flex;
  flex-direction: column;
  //height: 100%;
  width: 100%;
  //Scroll bar was visible
  overflow-y: none;
  margin-top: 3rem;

  &__content {
    @include grid-dashboard;
    padding: 0 3.25rem;

    &-header {
      &-text {
        font-size: 1.25rem;
      }
    }
  }

  &__header {
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
  }

  &__benchmark-section {
    min-width: 1rem;
  }

  &__add {
    margin-right: 0.4rem;
  }

  &__edit-dashboard {
    &-view {
      display: flex;
      flex-direction: column;
    }

    &-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      @media screen and (max-width: 1300px) {
        height: 80vh;
      }
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      border-bottom: 1px solid $lightest-grey;
    }

    &-close-button {
      @include invisible-button;
    }

    &__actions {
      padding: 1rem;
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      align-self: flex-end;
    }

    &-apply-button {
      @include primary-button;
    }

    &-disabled-button {
      @include primary-button-disabled;
    }

    &-reset-button {
      @include invisible-button;
      color: $primary;
      &:hover {
        background-color: $light-grey;
        transition: 0.7s;
        border-radius: 4px;
      }
    }

    &-reset-disabled-button {
      @include invisible-button-disabled;
      &:hover {
        background-color: transparent;
      }
    }
  }

  &__current-benchmarks {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    box-sizing: border-box;
    width: 100%;
    overflow-x: hidden;
    height: calc(100% - 4.5rem);
    font-size: 14px;
    padding: 0 1rem;

    &-heading-text {
      font-size: 14px;
      font-weight: 600;
      //color: $dark-grey;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }

    &-charts-list {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      overflow-y: auto;
    }
  }

  &__test {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__benchmark-show {
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
      border: 1px solid $primary;
      padding: 0.75rem 1.25rem;
      border-radius: 5px;

      &-hidden {
        border: 1px solid $light-grey;

        input {
          border: 1px solid $lightest-grey;
          cursor: pointer;
        }
      }
    }

    &-title {
      display: flex;
      gap: 0.5rem;
      line-height: 1.5rem;
      width: 90%;

      &-text {
        font-size: 1rem;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  &__edit-view-button {
    @include invisible-button;
    display: flex;
    gap: 0.5rem;
    color: $primary;

    .path {
      color: $primary;
    }
  }

  &__export-all {
    position: relative;

    &-button {
      @include primary-button;
      border: 1px solid $primary;
      background-color: $white;
      display: flex;
      gap: 0.5rem;
      color: $primary;
      align-items: center;

      &--disabled {
        @include primary-button-disabled;
        display: flex;
        gap: 0.5rem;
        color: $dark-grey;
        align-items: center;
      }

      &--active {
        path {
          transform: rotate(180deg);
          transform-origin: 50% 50%;
        }
      }
    }

    &-button:hover {
      transition: 0.3s;
    }
    &-options {
      display: none;
      position: absolute;
      top: 2.5rem;
      background-color: $white;
      z-index: 3;
      box-shadow: 0.1rem 0.1rem 0.2rem $light-grey;
      border: 1px solid $lightest-grey;

      &--show {
        display: block;
      }
    }

    &-option {
      @include invisible-button;
      padding: 0.5rem;
      display: flex;
      gap: 0.5rem;
      font-size: 0.875rem;

      &:hover {
        background-color: $lightest-blue;
        border-color: $primary;
      }

      &--text {
        padding: 0.2rem 0.5rem 0.2rem 0.5rem;
        white-space: nowrap;
      }
    }
  }

  &__benchmark-section {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  &__benchmark-button {
    @include font-family;
    background-color: $primary;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    border: none;
    border-radius: 0.25rem;
    color: $white;
    font-weight: 400;
    line-height: 1rem;
    font-size: 0.875rem;
  }

  &__benchmark-add-new {
    cursor: pointer;
    box-sizing: border-box;
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 50% auto 50% auto;
    align-items: center;
    justify-content: center;
    height: 4rem;
    &:hover {
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 4px;
    }
    &--tile {
      @include font-family;
      background-color: $primary;
      display: flex;
      align-items: center;
      cursor: pointer;
      border: none;
      border-radius: 50%;
      padding: 0.2rem;
      margin: auto;

      path {
        fill: $white;
      }
    }

    &--text {
      cursor: pointer;
    }
  }

  &__custom-benchmark {
    z-index: 1000;
    display: flex;
    position: fixed;
    width: 26.5rem;
    height: calc(100% - 6rem);
    margin-top: 6rem;
    right: 0rem;
    top: 0rem;
    background: #ffffff;
    box-shadow: -1px -1px 1px #e0e0e0;
    border-bottom: 1px solid #e0e0e0;

    &__header {
      width: 100%;
      min-height: 3rem;
      height: fit-content;
      left: 0rem;
      top: 0rem;
      box-shadow: inset 0px -1px 0px #e7e7e7;
      padding-bottom: 5rem;
      font-size: 1rem;

      &-section {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 3.5rem;
        border-bottom: 1px solid #ebebeb;
        padding: 1rem;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    &__dropdown {
      padding: 0.5rem 0rem;
    }

    &__peerCompanies-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    &__peerCompanies {
      background-color: $lightest-grey;
      padding: 0.25rem 0.25rem 0.25rem 0.5rem;
      border-radius: 1rem;
      margin-right: 0.5rem;
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
    }

    &__suggested-peers {
      @include invisible-button;
      margin-left: 0.3rem;
      display: flex;
      align-items: center;
      color: $primary;
      font-family: "Open Sans";
      column-gap: 0.25rem;
      &:hover {
        color: $secondary-blue;
        background-color: $light-grey;
        transition: 0.7s;
        border-radius: 4px;
      }
      &--disabled {
        color: $dark-grey;
        & svg path{
          fill: $dark-grey;
        }
        .icon {
          color: $dark-grey;
         
        }
        &:hover {
          background-color: transparent;
          color: $dark-grey;
        }
      }
    }
    &__suggested-peers-modal {
      width: 100%;
    }

    &__column-company {
      &--name {
        width: 15rem;
      }
      &--ticker {
        width: 3rem;
      }
      &--auditor {
        width: 5rem;
      }
      &--metrics {
        width: 2rem;
      }
      &--source {
        width: 6.1rem;
      }
    }

    &__options {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
    }

    &__continue {
      margin-left: 1rem;
    }

    &__remove {
      @include invisible-button;
      background-color: #a3a3a3;
      border-radius: 1rem;
      margin-left: 0.25rem;
      path {
        fill: $white;
      }
      &:hover {
        background-color: $grey;
      }
    }
    &__dropdown-label {
      font-size: 14px;
      font-weight: 600;
    }

    &__placeholder {
      font-size: 14px;
    }

    &__body {
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      top: 3rem;
      height: calc(100% - 7rem);
      background: #ffffff;
      padding: 0 1rem 0 1rem;
      font-size: 14px;
      overflow-y: auto;
      overflow-x: hidden;
      margin-top: 0.5rem;
    }

    &__edit-benchmark {
      &--label {
        font-weight: 600;
        font-size: 14px;
        margin: 1rem 0 0.5rem 0;
      }

      &--input {
        width: 100%;
        height: auto;
        text-align: center;
        outline: none;

        & input {
          width: 100%;
          height: fit-content;
          padding: 0.6rem;
          border: 1px solid #d0d0ce;
          box-sizing: border-box;
          border-radius: 4px;
          outline: none;
          resize: none;
          font-size: 0.95rem;
          font-weight: none;
        }
      }
    }

    &__peer-group-empty {
      display: flex;
      padding: 0.75rem;
      font-size: 0.875rem;
      line-height: 1.5rem;
      justify-content: center;
    }
    &__actions {
      column-gap: 1rem;
      justify-content: flex-end;
      &:hover {
        .tooltip {
          display: none;
        }
      }
    }

    &__action-icon {
      @include invisible-button;
      position: relative;
      cursor: pointer;
      .tooltip {
        display: none;
        top: -2rem;
        bottom: unset;
        &--top {
          &__arrow {
            left: 45%;
          }
        }
      }

      &:hover {
        .tooltip {
          display: inline-block;
        }
        background-color: black;
        border-radius: 4px;
      }
    }
  }

  .data-source {
    padding: 1.5rem;
    display: flex;
    font-size: 0.875rem;
    margin-block-start: auto;
    justify-content: flex-end;
  }
}
