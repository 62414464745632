.battery-chart {
  width: 100%;
  aspect-ratio: 1;
  margin: 0 auto;
  display: flex;
  background-color: $light-grey;
  position: relative;

  &__content{
    display: flex;
    flex-direction: column;
    align-items: center;
    &--left{
      margin-left: -1rem;
    }
    &--right{
      margin-left: -5rem;
    }
  }

  &__border {
    position: absolute;
    background-color: $lighter-grey;
    top: 2px;
    left: 7px;
    height: calc(100% - 4px);
    width: calc(100% - 14px);
    display: flex;
    box-sizing: border-box;
  }

  &__data-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column-reverse;
    gap: 2%;
    max-width: 30%;
    padding-top: 5%;
    padding-bottom: 2%;
    margin: 0 auto;
  }

  &__data {
    background-color: $white;
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 10rem;
    max-width: 20rem;
    margin: 0 auto;
  }

  &__range {
    @include tile-score-range;
  }

  &__value{
    height: 15%;
    aspect-ratio: 1;
    justify-content: center;
    display: flex;

    fill: $white;
    border-radius: 100%;

    position: absolute;

    font-weight: 600;
    font-size: 15rem;
    &--left{
      right: 0;
      transform: translate(-500%, -100%);
    }
    &--right{
      right: 5px;
      transform: translate(-30%, -100%);
    }
  }

  &__join-line {
    height: 3%;
    width: 100%;
    background-color: $light-grey;
    display: flex;
    position: absolute;
    top: 50%;
    &--left{
      left: 100%;
    }
    &--right{
      left: -100%;
    }
  }

  &__value-indicator {
    height: 10%;
    width: 10%;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    border: $white solid 1px;
    &--left{
      left: 200%;
    }
    &--right{
      left: -110%;
    }
  }

  &__footer {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: rgba(33, 33, 33, 1);
    padding: 0.75rem;
    text-align: center;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 7rem;
  }
}
