.tabbed-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px -1px 0px $secondary-light-grey;

  &__item {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.75rem 1rem;
    color: $dark-grey;
    font-size: 0.875rem;
    line-height: 1.5rem;
    width: fit-content;
    align-items: center;
    justify-content: center;
    font-family: "Open Sans";
    &:hover {
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 4px;
    }
    &--selected {
      color: $black;
      border-bottom: 0.25rem solid $primary;
      padding-bottom: 0.5rem;
      font-weight: 600;
    }
    &--selectedPopover{
      color:$light-green;
      border-bottom: 0.15rem solid $light-green;
     
    }

    &--disabled{
      cursor: not-allowed;
      &:hover {
        background-color: #F7F7F7;
        border-radius: 8px 8px 0 0;
       
      }
    }

    &__insight {
      &-icon {
        min-width: 18px;
        width: fit-content;
        height: 18px;
        border-radius: 12px;
        background-color: #43b02a;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        display: flex;
        margin-left: 0.5rem;

        &-content {
          color: white;
          font-weight: 500;
          font-size: 10px;
        }
      }
    }

    &__shortlist {
      // Not working, need to ask

      &-icon {
        min-width: 18px;
        width: fit-content;
        height: 18px;
        border-radius: 12px;
        background-color: $primary;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        display: flex;
        margin-left: 0.5rem;

        &-content {
          color: white;
          font-weight: 500;
          font-size: 10px;
        }
      }
    }
  }
}
