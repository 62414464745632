.manage-adf-pipeline {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: center;

  &__emptytext {
    width: 26rem;
    height: 1.5rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5px;
    text-align: center;
  }

  &__empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin-top: 8%;
  }
}
