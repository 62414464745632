.DeleteModal {
  width: 26rem;
  display: flex;
  flex-direction: column;

  &__name {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: $black;
    font-weight: 500;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  &__options {
    padding-top: 2rem;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
