.textfield {
  display: flex;
  flex-direction: column;

  &__label {
    padding-bottom: 0.5rem;
  }

  &__input {
    font-size: 0.9rem;
    padding: 0.75rem 1rem;
    min-width: 20rem;
    border-radius: 0.25rem;
    border: 1px solid $light-grey;

    &--error {
      border-color: $red;
    }
  }

  &__assertive-text {
    color: $grey;
    font-size: 0.75rem;
    padding-left: 1rem;
    padding-top: 0.25rem;

    &--error {
      color: $red;
    }
  }
}
