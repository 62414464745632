.waste {
  &__container {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 3rem;
  }
  &__no-data-section {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  &Chart {
    &__container {
      display: flex;
      flex-direction: column;
      row-gap: 3rem;
      width: 100%;
    }

    &__legend {
      display: flex;
      column-gap: 0.25rem;
      align-items: center;
      justify-content: space-between;

      &-section {
        display: flex;
        column-gap: 0.5rem;
        width: 100%;
      }
      &-icon {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
      }
      &-text {
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 400;
        color: $dark-grey;
      }
    }

    &__graphic {
      &-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        row-gap: 1rem;
        &-body {
          display: flex;
          width: 100%;
          flex-direction: column;
        }

        &-header {
          display: flex;
          width: 100%;
          justify-content: space-between;
        }

        &-company-data {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          &--dashed {
            border-bottom: 1px dashed $light-grey;
            padding: 0.5rem 0;
          }
        }
        &-metric {
          display: flex;
          width: 45%;
          column-gap: 0.5rem;
        }

        &-metrics-wrapper {
          display: flex;
          width: 100%;
          justify-content: space-between;
          &-col-2 {
            width: 90%;
          }
        }
        &-col-3 {
          width: 29%;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    &__metric {
      &-name {
        color: $neutral-black;
        @include fz-16--600;
        &-container {
          width: 45%;
        }
      }

      &-values {
        display: flex;
      }
      &-value {
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        color: $dark-blue;
        &-empty {
          color: $neutral-black;
        }
      }

      &-unit {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        color: $neutral-black;
      }
    }
    &__submetric {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 0.25rem;
      width: calc(100% / 2);
      border-radius: 10px;
      padding: 0.75rem 1rem;
      &-empty {
        background-color: unset;
        width: 100%;
        justify-content: center;
      }
    }

    &__legendDetailedPeer {
      display: flex;
      column-gap: 0.25rem;
      align-items: center;
      justify-content: space-between;
      width: calc(100% / 2);

      &-section {
        display: flex;
        column-gap: 0.5rem;
        width: 100%;
        // justify-content: space-between;
      }

      &-container {
        display: flex;
        width: 90%;
        justify-content: space-between;
      }

      &-container-metric {
        display: flex;
        width: 45%;
      }

      &-col-3 {
        width: 29%;
        font-weight: 400;
        font-size: 14px;
      }

      &-text {
        font-family: Open Sans;
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 1rem;
        letter-spacing: 0px;
        text-align: left;
        color: #000000;
      }
    }
  }

  &Table {
    &__container {
      display: flex;
      flex-direction: column;
      width: 100%;

      &--horizontal {
        flex-direction: row;
      }
    }

    &__company-section {
      display: flex;
      width: 100%;
      flex-direction: column;
      &--align-center {
        flex-direction: row;
        align-items: center;
      }
      &--dashed {
        padding: 1rem 0;
        border-bottom: 1px dashed $light-grey;
      }
    }

    &__submetric {
      &-container {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        border-bottom: 1px dashed $light-grey;
      }
      &-text {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        &--bold {
          font-weight: 600;
        }
        &--center {
          text-align: center;
        }

        &--w-30 {
          width: calc(100% / 3);
        }
      }
    }
    &__metric {
      &-col {
        display: flex;
        flex-direction: column;
        width: 50%;
      }
      &-text {
        @include fz-14--400;
        font-weight: 600;

        &--padding {
          margin-bottom: 2rem;
        }
      }
    }

    &-leftAligned {
      text-align: left;
    }

    &-centerAligned {
      text-align: center;
    }
    &-darkColoredWeight {
      font-weight: 600;
    }

    &-lightColoredWeight {
      font-weight: 400;
    }

    &__legend {
      &-container {
        width: 100%;
        display: flex;
        padding-bottom: 1.5rem;
        border-bottom: 1px dashed $light-grey;
      }
      &-text {
        width: calc(100% / 3);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }

    &__legendIndustry {
      &-container {
        width: 100%;
        display: flex;
        padding: 1rem 0rem;
        border-bottom: 1px dashed $light-grey;
      }
      &-text {
        width: calc(100% / 3);
        font-size: 12px;
        font-style: normal;
        line-height: 16px;
      }
    }
  }

  &__popover-container {
    width: fit-content;
    display: inline-block;
  }
}
