.tooltip-overlay {
  position: fixed;
  z-index: 9999;
  pointer-events: none;
  width: 100%;
  height: 100vh;
  top: 0;
  bottom: 0;

  &__not-empty {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px;
  }
}
