.checkbox-container {
  width: 100%;
  height: fit-content;
  margin-top: 1rem;

  &__header {
    font-weight: 600;
  }

  &__body {
    margin: 0.5rem 0 0 1rem;
  }

  &__AlignInRow {
    display: flex;
    flex-direction: row;
    margin: 0rem;
    grid-gap: 1rem;
    padding-top: 0.5rem;
    font-weight: 400;
    color: $black;
  }
  &__NoMargin {
    margin: 0rem;
    margin-top: 0.5rem;
    font-weight: 400;
    color: $black;
  }

  &__checkbox-item {
    display: block;
  }

  &__checkbox-parent {
    margin-bottom: 0.5rem;
  }

  &__checkbox-parent-container {
    margin: 0 0 0 2rem;
    color: gray;
  }
}

.checkbox__label-disabled {
  color: gray;
  cursor: default;
  &:hover {
    background-color: transparent !important;
  }
}

.checkbox__label {
  &:hover {
    background-color: $light-grey;
    transition: 0.7s;
    border-radius: 4px;
  }
}
