.main-tile {
  border: 1px solid $lightest-grey;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem 0 $lightest-grey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  box-sizing: border-box;
  margin-bottom: 1rem;

  &__span {
    @for $i from 1 through 12 {
      &--#{$i} {
        grid-column-end: span #{$i};
        @if $i > 2 {
          @media screen and (max-width: 75rem) {
            grid-column-end: span 2;
          }
          @if $i > 3 {
            @media screen and (max-width: 1203px) {
              grid-column-end: span 3;
            }
          }
        }
      }
    }
  }

  &--loading {
    grid-row-end: span 256;
    min-height: 15rem;
  }

  &--loaded {
    padding: 1rem;
    min-height: unset;
  }

  &--targets-waves {
    background-image: url("../shared/icon/icons/waves-top.svg"),
      url("../shared/icon/icons/waves-bottom.svg"),
      linear-gradient(180.75deg, #e3f2e5 0.64%, #ffffff 135.65%);
    background-position: 100% 0, 0 100%, 100% 100%;
    background-repeat: no-repeat, no-repeat;
  }

  &--targetYear-background {
    background: linear-gradient(180.75deg, #e3f2e5 0.64%, #ffffff 135.65%);
    border-radius: 8px;
  }

  &--below-emission {
    background: linear-gradient(180.75deg, #f7eded 0.64%, #ffffff 135.65%);
  }

  &--company-emission {
    background: linear-gradient(180.75deg, #e3f2e5 0.64%, #ffffff 135.65%);
  }

  &--aligned-emission {
    background: linear-gradient(180.75deg, #f1eef8 0.64%, #ffffff 135.65%);
  }

  &--aligned-gap {
    background: linear-gradient(
      180deg,
      #ebfdfd 0%,
      rgba(233, 255, 255, 0.2) 100%,
      rgba(235, 253, 253, 0.2) 100%
    );
  }

  &--reportingDisclosure {
    background: linear-gradient(180.75deg, #eef5f8 0.64%, #f3fafc 135.65%);
  }
  &--reportingDisclosure-tableview {
    @media screen and (max-width: 1600px) {
      grid-column-end: span 2 !important;
    }
  }

  &--climate-strategy {
    background: linear-gradient(
      180.75deg,
      #eef5f8 0.64%,
      rgba(238, 245, 248, 0) 135.65%
    );
  }

  &--climate-reportingBoundary {
    background: linear-gradient(
      180.75deg,
      #eef5f8 0.64%,
      rgba(238, 245, 248, 0) 135.65%
    );
  }

  &--targetEmissionsYear-background {
    background-image: url("../shared/icon/icons/wave-3.svg");
    background-position: 100% 60%, 0% 0%;
    background-repeat: no-repeat, no-repeat;
    border-radius: 8px;
  }

  &--blue {
    background-color: #eef5f8;
  }
  &--green {
    background: #e3f2e5;
    background-image: url("../shared/icon/icons/wave-4.svg");
    background-position: 100% 0, 0 100%;
    background-repeat: no-repeat, no-repeat;
  }

  &--border-dashed {
    border: 1px dashed #75cdee;
  }

  &--wave {
    background: rgba(241, 248, 251, 1);
    background-image: url("../shared/icon/icons/wave.svg"),
      url("../shared/icon/icons/wave-2.svg");
    background-position: 100% 0, 0 100%;
    background-repeat: no-repeat, no-repeat;
  }

  &--top-lighting {
    background-image: url("../shared/icon/icons/bottom-ray.svg");
    background-position: 71% 0;
    background-repeat: no-repeat;
  }

  &--double-lighting {
    background-image: url("../shared/icon/icons/lighting-3.svg"),
      url("../shared/icon/icons/lighting-2.svg");
    background-position: 15.5% 0, 74.9% 100%;
    background-repeat: no-repeat, no-repeat;
  }

  &--water-droplet-bg {
    background-image: url("../shared/icon/icons/water-droplet-bg.svg");
    background-position: 0.5% 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &--directIndirectCost {
    background-image: url("../shared/icon/icons/polution.svg");
    background-position: 0 102%;
    background-repeat: no-repeat, no-repeat;
  }

  &--no-padding {
    padding: 0;
  }

  &__loader {
    flex: 1;
    background-color: $white;
    animation: light_shine 1000ms infinite ease-in-out;
  }

  &--risk-waves {
    background-image: url("../shared/icon/icons/wavesLeft5.svg"),
      url("../shared/icon/icons/wavesTop5.svg"),
      linear-gradient(181deg, #eef5f8 0.64%, rgba(238, 245, 248, 0) 135.65%);
    background-position: 0% 90%, 95% 0%, 100% 100%;
    background-repeat: no-repeat, no-repeat;
  }

  // ---------------------------------------------------------- Start header component ----------------------------------------------------------
  &__header {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;

    &--targets {
      position: absolute;
      z-index: 100;
      top: 1rem;
      right: 2rem;
    }

    &__sub-text {
      font-size: 0.75rem;
      padding: 0.5rem 1.5rem;
      color: $grey;
    }

    &__title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      font-size: 1rem;
      &-upstream {
        white-space: pre-wrap;
        text-align: initial;
      }
      &-cost {
        white-space: normal;
        text-align: left;
      }
      &-target {
        display: none;
      }
      &--align-left {
        font-weight: 600;
        font-size: 1rem;
        text-align: left;
      }

      &--wrap {
        overflow: unset;
        text-align: left;
        white-space: normal;
      }

      padding-bottom: 0.1rem;
    }
  }

  &__title-container {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__title-tile {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__tile-actions {
    display: flex;
  }

  &__actions {
    display: flex;
    position: relative;
    column-gap: 0.3rem;
    background: $dark-grey;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem 0 0 1rem;
    position: absolute;
    right: -1rem;
    top: -0;
  }

  &__action {
    cursor: pointer;
    &:hover {
      background-color: #d9d7d7;
      transition: 0.7s;
      border-radius: 4px;

      path {
        fill: $black;
      }
    }

    &--white path {
      fill: $white;
    }
  }

  &__hidden-menu {
    position: relative;
    .insights-options {
      display: none;
    }

    &:hover {
      .insights-options {
        display: flex;
      }
      .insights-icon {
        visibility: hidden;
      }
      background-color: #d9d7d7;
      transition: 0.7s;
      border-radius: 4px;
    }
    &--show {
      .insights-options {
        display: flex;
      }
      .insights-icon {
        visibility: hidden;
      }
    }
  }

  &__filterInsight {
    display: none;
    right: -1rem;
    position: absolute;
    background-color: $white;
    padding: 0.5rem;
    flex-wrap: wrap;
    width: 19rem;
    z-index: 99;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.16);
    border-radius: 8px;

    &--show {
      display: flex;
    }
  }

  &__filters {
    width: inherit;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      min-width: 16rem;
      max-height: fit-content;

      h1 {
        font-size: 1rem;
        font-weight: 600;
        margin: 0;
      }
    }
    &__container {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }
    &__close {
      @include invisible-button;
      justify-content: flex-end;
    }
    &__filter-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    &__drop-down {
      margin-top: 1rem;
      position: relative;
      padding: 0.5rem 0rem 1rem 0rem;

      &:first-child {
        margin-top: 0;
      }
    }
    &__options {
      display: flex;
      justify-content: flex-end;
      gap: 2rem;
      width: 18rem;
    }
    &__reset {
      @include invisible-button;
      color: $primary;
      font-size: 14px;
      line-height: 1.5rem;

      &-disabled {
        @include invisible-button;
        color: #75787b;
        cursor: pointer;
        path {
          fill: #75787b;
        }
      }
    }

    &__apply {
      @include primary-button;
      padding: 0.375rem 1.5rem;
      font-size: 14px;
      line-height: 1.5rem;

      &-disabled {
        @include primary-button-disabled;
        padding: 0.375rem 1.5rem;
        font-size: 14px;
        line-height: 1.5rem;
      }
    }
  }

  // ---------------------------------------------------------- Start footer component ----------------------------------------------------------
  &__footer {
    display: flex;
    gap: 0.25rem;
    justify-content: flex-end;

    &--target {
      //position: absolute;
      right: 1.75rem;
      bottom: 2rem;

      &--tableView {
        bottom: 1.5rem;
      }
    }

    &--space-between {
      justify-content: space-between;
      align-items: center;
    }
  }

  // ---------------------------------------------------------- Start content ----------------------------------------------------------
  &__content {
    flex-grow: 1;
    position: relative;
    padding: 1rem 0;
    &--no-padding {
      padding: 0;
    }
    &--no-padding-up {
      padding-top: 0;
    }
  }
  &__dataset {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    &-info {
      padding: 0.5rem 2rem;
      font-size: 12px;
      color: $grey;
    }
  }

  &__data {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    padding: 0.3rem 1rem;
    box-sizing: border-box;
    width: 50%;

    &--group {
      width: 100%;
    }

    &--custom {
      width: auto;
    }

    &--centered {
      margin: 0 auto;
    }

    &--value > span:first-child {
      max-width: max-content !important;
    }

    &--color {
      width: 0.65rem;
      height: 0.65rem;
      border-radius: 0.65rem;
      margin-right: 0.5rem;
    }

    &--line-chart-color {
      @extend .customLegendMarker;
      border-color: #227786;
    }

    &--bubble-chart-color {
      @extend .customLegendMarker;
      border-color: #42c2ff;
    }

    &--value {
      display: flex;
      flex-grow: 1;
      column-gap: 0.75rem;

      > span:first-child {
        min-width: 2rem;
        max-width: 5rem;
      }
    }
  }
  .has-tooltip {
    position: relative;
    .tooltip {
      top: -1.5rem;
      transform: translateX(-45%);
      display: none;
    }

    &:hover .tooltip {
      display: block;
    }
  }

  // ---------------------------------------------------------- Start empty content ----------------------------------------------------------

  &-empty {
    &__container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0;
    }
    &__label {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.785rem;
      font-weight: bold;
    }
  }

  &__ghgEmissionPieChart {
    display: flex;
    gap: 1rem;
    align-items: center;

    &-container {
      width: 45%;

      &-category {
        display: flex;
        justify-content: space-between;
        margin: 0.5rem;
      }

      &-detail {
        display: flex;
        gap: 0.1rem;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: #757575;
      }

      &-value {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
  &__ghgEmissionPieChartPeer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 2rem;

    &-legendDot {
      width: 8px;
    }

    &-peerChart {
      display: flex;
      flex-grow: 1;
      width: 6.5rem;
      padding: 2rem 0;
    }

    &-noData {
      font-weight: 600;
    }

    &-groupName {
      font-size: 16px;
      font-weight: 600;
      margin-left: 1rem;
    }

    &-chartContainer {
      display: flex;
      align-items: center;
    }

    &-container {
      width: 100%;

      &-category {
        display: flex;
        text-align: left;
        align-items: center;
        margin: 0.5rem;
      }

      &-metricWrapper {
        text-align: left;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: #757575;
        width: 12.8rem;
        margin-left: 0.3rem;
      }

      &-metricName {
        width: fit-content;
        text-align: left;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: #757575;
      }

      &-detail {
        display: flex;
        text-align: left;
        gap: 0.1rem;
        align-items: center;
      }

      &-value {
        font-size: 12px;
        font-weight: 600;
        text-align: left;
      }
    }
  }
  &__ghgEmissionDetailPeer {
    margin-bottom: 3rem;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;

    &-metricWrapper {
      text-align: left;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #757575;
    }

    &-detailPeer {
      width: 10rem;
      padding: 1rem 2rem 0 2rem;
      margin: auto;
    }

    &-groupName {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }
    &-noDataMessage {
      font-size: 12px;
      font-weight: 400;
      margin: 0.5rem;
    }
    &-noData {
      height: 100%;
      min-height: 12rem;
      width: 11rem;
      align-items: center;
      display: flex;
      margin: auto;
      font-size: 16px;
      font-weight: 600;
    }

    &-legend {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem;

      &-container {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-right: 1rem;
      }
      &-dot {
        margin-right: 0.5rem;
      }
    }
  }
}
