.ReportModal {
  width: 100%;
  height: 100%;

  &__continue-message {
    width: 100%;
    @include font-family;
  }

  &__options {
    padding-top: 1rem;
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: flex-end;
  }
}
