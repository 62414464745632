
.cookie-notice{
    &__content{
        margin-left: 50px;
        overflow: auto;
        height: calc(100vh - 100px);
        padding-bottom: 2rem;
    }

    &__link {
        background-color: transparent;
        color: #00a3e0;
        text-decoration: none;
        &:active, &:focus, &:hover, &:visited {
            text-decoration: none;
            outline: 0;
        }
    }
    
    &__cookiesetting-btn {
        background: none;
        border: none;
        cursor: pointer;
        border-radius: 0;
        color: #00a3e0;
        font-size: 20px;
        border: #fff;
        padding: 0px;
        margin-bottom:  1.5rem;
        margin-top: 2rem;
    }

    &__title {
        margin: 25px 0px 10px 0px;
    }
    
    &__revision {
        font-weight: 600;
        padding-bottom: 1rem;
    }
    
    &__bulletpoint {
        &-element{
            list-style: inside;
            font-size: 16px;
            margin-bottom: 12px;
            line-height: 24px;
        }
    }

    &__subheader-3{
        font-weight: 700;
        font-size: 1.2rem;
        display: flex;
        justify-content: flex-start;
        margin: 1.2rem 0;
    }
    
    &__parragraph{
        font-size: 16px;
    
        &--single-spacing{
            padding-bottom: 0.75rem;
        }
        &--double-spacing{
            padding-bottom: 1.4rem;
        }
        
    }

}



