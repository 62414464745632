.TagNotification {
  max-width: 35rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__message {
  }

  &__actions {
    display: flex;
    gap: 1rem;
    justify-content: end;
  } 
}
