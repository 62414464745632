.reporting {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  font-size: 0.875rem;

  &--horizontal {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    &--horizontal {
      width: 47%;
    }
  }

  &__section-name {
    font-size: 0.75rem;
    font-weight: 600;
    position: relative;

    &-container {
      width: fit-content;
    }

    .tooltip {
      top: 100%;
      display: none;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }

  &__company-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  &__company-name {
    width: 100%;
    font-size: 0.75rem;
  }

  &__section-data {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &--1 {
      display: grid;
      grid-template-columns: 31% 31% 31%;
    }
  }

  &__metric-wrapper {
    width: 100%;
    flex: 1 1;
    min-height: 3rem;
    max-width: 6.75rem;
    background: linear-gradient(
      180deg,
      $white 0%,
      rgba(238, 245, 248, 0.3) 100%
    );
    border: 1px solid #e2eff4;
    border-radius: 1rem;
    position: relative;
    color: $dark-grey;
    display: flex;
    justify-content: center;
    align-items: center;

    &-lbl {
      color: $dark-grey;
      font-size: 0.75rem;
    }

    .tooltip {
      top: 100%;
      display: none;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }

    &--0,
    &--2,
    &--3 {
      max-width: unset;
      font-weight: 600;
      padding: 0.75rem;
      font-size: 1.25rem;
    }
    &--0,
    &--3 {
      color: #012169;
    }
    &--0 > div:first-child {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 1px;
      flex-grow: 1;
      padding: 0 1rem;
      line-height: 1.5rem;
    }
    &--2 {
      color: #00a3e0;
    }
    &--1 {
      min-height: 4.1rem;
      min-width: 6rem;
      max-width: unset;
      font-size: 0.75rem;
    }

    &--yes {
      background: linear-gradient(180deg, #e7f5f0 0%, #f1faf7 100%);
      border: 1px solid #6fc2b4;
      > div {
        font-weight: 600;
        color: #0d8390;
      }

      &-lbl {
        font-weight: 600;
        font-size: 0.75rem;
        color: #0d8390;
      }
    }

    &--neutral {
      border: 1px solid $grey;
      > div {
        font-weight: 600;
        align-self: start;
      }
    }
  }

  &__metric {
    padding: 0.875rem 0.5rem;
    text-align: center;
    line-height: 1rem;
    &--no-padding {
      padding: 0;
    }
  }

  &__neutral-label {
    font-size: 0.6rem;
    position: absolute;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    font-weight: normal;
    color: $dark-grey;
  }

  &__no-data {
    text-align: center;
    font-weight: bold;
    padding: 1.5rem 0.5rem;
  }

  &__no-data-footnote {
    font-size: 0.75rem;
    width: 100%;
  }

  &-table {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1rem;

    &__section {
      display: flex;
      gap: 1rem;
      flex-direction: column;
    }

    &__company,
    &__data {
      font-size: 0.75rem !important;
    }

    &__section-name {
      padding: 0 0.5rem;
      font-size: 0.75rem;
      width: fit-content;
      font-weight: 600;
      &-lbl {
        font-size: 0.75rem;
        font-weight: 600;
      }
    }

    &__data {
      width: 100%;
      td {
        padding: 1rem 0.5rem;
        font-size: 0.875rem;
        border-top: 1px dashed $light-grey;
        position: relative;
        &:last-child {
          width: 60%;
          font-weight: 600;
        }
      }

      tr:last-child td {
        border-bottom: 1px dashed $light-grey;
      }

      &-value {
        .tooltip {
          top: unset;
          bottom: 0;
          display: none;
          transform: translateY(40%);
        }

        &:hover .tooltip {
          display: block;
        }
      }
    }
  }
}
