.cdp-benchmark-grid {
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  padding: 0 2rem;
  width: 100%;

  &-grid-header {
    display: flex;
    justify-content: space-between;
  }

  &-expand-collapse {
    display: flex;
  }

  &-category-row {
    display: flex;
    position: sticky;
    left: 0rem;
    justify-content: space-between;
    padding-right: 0.5rem;
  }

  &-categoryName {
    margin-left: 0.2rem;
  }

  &-categoryBox {
    background: #ffffff;
    border-radius: 4px;
    color: #007cb0;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &-category-expand-button {
    border: none;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(
      180.75deg,
      #eef5f8 0.64%,
      rgba(238, 245, 248, 0) 135.65%
    );
    &:hover {
      cursor: pointer;
    }
    & svg path {
      fill: #000000;
    }
  }

  &__table {
    box-sizing: border-box;
    height: fit-content;
    border-spacing: 0;
    border-collapse: collapse;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;

    &--container {
      flex-grow: 1;
      display: flex;
      border: 1px solid #e0e0e0;
      box-shadow: 0px 4px 8px rgba(238, 240, 241, 0.48);
      box-sizing: border-box;
      border-radius: 8px;
      width: 100%;
      overflow: auto;
    }

    &--body {
      width: 100%;
      height: fit-content;
      overflow-x: auto;

      &--normal {
        & tr:first-child {
          z-index: 31;
          border-bottom: none;
        }
      }
    }

    &--row {
      width: 100%;
    }

    &--heading {
      top: 0px;
      background: white;
      flex-grow: 1;
    }

    thead,
    th {
      position: sticky;
      z-index: 32;
      top: 0;
    }

    th:last-child {
      outline: none;
    }

    th:nth-child(1) {
      position: sticky;
      z-index: 33;
      left: 0;
      box-sizing: border-box;
    }

    th {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 1rem;
      align-items: center;
      color: $black;
      padding: 0.75rem 2rem 0.75rem 2rem;
      box-shadow: inset -1px -1px 0px $darker-grey;
      background: $white;

      &:last-child {
        box-shadow: none;
      }
    }

    th:not(:nth-child(1)) {
      white-space: nowrap;
      box-sizing: border-box;
    }

    tbody td:nth-child(1) {
      position: sticky;
      z-index: 5;
      left: 0;
      padding-left: 0.5rem;
    }

    .single-item {
      margin: 0 auto;
      justify-content: center;

      span {
        font-size: 0.875rem;
        padding: 0.25rem 0.5rem;
      }
    }

    &--transpose {
      & thead {
        z-index: 50;
      }

      & th:nth-child(1) {
        min-width: 19rem;
      }

      & th:nth-child(2) {
        position: sticky;
        z-index: 20;
        left: 19rem;
      }

      & tbody td:nth-child(1) {
        z-index: 30;
      }

      & th:not(:nth-child(1)) > &__company-rows {
        align-items: center;
      }
    }

    &--header {
      background: #ffffff;
      box-sizing: border-box;

      &-metrics {
        height: 100%;
        margin-top: 0.2rem;
      }
      &-remove-button {
        @include invisible-button;
        visibility: hidden;
        position: absolute;
        left: -1.25rem;
        &:hover {
          background-color: $light-grey;
          border-radius: 4px;
        }
      }

      &--content {
        &:hover {
          .cdp-benchmark-grid__rowItem--remove-button {
            visibility: visible;
          }
        }

        &-wrapper {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &--content--container {
        align-items: center;
        display: flex;
        height: 100%;
      }
    }
  }

  &__rowItem {
    &--company--items--name {
      display: flex;
    }
    &--company--items--icon {
      margin-left: 0.2rem;
    }
  }

  &__rowItem-companyName {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
  }

  &__rowItem--remove-button {
    @include invisible-button;
    visibility: hidden;
    left: -1.25rem;
    position: absolute;
    &:hover {
      background-color: $light-grey;
      border-radius: 4px;
    }
  }

  &__drag-header {
    position: relative;
    padding-right: 1rem;
    .hide-drag-icon {
      display: none;
    }
    &__icon {
      position: absolute;
      right: -1rem;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      path {
        fill: $dark-grey;
      }
    }
  }

  &__drag-th {
    &:hover {
      .hide-drag-icon {
        display: block;
      }
    }
  }

  &__col-resize-handler {
    user-select: none;
    top: 0;
    right: 0;
    position: absolute;
    width: 0.25rem;
    height: calc(#{$peer-benchmark-grid-height} - 2vh);
    z-index: 32;
    @media screen and (max-width: 1400px) {
      height: calc(#{$peer-benchmark-grid-height-sm} - 2vh);
    }
    &:hover {
      cursor: col-resize;
    }
  }

  &__base-company {
    position: absolute;
    right: -1.25rem;
    top: 0;
  }

  &__rowItem {
    position: relative;
    height: fit-content;
    box-sizing: border-box;
    width: 100%;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    color: #212121;

    &--tdMetric {
      background: #fafafa !important;
      &:hover {
        .cdp-benchmark-grid__rowItem--transpose-remove-button {
          visibility: visible;
        }
      }
    }

    &-multiple {
      padding: 0 !important;
    }

    &-multipleItem {
      display: block;
    }

    &-firstmultipleItem {
      border-top: 1px solid #e0e0e0;
      padding: 0;
    }

    &--count {
      text-align: right;
      padding-right: 1rem !important;
    }

    &--metricCategory {
      box-shadow: inset 0px -1px 0px $darker-grey !important;
      background: linear-gradient(
        180.75deg,
        #eef5f8 0.64%,
        rgba(238, 245, 248, 0) 135.65%
      );
    }

    td {
      padding: 0.2rem;
      box-shadow: inset -1px -1px 0px $darker-grey;
      vertical-align: top;
    }

    &--box {
      display: flex;
      grid-gap: 0.2rem;
      gap: 0.2rem;
      align-items: center;

      &--value {
        position: relative;
      }
    }

    &--remove-button {
      @include invisible-button;
      visibility: hidden;
      &:hover {
        background-color: $light-grey;
        border-radius: 4px;
      }
    }

    &--icon {
      background: transparent;
      position: relative;

      .tooltip {
        display: none;
        left: 100%;
      }

      &:hover {
        .tooltip {
          display: block;
        }
      }
    }

    &--center {
      margin: 0 auto;
    }

    &--company {
      box-shadow: inset -1px 0px 0px #ebebeb;

      &:hover {
        .cdp-benchmark-grid__rowItem--remove-button {
          visibility: visible;
        }
      }

      &--items {
        display: flex;
        flex-direction: column;

        &--name {
          max-width: 100%;
          display: flex;
        }
        &--date {
          position: relative;
          font-style: italic;
          font-size: 0.75rem;
        }

        &--icon {
          padding-left: 1rem;
        }
      }
    }

    &--transpose {
      &:nth-child(1) {
        padding-left: 0.7rem !important;
      }

      &-remove-button {
        @include invisible-button;
        visibility: hidden;
        &:hover {
          background-color: $light-grey;
          border-radius: 4px;
        }
      }

      &:hover {
        .cdp-benchmark-grid__rowItem--transpose-remove-button {
          visibility: visible;
        }
      }

      &-metric-title {
        display: flex;
        gap: 0.2rem;
        align-items: center;
      }
    }
  }

  &__rowMetric {
    display: flex;
    align-items: flex-start;
    height: 100%;
  }
}
