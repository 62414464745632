.session-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 20rem;

  &__countdown {
    font-weight: 400;
    font-size: 2rem;
    padding: 0.5rem 0;
  }

  &__options {
    padding-top: 1rem;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  &__continue {
    margin-left: 1rem;
  }
}
