.talking-points-accordion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 1rem;

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    &-header {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    &-button {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      border: none;
      background-color: #ffffff;
      gap: 0.5rem;
      padding: 0.75rem 1rem;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      cursor: pointer;
      &:hover {
        background-color: $lightest-blue;
      }
      & svg path {
        fill: #000000;
      }

      &--short {
        width: auto;
        padding: 0.75rem 0;
        & div {
          display: flex;
        }
        & svg path {
          fill: #000000 !important;
        }
      }
    }
    &-collapse {
      &--hide {
        display: none;
      }
    }
    &-body {
      padding: 0.25rem 0.5rem;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      & svg path {
        fill: #007cb0;
      }
    }

    &__subcategories-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-category {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0.5rem;

      &-sub {
        justify-content: flex-start;
      }
    }

    &-factors {
      display: flex;
      flex-direction: column;
      margin-left: 1.5rem;
      //margin-left: 1.25rem;
      margin-bottom: 1rem;
      &--hide {
        display: none;
      }
      &--wide {
        margin-left: 1.5rem;
        width: 90%;
        margin-bottom: 0;
        @media (min-width: 1380px) {
          width: 44%;
          margin-bottom: 1rem;
        }
      }
      &--shadow {
        box-shadow: none;
        padding-left: 0;
        margin-bottom: 1rem;
        margin-left: 1.5rem;
        @media (min-width: 1380px) {
          box-shadow: inset 1px 0px 0px #e0e0e0;
          padding-left: 1.75rem;
        }
      }
      &-container {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #bbbcbc;
        margin-bottom: 1rem;
      }
    }
    &-checkbox {
      display: flex;
    }

    &-category,
    &-checkbox {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      position: relative;
      & input {
        border-radius: 2px;
        border: 1px solid #d0d0ce;
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        background: #007cb0;
      }

      .checkbox__indeterminate {
        background: #007cb0;
        border: 1px solid #007cb0;

        & div {
          display: flex;
        }
      }

      .checkbox__label {
        position: relative;
      }
      .tooltip {
        display: none;
        z-index: 100;
        top: auto;
        bottom: 1.5rem;
        max-width: 27rem;
      }
      .tooltip__label {
        max-width: 27rem;
      }

      &:hover {
        .tooltip--top {
          display: inline-block;
        }
      }
    }
    &-checkbox {
      width: fit-content;
    }
    &-category {
      &--sub {
        height: 1.5rem;
        margin-bottom: 1rem;
        .checkbox__indeterminate {
          left: 1.5rem;
          top: auto;
          left: 0.7rem;
        }
      }
    }
  }

  &__not-results {
    color: #f54242;
    display: flex;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-bottom: 1rem;
  }
}
