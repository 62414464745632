.slider-component {
  &__slides {
    display: flex;
    align-items: center;
    margin: 0 1rem;
  }

  &__slide {
    width: 0px;
    transform: scale(0.5);
    overflow: hidden;
    transition: all 100ms ease-in-out;

    img {
      width: 100%;
      max-height: 64vh;
    }

    &--active {
      width: auto;
      transform: scale(1);
      margin: 0 auto;
    }
  }

  &__title {
    background-color: $black;
    color: $white;
    text-align: center;
    padding: 0.5rem;
    font-weight: 500;
    margin: 0 1rem;
  }

  &__controls {
    text-align: center;
    padding: 0.75rem 0;
  }

  &__control {
    @include invisible-button;
    min-width: 0.5rem;
    min-height: 0.5rem;
    border-radius: 1rem;
    margin-right: 0.5rem;
    background-color: $light-grey;

    &--active {
      background-color: $black;
    }
  }

  &__next-prev {
    padding-top: 1rem;
    border-top: 1px solid $light-grey;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;
  }

  &__button {
    margin: 0 1rem;
  }

  &__button + &__button {
    margin: 0 1rem 0 auto;
  }

  &__next {
    @include primary-button;
  }

  &__previous {
    @include primary-button-outline;
  }

  &__do-not-show-checkbox {
    margin: 1rem;
    display: flex;
    align-items: center;
  }
}
