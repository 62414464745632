.notificationESG {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 1rem;
  padding: 1rem;
  opacity: 0;
  transition: all 200ms ease-in-out;
  z-index: -1;
  height:fit-content;

  &--show {
    opacity: 1;
    z-index: 9999;
  }

  &__content {
    width: 25rem;
    min-height: 9.5rem;
    background-color: $white;
    border-radius: 0.5rem;
    transform: translateY(-50%);
    transition: all 200ms ease-in-out;
    height: fit-content;
    margin-bottom: 1rem;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.12);

    &--show {
      transform: translateY(0);
    }
  }

  &__header {
    padding: 1rem;
    display: flex;
    max-height: fit-content;

    h1 {
      padding-left: .5rem;
      font-size: 1rem;
      font-weight: 600;
      margin: 0;
      font-family: Open Sans;
      font-style: normal;
      line-height: 1.5rem;
    }
    div{
      transform: translateY(.2rem);
    }
  }

  &__close {
    @include invisible-button;
  }

  &__body {
    border-top: 1px solid $lightest-grey;
    height: fit-content;
  }
}
