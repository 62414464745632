.ShareShortlist {
  width: 40rem;
  display: flex;
  flex-direction: column;

  &__message {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  &__messageNote {
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }

  &__checkbox {
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    width: 4.2rem;
    justify-content: space-between;
    input {
      border-radius: 2px;
      border: 1px solid $light-grey;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }
    &__wrapper {
      display: flex;
      margin-top: 0.5rem;
    }
  }
  &__type {
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-top: 0.5rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
  }
  &__sharetype {
    display: flex;
    position: relative;
    padding-left: 2rem;
    cursor: pointer;
    margin-left: 1rem;
    &:first-of-type {
      margin-left: 0;
    }
    input {
      position: absolute;
      opacity: 0;
      margin: 0;
      left: 0.2rem;
      top: 0.2rem;
      cursor: pointer;
      height: 1rem;
      width: 1rem;
      z-index: 2;
    }
    &:hover input ~ .ShareShortlist__checkmark {
      background-color: #ccc;
    }
    input:checked ~ .ShareShortlist__checkmark {
      background-color: #007cb0;
    }
    input:checked ~ .ShareShortlist__checkmark::after {
      display: block;
    }
    .ShareShortlist__checkmark::after {
      position: absolute;
      width: 0.5rem;
      height: 0.5rem;
      left: 0.25rem;
      top: 0.25rem;
      background: #ffffff;
      border-radius: 50%;
    }
  }
  &__checkmark {
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;
    height: 1rem;
    width: 1rem;
    background: #ffffff;
    border: 1px solid #c2c2c2;
    border-radius: 50%;
    &::after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  &__users-wrapper {
    overflow-y: auto;
    min-height: 11rem;
    max-height: 16rem;
    scroll-behavior: smooth;
  }

  &__users {
    display: flex;
    height: 2rem;
    width: 99%;
    padding-top: 1rem;
    align-items: center;
    justify-content: space-between;
    &__text {
      padding-left: 0.5rem;
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      color: #000000;
    }
  }
  &__names {
    display: flex;
  }

  &__dropdown-label {
    font-size: 0.875rem;
    line-height: 1.5rem;
    padding-top: 1rem;
    color: $black;
    text-align: left;
  }

  &__input {
    width: 100%;
    height: auto;
    padding-top: 0.5rem;
    outline: none;

    & input {
      width: 100%;
      height: fit-content;
      padding: 0.6rem;
      color: #212121;
      border: 1px solid #d0d0ce;
      box-sizing: border-box;
      border-radius: 4px;
      outline: none;
    }
  }

  &__options {
    padding-top: 2rem;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
  }

  &__share {
    margin-left: 1rem;
  }

  &__selected-users-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding-top: 0.5rem;
  }

  &__selected-users-container {
    font-size: 0.75rem;
    line-height: 1.25rem;
    background-color: #f0f0f0;
    border-radius: 6.875rem;
    padding: 0.25rem 0.35rem 0.25rem 0.75rem;
    display: flex;
  }

  &__selected-users-remove {
    background-color: #757878;
    border-radius: 0.625rem;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;

    & button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      padding: 0;
    }
  }
}
