.confirmation-modal{
    width: 100%;
    &__options{
        display: flex;
        margin-top: 1rem;
        justify-content: end;
        column-gap: 1rem;
    }

    &__content-wrapper{
        display: flex;
    }
}