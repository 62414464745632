.modalESG {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba($black, 0.5);
  opacity: 0;
  transition: all 200ms ease-in-out;
  z-index: -1;
  overflow-y: auto;
  overflow-x: hidden;

  &--show {
    opacity: 1;
    z-index: 9999;
  }

  &__content {
    max-width: 80%;
    min-width: fit-content;
    max-height: 80%;
    border-radius: 0.5rem;
    transform: translateY(-50%);
    transition: all 200ms ease-in-out;
    max-height: fit-content;
    margin-bottom: 1rem;

    &--showBackground {
      background-color: $white;
    }

    &--show {
      transform: none;
    }

    &--fullscreen {
      width: 100%;
      max-height: 90vh;
      overflow: auto;
    }

  }

  &__header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    max-height: fit-content;

    &--information {
      position: relative;
      &:hover {
        .tooltip {
          display: inline-block;
        }
        cursor: pointer;
      }
      .tooltip {
        @include white-tooltip;
        left: -5rem;
        top: 1.5rem;
        bottom: 0rem;
        position: absolute;
      }
    }

    h1 {
      font-size: 1rem;
      font-weight: 600;
      margin: 0;
      display: flex;
    }
  }

  &__close {
    @include invisible-button;
    &:hover {
      background-color: $light-grey;
      border-radius: 4px;
    }
  }

  &__body {
    max-height: fit-content;
    border-top: 1px solid $lightest-grey;
    box-sizing: border-box;
    padding: 1rem 1rem;

    &--hideBorder {
      border-top: none;
    }
  }

  //modal sizes
  &__m{
    &-sm{
      width: 30%;
      min-width: unset;
      max-width: unset;
    }
    &-lg{
      width: 50%;
      min-width: unset;
      max-width: unset;
    }
    &-xlg{
      width: 75%;
      min-width: unset;
      max-width: unset;
    }
  }
}
