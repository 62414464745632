.insights {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: none;
  margin-top: 3rem;

  &__add {
    margin-right: 0.5rem;
    justify-content: center;
    & svg {
      width: 15px;
      height: 15px;
    }
    & svg path {
      fill: white;
    }
  }
  &__header {
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
  }
  &__Mainheader {
    display: flex;
  }

  &__typeHeader {
    display: flex;
    justify-content: flex-start;
    margin-left: 2rem;
  }

  &__type {
    margin-right: 0.3rem;
    margin-top: 0.1rem;
    padding: 0.35rem;
    border: 1px solid #007cb0;
    color: #007cb0;
    border-radius: 6.8rem;
    font-size: 0.75rem;
    cursor: pointer;
    &--selected {
      color: #ffffff;
      background: #007cb0;
    }
  }

  &__create-new {
    &__contentError {
      font-size: 14px;
      color: #f54242;
    }

    &__header {
      width: 100%;
      min-height: 3rem;
      height: fit-content;
      left: 0rem;
      top: 0rem;
      box-shadow: inset 0px -1px 0px #e7e7e7;
      padding-bottom: 5rem;

      &__content {
        position: absolute;
        width: 20rem;
        min-height: 2rem;
        height: fit-content;
        left: 1rem;
        top: 0.5rem;
        font-size: 16px;
        font-weight: 400;
        box-sizing: border-box;
      }

      &__cross {
        position: absolute;
        right: 1rem;
        top: 0.5rem;
        cursor: pointer;
      }
    }

    &__dropdown {
      padding: 0.5rem 0rem 0.5rem 0.1rem;
    }

    &__peerCompanies-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 0.5rem;
    }
    &__peerCompanies {
      background-color: $lightest-grey;
      padding: 0.25rem 0.25rem 0.25rem 0.5rem;
      border-radius: 1rem;
      margin-right: 0.5rem;
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
    }

    &__suggested-peers {
      @include invisible-button;
      margin-left: 0.3rem;
      display: flex;
      align-items: center;
      color: $primary;
      font-family: "Open Sans";
      column-gap: 0.25rem;
      &:hover {
        color: #005478;
        background-color: $light-grey;
        transition: 0.7s;
        border-radius: 4px;
      }

      &--disabled {
        color: $dark-grey;
        & svg path {
          fill: $dark-grey;
        }
        .icon {
          color: $dark-grey;
        }
        &:hover {
          background-color: transparent;
          color: $dark-grey;
        }
      }
    }
    &__suggested-peers-modal {
      width: 100%;
    }

    &__column-company {
      &--name {
        width: 18rem;
      }
      &--ticker {
        width: 3rem;
      }
      &--auditor {
        width: 5rem;
      }
      &--metrics {
        width: 2rem;
      }
      &--source {
        width: 6.1rem;
      }
    }

    &__options {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
      gap: 0.5rem;
    }

    &__continue {
      margin-left: 1rem;
    }

    &__remove {
      @include invisible-button;
      background-color: #a3a3a3;
      border-radius: 1rem;
      margin-left: 0.25rem;
      path {
        fill: $white;
      }
      &:hover {
        background-color: $grey;
      }
    }
    &__dropdown-label {
      font-size: 14px;
      font-weight: 600;

      &-additionalText {
        font-weight: 500;
        font-size: 0.83rem;
        color: #75787b;
      }

      &-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
    &__non-sec-btn {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      column-gap: 0.25rem;
      color: $primary;
      &--disabled {
        color: $dark-grey;
        & svg path {
          fill: $dark-grey;
        }
      }
    }

    &__confirmation {
      &-header {
        @include fz-16--600;
        display: flex;
        column-gap: 0.5rem;
      }
      &-content {
        @include fz-14--400;
      }
    }

    &__placeholder {
      font-size: 14px;
    }

    &__body {
      box-sizing: border-box;
      width: 100%;
      background: #ffffff;
      overflow-x: hidden;
      height: calc(100% - 4.5rem);
      font-size: 14px;
      padding: 0 1rem;
    }

    &__exportbody {
      box-sizing: border-box;
      width: 100%;
      padding: 0 1rem;
      font-size: 14px;
      overflow-x: hidden;
      height: calc(100% - 4.5rem);
    }

    &__dashboard-name {
      &--label {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
      }

      &--export {
        &-header {
          padding-top: 1rem;
          padding-bottom: 0.5rem;
          color: #757575;
          font-weight: 700;
          font-size: 0.75rem;
        }

        &-subheader {
          color: #000000;
        }

        &-option {
          &-disabled {
            width: 7rem;
            height: 5rem;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 33.333333%;
            margin: 0.5rem;
            border: 1px solid #e0e0e0;
            cursor: pointer;
            &:hover {
              background-color: #f0f0f0;
              transition: 0.7s;
              border-radius: 4px;
            }
          }

          &-selected {
            width: 7rem;
            height: 5rem;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 33.333333%;
            margin: 0.5rem;
            border: 1px solid #007cb0;
          }
        }
        &--list {
          display: flex;
          margin-top: 0.5rem;
          flex-direction: row;
          flex-wrap: wrap;
        }
        &--footer {
          border-top: 2px solid #ebebeb;
        }
      }

      &--input {
        width: 100%;
        height: auto;
        text-align: center;
        outline: none;
        margin-bottom: 0.25rem;

        & input {
          width: 100%;
          height: fit-content;
          padding: 0.6rem;
          border: 1px solid #d0d0ce;
          box-sizing: border-box;
          border-radius: 4px;
          outline: none;
          resize: none;
          font-size: 0.95rem;
          font-weight: none;
          &:focus,
          &:hover {
            border-color: $primary;
          }
        }
      }
      &--inputDisabled {
        width: 100%;
        height: auto;
        text-align: center;
        outline: none;
        margin-bottom: 0.25rem;
        background-color: $lightest-grey;
        & input {
          width: 100%;
          height: fit-content;
          padding: 0.6rem;
          border: 1px solid #d0d0ce;
          box-sizing: border-box;
          border-radius: 4px;
          outline: none;
          resize: none;
          font-size: 0.95rem;
          font-weight: none;
          &:focus,
          &:hover {
            border-color: #d0d0ce;
          }
        }
      }
    }

    &__disclaimer {
      display: flex;
      flex-direction: column;
      &-text {
        margin-top: 1rem;
        font-size: 14px;
      }
    }

    &__peer-group-empty {
      display: flex;
      padding: 0.75rem;
      font-size: 0.875rem;
      line-height: 1.5rem;
      justify-content: center;
    }
    &__actions {
      column-gap: 1rem;
      justify-content: flex-end;
      &:hover {
        .tooltip {
          display: none;
        }
      }
    }

    &__action-icon {
      @include invisible-button;
      position: relative;
      cursor: pointer;
      .tooltip {
        display: none;
        top: -2rem;
        bottom: unset;
        &--top {
          &__arrow {
            left: 45%;
          }
        }
      }

      &:hover {
        .tooltip {
          display: inline-block;
        }
        background-color: $black;
        border-radius: 4px;
        path {
          fill: $white;
        }
      }
    }
    &__restriction-msg {
      font-size: 14px;
      font-weight: 400;
      color: gray;
      margin-top: 1rem;
    }

    &__export-option {
      &--disabled {
        & svg path {
          fill: gray;
        }
      }

      &--disabled-esrs {
        & svg path {
          fill: gray;
        }
      }

      &--unclickable {
        cursor: default;
        &:hover {
          background-color: unset;
        }
      }

      &--unclickable-esrs {
        cursor: default;
        &:hover {
          background-color: unset;
        }
      }
    }
  }

  &__empty-container {
    display: flex;
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }
  &__empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin-top: 4rem;
  }
  &__empty-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $black;
    font-style: normal;
  }

  &__img {
    width: 249px;
    height: 210px;
    margin-bottom: 2rem;
  }

  &__empty-redirect {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    color: #75787b;

    font-style: normal;
  }
  &__link {
    border: none;
    background-color: transparent;
    padding: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &::before {
      content: "\00a0";
    }
    &::after {
      content: "\00a0";
    }
  }
}
