@mixin font-family {
  font-family: "Open Sans";
}

@mixin invisible-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

@mixin invisible-button-disabled {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  color: $grey;
  font-family: "Open Sans";
}

@mixin blank-link {
  text-decoration: none;
  color: unset;
}

@mixin primary-button {
  padding: 0.5rem 1.5rem;
  background-color: $primary;
  border: none;
  color: $white;
  cursor: pointer;
  border-radius: 0.25rem;
  line-height: 1.5rem;
  font-family: "Open Sans";

  &:hover {
    background-color: $secondary-blue;
  }

  &:active {
    background-color: $dark-blue;
  }
}

@mixin primary-button-disabled {
  @include primary-button;
  padding: 0.5rem 1.5rem;
  background-color: $light-grey;
  color: $grey;
  cursor: auto;
  font-family: "Open Sans";

  &:hover {
    background-color: $light-grey;
  }
}

@mixin primary-button-small {
  @include primary-button;
  padding: 0.125rem 1rem 0.125rem 0.2rem;
  height: 1.75rem;
  width: 6.5625rem;
  line-height: 1rem;
  font-family: "Open Sans";

  &:hover {
    background-color: $secondary-blue;
    color: $white;
  }

  &:active {
    background-color: $dark-blue;
  }
}

@mixin primary-button-small-disabled {
  @include primary-button-small;
  background-color: $light-grey;
  color: $grey;

  &:hover {
    background-color: $light-grey;
    color: $grey;
    cursor: auto;
  }
}

@mixin primary-button-rounded {
  padding: 0.5rem 1rem;
  background-color: $primary;
  border: none;
  color: $white;
  cursor: pointer;
  border-radius: 0.25rem;
  font-family: "Open Sans";
}

@mixin primary-button-outline {
  padding: 0.5rem 1rem;
  background-color: $white;
  border: 1px solid $primary;
  color: $primary;
  cursor: pointer;
  border-radius: 0.25rem;
  font-family: "Open Sans";
}

@mixin secondary-button {
  padding: 0.5rem 1.5rem;
  background-color: $white;
  outline: 1px solid $primary;
  font-family: "Open Sans";
  color: $primary;
  cursor: pointer;
  border-radius: 0.25rem;
  box-sizing: border-box;

  &:hover {
    background-color: $secondary-blue;
    outline: 1px solid $secondary-blue;
    color: $white;

    path {
      fill: $white;
    }
  }

  &:active {
    background-color: $dark-blue;
    outline: 1px solid $dark-blue;
    color: $white;
  }
}

@mixin secondary-button-small {
  @include secondary-button;
  padding: 0.5rem 1rem;
  line-height: 1rem;

  &:hover {
    background-color: $secondary-blue;
    outline: 1px solid $secondary-blue;
    color: $white;
  }
}

@mixin secondary-button-disabled {
  padding: 0.5rem 1.5rem;
  background-color: $white;
  outline: 1px solid $grey;
  color: $grey;
  cursor: pointer;
  border-radius: 0.25rem;
}

@mixin critical-button {
  padding: 0.5rem 1.5rem;
  background-color: $red;
  color: $white;
  cursor: pointer;
  border-radius: 0.25rem;

  &:hover {
    background-color: $red-secondary;
    color: $white;

    path {
      fill: $white;
    }
  }

  &:active {
    background-color: $dark-red;
    color: $white;

    path {
      fill: $white;
    }
  }
}

@mixin critical-button-small {
  @include critical-button;
  padding: 0.5rem 1rem;
  line-height: 1rem;
}

@mixin critical-button-disabled {
  @include primary-button-disabled;
}

@mixin text-button {
  @include invisible-button;
  color: $primary;
  padding: 0.5rem;
  border-radius: 0.25rem;

  &:hover {
    color: $secondary-blue;
    background-color: $lightest-grey;
  }

  &:active {
    background-color: $darker-grey;
  }
}

@mixin text-button-disabled {
  @include text-button;
  color: $dark-grey;
  cursor: auto;

  path {
    fill: $dark-grey;
  }

  &:hover {
    color: $dark-grey;
    background-color: transparent;
  }

  &:active {
    color: $dark-grey;
    background-color: transparent;
  }
}

@mixin icon-text-button {
  @include text-button;
  padding-right: 0.75rem;
  font-family: "Open Sans";
}

@mixin primary-button-outline-disabled {
  padding: 0.5rem 1rem;
  background-color: $white;
  border: 1px solid $grey;
  color: $grey;
  cursor: pointer;
  border-radius: 0.25rem;
}

@mixin primary-button-outline-rounded {
  padding: 0.5rem 1rem;
  background-color: $white;
  border: 1px solid $primary;
  color: $primary;
  cursor: pointer;
  border-radius: 1rem;
}

@mixin primary-button-rounded {
  padding: 0.5rem 1rem;
  background-color: $primary;
  border: 1px solid $primary;
  color: $white;
  cursor: pointer;
  border-radius: 1rem;
}

@mixin loading-icon {
  animation-name: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  margin: auto;
}

@mixin white-tooltip {
  left: 30%;
  top: -30%;
  z-index: 3;
  display: none;

  &__label {
    max-width: 23rem;
    text-align: left;
    background-color: $white;
    color: $black;
    padding: 1rem;
    border: 1px solid $light-grey;
    position: fixed;
  }

  &__arrow {
    display: none;
  }
}

@mixin grid-dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
  column-gap: 1rem;
  padding: 0 2rem 1.5rem 2rem;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  }
}

@mixin fz-12--400 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
}

@mixin fz-14--400 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

@mixin fz-16--600 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

@mixin vertical-scroll {
  &::-webkit-scrollbar {
    width: 0.25rem;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 32px;
    background-color: rgba(0, 0, 0, 0.24);
    outline: 1px solid rgba(0, 0, 0, 0.24);
  }
}

@mixin tile-score-range {
  color: $dark-grey;
  font-size: 0.75rem;
  position: absolute;
  top: 0;
}
