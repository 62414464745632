.create-new {
  width: 100%;
  height: 100%;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    background: #ffffff;
    border-bottom: 0.0625rem solid $darker-grey;

    &--title {
      padding-left: 1.5rem;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #212121;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    &--leftSide {
      width: 25%;
      padding: 1.5rem;
      border-bottom: 0.0625rem solid $darker-grey;
      overflow-y: auto;
    }

    &--rightSide {
      background: #ffffff;
      /* Divider / Left 1px */
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-grow: 2;
      overflow-y: auto;
      padding: 1rem;
      border-width: 1px;
      border-style: solid;
      border-color: $darker-grey;
      
      border-radius: 0 8px 8px 8px;
      margin-top: -1px;
      &-wrapper{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 0 1.5rem 1.5rem 1.5rem;
      }
      &-container{
        display: flex;
        
        flex-direction: column;
       width: 75%;
        border-bottom: 0.0625rem solid $darker-grey;
        box-shadow: inset 1px 0px 0px $darker-grey;
        
      }
      &-header{
        display: flex;
        gap: 1rem;
       
      }
    }

    
    
  }

  &__metrics-header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    column-gap: 1.5rem;
  }

  &__metrics-header {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0rem;
    width: calc( (100% - 1.5rem) * 0.5 );

    &--gray{
      color: $dark-grey;
    }

  }

  &__metrics {
    
    &--search {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      justify-content: space-between;
      padding-right: 0.5rem;
    
      &-options {
        display: flex;
        position: relative;
        left: 1.5rem;
        bottom: 0.3rem;
        &-wrapper{
          display: flex;
          justify-content: end;
          align-items: center;
          column-gap: 2rem;
          flex-grow: 1;
        }
      }

      &-options-collapse,
      &-options-expand {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        display: flex;
        align-items: center;
        text-align: center;

        color: #007cb0;

        :hover {
          cursor: pointer;
        }
      }

      &-options-collapse {
        padding-right: 1rem;
      }
    }

    &--searchBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      padding: 0.5rem 0.6rem;
      background-color: $white;
      border: 1px solid $light-grey;
      border-radius: 0.25rem;
      height: 1.5rem;
      &-enabled{
        &:hover,
      &:focus-within {
        border-color: $primary;
      }
      }
      
      flex-grow: 1;
    }
  }

  &__input {
    padding: 0;
    height: 1.5rem;
    overflow: hidden;
    border: none;
    flex-grow: 1;
    width: 1px;
    outline: none;
    z-index: 1;
    resize: none;
    background-color: transparent;
    @include font-family;
    font-size: 0.875rem;
    line-height: 1.5rem;

    &:focus {
      background-color: $white;
    }
  }

  &__dropdown {
    margin-bottom: 1rem;
  }

  &__dropdown-label {
    font-size: 1rem;
    font-weight: 600;
  }

  &__suggested-peers {
    @include invisible-button;
    margin-left: 0.3rem;
    display: flex;
    align-items: center;
    color: $primary;
    column-gap: 0.25rem;
    &:hover {
      color: #005478;
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 4px;
    }
   

    &--disabled {
      & svg path{
        fill: $dark-grey;
      }
      color: $dark-grey;
      .icon {
        color: $dark-grey;
       
      }
      &:hover {
        color: $dark-grey;
        background-color: transparent;
      }
    }
  }

  &__peerCompanies-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__peerCompanies {
    background-color: $lightest-grey;
    padding: 0.25rem 0.25rem 0.25rem 0.5rem;
    border-radius: 1rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
  }
  &__remove {
    @include invisible-button;
    background-color: $grey;
    border-radius: 1rem;
    margin-left: 0.25rem;

    path {
      fill: $white;
    }
  }

  &__suggested-peers-modal {
    width: 100%;
  }

  &__options {
    text-align: right;
    
  
  }

  &__continue {
    margin-left: 1rem;
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: inherit;
  }
  &__options {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    &-save {
      margin-left: 1.5rem;
    }

    &-container{
      display: flex;
      padding: 1.25rem ;
      justify-content: space-between;
     
    }
  }

  &__peer-group-empty {
    display: flex;
    padding: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    justify-content: center;
  }
  &__actions {
    column-gap: 1rem;
    justify-content: flex-end;
    &:hover {
      .tooltip {
        display: none;
      }
    }
  }

  &__action-icon {
    @include invisible-button;
    position: relative;
    cursor: pointer;
    .tooltip {
      display: none;
      top: -2rem;
      bottom: unset;
      &--top {
        &__arrow {
          left: 45%;
        }
      }
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
      background-color: $black;
      border-radius: 4px;
    }
  }

  &__tabbed-content{
    column-gap: 0.5rem;
    width: unset;
    z-index: 1;
    &-item{
      color: $dark-grey;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      padding: 0.75rem 2rem;
      background: #F7F7F7;
      border: 1px solid #E0E0E0;
      border-radius: 8px 8px 0px 0px;

      &.create-new__tabbed-content-item:hover{
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
      
      }

      &--selected{
        border-bottom: 1px solid white;
        background-color: white;
        color: black;
        &:hover{
          cursor: unset;
          background-color: unset;
          transition: unset;
          border-radius: 8px 8px 0px 0px;
          border-bottom-left-radius: unset;
          border-bottom-right-radius: unset;
        }
      }
    }

    
  }

  &__reset-btn{
      &.button.button{
      border: none;
      outline: unset;
      padding: 0.5rem 1rem;
      svg path{
        fill: black;
      }
    }
  &--disabled.create-new__reset-btn.button{
    color: $dark-grey;
    svg path{
      fill: $dark-grey;
    }
    &:hover{
      background-color: unset;
      cursor:auto;
    }
  }
  }

  &__guidance{
    &-container{
      display: flex;
      column-gap: 0.5rem;
      align-items: end;
      margin-bottom: 1.5rem;
      width: calc(100% - 1.5px);
      border-left: 1.5px solid #e0e0e0;
    }
    &-wrapper{
      display: flex;
      background: #EBF7F8;
      box-shadow: inset 0px -1px 0px #0097A9;
      padding: 0.5rem 1rem;
      align-items: center;
      column-gap: 0.5rem;
      width: 100%;
      &--warning{
        background: #FFFBEA;
        box-shadow: inset 0px -1px 0px #FFE475;
      }
    }
    &-text{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $neutral-black;
    }
  }

  &__expand-collapse-btn.text-button.button{
    padding: unset;
  }

  //styling global search btn
  &__dropdown-label {
    font-size: 14px;
    font-weight: 600;

    &-additionalText {
      font-weight: 500;
      font-size: 0.83rem;
      color: #75787b;
    }

    &-wrapper{
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }

  &__non-sec-btn{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    column-gap: 0.25rem;
    color: $primary;
    &--disabled{
      color: $dark-grey;
      & svg path{
        fill: $dark-grey;
      }
    }
    
  }
  
  &__confirmation{
    &-header{
      @include fz-16--600;
      display: flex;
      column-gap: 0.5rem;
    }
    &-content{
      @include fz-14--400;
    }
  }

  &__column-company {
    &--name {
      width: 18rem;
    }
    &--ticker {
      width: 3rem;
    }
    &--auditor {
      width: 5rem;
    }
    &--metrics {
      width: 2rem;
    }
    &--marketCap {
      width: 6rem;
    }
    &--source {
      width: 6.1rem;
    }
  }
}
