@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes shine {
  @for $i from 0 to 100 {
    #{$i * 1%} {
      background-image: linear-gradient(
        90deg,
        $light-grey #{$i * 1% - 10%},
        $lightest-grey #{$i * 1% - 5%},
        $light-grey #{$i * 1%}
      );
    }
  }
}


@keyframes light_shine {
  @for $i from 0 to 100 {
    #{$i * 1%} {
      background-image: linear-gradient(
        90deg,
        $lightest-grey #{$i * 1% - 10%},
        $white #{$i * 1% - 5%},
        $lightest-grey #{$i * 1%}
      );
    }
  }
}

@keyframes toast-in-bottom {
  from {
    transform: translate(25%, 100%);
  }
  to {
    transform: translate(25%, 0);
  }
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
