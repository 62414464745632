.reportingBoundaryClimate {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__companyViewContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  &__section-data {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &--1 {
      display: grid;
      grid-template-columns: 31% 31% 31%;
    }
  }
  &__noData {
    font-family: "Open Sans";
    font-style: normal;
    font-size: 1rem;
    font-weight: 700;
    line-height: 16px;
    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &__rowContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    &--companyName {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #212121;
      padding: 0.5rem 0 0.5rem 0;
    }
  }

  &__tableViewContainer {
    width: 100%;
  }

  &__tableViewRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-width: 0 0 1px 0;
    border-style: dashed;
    border-color: #e6e6e6;
    padding: 1rem 0rem;

    &--topBorder {
      border-width: 1px 0;
    }

    &--companyName,
    &--metricValue {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      text-align: left;
      position: relative;
      color: #212121;
      flex: 1 1 50%s;
    }
    &--companyName {
      font-weight: 400;
      text-align: left;
    }
  }
  &__noData {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;

    &--footer {
      justify-content: flex-start;
      padding-top: 1rem;
      display: flex;
      font-size: 0.875rem;
    }
  }

  &__peerViewRowValues {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &--valueContainer {
      width: 292px;
      height: 56px;
      align-items: center;
      border: 1px solid #e2eff4;
      border-radius: 1rem;
      position: relative;
      color: #012169;
      display: flex;
      font-weight: 600;
      justify-content: center;
      align-items: center;
      background: linear-gradient(
        180deg,
        $white 0%,
        rgba(238, 245, 248, 0.3) 100%
      );

      &--noData {
        background: none;
        border: none;
      }
    }

    &--metricValue {
      &--noData {
        color: $dark-grey;
        background: none;
      }
    }
  }

  &__detailedPeerViewContainer {
    width: 100%;
    display: flex;
  }

  &__detailedPeerViewWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__detailedPeerViewRow {
    width: 45%;
    padding-bottom: 2rem;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 0.5rem;
  }

  &__detailedPeerViewRowValues {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &--companyName {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      position: relative;
      color: #212121;
      text-align: left;
      width: 50%;
      margin-right: 0.5rem;
      word-wrap: break-word;
      white-space: break-spaces;
    }

    &--metricValue {
      &--noData {
        color: $dark-grey;
        background: none;
      }
    }

    &--valueContainer {
      width: 50%;
      height: 56px;
      padding: 0.5rem;
      align-items: center;
      border: 1px solid #e2eff4;
      border-radius: 1rem;
      position: relative;
      color: #012169;
      display: flex;
      font-weight: 600;
      justify-content: center;
      align-items: center;
      background: linear-gradient(
        180deg,
        $white 0%,
        rgba(238, 245, 248, 0.3) 100%
      );
      word-wrap: break-word;
      white-space: break-spaces;

      &--noData {
        background: none;
        border: none;
      }
    }
  }
}
