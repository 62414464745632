.module-access {
  &__loading {
    display: flex;
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  &__container {
    width: 100%;
    border: 1px solid $lightest-grey;
    box-shadow: 0 0.25rem 0.5rem rgba(238, 240, 241, 0.48);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
  }

  &__header {
    border-bottom: 1px solid $secondary-light-grey;
    padding: 0.25rem 1rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.5rem;

    &-left {
      display: flex;
      align-content: center;
      gap: 0.5rem;
    }

    &-right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: auto;
    flex: 1 1 60%;
  }

  th:nth-child(2),
  td:nth-child(2) {
    flex: 1 1 40%;
  }

  &__drop-down {
    width: 100%;
    margin-top: 1rem;
    position: relative;

    &:first-child {
      margin-top: 0;
    }
  }
}
