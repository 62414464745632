.active-filter-tag {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  @include primary-button-rounded;
  padding: 0.25rem 0.35rem 0.25rem 0.75rem;
  line-height: 1.4rem;
  cursor: auto;
  width: fit-content;

  span {
    font-size: 0.875rem;
  }

  &__filter-icon {
    line-height: 1rem;
    path {
      fill: $white;
    }
  }

  &__remove {
    @include invisible-button;
    background-color: $secondary-blue;
    border-radius: 1rem;

    path {
      fill: $white;
    }
  }
}
