.collapsable-layout {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 3rem;
  padding-right: 2rem;

  &__parent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1.5rem;
    overflow-x: hidden;
  }

  &__section-title {
    font-size: 0.8rem;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    width: fit-content;

    &--color-1 {
      background-color: $lightest-green;
      color: $dark-green-2;
      path {
        fill: $dark-green-2;
      }
    }

    &--color-2 {
      background-color: $lightest-blue;
      color: $primary;
      path {
        fill: $primary;
      }
    }

    &--color-3 {
      background-color: $light-yellow;
      color: $orange;

      path {
        fill: $orange;
      }
    }

    &--collapsed {
      font-size: 1rem;
      font-weight: 600;
      width: unset;
      background-color: transparent;
      border-radius: 0;
      padding: 0 0 0.25rem;
      border-bottom: 1px solid $light-grey;
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }

  &__section-collapse {
    position: absolute;
    left: 0.5rem;
    top: 0;

    span {
      transform: rotate(-90deg) translateX(-100%);
      transform-origin: top left;
      position: absolute;
      font-weight: 600;
      top: 2.5rem;
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }

  &__collapse-icon {
    border-radius: 0.25rem;
    padding: 3.5px;

    &--color-1 {
      background-color: $lightest-green;
    }

    &--color-2 {
      background-color: $lightest-blue;
    }

    &--color-3 {
      background-color: $light-yellow;
    }

    svg {
      transition: transform 200ms ease-in-out;
    }

    &--collapsed {
      svg {
        transform: rotate(-90deg);
      }
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 1rem;
    transition: height 200ms ease-in-out;
    &--collapsed {
      overflow: hidden;
      height: 0;
    }
  }

  &__subsection {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__subsection-title {
    width: 100%;
    border-bottom: 1px solid $light-grey;
    padding-bottom: 0.25rem;
  }

  &__subsection-content {
    display: grid;
    column-gap: 1rem;
  }
}
