.weater-stress {
  width: 50%;
  aspect-ratio: 1;
  margin: 0 auto;
  display: flex;
  background-color: $light-grey;
  position: relative;
  min-width: 7rem;

  &__content{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__border {
    position: absolute;
    background-color: $white;
    display: flex;
    top: 2px;
    left: 2px;
    aspect-ratio: 1;
    width: calc(100% - 4px);
  }
  &__data {
    width: 100%;
    background: linear-gradient(
      90deg,
      rgba($light-blue, 1) 0%,
      rgba($primary, 1) 100%
    );
    align-self: flex-end;
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    margin: 0 auto;
    align-items: center;
  }

  &__range {
    @include tile-score-range;
  }

  &__value {
    width: 15%;
    aspect-ratio: 1;
    justify-content: center;
    display: flex;
    min-width: 2rem;

    background-color: $white;
    border: 2px solid $light-grey;
    border-radius: 100%;

    margin: -1rem auto 0 auto;
    z-index: 1;

    font-weight: 600;
    font-size: 14rem;
  }

  &__footer {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: rgba(33, 33, 33, 1);
    padding: 0.75rem;
    text-align: center;
    white-space: pre-wrap;
    word-wrap: break-word;
    max-width: 7rem;
  }
}
