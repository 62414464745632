.save-peer-modal {
  &__body {
    box-sizing: border-box;
    width: 30rem;
    max-width: 35rem;
    top: 2.5rem;
    background: #ffffff;
    padding: 0 1rem 1px 1rem;
    font-size: 14px;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 10rem;
    max-height: 20rem;
    min-width: 10rem;
    height: fit-content;

    &-input {
      width: 100%;
      height: auto;
      outline: none;
      margin-bottom: 0.25rem;

      & input {
        width: 100%;
        margin-top: 0.5rem;
        height: fit-content;
        padding: 0.6rem;
        border: 1px solid #d0d0ce;
        box-sizing: border-box;
        border-radius: 4px;
        outline: none;
        resize: none;
        font-size: 0.95rem;
        font-weight: none;
        &:hover {
          border-color: $primary;
        }
      }
    }
  }
  &__dropdown {
    padding: 0.5rem 0rem 0.5rem 0.1rem;
  }
  &__options {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  &__peerCompanies-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  &__peerCompanies {
    background-color: $lightest-grey;
    padding: 0.25rem 0.25rem 0.25rem 0.5rem;
    border-radius: 1rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
  }

  &__text {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  &__remove {
    @include invisible-button;
    background-color: #a3a3a3;
    border-radius: 1rem;
    margin-left: 0.25rem;
    path {
      fill: $white;
    }
    &:hover {
      background-color: $grey;
    }
  }

  &__add {
    margin-left: 1rem;
  }

  &__contentError {
    font-size: 14px;
    color: #f54242;
  }
}
