.risksAndOpportunities {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  font-size: 0.75rem;

  &__container {
    width: fit-content;
  }

  &--horizontal {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    &--horizontal {
      width: 47%;
    }

    &--table {
      font-size: 12px;

      &__data {
        padding: 1rem 0.5rem;
        font-size: 0.875rem;
        border-bottom: 1px dashed #d0d0ce;
      }

      & thead {
        & tr th {
          border-bottom: 1px dashed $light-grey;
          height: 3rem;
        }
      }
      & tbody {
        & tr td {
          border-bottom: 1px dashed $light-grey;
          height: 3rem;
        }
      }
      &-metric {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        border-bottom: 1px dashed $light-grey;
      }
      &-value {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        border-bottom: 1px dashed $light-grey;
      }
    }
  }

  &__detailedSection {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    &-section {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      font-size: 0.75rem;
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;

      &-metric-title {
        display: block;
        font-weight: 600;
        font-size: 0.75rem;
      }
    }

    &-company {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      min-height: 5rem;
      background: linear-gradient(180deg, #ffffff 0%, #f3fff7 100%);
      justify-content: space-between;
      align-items: center;
      border: 1px solid #e3f3df;
      border-radius: 12px;
      white-space: unset;
      padding: 0.5rem;
      &:nth-child(4n) {
        page-break-after: always;
        break-after: always;
      }
      &-2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        box-sizing: border-box;
      }
    }

    &__section-data {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    &-metric-box {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &-company-name {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */
      color: #212121;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      text-align: left;
      max-width: 11rem;      
    }
    &-company-metrics {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #212121;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      text-align: left;
      max-width: 6rem;
      &-2 {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #2c5234;
      }
      &-value {
        display: block;
        padding: 0.2rem;
        // margin-left: 1rem;
      }
    }
  }

  &__peer-table {
    &-header {
      background: rgba(0, 0, 0, 0.02);
      border-radius: 4px;
    }
    &-header-text {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 16px;
      color: #000000;
      vertical-align: top;
      padding: 0.2rem;
    }
    &-subheader {
      td {
        font-weight: 400;
        line-height: 16px;
        color: #757575;
        font-size: 0.75rem;
        border-bottom: 1px dashed #d0d0ce;
        padding: 0.5rem 0.2rem;
      }
      &:first-child {
        border-top: 1px dashed #d0d0ce;
      }
    }
    &-chart {
      td {
        font-weight: 400;
        line-height: 16px;
        color: #212121;
        font-size: 0.75rem;
        border-bottom: 1px dashed #d0d0ce;
        padding: 0.5rem 0.2rem;
        text-align: left;
      }
    }
    &-blocklevelmetric {
      display: block;
      padding-bottom: 0.4rem;
    }
  }

  &__section-name {
    font-size: 0.75rem;
    font-weight: 600;
    position: relative;

    &-container {
      width: fit-content;
    }

    .tooltip {
      top: 100%;
      display: none;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }

  &__table-metric-wrapper {
    border: none;
    font-size: 0.75rem;
    &--0 {
      padding-top: 0.4rem;
      &--undefined {
        padding: 0;
      }
    }
    &--1 {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      border-bottom: 1px dashed #e0e0e0;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  &__table-section-data {
    &--0 {
      display: flex;
      flex-direction: column;
    }
    &--1 {
      display: table;
      width: 100%;
      padding: 0px;
      gap: 8px;
    }
    &--2 {
      align-self: flex-start;
    }
  }

  &__company-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    &-table {
      align-items: normal;
      border-bottom: 1px dashed $light-grey;
      padding-bottom: 0.2rem;
    }
  }

  &__company-name {
    width: 100%;
    font-size: 0.75rem;
    font-weight: 400;
    font-size: 12px;
  }

  &__section-data {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &--0 {
      display: grid;
      grid-template-columns: 31% 31% 31%;
    }
    &--1 {
      display: flex;
      flex-direction: column;
    }
  }
  &__relevant-table-row {
    color: #757575;
    height: 2.6rem;
    font-size: 0.75rem;
    border: 1px solid #e3f3df;
    border-radius: 8px;
    padding: 0.1rem;
    margin: 0.1rem;
    height: 40px;
    border-spacing: 0 0.5rem;
    & thead {
      & th {
        text-align: left;
        padding: 0.5rem 0.5rem;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        word-wrap: break-word;
        white-space: break-spaces;
        max-width: 8rem;
      }
    }
    & tbody {
      & tr {
        background: linear-gradient(180deg, #ffffff 0%, #f3fff7 100%);
        color: #757575;
        height: 2.6rem;
        font-size: 0.75rem;
        & td {
          text-align: left;
          padding: 0.5rem 0.5rem;
          border: none;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          position: relative;
        }
        & td:nth-child(1) {
          font-weight: 400;
          line-height: 18px;
          padding-left: 0;
        }

        & td:nth-child(1) {
          font-weight: 400;
          line-height: 18px;
        }

        & td:nth-child(1) {
          font-weight: 400;
          line-height: 18px;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          padding-left: 0.5rem;
        }
        & td:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          padding-right: 0.4rem;
        }
      }
    }
    &-data {
      border: none;
      margin: auto;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #212121;
    }
  }

  &__metric-wrapper {
    width: 100%;
    flex: 1 1;
    min-height: 3.5rem;
    max-width: 11.9rem;
    background: linear-gradient(
      180deg,
      $white 0%,
      rgba(238, 245, 248, 0.3) 100%
    );
    border: 1px solid #e3f3df;
    border-radius: 0.75rem;
    position: relative;
    color: $dark-grey;
    display: flex;
    justify-content: center;
    align-items: center;

    &-lbl {
      color: $dark-grey;
      font-size: 0.75rem;
    }

    .tooltip {
      top: 100%;
      display: none;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
    &--2 {
      max-width: unset;
      font-weight: 600;
      padding: 0.75rem;
      font-size: 1.25rem;
      height: 2rem;
    }
    &--0 {
      &--yes,
      &--Neutral,
      &--neutral {
        border: 1px solid #43b02a;
      }
      &-neutral {
        text-align: center;
        font-size: 0.65rem;
        padding-bottom: 0.3rem;
      }
    }

    &--3 {
      color: #012169;
    }
    &--2 {
      font-style: normal;
      font-weight: 600;
      font-size: 0.75rem;
      color: #2c5234;
    }
    &--1 {
      height: 3rem;
      max-width: 92%;
      font-size: 0.75rem;
      border-radius: 0.75rem;
      background: linear-gradient(180deg, #ffffff 0%, #f3fff7 100%);
      border: 1px solid #e3f3df;
      display: flex;
      justify-content: space-between;
      grid-column-gap: 0.5rem;
      column-gap: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      margin: 0.1rem;
      div span:nth-child(2) {
        margin-left: auto;
      }

      &-metric-value {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #212121;
        margin-right: 2rem;
        display: flex;
      }
      &-metric-name {
        margin-left: 1rem;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
      &-table {
        &-metric-name {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          display: table-row;
          width: 100%;
          padding-top: 0.5rem;
        }
        &-metric-value {
          display: table-cell;
          width: 50%;
          padding: 1em;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    &--yes,
    &--neutral,
    &--Neutral {
      background: linear-gradient(180deg, #e7f5f0 0%, #f1faf7 100%);
      border: 1px solid #6fc2b4;
      > div {
        font-weight: 600;
        color: #0d8390;
      }

      &-lbl {
        font-weight: 600;
        font-size: 0.75rem;
        color: #046a38;
      }
    }

    &--neutral {
      border: 1px solid $grey;
      > div {
        font-weight: 600;
        align-self: start;
      }
    }
  }

  &__metric {
    padding: 0.2rem 0.5rem;
    text-align: center;
    line-height: 1rem;
    &--no-padding {
      padding: 0;
    }
  }

  &__neutral-label {
    font-size: 0.6rem;
    position: absolute;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    font-weight: normal;
    color: $dark-grey;
  }

  &__no-data {
    text-align: center;
    font-weight: bold;
    padding: 1.5rem 0.5rem;
    font-size: 0.875rem;
  }

  &__no-data-footnote {
    font-size: 0.75rem;
    width: 100%;
  }

  &-table {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1rem;

    &__normal-text {
      font-size: 0.65rem;
      font-weight: 600;
    }

    &__section {
      display: flex;
      gap: 1rem;
      flex-direction: column;
    }

    &__company,
    &__data {
      font-size: 0.75rem !important;
    }

    &__section-name {
      padding: 0 0.5rem;
      font-size: 0.75rem;
      width: fit-content;
      font-weight: 600;
      &-lbl {
        font-size: 0.75rem;
        font-weight: 600;
      }
    }

    &-2 {
      &__data {
        width: 100%;
        td {
          padding: 1rem 0.5rem;
          font-size: 0.75rem;
          border-bottom: 1px dashed $light-grey;
          position: relative;
          &:last-child {
            width: 60%;
            font-weight: 600;
          }
        }

        tr:last-child td {
          border-bottom: 1px dashed $light-grey;
        }

        &-value {
          &-metric {
            display: block;
          }
        }
      }
    }

    &-0 {
      &__data {
        width: 100%;
        td {
          padding: 1rem 0.5rem;
          font-size: 0.75rem;
          border-top: 1px dashed $light-grey;
          position: relative;
          &:last-child {
            width: 60%;
            font-weight: 600;
          }
        }

        tr:last-child td {
          border-bottom: 1px dashed $light-grey;
        }

        &-value {
          &-metric {
            display: block;
          }
        }
      }
    }

    &__data {
      width: 100%;
      td {
        padding: 1rem 0.5rem;
        font-size: 0.875rem;
        border-top: 1px dashed $light-grey;
        position: relative;
        &:last-child {
          width: 60%;
          font-weight: 600;
        }
      }

      tr:last-child td {
        border-bottom: 1px dashed $light-grey;
      }

      &-value {
        &-metric {
          display: block;
          padding-bottom: 0.2rem;
        }
      }
    }
  }
}


.risks-and-opportunities-table{
  &__table {
    width: 100%;
  }

  &__company {
    font-size: 0.75rem;
  }

  &__label {
    font-size: 0.75rem;
    position: relative;

    .tooltip {
      display: none;
      z-index: 1;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
    &--align-left {
      text-align: left;
    }

    &--left {
      width: fit-content;
      display: inline-block;
    }
  }

  &__data {
    text-align: right;
    font-weight: 600;
    font-size: 0.75rem;
    position: relative;

    .tooltip {
      position: absolute;
      transform: translateX(-50%);
      display: none;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }

  }

}