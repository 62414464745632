.main-tooltip{
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    font-size: 12px;
    width: max-content;
    max-width: 18.5rem;
    pointer-events: auto;
    z-index: 1;
    
    &__label {
        max-width: 18.5rem;
        padding: 0.2rem 0.5rem;
        color: $white;
        text-align: left;
        background-color: $black;
        border-radius: 0.15rem;
        overflow-wrap: break-word;
        white-space: initial;
      }

}

