.ShowHideTile {
  &__current-benchmarks {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    box-sizing: border-box;
    width: 100%;
    overflow-x: hidden;
    height: calc(100% - 4.5rem);
    font-size: 14px;

    &-heading-text {
      font-size: 14px;
      font-weight: 600;
      //color: $dark-grey;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }

    &-charts-list {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      overflow-y: auto;
    }
  }

  &__benchmark-show {
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
      border: 1px solid $primary;
      padding: 0.75rem 1.25rem;
      border-radius: 5px;

      &-hidden {
        border: 1px solid $light-grey;

        input {
          border: 1px solid $lightest-grey;
          cursor: pointer;
        }
      }
    }

    &-title {
      display: flex;
      gap: 0.5rem;
      line-height: 1.5rem;
      width: 90%;

      &-text {
        font-size: 1rem;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
