.cdpScope3CategoriesReported {
  &__heading {
    font-size: 0.75rem;
    font-weight: 600;
    margin: 1rem 0 1rem 0;
  }
  &__metricWrapper {
    width: fit-content;
    padding-bottom: 1rem;
  }
  &__no-data {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.785rem;
    font-weight: bold;
    height: 7rem;
    margin-left: 26%;
  }
  &__vertical {
    &-container {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      width: 100%;
      height: 100%;
      margin: 1rem 0;
      padding-bottom: 1.5rem;
    }

    &-chart {
      min-width: 65%;
    }

    &-legends {
      flex-shrink: 2;
      min-width: 30%;
    }

    &__datasetLegend {
      &--right {
        flex-shrink: 2;
        min-width: 30%;
        margin-top: 2rem;
      }

      &-title {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $dark-grey;
        position: relative;
        &-container {
          width: fit-content;
        }
        .tooltip {
          top: 1.5rem;
          z-index: 2;
          opacity: 0;

          &--bottom__arrow {
            left: 14%;
          }

          &__label {
            height: 0;
          }
        }

        &:hover {
          .tooltip {
            opacity: 1;

            &__label {
              height: 100%;
            }
          }
        }
      }

      &-list {
        padding-left: 1rem;
        margin-top: 0.5rem;

        &-item {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 28px;
          color: $neutral-black;
        }
      }
    }
  }
}

.cdpScope3CategoriesStatus {
  padding-top: 1rem;
  &__heading {
    font-size: 0.75rem;
    font-weight: 600;
    margin: 1rem 0 1rem 0;
  }

  &__metricWrapper {
    width: fit-content;
  }
  &__category {
    width: 100%;

    &-container {
      display: grid;
      grid-template-rows: repeat(9, 1fr);
      grid-auto-flow: column;
      grid-gap: 1rem;
      align-self: flex-start;
      height: fit-content;
      padding: 1rem;
    }

    &-detail {
      display: flex;
      gap: 1rem;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.12);
      align-items: center;

      &--legendDot {
        width: 8px;
      }

      &--name {
        font-size: 0.75rem;
        font-weight: 400;
        width: 60%;
      }
      &--value {
        font-size: 0.75rem;
        font-weight: 600;
        width: 40%;
        gap: 0.3rem;
        display: flex;
        align-items: first baseline;
      }

      &--noData {
        color: #757575;
      }
    }
  }
}

.cdpScope3CategoriesStatusPeer {
  &__table {
    width: 98%;
    margin: 1rem;

    &-detailView {
      border-collapse: collapse;
    }

    &-header {
      &--detailView {
        background: linear-gradient(
          180.75deg,
          #eef5f8 0.64%,
          rgba(238, 245, 248, 0) 135.65%
        );

        & tr th {
          border-bottom: none !important;
          &:first-child {
            padding-left: 0.2rem !important;
          }
        }
      }

      & tr th {
        border-bottom: 1px dashed $light-grey;
        padding: 0.75rem 0.25rem;
        word-break: break-word;
        inline-size: 5rem;
        text-align: left;
        font-size: 0.75rem;
        font-weight: 600;

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }

      &-label-container {
        text-align: center;
      }

      &-label {
        text-align: left;
        font-size: 0.75rem;
        font-weight: 400;
        &--left {
          text-align: left;
        }
      }
    }

    &-body {
      &-detailView {
        & tr td {
          border-bottom: none !important;
        }
      }
      & tr td {
        border-bottom: 1px dashed $light-grey;
        padding: 0.75rem 0.5rem;
        overflow-wrap: break-word;
        inline-size: 5rem;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
      &-cell--border-top {
        & tr:first-child {
          & td {
            border-top: 1px dashed $light-grey;
          }
        }
      }

      &-value {
        text-align: left;
        font-size: 0.75rem;
        font-weight: 400;
        position: relative;

        &--left {
          text-align: left;
        }
        &--bold {
          font-weight: 600;
        }
        &--right {
          text-align: right;
        }
        &--center {
          text-align: center;
        }

        &--container {
          display: flex;
          gap: 0.5rem;
          align-items: first baseline;
        }
      }
    }
  }

  &__missing-data-msg {
    margin: 2rem 1rem 1rem 0.5rem;
    align-items: center;
    font-size: 0.785rem;
    display: flex;
    font-weight: normal;
    text-align: left;
  }

  &__fit-content {
    width: fit-content;
    display: inline-block;
  }

  &__text--line-height {
    line-height: unset;
  }

  &__text--top {
    vertical-align: top;
  }
}

.cdpScope3PeerInd {
  margin-bottom: 1rem;

  &__emissions {
    width: 100;
    display: flex;
    justify-content: center;
    padding: 8px;
    align-items: center;
    background: linear-gradient(
      180.75deg,
      #eef5f8 0.64%,
      rgba(238, 245, 248, 0) 135.65%
    );
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    margin-top: 1rem;
  }

  &__categoryName {
    font-size: 14px;
    font-weight: 600;
  }

  &__titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 98%;
    margin: 0 2rem 2rem 1rem;
  }

  &__title {
    flex: 1;

    &-baseCompany {
      font-size: 0.875rem;
      font-weight: 600;
    }

    &-emissions {
      font-size: 0.75rem;
      font-weight: 400;
      color: #757575;
    }
  }

  &__title:last-child {
    display: flex;
    flex: 1.5;
    justify-content: flex-end;
  }

  &__chartSection {
    display: grid;
    grid-template-columns: 40% 60%;
    margin: 1rem;

    &-metricName {
      margin-bottom: 1.5rem;
      font-size: 0.75rem;
      font-weight: 400;
    }

    &-rangeData {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding-top: 2rem;
      justify-content: space-between;
      width: 100%;
      left: 1.5rem;
      font-size: 0.75rem;
      font-weight: 400;
      color: #757575;
    }
    &-dashedRange {
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 100%;
    }

    &-dashedSeparator {
      position: relative;
    }
    &-chartWrapper {
      position: relative;
    }
  }
}

.tabularCDPView {
  &__container {
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem 0;
    width: 100%;

    &--inherit {
      width: inherit;
      padding: 0;
    }
  }

  &__data-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    &-left {
      width: 47.5%;
      &--full-width {
        width: 100%;
      }
    }

    &--sm {
      width: 24%;
    }

    &--detailed {
      flex-wrap: wrap;
      justify-content: flex-start;
      column-gap: 1rem;
      row-gap: 1rem;
    }

    &-right {
      width: 47.5%;
      &--margin-top {
        margin-top: 1.375rem;
      }
    }
  }

  &__subtitle {
    &-wrapper {
      margin-bottom: 1rem;
    }
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    &-container {
      display: inline-block;
      width: fit-content;
    }

    position: relative;
    .tooltip {
      top: 1.5rem;
      z-index: 2;
      opacity: 0;
      &--bottom__arrow {
        left: 10%;
      }

      &__label {
        height: 0;
      }
    }

    &:hover {
      .tooltip {
        opacity: 1;

        &__label {
          height: 100%;
        }
      }
    }
  }

  &__label-box {
    display: flex;
    justify-content: space-between;
    column-gap: 0.5rem;
    margin-bottom: 0.5rem;
    background: #f7f7f7;
    border-radius: 4px;
    padding: 0.5rem 1rem;

    &--dotted {
      margin: 0;
      padding: 0.75rem 1rem 0.75rem 0;
      border-bottom: 1px dashed #e0e0e0;
      background-color: white;
    }

    &--top-dotted {
      border-top: 1px dashed #e0e0e0;
    }

    &--grey {
      background-color: #f7f7f7;
    }

    &--missed {
      background-color: #f0f8ee;
    }
    &--pd-0 {
      padding: 0;
      background-color: white;
    }
    &--br-8 {
      border-radius: 8px;
      background-color: #f9f9f9;
    }
  }

  &__value {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    &--fs-12 {
      font-size: 12px;
    }
    &--gray {
      display: flex;
      justify-content: center;
      background-color: #f7f7f7;
      padding: 0.8rem 1rem;
      width: 50%;
      border-radius: 2px;
    }
  }

  &__label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    &-risk {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: left;
    }
    &--white {
      background-color: transparent;
      padding: 0.8rem 1rem;
    }

    &-container {
      display: flex;
      align-items: center;
    }
  }

  &__guidance {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    svg path {
      fill: #43b02a;
    }
  }
  &__note {
    display: flex;
    &-label {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__no-data-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__accordion {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1.25rem;
    &-item {
      width: 100%;
    }
    &-header {
      display: flex;
      width: 100%;
      background-color: white;
      border: 1px solid #e0e0e0;
      border-top: none;
      padding: 1rem 1.125rem;

      & svg path {
        fill: black;
      }
      &-text {
        margin-left: 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        &--peer {
          margin: 0 0 1rem 0;
        }
      }
      &--top-border {
        border-top: 1px solid #e0e0e0;
      }
    }
    &-body {
      display: flex;
      width: 100%;
      background: #ffffff;
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #e9e9e9;
      padding: 1rem 2.5rem;
      flex-direction: column;
    }
    &-collapsable {
      display: none;
      width: 100%;
      &--show {
        display: flex;
      }
    }
    &-actions {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1.25rem;
      column-gap: 2rem;
    }
    &-btn {
      display: flex;
      border: none;
      background-color: white;
      color: $primary;
      cursor: pointer;
      column-gap: 0.25rem;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      & svg path {
        fill: $primary;
      }
      padding: unset;
    }
  }
  &__relevant {
    &-no-data {
      width: 100%;
      text-align: left;
      padding: 1rem 0;
      font-size: 0.785rem;
      &-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }

    &-table {
      width: 100%;
      border-spacing: 0 0.5rem;

      & thead {
        & th {
          text-align: left;
          padding: 0.5rem 1rem;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }
      }
      & tbody {
        & tr {
          background: #f9f9f9;
          border-radius: 8px;

          & td {
            text-align: left;
            padding: 0.5rem 1rem;
            border: none;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            position: relative;

            .tooltip {
              display: none;
              z-index: 10;
              top: 60%;
              .tooltip__arrow {
                left: 15%;
              }
              .tooltip__label {
                max-width: 10rem;
              }
            }
            &:hover {
              .tooltip {
                display: flex;
              }
            }
          }
          & td:nth-child(1) {
            font-weight: 400;
            line-height: 18px;
            padding-left: 0;
          }

          & td:nth-child(1) {
            font-weight: 400;
            line-height: 18px;
          }

          & td:nth-child(1) {
            font-weight: 400;
            line-height: 18px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
          & td:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            padding-right: 0;
          }
        }
      }
      &-row {
        & td {
          &:nth-child(1) {
            background-color: white;
          }
          &:nth-child(2) {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
        }
      }

      &-row-industry {
        background-color: white;
        & td {
          background-color: #f9f9f9;
          border-radius: 2px;
          &:nth-child(1) {
            background-color: white;
            padding-left: 0.5rem;
          }
        }
      }

      &--tableView {
        border-spacing: 0.5rem;
        & thead {
          & th {
            font-weight: 600;
            &:nth-child(1) {
              padding-left: 0.5rem;
            }
          }
        }
        & tbody {
          & tr {
            & td {
              font-weight: 400;
              line-height: 18px;
              &:last-child {
                border-top-right-radius: unset;
                border-bottom-right-radius: unset;
                border-radius: 2px;
              }
            }
          }
        }
      }
    }
  }
}
