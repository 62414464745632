.my-searches {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  &__content {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &-header {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &-text {
        font-size: 1.25rem;
        font-weight: 400;
      }
    }
  }

  &__empty-container {
    display: flex;
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  &__header {
    height: 2rem;
    display: flex;
    justify-content: space-between;
  }

  &__notAdmin-header {
    height: 2rem;
    display: flex;
    justify-content: space-between;
    padding-top: 0.5rem;
  }

  &__empty-header {
    padding: 2rem;
    display: flex;
    justify-content: right;
  }

  &__empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin-top: 3.6rem;
  }

  &-auto-delete {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  &__img {
    width: 249px;
    height: 210px;
  }

  &__emptytext {
    width: 309px;
    height: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $black;
  }

  &__emptyredirect {
    width: 263px;
    height: 24px;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    color: #75787b;
  }

  &__link {
    color: $primary;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  &__commenting {
    margin-right: 0.4rem;
  }

  &__feedback-section {
    min-width: 1rem;
  }

  &__feedback-div {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
  }

  &__notAdmin-feedback-div {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.2rem;
  }

  &__feedback-button {
    @include font-family;
    background-color: $primary;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    border: none;
    margin-right: 0.3rem;
    border-radius: 0.25rem;
    color: $white;
    font-weight: 400;
    line-height: 1rem;
    font-size: 0.875rem;
  }
}
