.tag-card {
  &__container {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid#E0E0E0;
    &__highlight {
      background: #f1f8fb;
      box-shadow: inset 0px -1px 0px #007cb0, inset 0px 1px 0px #007cb0;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 0.5rem;
  }

  &__body {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  &__name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0.5rem;
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    max-height: 2.5rem;
    overflow: hidden;
    &--preview {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    column-gap: 0.5rem;
  }
  &__owner {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    &-icon {
      background: #000000;
      border-radius: 100px;
      width: 1rem;
      height: 1rem;
      font-style: normal;
      font-weight: 600;
      font-size: 8px;
      line-height: 12px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-name {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      flex-grow: 1;
    }
  }
  &__date {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $dark-grey-2;
  }

  &__action {
    position: relative;
    .tooltip {
      right: 1rem;
      top: 2rem;
      left: initial;
      display: none;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__nameIcon {
    cursor: pointer;
    &:hover {
      z-index: 2;
    }
  }

  &__tag-actions {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 0 1rem 0;
    gap: 1rem;

    .button {
      padding: 0.5rem 1.5rem;
    }
  }

  &__description-input {
    min-height: 5.5rem !important;
    margin-top: 0 !important;
  }

  &__name-input {
    max-height: 2.75rem !important;
    min-height: unset !important;
    margin-top: 0 !important;
    resize: none;
  }

  &__show-all {
    margin: 1rem 0 0.5rem 1rem;
    color: $primary;
    path {
      fill: $primary;
    }
  }

  &__separator {
    border-left: 3px solid $green-blue;
    padding-left: 1rem;
  }
}
