.toggle {
  display: flex;
  align-items: center;

  &__toggle {
    position: relative;
    display: inline-block;
    width: 2.5rem;
    height: 1.5rem;
    outline: none;
  }

  .tooltip {
    top: 100%;
    display: none;
    z-index: 5;
  }

  &:hover {
    .tooltip {
      display: inline-block;
    }
  }
  input {
    visibility: hidden;
  }

  &__label {
    font-size: 0.875rem;
    margin: 0 0.5rem;
    cursor: pointer;
  }

  &__label:hover {
    background-color: $light-grey;
    transition: 0.7s;
    border-radius: 4px;
  }
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $grey;
  transition: 200ms;
  border-radius: 2rem;
}
.slider:before {
  position: absolute;
  content: "";
  height: 1rem;
  width: 1rem;
  left: 0.25rem;
  bottom: 0.25rem;
  background-color: white;
  transition: 200ms;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: $primary;
}
input:focus + .slider {
  box-shadow: 0 0 1px $primary;
}
input:checked + .slider:before {
  transform: translateX(1rem);
}
input:hover + .slider:hover {
  background-color: #005478;
  transition: 0.7s;
}
