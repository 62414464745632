.rdrDay {
    /* background: transparent; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: -1.5px 0 ;
    height: 2rem;
    text-align: center;
    color: #1d2429;
}

.rdrDays {
    display: flex;
    flex-wrap: wrap;
    height: 0.1rem;
}

.rdrMonthName {
    text-align: left;
    font-weight: 600;
    color: #849095;
    padding: 0.7em;
    height: 0.2rem;
}

.rdrDateDisplayWrapper {
    background-color: black;
    width: 100%;
    top:-5rem;
}

.rdrDateDisplayItem input {
    cursor: pointer;
    height: 0.8rem;
    line-height: 2.5em;
    border: 0px;
    background: transparent;
    width: 100%;
    color: white;
}


.rdrDateDisplayItem {
    border-radius: 4px;
    background-color: black;
    // box-shadow: 0 1px 2px 0 rgb(35 57 66 / 21%);
    border: 1px solid transparent;
}

.rdrMonthAndYearWrapper {
    align-items: center;
    padding-top: 10px;
    width:100%;
    height: 1.2rem;
}



.rdrCalendarWrapper {
    box-sizing: border-box;
    background: #ffffff;
    display: inline-flex;
    flex-direction: column;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.16);
    width: 27rem;
    position: absolute;
    top:-5rem;
    left:1rem;
    height: 19.8rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.rdrWeekDays{
    line-height: 1.5em;
}

.back{
    position: absolute;
    bottom: 0;
  }