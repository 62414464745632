.SidePanel {
  z-index: 1104;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 26.3rem;
  height: 100%;
  right: 0rem;
  top: 0rem;
  background: #ffffff;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.06);
  height: calc(100% - 6rem);
  margin-top: 6rem;
  font-size: 1rem;

  &__header {
    width: 100%;
    height: 3.5rem;
    line-height: 1.5rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    box-shadow: inset 0px -1px 0px #e7e7e7;

    &__content {
      position: absolute;
      width: 13rem;
      min-height: 24px;
      height: fit-content;
      left: 16px;
      top: 11px;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
      display: flex;
      flex-direction: row;

      &__icon {
        width: 16px;
        height: 16px;
        border-radius: 12px;
        background-color: #43b02a;
        justify-content: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        margin-left: 0.4rem;
        margin-top: 3px;

        &-content {
          color: white;
          font-weight: 500;
          font-size: 10px;
        }
      }
    }

    &__cross {
      position: absolute;
      left: 22.3rem;
      top: 11px;
      cursor: pointer;

      .tooltip {
        top: -1.25rem;
        left: -1rem;
        z-index: 10;
        display: none;
      }

      &:hover {
        .tooltip {
          display: inline-block;
        }
      }
    }
  }

  &__secondHeader {
    padding: 1rem;
    font-size: 0.75rem;
    line-height: 20px;
    text-transform: uppercase;
    color: $dark-grey;
    font-weight: 700;
  }

  &__body {
    box-sizing: border-box;
    flex-grow: 1;
    overflow-y: auto;
  }

  &__close {
    .tooltip {
      left: unset;
      right: 2.75rem;
      top: 0.75rem;
      display: none;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }
}
