.savedInsightsTable{
    display: flex;
    justify-content: center;
    margin: 0 2rem;



    &__table{
        box-shadow: 0px 4px 8px rgba(238, 240, 241, 0.48);
        border-radius: 8px;
        font-weight: 400;
        border: 1px solid #ebebeb;
        border-bottom: none;

        &__header{
            box-shadow:  none;
        }
        & thead{
            border-radius: 8px;

            & tr{
                box-shadow: inset 0px -1px 0px #ebebeb;
            }
        }
        & thead tr th, tbody tr td{
            flex: 1 0 20%;
        }

        & thead tr th:first-child, tbody tr td:nth-child(1){
            width: 20%;
        } 
        
        & thead tr th:last-child, tbody tr td:nth-last-child(0){
            width: 10%;
        } 
    }

    &__column-content {
        display: flex;
        align-items: center;
        gap: 1rem;
        position: relative;
      }

      &__column-text {
        font-weight: 400;
        font-size: 0.875rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        max-width: 100%;
        .tooltip {
          left: -4rem;
          top: -200%;
        }
      }

      &__peers{
        width: 100%;
        gap: 0rem;
        display: flex;

        &-count {
            width: 1.5rem;
            padding-left: 0.2rem;
            margin-left: 0.2rem;
            position: relative;
            font-size: 14px;
        
            svg:not(:root) {
              transform: translateX(8px) translateY(4px);
            }
        
            &:hover {
              background: $secondary-light-grey;
              cursor: pointer;
              height: 20px;
              border-radius: 15px;
            }
          }
      }

    
  &__column-text {
    font-weight: 400;
    font-size: 0.875rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
    .tooltip {
      left: -4rem;
      top: -200%;
    }
  }
  

    &__actions {
        gap: 0.75rem;
        &:hover {
          .tooltip {
            display: none;
          }
        }
      }

      &__action-icon {
        @include invisible-button;
        position: relative;
        cursor: pointer;
        .tooltip {
          display: none;
          top: -2rem;
          bottom: unset;
    
          &--top {
            &__arrow {
              left: 45%;
            }
          }
        }
    
        &:hover {
          .tooltip {
            display: inline-block;
          }
        }
      }

      &__share {
        .tooltip {
          left: -1.2rem;
        }
      }
    
      &__edit {
        .tooltip {
          left: -0.8rem;
        }
      }
    
      &__delete {
        .tooltip {
          left: -1.4rem;
        }
      }
}