.user-management-modal {
  &__container {
    width: 40rem;
    display: flex;
    flex-direction: column;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 0.5rem;
  }
}
