.carbon-credit{
    background-color: #f7fbfc;
    width: 100%;
    height:100%;
    border: 1px solid #e2eff4;
    border-radius: 0.5rem;

    &__wrapper{
        margin-bottom: 2rem;
    }

    &__noAvgAvailable{
        padding: 0.5rem;
        text-align: center;
        font-size: 0.75rem;
    }
    &__groupName{
        font-size: 0.875rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }
    &__header{
        display: grid;
        padding: 1rem;
        grid-template-columns: 1.5fr 1.5fr 1fr 1fr;
        column-gap: 1rem;
        
    

        &-name{
            font-size: 0.75rem;
            font-weight: 400;
            text-align: left;
            line-height: normal;
        }

        &-border{
            border-top: 1px dashed lightblue
        }
    }

    &__companyTable{
        &-wrapper{
            display: grid;
            grid-template-columns: 2fr 1fr;
            column-gap: 1rem;
            border-top: 1px dashed #E0E0E0;
            padding: 0.3rem 
        }

        &-metricName{
            font-size: 0.75rem;
            font-weight: 400;
            text-align: left;
            line-height: normal;
        }
        &-metricValue{
            font-size: 0.75rem;
            font-weight: 600;
        }
    }

    &__indTable{
        &-groupName{
            font-size: 0.75rem;
            font-weight: 600;
            margin-bottom: 2rem;
        }
        &-wrapper{
            display: grid;
            grid-template-columns: 2fr 1fr;
            column-gap: 1rem;
            border-bottom: 1px dashed #E0E0E0;
            padding: 0.3rem 
        }

        &-metricName{
            font-size: 0.75rem;
            font-weight: 400;
        }
        &-metricValue{
            font-size: 0.75rem;
            font-weight: 600;
        }
    }
    
    &__peerTable{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 1rem;
        row-gap: 1rem;

        &-groupName{
            font-size: 0.865rem;
            font-weight: 600;
            background-color:#F7F7F7;
            padding:0.5rem;
        }
    }
}