.cost-chart {
  margin-top: 5%;
  width: 100%;

  &__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  &__container {
    margin-bottom: 2rem;
    &-metricName {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
    }
    &-metricValue {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      font-weight: 600;
      color: #012169;
    }
  }
  &__container__peerIndustry {
    margin-bottom: 1rem;
    &-metricName {
      display: flex;
      flex: 1;
      font-size: 14px;
      font-weight: 600;
      padding-bottom: 1rem;
    }
    &-valueChart {
      display: flex;
      &-name {
        display: flex;
        flex: 1;
        align-items: left;
        font-size: 12px;
        font-weight: 400;
        line-height: 2rem;
        word-wrap: break-word;
      }
      &-metricValue {
        display: flex;
        align-items: center;
        font-size: 2rem;
        font-weight: 600;
        color: #012169;
        padding-right: 2rem;
      }
    }
  }
  &__container__detailedPeer {
    margin-bottom: 2rem;
    &--grid-container {
      display: grid;
      grid-template-columns: 35% 30% 30%;
      gap: 2rem 0.5rem;
      grid-auto-flow: row;
      &--item {
        font-size: 12px;
        font-weight: 400;
        line-height: 1rem;
        padding-bottom: 1rem;
        text-align: left;
        word-wrap: break-word;
      }
      &--value {
        font-size: 24px !important;
        line-height: 1rem;
        font-weight: 600;
        color: #012169;
        align-items: right;
        padding-bottom: 1rem;
      }
    }
  }
  &__empty-values {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
}
