// Global variables
@import "./styleguide/variables";
@import "./styleguide/colors";
@import "./styleguide/animations";
@import "./styleguide/mixins";

@import "react-date-range/dist/styles.css"; // main css file
@import "react-date-range/dist/theme/default.css"; // theme css file

// Layout
@import "./components/app/layout";
@import "./components/footer/footer";

// Shared
@import "./components/shared/tooltip/tooltip";
@import "./components/shared/tooltip//tooltip.container";
@import "./components/shared/modal/modal";
@import "./components/shared/tabbed-content/tabbed-content";
@import "./components/shared/session-timeout-modal/session-timeout-modal";
@import "./components/shared/checkbox/checkbox";
@import "./components/shared/button/button";
@import "./components/shared/table/table";
@import "./components/shared/filter-popup/filter-popup.scss";
@import "./components/shared/active-filter-tag/active-filter-tag.scss";
@import "./components/shared/toast/toast";
@import "./components/shared/notification/notification";
@import "./components/shared/delete-modal/delete-modal";
@import "./components/shared/edit-modal/edit-modal";
@import "./components/shared/share-to-users-modal/share-to-users-modal";
@import "./components/shared/share-to-users-modal/dropdown";
@import "./components/shared/note/note.scss";
@import "./components/shared/textfield/textfield";
@import "./components/shared/checkbox-container/checkbox-container";
@import "./components/shared/action-container/action-container";
@import "./components/shared/date-range-picker/date-range-picker";
@import "./components/shared/toggle/toggle";
@import "./components/shared/disclaimer-modal/disclaimer-modal";
@import "./components/shared/save-peer-modal/save-peer-modal";
@import "./components/shared/show-hide-tile/show-hide-tile";
@import "./components/shared/suggested-peers/suggested-peers";
@import "./components/shared/side-panel/side-panel";
@import "./components/shared/main-tooltip/main-tooltip";
@import "./components/shared/confirmation-modal/confirmation-modal";
@import "./components/shared/global-search/global-search.saved";
@import "./components//shared/global-search/global-search-modal";
@import "./components/shared/date-time-range-picker/date-time-range-picker";

// Components
@import "./components/unauthorized/unauthorized";
@import "./components/header/header";
@import "./components/feedback/feedback";
@import "./components/maintenance-messages/active-maintenance-message";
@import "./components/compare/compare";
@import "./components/compare/dropdown";
@import "./components/side-menu/side-menu";
@import "./components/pdf-viewer/pdf-viewer";
@import "./components/slider/slider.styles";
@import "./components/search/filters/drop-down";
@import "./components/search/search";
@import "./components/document-viewer/document-viewer";
@import "./components/document-viewer/document.controls";
@import "./components/saved-shortlist/saved-shortlist";
@import "./components/saved-shortlist/saved-shortlist.table";
@import "./components/shortlist-actions/rename.shortlist";
@import "./components/table-of-contents/table.of.contents";
@import "./components/shortlist/shortlist";
@import "./components/search/report.modal";
@import "./components/downloads/downloads";
@import "./components/my-searches/my-searches";
@import "./components/my-searches/published-searches";
@import "./components/my-searches/saved-searches.table";
@import "./components/my-searches/published-searches.table";
@import "./components/searchlist-actions/publish.search";
@import "./components/searchlist-actions/dropdown";
@import "./components/notifications/notifications";
@import "./components/my-comparisons/my-comparisons";
@import "./components/my-comparisons/saved-comparisons.table";
@import "./components/comparison/comparison";
@import "./components/manage-files/manage-files";
@import "./components/manage-files/mapfiles";
@import "./components/manage-files/table-mapfiles";
@import "./components/manage-files/mapfiles-row.scss";
@import "./components/manage-files/upload-files";
@import "./components/dashboard/dashboard";
@import "./components/dashboard/benchmark-dropdown";
@import "./components/workspace/workspace";
@import "./components/manage-files/edit-file";
@import "./components/visualizations/graph-tile";
@import "./components/peer-benchmark/saved-peerbenchmarks.table";
@import "./components/peer-benchmark/peer-benchmark";
@import "./components/peer-benchmark/create-new";
@import "./components/peer-benchmark/metrics-accordion";
@import "./components/peer-benchmark/benchmark-grid";
@import "./components/peer-benchmark/benchmark-grid-row";
@import "./components/peer-benchmark/cdpbenchmark-grid";
@import "./components/shared/dropdown/dropdown";
@import "./components/insights/insights";
@import "./components/insights/saved-insights-table";
@import "./components/insights/saved-insights-dashboard";
@import "./components/insights/saved-insight-tile";
@import "./components/insights/saved-insights-list";
@import "./components/insights/dashboard/insight-dashboard";
@import "./components/insights/dashboard/insight-dashboard-layout";
@import "./components/insights/talking-points-accordion";
@import "./components/search/request-upload-panel.scss";
@import "./components/powerbi-dashboard/powerbi-dashboard";
@import "./components/manage-files/validate-reports.scss";
@import "./components/tags/tag-card";
@import "./components/tags/tag-notification";
@import "./components/tags/tags-panel";
@import "./components/tags/tags-create";
@import "./components/shared/popover/popover";
@import "./components/manage-files/maintenance-messages";
@import "./components/manage-files/maintenance-message-modal";
@import "./components/manage-files/manage-adf-pipeline";
@import "./components/user-management/super-admin/super-admin";
@import "./components/user-management/user-management-modal/user-management-modal";
@import "./components/user-management/user-management-modal/user-dropdown";
@import "./components//module-access/module-access";
@import "./components/module-access/member-firm.drop-down";

// Tiles
@import "./components/insight-tile/main-tile";
@import "./components/visualizations/charts/reporting.tile.scss";
@import "./components/visualizations/charts/reporting.disclosure.tile.scss";
@import "./components/visualizations/charts/risk.opportunities.scss";
@import "./components/insight-tile/tiles/cdp-scope-3-categories-reported";
@import "./components/insight-tile/tiles/climate.related.incentive";
@import "./components/insight-tile/tiles/carbon.credit";
@import "./components/insight-tile/tiles/links.scss";
@import "./components/insight-tile/tiles/tabular.chart.scss";
@import "./components/insight-tile/tiles/energy.chart.scss";
@import "./components/insight-tile/tiles/climate-related-tabular";
@import "./components/insight-tile/tiles/pillar-score.scss";
@import "./components/insight-tile/tiles/climate.scss";
@import "./components/insight-tile/tiles/msci-esg-rating.scss";
@import "./components/insight-tile/tiles/add-additional-information.scss";
@import "./components/insight-tile/tiles/time-chart.scss";
@import "./components/insight-tile/tiles/targetTileReportingYear";
@import "./components/insight-tile/tiles/verification.scss";
@import "./components/insight-tile/tiles/targetTile";
@import "./components/insight-tile/tiles/reportingBoundaryClimate";
@import "./components/insight-tile/tiles/ghg-intensity";
@import "./components/insight-tile/tiles/waterTile.scss";
@import "./components/insight-tile/tiles/targets-tile-chart";
@import "./components/insight-tile/tiles/ghg-target.scss";
@import "./components/visualizations/charts/table-chart.scss";
@import "./components/visualizations/charts/cost.chart.scss";
@import "./components/visualizations/charts/detailed.score.chart.scss";
@import "./components/visualizations/charts/water-drop/water-drop.scss";
@import "./components/visualizations/charts/battery/battery-chart.scss";
@import "./components/insight-tile/tiles/waste.scss";
@import "./components/visualizations/charts/water-intensity";
@import "./components/visualizations/charts/horizontal.graduated.chart.scss";
@import "./components/visualizations/charts/circular-bar/circular-bar.chart.scss";
@import "./components/insight-tile/tiles/biodiversity-management.scss";
@import "./components/insight-tile/tiles/climate-related-disclosures-metrics.scss";
@import "./components/insight-tile/tiles/opportunitiesRenewableEnergy.scss";
@import "./components//insight-tile/tiles/toxicEmissionsWasteScore";
@import "./components//insight-tile/tiles/waterStress.scss";
@import "./components/insight-tile/tiles/waterIntensity.scss";
@import "./components//insight-tile/tiles/energyConsumption.scss";

//cookie-notice
@import "./components/cookie-notice/cookie-notice.scss";

html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  @include font-family;
}

.cookielink {
  display: none;
}
