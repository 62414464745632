.targetTile {
    &__container {
        display: flex;
        flex-direction: column;

        &--multipleTargets {
            padding-right: 1rem;
            padding-left: 1rem;

            &--tableView {
                background: none;
            }
        }
    }

    &__popoverContainer {
        display: inline-block;
        width: fit-content;
    }

    &__peersRowMultipleTiles {
        &--data {
            width: 100%;
        }
        &--content {
            &--container {
                display: flex;
                flex-direction: column;
                padding-top: 3rem;
                width: 100%;
            }
        }
        &--headers {
            display: flex;
            flex-direction: row;
            padding-bottom: 0.5rem;
            justify-content: space-around;
            padding-left: 9.5rem;
            width: 85%;

            &--baseYear {
                padding-left: 1rem;
            }
            &--targetYear {
                padding-left: 5rem;
            }

            &--title {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #212121;
            }
        }
        &--row{
            padding-bottom: 1rem;
            display: flex;
            width: 100%;
            align-items: center;

            // &--tableView {
            //     border: 1px solid $lightest-grey;
            //     border-radius: 0.5rem;
            //     box-shadow: 0 0.25rem 0.5rem 0 $lightest-grey;
            // }

            &--companyName {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                min-width: 15%;
                max-width: 15%;
            }
            &--values {
                &--container { 
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 85%;
                    justify-content: space-around;
                }
                &--cell {
                    display: flex;
                    flex-direction: column;
                    width: 25%;

                    &--oneTarget {
                        width: 50%;
                    }
                }
            }
        }

        &--value {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            position: relative;
            color: $dark-grey;
        
            &-wrapper {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              width: 25%;
            }
            &-wrapperMultiValue {
                display: flex;
                justify-content: center;
            }
        
            &-tooltip {
              display: flex;
              top: -110%;
            }
        
            &--small {
              font-size: 14px;
              line-height: 16px;
            }
            &--fix-wdith {
              min-width: 60%;
              max-width: 60%;
              flex-grow: 1;
            }
        
            &--grow {
              flex-grow: 1;
            }
            
            &--unit {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 12px;
                color: $neutral-black;
            }
    
            &--number {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: 32px;
                color: $neutral-black;
            }
    
            &--text {
                &--center {
                    text-align: center;
                }
            }
          }
        // &--data {
        //     display: flex;
        // }

    }


    &__eachRow {

        &--paddingBot {
            padding-bottom: 2rem;
        }
    }

    &__targetHeader {
        display: flex;
        flex-direction: row;
        position: relative;
        top: 1rem;
        left: 1rem;

        &--title {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #212121;
        }
    }

    &__mainContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &--multipleTargets {
            position: relative;
            bottom: 1rem;
            min-height: 11.25rem;
        }
    }

    &__row {
        display: flex;
        column-gap: 1.625rem;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1rem;

        &--multipleTiles {
            justify-content: center;
        }
        &--dashed-border {
            border-width: 0 0 1px 0;
            border-style: dashed;
            border-color: #e6e6e6;
            padding: 1rem 0.5rem;
        }
        &--dashed-top-border {
            border-width: 1px 0;
        }
        &--table {
            justify-content: space-between;
        }
    }
    &__peerBenchMark {
        &--name {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            min-width: 30%;
            max-width: 30%;
        }
    }

    &__metricContainer {
        width: 32%;
        min-height: 11.25rem;

        &--multipleTiles {
            padding-right: 1rem;

            &--targetYear {
                padding-left: 2rem;
            }

            &--widthLarger {
                width: 45%;
            }
        }

        &--tableView {
            border: 1px solid $lightest-grey;
            border-radius: 0.5rem;
            box-shadow: 0 0.25rem 0.5rem 0 $lightest-grey;

            &--multipleTargets {
                border: none;
                border-radius: none;
                box-shadow: none;
            }
        }

        &--baseYear {
            background: linear-gradient(180.75deg, #F7EDED 0.64%, #FFFFFF 135.65%);
            border-radius: 0.5rem;
            box-shadow: 0 0.25rem 0.5rem 0 $lightest-grey;
        }

        &--targetYear {
            background: linear-gradient(180.75deg, #E3F2E5 0.64%, #FFFFFF 135.65%);
            border-radius: 0.5rem;
            box-shadow: 0 0.25rem 0.5rem 0 $lightest-grey;
        }

        &--targetReduction {
            background: linear-gradient(180.75deg, #F1EEF8 0.64%, #FFFFFF 135.65%);
            border-radius: 0.5rem;
            box-shadow: 0 0.25rem 0.5rem 0 $lightest-grey;
        }
    }

    &__noData {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    &__value {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: right;
        position: relative;
        color: $dark-grey;
    
        &-wrapper {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }
    
        &-tooltip {
          display: flex;
          top: -110%;
        }
    
        &--small {
          font-size: 14px;
          line-height: 16px;
        }
        &--fix-wdith {
          min-width: 60%;
          max-width: 60%;
          flex-grow: 1;
        }
    
        &--grow {
          flex-grow: 1;
        }
        
        &--unit {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 12px;
            color: $neutral-black;
        }

        &--number {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 32px;
            color: $neutral-black;
        }

        &--text {
            &--center {
                text-align: center;
            }
        }
      }

    &__metric {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding-top: 1rem;

        &--multipleTiles {
            &--title {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                text-align: center;
                color: #212121;
                height: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                top: 3.5rem;

                &--peerBenchmark {
                    justify-content: flex-end;
                }
            }

            &--content {
                height: 80%;
            }

            &--fieldName {
                display: flex;
                justify-content: flex-end;
                padding-bottom: 0.5rem;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #212121;

            }

            &--peerBenchmark {
                padding-top: 4rem;
            }
        }
        

        &--content{
            padding: 1rem 1rem 2rem 1rem;
        }

        &--reductionPercent {
            padding-top: 0rem;
        }

        &--title {
            padding-top: 1rem;
            padding-left: 1rem;

            &--text {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 16.5px;
                line-height: 24px;
                color: #212121;
                text-align: left;
            }
        }

        &--fieldValue {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 40px;
            line-height: 40px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #212121;
            justify-content: center;
            height: 100%;

            &--noData {
                font-size: 1rem;
                font-weight: 700;
            }

            &--targetReduction {
                padding-bottom: 1.5rem;
            }
            &--text {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                /* or 120% */

                display: flex;
                align-items: center;
                text-align: center;
                color: $neutral-black;
            }
        }
    }
}