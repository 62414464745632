.waterIntensityTile {
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: inherit;
  }
  &__legend {
    &-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: flex-start;
      row-gap: 0.75rem;
      margin-bottom: 2rem;
      margin-top: 0.5rem;
    }

    &-text {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: $dark-grey;
    }

    &-companies {
      display: flex;
      column-gap: 1rem;
    }

    &-company {
      &-block {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: $dark-grey;
        column-gap: 0.5rem;
      }

      &-pill {
        width: 20px;
        height: 6px;
        border-radius: 4px;
        background-color: #007cb0;
      }
    }
  }

  &__peerChart-Content {
    padding-right: 0.4rem;
    width: inherit;
    height: 100%;

    @media screen and (min-width: 1500px) {
      padding-right: 0.5rem;
      // padding-top: 3.5rem;
      // padding-bottom: 6rem;
    }

    @media screen and (min-width: 1650px) {
      padding-right: 1rem;
      // padding-top: 3.5rem;
      // padding-bottom: 6rem;
    }
  }

  &__detailChart-Content {
    margin-top: 1rem;
    white-space-collapse: break-spaces;
    width: inherit;
    height: 100%;
  }

  &__table-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding-top: 2rem;
    width: 100%;
    height: 100%;
    &--company-peer {
      & .tableChart__table-body tr:nth-child(1) {
        td {
          border-top: 1px dashed $light-grey;
        }
      }
    }
  }

  &__industry-table {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
}
