.water {
    display: flex;
    width: 100%;

    &__container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__textHeader {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 16px;
        text-align: left;
        color: #012169;
        display: flex;
        align-items: center;
        word-wrap: break-word;
        white-space: break-spaces;

        &--tableView {
            color: #212121;
        }
    }

    &__peerView {
        width: 100%;

        &--container {
            display: flex;
            flex-direction: column;
        }

        &--header{
            display: flex;
            flex-direction: row;
            width: 100%;
            background: linear-gradient(180.75deg, #EEF5F8 0.64%, rgba(238, 245, 248, 0) 135.65%);
            border-radius: 4px;
            justify-content: flex-end;

            &--tableView {
                display: flex;
                flex-direction: row;
                width: 100%;
                background: none;
                border-radius: none;
                justify-content: flex-end;
                border-width: 0 0 1px 0;
                border-style: dashed;
                border-color: #e6e6e6;
            }


            &--companyName {
                width: 50%;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                color: #012169;
                display: flex;
                align-items: center;
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
                display: flex;
                justify-content: center;

                &--tableView {
                    color: #212121;
                    width: 20%;
                    display: flex;
                    justify-content: center;
                }

                &--detailedView {
                    width: 20%;
                    display: flex;
                    justify-content: center;
                }
            }
        }

        &--headerValues {
            display: flex;
            flex-direction: row;
            width: 60%;
            justify-content: space-around;

            &--tableView {
                display: flex;
                flex-direction: row;
                width: 80%;
                justify-content: space-around;
            }
        }

        &--valuesContainer {
            display: flex;
            flex-direction: column;
            padding-top: 1rem;

            &--tableView {
                padding-top: 0rem;
            }
        }

        &--rowContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 1rem;
            align-items: center;

            &--tableView {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-bottom: 1rem;
                padding-top: 1rem;
                align-items: center;
                border-width: 0 0 1px 0;
                border-style: dashed;
                border-color: #e6e6e6;

                &--companyName {
                    display: flex;
                    flex-wrap: wrap;
                    width: 20%;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.75rem;
                    line-height: 16px;
                    color: #212121;
                    display: flex;
                    align-items: center;
                }

                &--valuesContainer {
                    display: flex;
                    flex-direction: row;
                    width: 80%;
                }
                &--value {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 0.75rem;
                    line-height: 16px;
                    text-align: center;
                    color: #212121;
                    display: flex;
                    justify-content: space-around;
                    width: 100%;
                }
            }

            &--valuesContainer {
                display: flex;
                flex-direction: row;
                width: 60%;
            }

            &--metricName {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                display: flex;
                align-items: center;
                color: #757575;
                display: flex;
                flex-wrap: wrap;
                width: 40%;
            }

            &--value {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                color: #212121;
                display: flex;
                justify-content: space-around;
                width: 100%;
            }
        }
    }

    &__companyView {
        display: flex;
        width: 100%;
        
        &--row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 0.5rem;
            padding-top: 0.5rem;

            &--tableView {
                border-width: 0 0 1px 0;
                border-style: dashed;
                border-color: #e6e6e6;

                &--topBorder {
                    border-width: 1px 0;
                }
            }

            &--metricName {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #757575;
                text-align: left;
            }

            &--metricValue {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: #212121;
            }
        }
    }

    &__noData {
        align-items: center;
        justify-content: center;
        font-family: "Open Sans";
        font-style: normal;
        font-size: 1rem;
        font-weight: 700;
        line-height: 16px;
        display: flex;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        width: 100%;
    }

    &__noData--footer {
        justify-content: flex-start;
        padding-top: 0.5rem;
        display: flex;
        font-size: 0.75rem;
    }
}