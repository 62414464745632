.bench-mark {
  position: relative;

  &__outline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    padding: 0.5rem 0.6rem;
    background-color: $white;
    border: 1px solid $light-grey;
    border-radius: 0.25rem;
    height: 1.5rem;
    &:hover,
    &:focus-within {
      border-color: $primary;
    }
    &-disabled {
      background-color: $lightest-grey;
      &:hover,
      &:focus-within {
        border-color: $light-grey;
      }
    }
  }

  &__outlineFilter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.6rem;
    background-color: $white;
    border: 1px solid $light-grey;
    border-radius: 0.25rem;
    height: 1rem;
    width: 15rem;
    &:hover,
    &:focus-within {
      border-color: $primary;
    }
  }

  &__outBox {
    margin-right: 1rem;
  }

  &__benchmarkReportOutlineFilter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.6rem;
    background-color: $white;
    border: 1px solid $light-grey;
    border-radius: 0.25rem;
    height: 1rem;
    width: 8rem;
    &:hover,
    &:focus-within {
      border-color: $primary;
    }
  }

  &__option-value {
    text-align: left;
  }

  &__placeholder {
    position: absolute;
    color: $grey;
    font-size: 0.83rem;
  }
  &__placeholder--filter-type {
    position: absolute;
    color: $grey;
    font-size: 0.83rem;
    padding-left: 0.3rem;
  }

  &__placeholder--dashboardType {
    padding: 0;
    position: absolute;
    color: $grey;
    font-size: 0.83rem;
  }

  &__input {
    //padding-left: 0.5rem;
    height: 1.5rem;
    overflow: hidden;
    border: none;
    flex-grow: 1;
    width: 1px;
    outline: none;
    z-index: 1;
    resize: none;
    background-color: transparent;
    @include font-family;
    font-size: 0.875rem;
    line-height: 1.5rem;

    &:focus {
      background-color: $white;

      + .bench-mark__placeholder {
        display: none;
      }
    }
  }

  &__input-size {
    min-height: 1.25rem;
    min-width: 1px;
    max-width: 14rem;
    visibility: hidden;
    position: absolute;
  }

  &__values {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding-top: 0.5rem;
  }

  &__value {
    font-size: 0.75rem;
    background-color: $lightest-grey;
    padding: 0.25rem 0.35rem 0.25rem 0.75rem;
    border-radius: 6.875rem;
    display: flex;
    line-height: 1.25rem;

    &--content {
      background-color: transparent;
      margin-top: 0;

      &:first-child {
        margin-top: 0.5rem;
      }
    }
  }

  &__option-container {
    display: flex;
    flex-direction: column;
  }

  &__option-value {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  &__option-secondary-value {
    font-size: 0.75rem;
    line-height: 1rem;
    color: $light-grey;
  }

  &__remove {
    @include invisible-button;
    background-color: $grey;
    border-radius: 1rem;
    margin-left: 0.5rem;

    path {
      fill: $white;
    }
  }

  &__chevron-button {
    @include invisible-button;
    &:hover {
      background-color: $light-grey;
      transition: 0.7s;
      border-radius: 4px;
    }
    &--not-empty {
      margin-top: 0.5rem;
    }
  }

  &__chevron-icon {
    cursor: pointer;
    transition: transform 200ms ease-in-out;
    text-align: right;
    &--open {
      transform: rotate(180deg);

      path {
        fill: $primary;
      }
    }

    &--close {
      transform: rotate(0);
    }
  }

  &__options {
    width: 100%;
    border-radius: 0.25rem;
    margin-top: 0.25rem;
    background-color: $white;
    position: absolute;
    z-index: 2;
    overflow: hidden;
    transition: max-height 200ms ease-in-out;

    &--ontop {
      z-index: 100;
      width: 9.2rem;
    }

    &--show {
      max-height: 15rem;
      border: 1px solid $light-grey;
      min-height: 2.5rem;
      box-shadow: 0px 1px 4px $light-grey;
      overflow: auto;
    }

    &--hide {
      max-height: 0rem;
    }
    &--region-hide {
      width: 0rem;
      max-height: 0rem;
      max-width: 0rem;
      
    }
  }

  &__loading {
    margin: 0 auto;
  }

  &__vendorTag {
    border: 1px solid $dark-grey;
    border-radius: 110px;
    padding: 0.25rem 0.5rem;
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 10px;
    max-width: 1.5rem;
  }

  &__option {
    @include invisible-button;
    background-color: $white;
    padding: 0.75rem 0.5rem;
    width: 100%;
    display: flex;

    table {
      width: 100%;
    }
    &-item {
      display: flex;
      width: 100%;

      &--name {
        text-align: left;
        padding-right: 1rem;
        flex-wrap: wrap;
        width: 65%;
      }
      &--ticker {
        text-align: left;
        padding-right: 1rem;
        flex-wrap: wrap;
        width: 20%;
        min-width: 20%;
      }
      &--vendor-wrapper {
        padding-left: 1rem;
        text-align: right;
        display: flex;
        column-gap: 0.25rem;
        align-items: flex-end;
        width: 15%;
      }
    }
    &--selected {
      background-color: $lightest-grey;
    }

    &:first-child {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }

    &:last-child {
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    &:hover {
      background-color: $lightest-grey;
    }
  }

  &__unclickable {
    cursor: auto;
    line-height: 1.5rem;
    font-size: 0.875rem;

    &:hover {
      background-color: transparent;
    }
  }

  &__option-category {
    padding: 0.5rem 1rem;
    color: $grey;
    font-weight: 700;
  }

  &__option-value {
    text-align: left;
  }
  &__option-items {
    display: flex;
    line-height: 24px;
    span {
      margin-right: 0.4rem;
      transform: translateY(-0.2rem);
      font-size: 0.9rem;
    }
  }
  &__compare-button {
    position: relative;
    @include invisible-button;
    color: #007cb0;
    padding-right: 1rem;
    padding-left: 1rem;
    background-color: $white;
    padding: 0.75rem 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;

    &--selected {
      background-color: $lightest-grey;
    }

    &:first-child {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }

    &:last-child {
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    &:hover {
      background-color: $lightest-grey;
    }

    font-size: 14px;
    line-height: 24px;
    &--disabled {
      color: #75787b;
      cursor: pointer;
      path {
        fill: #75787b;
      }
    }
    .tooltip {
      left: -95%;
      top: -125%;
      z-index: 2;
      display: none;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }
  &__compare-button-new {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 0.9rem;
    line-height: 1.5rem;
    span {
      padding-left: 0.5rem;
      transform: translateY(-0.1rem);
    }
  }

  &__option-container {
    position: relative;
    .tooltip {
      display: none;
      left: -0.9rem;
      z-index: 2500;
      white-space: pre-wrap;

      .tooltip__arrow {
        display: none;
      }
      .tooltip__label {
        overflow: auto;
        overflow-x: hidden;
        // overflow-y: scroll;
        max-width: 19.5rem;
        min-width: 19.5rem;
        max-height: 4rem;
        transform: translateY(-15%);
        position: absolute;
      }
    }

    svg:not(:root) {
      transform: translateX(8px) translateY(88px);
    }

    &:hover {
      cursor: pointer;

      .tooltip {
        display: inline-block;
        z-index: 2500;
      }
    }
  }
}
