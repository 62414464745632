$base-z-index: 1101;

.comparison {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $white;
  z-index: $base-z-index;

  &__header {
    padding: 1rem;
    border-bottom: 1px solid $secondary-light-grey;

    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__exportPanel {
    height: 100%;
    margin-top: 0rem;

    &__header {
      width: 100%;
      min-height: 3rem;
      height: fit-content;
      left: 0rem;
      top: 0rem;
      box-shadow: inset 0px -1px 0px #e7e7e7;
      padding-bottom: 5rem;

      &__cross {
        position: absolute;
        right: 1rem;
        top: 0.5rem;
        cursor: pointer;
      }
    }

    &__body {
      box-sizing: border-box;
      width: 100%;
      background: #ffffff;
      overflow-x: hidden;
      height: calc(100% - 4.5rem);
      font-size: 14px;
      padding: 0 1rem;
    }

    &__exportbody {
      box-sizing: border-box;
      width: 100%;
      padding: 0 1rem;
      font-size: 14px;
      overflow-x: hidden;
    }

    &__dashboard-name {
      &--label {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
      }
    }
  }

  &__searchPanel {
    height: 100%;
    margin-top: 0rem;

    &__header {
      width: 100%;
      min-height: 3rem;
      height: fit-content;
      left: 0rem;
      top: 0rem;
      box-shadow: inset 0px -1px 0px #e7e7e7;
      padding-bottom: 5rem;

      &__cross {
        position: absolute;
        right: 1rem;
        top: 0.5rem;
        cursor: pointer;
      }
    }

    &-textbox {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 0.5rem;
      padding: 0.5rem 1.5rem 0.5rem 1rem;
      background-color: #fff;
      border: 1px solid #d0d0ce;
      border-radius: 0.25rem;
      min-height: 4.5rem;
      flex-grow: 1;
      outline: none;
      resize: none;
      font-family: "Open Sans";
      font-size: 14px;
      width: -webkit-fill-available;
      @include vertical-scroll;
      &--error {
        border-color: $red;
        margin-bottom: 0.5rem;
      }
    }

    &__body {
      box-sizing: border-box;
      width: 100%;
      background: #ffffff;
      overflow-x: hidden;
      height: calc(100% - 4.5rem);
      font-size: 14px;
      padding: 2rem 1rem 1rem 1rem;
      position: relative;
    }

    &__icon {
      position: absolute;
      right: 1.25rem;
      top: 2.75rem;
    }

    &__error-message {
      color: $red;
      font-size: 0.75rem;
    }
  }

  &__title {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-grow: 1;
  }

  &__title-actions {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__selector-title {
    margin-left: 0.75rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      .tooltip {
        display: flex;
      }
    }

    .tooltip {
      top: 2.5rem;
      left: 4.5rem;
      display: none;

      &__label {
        max-width: 40rem;
        white-space: normal;
        text-align: left;
        position: fixed;
      }

      &--bottom__arrow {
        margin-left: 1rem;
      }
    }
  }

  &__comparison-name {
    flex-grow: 1;
    margin-left: 0.75rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 1px;

    &:hover {
      .tooltip {
        display: flex;
      }
    }

    .tooltip {
      top: 3.5rem;
      left: 1rem;
      display: none;

      &__label {
        max-width: 40rem;
        white-space: normal;
        text-align: left;
        position: fixed;
      }

      &--bottom__arrow {
        margin-left: 1rem;
      }
    }
  }

  &__container {
    flex: 1;
    display: flex;
    position: relative;

    &--hide-document {
      .document-viewer__title,
      .document-viewer__company,
      .fixed-report .report-dropdown {
        display: none;
      }
    }

    &--hide-keywords {
      .document-viewer__keywords {
        display: none;
      }
    }
  }

  &__share-compare-rightHand-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__share-compare-button-icon,
  &__export-container {
    display: flex;
  }

  &__share-compare-button,
  &__export-button {
    @include primary-button-outline;
    @include font-family;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-direction: row;
    border: none;
    background: none;
    padding: 0rem 0.5rem;
    margin-right: 0rem;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    &--disabled {
      @include font-family;
      display: flex;
      align-items: center;
      flex-direction: row;
      background: none;
      border: none;
      color: #75787b;
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;

      path {
        fill: #75787b;
      }
    }
  }

  &__share-compare-button {
    gap: 0rem;
  }

  &__share-compare-button,
  &__export-button {
    position: relative;
    .tooltip {
      display: none;
      line-height: 1rem;
      left: -3rem;
      top: 1.5rem;

      &__arrow {
        left: 5rem;
      }
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__share-compare-icon {
    padding-bottom: 0.13rem;
    path {
      fill: $primary;
    }

    &--disabled {
      padding-right: 0.5rem;
      padding-bottom: 0.13rem;
      path {
        fill: #75787b;
      }
    }
  }

  .split {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .gutter {
    background-color: $secondary-light-grey;
    background-repeat: no-repeat;
    background-position: 50%;

    &:hover {
      cursor: col-resize;
    }
  }

  &__guidance {
    cursor: none;
    z-index: 9999;

    .tooltip {
      @include white-tooltip;
    }
  }

  &__report-dropdown {
    position: absolute;
    width: 80%;
    padding: 1rem;
    box-sizing: border-box;
    border: 1px solid transparent;
    z-index: $base-z-index + 2;

    &--sec {
      font-size: 0.875rem;
    }

    &--esg {
      padding-left: 4.5rem;
      padding-bottom: 0.55rem;
    }

    &--hidden {
      display: none;
    }
  }

  &__base-report-title {
    cursor: pointer;
    background-color: $white;
    display: flex;

    &--sec {
      height: 1.2rem;
    }
  }

  &__dropdown-icon {
    margin-left: 1rem;
    transition: transform 200ms ease-in-out;
    align-self: center;

    &--open {
      transform: rotate(180deg);
    }
  }

  &__hidden-menu {
    display: none;
    margin-top: 0.875rem;
    flex-direction: column;
    border: 1px solid $lightest-grey;
    box-shadow: 0 1px 4px rgba($black, 0.12);

    &--open {
      display: flex;
      overflow: auto;
      overflow-x: hidden;
      max-height: 70vh;
    }
  }

  &__menu-item {
    @include invisible-button;
    text-align: left;
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
    font-family: "Open Sans";
    background-color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    &:hover {
      background-color: $lightest-grey;
    }

    &-title {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__report-controls {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 50%;
    left: 0.2rem;
    right: 1.5rem;
    z-index: $base-z-index;
  }

  &__control {
    @include invisible-button;
    position: absolute;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &__base-report {
    display: flex;
    min-width: 40%;
    position: relative;

    .comparison__report-dropdown {
      &--sec {
        padding: 0.55rem 3rem;
      }
    }
  }

  &__other-reports {
    display: flex;
    min-width: 40%;
    position: relative;

    .comparison__report-dropdown {
      &--sec {
        padding: 0.55rem 1rem;
      }

      &--esg {
        padding: 1rem 2.5rem;
      }
    }
  }

  &__report {
    display: flex;
    width: 100%;
    height: 100%;

    &--hide {
      display: none;
    }
  }

  &__settings-title {
    color: $grey;
    cursor: none;
    font-size: 0.75rem;
    padding: 1rem 1rem 0.5rem 1rem;
  }

  &__settings-menu {
    width: 15rem;
    position: absolute;
    background-color: $white;
    z-index: $base-z-index + 5;
    //left: 10rem;
    top: 1.5rem;
  }

  &__reorder-panel {
    display: none;
    position: absolute;

    &--open {
      display: flex;
      width: 25rem;
      height: 100%;
      background-color: white;

      z-index: $base-z-index + 5;
      overflow-y: auto;

      box-shadow: 0.125rem 0rem 2rem rgba(0, 0, 0, 0.06);
    }

    &--items {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &--header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 3.5rem;
      box-shadow: inset 0rem -0.063rem 0rem #e7e7e7;

      &--title {
        @include font-family;
        padding-top: 1rem;
        padding-left: 1rem;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #212121;
      }

      &--close {
        top: 0.1rem;
        right: 1.375rem;
      }
    }

    &--guidance {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 0.625rem;
      line-height: 1.5rem;
      text-transform: uppercase;
      color: #757575;
      display: flex;

      padding-top: 1rem;
      justify-content: center;
    }

    &--documents {
      &--report {
        display: flex;
        flex-direction: row;
        align-items: center;

        padding-left: 1.313rem;

        &:hover {
          background: #f2f2f2;
          cursor: pointer;
        }

        &--title {
          @include font-family;
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.5rem;
          color: $black;
          align-items: center;
          position: relative;
          white-space: nowrap;
          padding: 0.5rem 0.75rem;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;

          .tooltip {
            display: none;
            list-style: none;
            line-height: 1rem;
            left: 2rem;
            top: 1rem;
            z-index: 1000;
            .tooltip__label {
              width: 18rem;
              max-height: fit-content;
              position: absolute;
              font-weight: inherit;
              color: white;
            }
            .tooltip__arrow {
              bottom: -6px;
            }
          }
          .tooltip--top {
            .tooltip__label {
              font-weight: inherit;
              color: white;
              bottom: 0;
            }
            .tooltip__arrow {
              bottom: -4px;
            }
          }

          .tooltip--bottom {
            .tooltip__label {
              transform: translateY(-93%);
              transform: translateY(1rem);
            }
            .tooltip__arrow {
              left: 5px;
              top: 16px;
              bottom: auto;
            }
          }
          &:hover {
            .tooltip {
              display: inline-block;
            }
          }
        }

        &--icon {
          margin-top: -0.5rem;
        }
      }
    }
  }

  &__empty-section {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: $lightest-grey;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
    max-width: 39rem;
  }

  &__empty-image {
    max-width: 13.5rem;
  }

  &__empty-title {
    font-weight: 600;
    padding: 0 0.5rem;
  }

  &__empty-message {
    font-size: 0.875rem;
    color: $grey;
  }

  &__action {
    @include invisible-button;
    display: flex;
    padding: 0.5rem 1rem;
    background-color: $primary;
    border-radius: 0.25rem;
    margin-top: 1rem;
    align-items: center;
    color: $white;

    &:hover {
      background-color: $dark-primary;
    }

    div:first-child {
      padding-right: 0.5rem;
    }

    path {
      fill: $white;
      margin-right: 0.5rem;
    }
  }

  &__close {
    position: relative;

    .tooltip {
      top: 0;
      z-index: $base-z-index + 5;
      display: none;
    }
    .tooltip--left {
      right: 100%;
      left: unset;
    }

    .tooltip--right {
      left: 100%;
      right: unset;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__icon {
    position: relative;
    @include invisible-button;

    .tooltip {
      top: 0;
      z-index: $base-z-index + 5;
      display: none;
    }
    .tooltip--left {
      right: 100%;
      left: unset;
    }

    .tooltip--right {
      left: 100%;
      right: unset;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__reorder {
    display: flex;
    position: relative;

    .tooltip {
      display: none;
      top: 1.5rem;
      left: -2.4rem;
      position: absolute;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__reorder--icon {
    .tooltip {
      display: none;
      top: 1.5rem;
      left: -2.4rem;
      position: absolute;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__save-dropdown-menu {
    position: absolute;
    width: 100%;
    border: 1px solid $light-grey;
    top: 2.75rem;
    z-index: 5;
  }

  &__save-dropdown-btn {
    display: flex;
    height: 2.5rem;
    @include primary-button-outline;
    @include font-family;
    align-items: center;
    padding: 0;
  }

  &__save-as-dropdown-btn {
    display: flex;
    @include primary-button-outline;
    @include font-family;
    align-items: center;
    padding: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    &--active {
      background-color: $dark-blue;
      & svg path {
        fill: white;
      }
    }
  }
  &__save-label {
    padding: 0.5rem 1.5rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  }
  &__save-icon {
    width: 2.5rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__contributor-list {
    display: flex;
    gap: 0.5rem;
  }

  &__contributor-icon {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.625rem;
    font-weight: 600;
    border-radius: 100%;
    color: $white;
    background-color: $black;
    display: flex;
    justify-content: center;
    align-items: center;
    .tooltip {
      display: none;
      top: 2rem;
      left: unset;
      right: 0;
    }

    &:hover .tooltip {
      display: block;
    }

    &--more {
      background-color: $light-grey;
      color: $grey;

      .tooltip {
        top: 0;
        padding-top: 2rem;
        .tooltip__label {
          padding: 0.5rem;
          overflow: auto;
          max-width: 35rem;
          max-height: 15rem;
          min-width: 15rem;
          background: $white;
          color: $black;
          border: 1px solid $light-grey;
          flex-direction: column;
          display: flex;
          gap: 0.5rem;
          > span {
            display: flex;
            gap: 0.5rem;
          }
        }
      }
    }
  }

  .tooltip {
    z-index: $base-z-index + 5;
  }

  .document-viewer__title {
    min-height: 2.25rem;
    align-items: center;
  }

  .base-star {
    margin-right: 1rem;
  }

  .loading-icon {
    @include loading-icon;
  }
}
