.linksTile {
    &__body {
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
      padding-top: 0.5rem;
      position: relative;
      &--no-data {
        height: 100%;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  
    &__link {
      &-container {
        display: flex;
        margin-top: 1rem;
        cursor: pointer;
        text-decoration: none;
      }
      &-data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      &-icon {
        width: 2.5rem;
        height: 2.5rem;
        background: linear-gradient(
          180.75deg,
          #eef5f8 0.64%,
          rgba(238, 245, 248, 0) 135.65%
        );
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
        flex-grow: 0;
      }
  
      &-text {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        color: $neutral-black;
      }
      &-label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
      &-description {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        color: $dark-grey;
      }
  
      &-external {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.5rem;
      }
    }
  
    &__add {
      display: flex;
      margin-top: 1.5rem;
      background-color: #ffffff;
      border: none;
      align-items: center;
      cursor: pointer;
      &-text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $primary;
        margin-left: 0.25rem;
      }
      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    &__no-data {
      min-height: 3rem;
      transform: unset;
      font-weight: bold;
    }
  }