.workspace {
  width: 100%;
  height: 100%;

  &__header {
    display: flex;
    flex-direction: row;
    padding: 0 2rem;
    align-items: center;
    box-shadow: inset 0 -1px 0 $lightest-grey;
    position: fixed;
    z-index: 52;
    background-color: white;
    width: calc( 100% - 7.5rem)
  }

  &__commenting {
    margin-right: 0.4rem;
  }

  &__content {
    display: flex;
    flex-direction: row;
   
    overflow: auto;
    box-sizing: border-box;
  }

  &__content__myworkspace {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    overflow: auto;
    z-index: 100;
    height: inherit;
    overflow: hidden;
    &--100-h{
      height: 100%;
    }
    &--mt-3{
     margin-top: 3rem;
    }
  }

  &__tab-panel {
    flex-grow: 1;
    display: none;
  
    &--selected {
      display: flex;
    }
  }

  &__feedback-section {
    min-width: 1rem;
  }

  &__feedback-button {
    @include font-family;
    background-color: $primary;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    border: none;
    border-radius: 0.25rem;
    color: $white;
    font-weight: 400;
    line-height: 1rem;
    font-size: 0.875rem;
  }
}
