.checkbox {
  position: relative;
  z-index: 1;

  &__label {
    cursor: pointer;
  }

  &:indeterminate {
    background: $primary;
    color: $white;
  }

  &__indeterminate {
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: 1;
    height: 1rem;
    width: 1rem;
    background: #0075ff;
    border-radius: 2px;
    border: 1px solid #0075ff;
    margin: 3px 3px 3px 4px;
    box-sizing: border-box;
    cursor: pointer;

    path {
      fill: $white;
    }

 
  }

  &__checkbox-blue {
    pointer-events: none;
    position: absolute;
    top:0;
    z-index: 1;
    height: 1rem;
    width: 1rem;
    background: #007cb0;
    border-radius: 2px;
    margin: 3px 3px 3px 4px;
    box-sizing: border-box;
    cursor: pointer;

    &--global-search{
      transform: translateY(-50%);
      top: 50%;
    }
  }
}

.checkbox:hover {
  cursor: pointer;
}
