.mapfiles-table-row {
  &__row {
    height: fit-content;
    box-shadow: inset 0 -0.063rem 0 #ebebeb;
    box-sizing: border-box;

    &--missing {
      background: #ffecea;
      box-shadow: inset 0px -1px 0px #e0e0e0;
    }

    &--title {
      display: flex;
      box-sizing: border-box;
      flex-direction: row;
      justify-content: flex-start;
      padding: 1rem 0rem 1rem 1rem;

      &--icon {
        padding-right: 0.5rem;
      }

      .tooltip {
        display: none;
        list-style: none;
        line-height: 1rem;
        left: 5rem;
        top: 0rem;
        white-space: pre-wrap;
        word-wrap: break-word;
        z-index: 10;
      }

      &:hover {
        .tooltip {
          display: inline-block;
        }
      }
    }

    &--cik {
      position: relative;
      padding-top: 0.3rem;
      top: 0.17rem;
    }

    &--reportYear {
      display: flex;
      position: absolute;
      right: 0rem;
    }

    &--esgFrameWork {
      position: absolute;
      right: 0;
    }

    &--reportType {
      position: absolute;
      right: 0rem;
    }

    &--removeIcon {
      // position: relative;
      padding: 1.3rem 0 0 1rem;
      right: 2rem;
      display: flex;
      justify-content: flex-end;

      &:hover {
        cursor: pointer;
      }

      &--red {
        display: flex;

        // &__inner{
        //     //padding-left: 3rem;
        //     //margin-left: calc(19.5%);
        // }
      }
    }

    &--errorMessage {
      display: flex;
      flex-direction: row;
      min-width: 55rem;
      //padding-left: 10rem;
      padding-top: 1rem;
      // margin-right: 32rem;
      // margin-left: 15rem;
      flex-grow: 1;

      &--title {
        color: #da291c;
        position: relative;
        padding-left: 1rem;
        left: 20rem;
      }
      &--icon {
        padding-top: 0.1rem;
        position: relative;
        left: 20rem;
      }
    }
  }

  &__missingField {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    margin-bottom: 0.5rem;

    color: #da291c;
  }

  &__input {
    border: 0.063rem solid #d0d0ce;
    box-sizing: border-box;
    border-radius: 0.25rem;
    z-index: 1;
    resize: none;
    background-color: transparent;
    @include font-family;
    font-size: 0.875rem;
    width: 9rem;
    height: 3rem;
    padding-top: 0.75rem;
    padding-left: 1rem;

    &:focus {
      background-color: $white;
    }

    &--missing {
      border: 0.063rem solid #da291c;
    }
  }

  &__dropdown {
    display: flex;
    &--report {
      width: 9rem;
      padding-left: 0rem;
      &--missing {
        border: 0.063rem solid #da291c;
      }
    }

    &--framework {
      width: 11rem;
      padding-left: 0rem;
    }

    &--year {
      width: 10rem;
      padding-left: 0rem;
    }
  }
}
