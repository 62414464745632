.EditModal {
  width: 20rem;
  min-height: 9rem;
  height: auto;
  left: 760px;
  top: 410px;
  display: flex;
  flex-direction: column;

  &--saveSearchlist {
    width: 25rem;
  }
  &--deleteSearch {
    min-height: 5.3rem;
  }
  &__name {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: $black;
    font-weight: 500;
    text-align: left;

    &--saveSearchList {
      white-space: pre-wrap;
      word-wrap: break-word;
    }
    &--deleteSavedSearch {
      font-size: 14px;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }

  &__nameError {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #f54242;
    font-weight: 500;
    text-align: left;
  }

  &__input {
    width: 100%;
    height: auto;
    padding-top: 0.5rem;
    text-align: center;
    outline: none;

    & input,
    textarea {
      width: 80%;
      height: fit-content;
      padding: 0.6rem;
      color: #212121;
      border: 1px solid #d0d0ce;
      box-sizing: border-box;
      border-radius: 4px;
      outline: none;
      @include font-family;
      &:hover,
      &:focus-within {
        border-color: $primary;
      }
    }
    &--saveSearchList input,
    textarea {
      padding: 0.75rem 1rem;
      width: 100%;
      @include font-family;
      &:hover,
      &:focus-within {
        border-color: $primary;
      }
    }
  }

  &__inputError {
    width: 100%;
    height: auto;
    padding-top: 0.5rem;
    text-align: center;
    outline: none;

    & input,
    textarea {
      width: 90%;
      height: fit-content;
      padding: 0.6rem;
      color: #212121;
      border: 1px solid #f54242;
      box-sizing: border-box;
      border-radius: 4px;
      outline: none;
    }
    &--saveSearchList input,
    textarea {
      padding: 0.75rem 1rem;
      width: 100%;
    }
  }

  &__content {
    text-align: right;
    padding-top: 0.25rem;
    font-size: 0.75rem;
    color: #63666a;
    padding-right: 10%;

    &--saveSearchList {
      padding-right: 4.5%;
    }
  }

  &__contentError {
    padding-top: 0.25rem;
    font-size: 0.75rem;
    color: #f54242;
    padding-left: 5%;
    &--saveSearchList {
      padding-left: 8.5%;
    }
  }

  &__options {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
    padding-top: 1rem;
    text-align: right;

    &--saveSearchList {
      padding-top: 2rem;
    }

    &--deleteSearch {
      padding-top: 2rem;
    }
  }

  &__continue {
    margin-left: 1rem;

    &-disabled {
      background-color: $grey;
    }
  }
  &__description {
    &.EditModal__description {
      font-family: inherit;
      height: 8.5rem;
      resize: none;
    }

    &-label {
      margin-top: 1rem;
    }
  }
}

.SaveSearchList__btn {
  &.SaveSearchList__btn {
    padding: 0.5rem 1.5rem;
  }
}
